/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-alert */
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import uploadPattern from '../../../img/icon_upload_blue.svg';
import UploadSelect from './UploadSelect/UploadSelect';
import UploadNewPattern from './UploadNewPattern';
import uploadPatternStyles from './UploadPattern.module.scss';
import UploadCoverage from './UploadCoverage/UploadCoverage';

import { findMissingId, isValidTags } from '../../../utils/function';
import VehicleSelection from './VehicleSelection/VehicleSelection';
import {
  createPattern,
  editMyPattern,
  getPatternById
} from '../../../api/patterns';
import { getCategories } from '../../../api/categories';

import { getFloatVariables, uploadFile } from '../../../api/utils';
import { useAlert } from '../../hooks/useAlert/useAlert';

function UploadPattern({ editMode }) {
  const { patternId } = useParams();
  const navigate = useNavigate();
  const { onAlert } = useAlert();
  // let newPatKey = +sessionStorage.getItem('newPatKey') || 1;

  const patternLimit = 5;
  const [patternToRemove, setPatternToRemove] = useState('');

  // const [largeCategoriesIdMapping, setLargeCategoriesIdMapping] = useState({});
  // const [largeCategoriesParentMapping, setLargeCategoriesParentMapping] =
  //   useState([]);

  const [largeCategories, setLargeCategories] = useState([]);
  const [largeCategoryFilterOptions, setLargeCategoryFilterOptions] = useState({
    // region: [],
    // year: [],
    // make: [],
    // model: [],
    // subModel: [],
    // series: []
  });
  const [subCategories, setSubCategories] = useState([]);

  const [patternToEdit, setPatternToEdit] = useState(null);

  const [patternPricePerArea, setPatternPricePerArea] = useState([]);

  // general newPattern data states
  // const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSubModel, setSelectedSubModel] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);

  // selected Sub-Category part and sub-part state
  const [newPatternsParams, setNewPatternsParams] = useState([
    // {
    //   id: id,
    //   part: part,
    //   subPart: {id: subPartId, name: subPartName}
    // }
  ]);

  // const mapData = (categories) => {
  //   for (const category of categories) {
  //     setLargeCategoriesIdMapping((prevMapping) => ({
  //       ...prevMapping,
  //       [category.id]: category
  //     }));

  //     // setLargeCategoriesParentMapping((prevMapping) => [
  //     //   ...prevMapping,
  //     //   [category.patternId].push(category)
  //     // ]);

  //     mapData(category.subCategory);
  //   }
  // };

  const fetchPatternCategories = async () => {
    const patternCategoriesResponse = await getCategories();

    const largePatternCategories = patternCategoriesResponse.filter(
      (category) => category.column === 'region' && category.type === 'vehicle'
    );

    // mapData(largePatternCategories);

    setLargeCategories(largePatternCategories);

    setSubCategories(
      patternCategoriesResponse
        .filter(
          (category) =>
            category.column === 'part_section' && category.type === 'category'
        )
        .sort((categoryA, categoryB) => categoryA.order - categoryB.order)
    );
  };

  const fetchPatternPricePerArea = async () => {
    const patternPicePerAreaRes = await getFloatVariables('pattern_unit_price');

    setPatternPricePerArea(patternPicePerAreaRes);
  };

  const fetchCurrentPattern = async (currentPatternId) => {
    const currentPattern = await getPatternById(currentPatternId);

    setPatternToEdit(currentPattern);

    setNewPatternsParams([
      {
        id: currentPattern.patternId,
        part: subCategories.reduce((currentPart, category) => {
          if (category?.id === currentPattern?.vehicles[0]?.id) {
            currentPart = category;
          }

          return currentPart;
        }, {}),
        subPart: currentPattern?.vehicles[1],
        pricePerArea: currentPattern?.unitPrice
          ? +Number(currentPattern.unitPrice).toFixed(1)
          : 0,
        patternImg: currentPattern.svg,
        descr: currentPattern.description,
        tags: currentPattern?.tags?.split(',')
      }
    ]);
  };

  const uploadNewPattern = () => {
    setNewPatternsParams((currentParams) => [
      ...currentParams,
      {
        id: findMissingId(currentParams) || currentParams.length + 1
      }
    ]);
  };

  const handleApprovalRequest = async () => {
    if (editMode) {
      let updatedPatternData = {};
      let updatePatternRes = null;

      updatedPatternData = {
        modelId:
          selectedSeries?.id ||
          selectedSubModel?.id ||
          selectedModel?.id ||
          selectedMake?.id ||
          selectedYear?.id ||
          selectedRegion?.id ||
          null,
        unitPrice: newPatternsParams[0].pricePerArea,
        description: newPatternsParams[0].descr,
        tags: newPatternsParams[0].tags.join(', ')
      };

      if (!isValidTags(newPatternsParams[0].tags)) {
        onAlert('', 'Okay', 'Tag must contain more than 3 symbols.');
      }

      updatePatternRes = await editMyPattern(patternId, updatedPatternData);

      if (updatePatternRes?.errorStatus) {
        onAlert(
          '',
          'Okay',
          `Something went wrong while uploading ${newPatternsParams[0].id}. Please try again.`
        );
        return;
      }

      onAlert('', 'Okay', 'All patterns were successfully uploaded!');
      navigate('/sell/pattern/mypattern');
      return;
    }

    if (selectedModel === null || selectedModel === undefined) {
      onAlert('', 'Okay', 'Please choose pattern categories up to "Model".');
      return;
    }

    newPatternsParams.every(async (newPatternParam, index) => {
      let newPatternData = {};
      let patternImgSrc = null;
      let createPatternRes = null;
      const patternImg = new FormData();

      if (
        newPatternParam.patternImg !== undefined &&
        newPatternParam.patternImg !== null
      ) {
        patternImg.append(
          'file',
          newPatternParam.patternImg,
          newPatternParam.patternImg?.name
        );

        patternImgSrc = await uploadFile(patternImg);
      }

      newPatternData = {
        modelId:
          selectedSeries?.id ||
          selectedSubModel?.id ||
          selectedModel?.id ||
          selectedMake?.id ||
          selectedYear?.id ||
          selectedRegion?.id ||
          null,
        vehicleId:
          newPatternParam.subPart?.id ||
          newPatternParam.part?.id ||
          newPatternParam.section?.id,
        unitPrice: newPatternParam.pricePerArea,
        svg: patternImgSrc,
        description: newPatternParam.descr,
        tags: newPatternParam.tags.join(', '),
        vehicleType: selectedVehicleType
      };

      if (!isValidTags(newPatternParam.tags)) {
        onAlert('', 'Okay', 'Tag must contain more than 3 symbols.');
      }

      if (
        newPatternData?.modelId === null ||
        newPatternData?.modelId === undefined
      ) {
        onAlert('', 'Okay', 'Choose the pattern categories.');
        return;
      }

      if (
        newPatternData?.vehicleType === null ||
        newPatternData?.vehicleType === undefined
      ) {
        onAlert('', 'Okay', 'Choose vehicle selection.');
        return;
      }

      const errorSubcategory =
        !newPatternParam.section?.id ||
        (newPatternParam.section.subCategory.length > 0 &&
          !newPatternParam.part?.id) ||
        (newPatternParam.part.subCategory.length > 0 &&
          !newPatternParam.subPart?.id);

      // if (
      //   newPatternData?.vehicleId === null ||
      //   newPatternData?.vehicleId === undefined
      // ) {
      //   alert('Choose the pattern sub categories.');
      //   return;
      // }

      if (errorSubcategory) {
        onAlert('', 'Okay', 'Choose the pattern sub categories.');
        return;
      }

      if (
        newPatternData?.unitPrice === null ||
        newPatternData?.unitPrice === undefined
      ) {
        onAlert('', 'Okay', 'Choose the price per Sq.ft.');
        return;
      }

      if (newPatternData?.svg === null || newPatternData?.svg === undefined) {
        onAlert('', 'Okay', 'Choose the File Upload.');
        return;
      }

      createPatternRes = await createPattern(newPatternData);

      if (createPatternRes?.errorStatus) {
        onAlert(
          '',
          'Okay',
          `Something went wrong while uploading ${newPatternParam.id}. Please try again.`
        );
        return false;
      }

      if (index === newPatternsParams.length - 1) {
        onAlert('', 'Okay', 'All patterns were successfully uploaded!');
        navigate('/sell/pattern/mypattern');

        return false;
      }

      return true;
    });
  };

  useEffect(() => {
    if (editMode && largeCategories.length && patternToEdit !== null) {
      setSelectedRegion({
        id: patternToEdit?.models[0]?.id,
        value: patternToEdit?.models[0]?.category
        // subCategory:
        //   largeCategoriesIdMapping[patternToEdit?.models[0]?.id]?.subCategory
      });
      setSelectedYear({
        id: patternToEdit?.models[1]?.id,
        value: patternToEdit?.models[1]?.category
        // subCategory:
        //   largeCategoriesIdMapping[patternToEdit?.models[1]?.id]?.subCategory
      });
      setSelectedMake({
        id: patternToEdit?.models[2]?.id,
        value: patternToEdit?.models[2]?.category
        // subCategory:
        //   largeCategoriesIdMapping[patternToEdit?.models[2]?.id]?.subCategory
      });
      setSelectedModel({
        id: patternToEdit?.models[3]?.id,
        value: patternToEdit?.models[3]?.category
        // subCategory:
        //   largeCategoriesIdMapping[patternToEdit?.models[3]?.id]?.subCategory
      });
      setSelectedSubModel({
        id: patternToEdit?.models[4]?.id,
        value: patternToEdit?.models[4]?.category
        // subCategory:
        //   largeCategoriesIdMapping[patternToEdit?.models[4]?.id]?.subCategory
      });
      setSelectedSeries({
        id: patternToEdit?.models[5]?.id,
        value: patternToEdit?.models[5]?.category
      });
    }

    setLargeCategoryFilterOptions((currentState) => ({
      ...currentState,
      region: largeCategories.map((category) => ({
        id: category.id,
        value: category.category,
        subCategory: category.subCategory
      }))
    }));
  }, [largeCategories, patternToEdit]);

  useEffect(() => {
    fetchPatternCategories();
    fetchPatternPricePerArea();
  }, []);

  useEffect(() => {
    if (editMode && patternId && subCategories.length) {
      fetchCurrentPattern(patternId);
    }
  }, [subCategories]);

  useEffect(() => {
    setNewPatternsParams(
      newPatternsParams.filter(
        (newPatParams) => newPatParams.id !== patternToRemove
      )
    );
  }, [patternToRemove]);

  const useLagreCategoryFilterEffect = (option, parentValue) => {
    useEffect(() => {
      if (parentValue) {
        setLargeCategoryFilterOptions((currentState) => ({
          ...currentState,
          [option]: parentValue?.subCategory?.map((subCategory) => ({
            id: subCategory.id,
            value: subCategory.category,
            subCategory: subCategory.subCategory
          }))
        }));
      }
    }, [parentValue]);
  };

  useLagreCategoryFilterEffect('year', selectedRegion);
  useLagreCategoryFilterEffect('make', selectedYear);
  useLagreCategoryFilterEffect('model', selectedMake);
  useLagreCategoryFilterEffect('subModel', selectedModel);
  useLagreCategoryFilterEffect('series', selectedSubModel);

  useEffect(() => {
    console.log(newPatternsParams);
  }, [newPatternsParams]);

  // useEffect(() => {
  //   console.log('idMapping:', largeCategoriesIdMapping);
  // }, [largeCategoriesIdMapping]);

  return (
    <section className={uploadPatternStyles.upload_pattern}>
      <div>
        <div className={uploadPatternStyles.upload_title_text}>
          <h1 className={uploadPatternStyles.title}>
            {editMode ? 'edit' : 'pattern upload'}
          </h1>
          {!editMode && (
            <p className={uploadPatternStyles.subtitle}>
              You can upload up&nbsp;to&nbsp;5&nbsp;patterns for the selected
              car model.
            </p>
          )}
        </div>
        <div className={uploadPatternStyles.upload_container}>
          <div className={uploadPatternStyles.category_upper}>
            <div className={uploadPatternStyles.category_title}>
              <h2>Category</h2>
              <p>※ Please select all the required fields</p>
            </div>
            <Link to="/sell/register">REGISTER A NEW VEHICLE MODEL</Link>
          </div>
          <form className={uploadPatternStyles.upload_selectors}>
            <UploadSelect
              selectName="region"
              options={largeCategoryFilterOptions.region || []}
              selectedValue={selectedRegion}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedRegion(currentValue);
                setSelectedYear(null);
                setSelectedMake(null);
                setSelectedModel(null);
                setSelectedSubModel(null);
                setSelectedSeries(null);
              }}
            />
            <UploadSelect
              selectName="year"
              options={largeCategoryFilterOptions.year || []}
              selectedValue={selectedYear}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedYear(currentValue);
                setSelectedMake(null);
                setSelectedModel(null);
                setSelectedSubModel(null);
                setSelectedSeries(null);
              }}
              disabled={!largeCategoryFilterOptions.year}
            />
            <UploadSelect
              selectName="make"
              options={largeCategoryFilterOptions.make || []}
              selectedValue={selectedMake}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedMake(currentValue);
                setSelectedModel(null);
                setSelectedSubModel(null);
                setSelectedSeries(null);
              }}
              disabled={!largeCategoryFilterOptions.make}
            />
            <UploadSelect
              selectName="model"
              options={largeCategoryFilterOptions.model || []}
              selectedValue={selectedModel}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedModel(currentValue);
                setSelectedSubModel(null);
                setSelectedSeries(null);
              }}
              disabled={!largeCategoryFilterOptions.model}
            />
            <UploadSelect
              selectName="sub-model"
              options={largeCategoryFilterOptions.subModel || []}
              selectedValue={selectedSubModel}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedSubModel(currentValue);
                setSelectedSeries(null);
              }}
              disabled={!largeCategoryFilterOptions.subModel}
            />
            <UploadSelect
              selectName="series"
              options={largeCategoryFilterOptions.series || []}
              selectedValue={selectedSeries}
              editMode={editMode}
              onChange={(e) => {
                const currentValue = JSON.parse(e.target.value);

                setSelectedSeries(currentValue);
              }}
              disabled={!largeCategoryFilterOptions.series}
            />
          </form>
          {!editMode && (
            <VehicleSelection
              selectedVehicleType={selectedVehicleType}
              setSelectedVehicleType={setSelectedVehicleType}
            />
          )}

          {newPatternsParams.map((newPatParams) => (
            <UploadNewPattern
              key={newPatParams.id}
              id={newPatParams.id}
              defaultName={`pattern ${newPatParams.id}.`}
              setPatternToRemove={setPatternToRemove}
              subCategories={subCategories}
              patternPricePerArea={patternPricePerArea}
              newPatParams={newPatParams}
              setNewPatternsParams={setNewPatternsParams}
              editMode={editMode}
              selectedVehicleType={selectedVehicleType}
            />
          ))}
          {newPatternsParams.length < patternLimit && !editMode && (
            <button
              className={uploadPatternStyles.upload_btn}
              type="button"
              onClick={uploadNewPattern}>
              <img src={uploadPattern} alt="" />
              Add Pattern
            </button>
          )}
          {!editMode && selectedVehicleType?.length && (
            <UploadCoverage
              vehicleType={selectedVehicleType}
              newPatternsParams={newPatternsParams}
            />
          )}
          <button
            onClick={handleApprovalRequest}
            className={uploadPatternStyles.approval_btn}
            type="button">
            Request Approval
          </button>
        </div>
      </div>
    </section>
  );
}

export default UploadPattern;
