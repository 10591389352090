/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import patternImg from '../../../img/pattern.png';
import writeImg from '../../../img/icon_write.svg';
import listItemStyles from './PatternListItem.module.scss';
import MyPageReviewModal from '../MyPageReviewModal/MyPageReviewModal';
import {
  renderDate,
  filterByColumn,
  renderNumberToFixed
} from '../../../utils/function';
import { useAlert } from '../../hooks/useAlert/useAlert';

function PatternListItem({ pattern, setTimeRecall, isPlottingPage }) {
  const navigate = useNavigate();
  const [reviewIsOpen, setReviewIsOpen] = useState(false);

  const { onAlert } = useAlert();

  const handleDetailsNavigate = () => {
    if (pattern?.status === 'stop_selling') {
      // onAlert('', 'Okay', 'This Pattern is no longer available');
      onAlert('', 'Okay', 'This pattern was deleted by the uploader.');
      return;
    }
    if (
      (pattern?.deletedAt || pattern?.status !== 'approved') &&
      pattern?.status !== 'stop_selling'
    ) {
      onAlert('', 'Okay', 'This pattern was deleted by the uploader.');
      return;
    }

    navigate(`/pattern/patternId/${pattern.id}`);
  };

  return (
    <>
      <div
        onClick={handleDetailsNavigate}
        className={listItemStyles.item_container}>
        <img
          className={listItemStyles.item_pattern}
          src={pattern?.svg || patternImg}
          alt=""
        />
        <div className={listItemStyles.item_content}>
          <p className={listItemStyles.item_date}>
            Plotted Date : {renderDate(pattern.plottedAt)}
          </p>
          <h3 className={listItemStyles.item_title}>
            {pattern?.models?.length &&
              `${
                filterByColumn(pattern?.models, 'region').length
                  ? `${filterByColumn(pattern?.models, 'region')} >`
                  : ''
              } ${
                filterByColumn(pattern?.models, 'year').length
                  ? `${filterByColumn(pattern?.models, 'year')} >`
                  : ''
              } ${
                filterByColumn(pattern?.models, 'brand').length
                  ? `${filterByColumn(pattern?.models, 'brand')} >`
                  : ''
              } ${
                filterByColumn(pattern?.models, 'model').length
                  ? `${filterByColumn(pattern?.models, 'model')} >`
                  : ''
              } ${
                filterByColumn(pattern?.models, 'sub_model').length
                  ? `${filterByColumn(pattern?.models, 'sub_model')} >`
                  : ''
              } ${
                filterByColumn(pattern?.models, 'series').length
                  ? `${filterByColumn(pattern?.models, 'series')}`
                  : ''
              }`}
          </h3>
          {!isPlottingPage && (
            <div className={listItemStyles.item_rating}>
              <Rating
                ratingValue={pattern?.ratingAvg && +pattern.ratingAvg * 20}
                readonly
                size={20}
                emptyColor="#ededed"
                fillColor="#ffd500"
              />
            </div>
          )}
          <ul className={listItemStyles.item_stats}>
            <li>
              <p>Area</p>{' '}
              {pattern?.sqft
                ? `${renderNumberToFixed(
                    pattern.sqft,
                    1
                  )} Sq.ft (${renderNumberToFixed(
                    pattern.sqft,
                    0.09290304
                  )} Sq.m)`
                : '0 Sq.ft (0 Sq.m)'}
            </li>
            <li>
              <p>Price per Area</p>{' '}
              {pattern?.unitPrice
                ? `${renderNumberToFixed(
                    pattern.unitPrice,
                    1
                  )} Credits (per Sq.ft)`
                : '0 Credits (per Sq.ft'}
            </li>
            <li>
              <p>Downloaded</p> {pattern?.downloads || 0}
            </li>
          </ul>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (pattern?.status === 'stop_selling') {
              // onAlert('', 'Okay', 'This Pattern is no longer available');
              onAlert('', 'Okay', 'This pattern was deleted by the uploader.');
              return;
            }
            if (
              (pattern?.deletedAt || pattern?.status !== 'approved') &&
              pattern?.status !== 'stop_selling'
            ) {
              onAlert('', 'Okay', 'This pattern was deleted by the uploader.');
              return;
            }
            setReviewIsOpen(true);
          }}
          className={listItemStyles.item_review_btn}
          type="button"
          style={
            pattern?.reviewed
              ? {
                  color: '#222',
                  textDecoration: 'underline'
                }
              : { color: '#fd6908' }
          }>
          {pattern?.reviewed ? (
            'Check your review'
          ) : (
            <>
              <img src={writeImg} alt="" />
              Write a review
            </>
          )}
        </button>
      </div>
      {reviewIsOpen && (
        <MyPageReviewModal
          setReviewIsOpen={setReviewIsOpen}
          pattern={pattern}
          setTimeRecall={setTimeRecall}
        />
      )}
    </>
  );
}

export default PatternListItem;
