import patternHistoryPopUpStyles from './PatternHistoryPopUp.module.scss';

// setReviewIsOpen
function PatternHistoryPopUp({ setPopupIsOpen }) {
  const handleRateClick = () => {
    setPopupIsOpen(false);
    // setReviewIsOpen(true);
  };

  return (
    <div className={patternHistoryPopUpStyles.popup_wrapper}>
      <div className={patternHistoryPopUpStyles.popup_content}>
        <h1 className={patternHistoryPopUpStyles.popup_title}>Review</h1>
        <p className={patternHistoryPopUpStyles.popup_descr}>
          Look like you haven&rsquo;t rated previously plotted pattern. Would
          you like to&nbsp;rate now?
        </p>
        <hr />
        <div className={patternHistoryPopUpStyles.controls}>
          <button
            className={patternHistoryPopUpStyles.controls_cancel}
            onClick={() => setPopupIsOpen(false)}
            type="button">
            cancel
          </button>
          <button
            className={patternHistoryPopUpStyles.controls_rate}
            onClick={handleRateClick}
            type="button">
            rate now
          </button>
        </div>
      </div>
    </div>
  );
}

export default PatternHistoryPopUp;
