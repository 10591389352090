import { useState, memo } from 'react';
import filterStyles from './ShopDropdownFilter.module.scss';
import arrowImg from '../../../img/dropdown_arrow.svg';

function ShopDropdownSort({ value, onSelect, allTitle }) {
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  const handleFilterChange = (e) => {
    onSelect(e.target.value);
    setSelectorIsOpen(!selectorIsOpen);
  };

  return (
    <div className={filterStyles.filter_container}>
      <p className={filterStyles.filter_selected}>{allTitle}</p>
      <div className={filterStyles.filter_wrapper}>
        <button
          onClick={() => setSelectorIsOpen(!selectorIsOpen)}
          type="button">
          <p>{value === 'newest' ? 'Latest' : value}</p>
          <img
            src={arrowImg}
            alt="arrow"
            style={
              selectorIsOpen
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            }
          />
        </button>
        {selectorIsOpen && (
          <label htmlFor="filter">
            <select onChange={handleFilterChange} name="filter" multiple>
              <option value="popularity">Popularity</option>
              <option value="newest">Latest</option>
            </select>
          </label>
        )}
      </div>
    </div>
  );
}

export default memo(ShopDropdownSort);
