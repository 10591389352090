import { useState } from 'react';
import uploadPatternStyles from './UploadSelect.module.scss';
import dropdownArrow from '../../../../img/dropdown_arrow.svg';

function UploadSelect({
  selectName,
  options,
  disabled,
  selectedValue,
  editMode,
  onChange
}) {
  const [isOpened, setIsOpened] = useState(false);

  // const handleChange = (e) => {
  //   const currentValue = JSON.parse(e.target.value);

  //   setSelectedValue(currentValue);
  // };

  return (
    <label className={uploadPatternStyles.selector_label} htmlFor={selectName}>
      <p>{selectName}</p>
      <button
        type="button"
        onClick={() => setIsOpened(!isOpened)}
        disabled={editMode || disabled}>
        <span>
          {(selectedValue && selectedValue.value) ||
            (editMode ? '' : `Select ${selectName}`)}
        </span>
        {!editMode && <img src={dropdownArrow} alt="arrow" />}
        {isOpened && options.length > 0 && (
          <select onChange={onChange} name={selectName} multiple>
            {options
              .sort((optionA, optionB) => {
                const optionValueA = optionA.value.toUpperCase();
                const optionValueB = optionB.value.toUpperCase();
                if (optionValueA < optionValueB) {
                  return -1;
                }
                if (optionValueA > optionValueB) {
                  return 1;
                }

                return 0;
              })
              .map((option) => (
                <option key={option.id} value={JSON.stringify(option)}>
                  {option.value}
                </option>
              ))}
          </select>
        )}
      </button>
    </label>
  );
}

export default UploadSelect;
