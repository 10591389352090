import { Outlet } from 'react-router-dom';
import sellPageStyles from './SellPage.module.scss';

function SellPage() {
  return (
    <main className={sellPageStyles.sellpage_main}>
      <Outlet />
    </main>
  );
}

export default SellPage;
