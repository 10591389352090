import linkCardStyles from './SupportLinkCard.module.scss';
import iconCountryBlue from '../../../img/icon_country_blue.svg';
import iconCountryWhite from '../../../img/icon_country_white.svg';

function SupportLinkCard({ text, onSelect, selected }) {
  return (
    <button
      style={{
        color: selected === text ? 'white' : 'inherit',
        background: selected === text ? 'rgb(0, 70, 166)' : 'inherit'
      }}
      type="button"
      className={linkCardStyles.support_link_card_container}
      onClick={() => onSelect(text)}>
      <img
        src={selected === text ? iconCountryWhite : iconCountryBlue}
        alt="icon"
      />
      <span style={{ color: selected === text ? 'white' : 'inherit' }}>
        {text}
      </span>
    </button>
  );
}

export default SupportLinkCard;
