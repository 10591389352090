/* eslint-disable no-restricted-syntax */
import { useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
// import { patternsSearch, favouritePattern } from './api/api';
// import { useCookies } from 'react-cookie';
// import Cookies from 'react-cookie/cjs/Cookies';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MainPage from './components/MainPage/MainPage';
import LogInForm from './components/Forms/LogInForm/LogInForm';
import FindPass from './components/Forms/FindPass/FindPass';
import NewPass from './components/Forms/NewPass/NewPass';
import SignUpForm from './components/Forms/SignUpForm/SignUpForm';
import PatternPage from './components/PatternPage/PatternPage';
import Pricing from './components/Pricing/Pricing';
import PricingCreditPacksWrap from './components/Pricing/PricingCreditPacks/PricingCreditPacksWrap';
import PricingSubscription from './components/Pricing/PricingSubscription/PricingSubscription';
import Pattern from './components/PatternPage/Pattern';
import PatternDetails from './components/PatternDetails/PatternDetails';
import UploaderPage from './components/UploaderPage/UploaderPage';
import SellPattern from './components/SellPage/SellPattern/SellPattern';
import SellPage from './components/SellPage/SellPage';
import UploadApplication from './components/SellPage/UploadApplication/UploadApplication';
import MyPattern from './components/SellPage/SellPattern/SellPatternOutlets/MyPattern/MyPattern';
import Sales from './components/SellPage/SellPattern/SellPatternOutlets/Sales/Sales';
import ApprovalStatus from './components/SellPage/SellPattern/SellPatternOutlets/ApprovalStatus/ApprovalStatus';
import SponsorADItem from './components/SellPage/SellPattern/SellPatternOutlets/SponsorADItem/SponsorADItem';
import PayoutRequest from './components/SellPage/SellPattern/SellPatternOutlets/PayoutRequest/PayoutRequest';
import TransactionHistory from './components/SellPage/SellPattern/SellPatternOutlets/TransactionHistory/TransactionHistory';
import UploadPattern from './components/SellPage/UploadPattern/UploadPattern';
import RegisterNewCar from './components/SellPage/RegisterNewCar/RegisterNewCar';
import Bookmark from './components/MyPage/Bookmarks/Bookmark';
import MyProject from './components/MyPage/MyProject/MyProject';
import PatternHistory from './components/MyPage/PatternHistory/PatternHistory';
import UltrafitShop from './components/UltrafitShop/UltrafitShop';
import ShopMainPage from './components/UltrafitShop/ShopMainPage/ShopMainPage';
import ShopProductDetails from './components/UltrafitShop/ShopProductDatails/ShopProductDetails';
import ShopDetailsInfo from './components/UltrafitShop/ShopProductDatails/ShopDetailsInfo/ShopDetailsInfo';
import ShopDetailsReviews from './components/UltrafitShop/ShopProductDatails/ShopDetailsReviews/ShopDetailsReviews';
import ShopCart from './components/UltrafitShop/ShopCart/ShopCart';
import './App.scss';
import ShopOrder from './components/UltrafitShop/ShopOrder/ShopOrder';
import OrderHistory from './components/MyPage/OrderHistory/OrderHistory';
import PersonalInformation from './components/MyPage/PersonalInformation/PersonalInformation';
import EditPersonalInformation from './components/MyPage/PersonalInformation/EditPersonalInformation/EditPersonalInformation';
import Withdrawal from './components/MyPage/PersonalInformation/Withdrawal/Withdrawal';
import ShopCoupon from './components/MyPage/ShopCoupon/ShopCoupon';
import BonusCredit from './components/MyPage/BonusCredit/BonusCredit';
import CreditHistory from './components/MyPage/CreditHistory/CreditHistory';
import 'react-toastify/dist/ReactToastify.css';
import SupportPage from './components/SupportPage/SupportPage';
import CreditSubProblem from './components/SupportPage/SupportPageOutlets/CreditsSubProblem';
import BillingPaymentReceipt from './components/SupportPage/SupportPageOutlets/BillingPaymentReceipt';
import UploadSupport from './components/SupportPage/SupportPageOutlets/UploadSupport';
import DownloadPlotting from './components/SupportPage/SupportPageOutlets/DownloadPlotting';
import AccountManagement from './components/SupportPage/SupportPageOutlets/AccountManagement';
import EditorSubscriptionHistory from './components/MyPage/EditorSubscriptionHistory/EditorSubscriptionHistory';
import OtherProblems from './components/SupportPage/SupportPageOutlets/OtherProblems';
import ContactUs from './components/ContactUs/ContactUs';
import Notifications from './components/Notifications/Notifications';
import SignUpFormSNS from './components/Forms/SignUpForm/SignUpFormSNS';
import TermsAndPolicy from './components/TermsAndPolicy/TermsAndPolicy';
import PrivacyPolicy from './components/TermsAndPolicy/PrivacyPolicy';
import TermsConditions from './components/TermsAndPolicy/TermsConditions';
import MyProjectMain from './components/MyPage/MyProject/MyProjectMain';
import MyProjectList from './components/MyPage/MyProject/MyProjectList';

import { getMyProfile } from './api/profile';

import { UserProfileContext, LoginStateContext } from './store/Context';
import {
  getGiftNotification,
  setSeenNotification,
  getUploaderNotification,
  getNewestNotification
} from './api/notifications';
import { AlertProvider } from './components/hooks/useAlert/useAlert';
import { useStore } from './store/hooks';
// import './calendar.scss';

function App() {
  const [loginState, setLoginState] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [state] = useStore();

  const fetchGiftNotifications = async () => {
    try {
      const { data = [] } = await getGiftNotification();
      if (data?.length) {
        for await (const d of data) {
          const { content, id, status } = d || '';
          if (status === 'unread') {
            toast(content, {
              type: 'success'
            });
            await setSeenNotification(id);
          }
        }
      }
    } catch (e) {
      //
    }
  };

  const fetchUploaderNotifications = async () => {
    try {
      const { data = [] } = await getUploaderNotification();
      if (data?.length) {
        for await (const d of data) {
          const { content, id, status } = d || '';
          if (status === 'unread') {
            toast(content, {
              type: 'success'
            });
            await setSeenNotification(id);
          }
        }
      }
    } catch (e) {
      //
    }
  };

  const fetchNewestNotifications = async () => {
    if (!window.localStorage.getItem('token')) {
      return;
    }
    try {
      const { data = [] } = await getNewestNotification();
      if (data?.length) {
        for await (const d of data) {
          const { content, id, status } = d || '';
          if (status === 'unread') {
            toast(content, {
              type: 'success'
            });
            await setSeenNotification(id);
          }
        }
      }
    } catch (e) {
      //
    }
  };

  const userProfileValue = useMemo(
    () => ({ userProfile, setUserProfile }),
    [userProfile]
  );

  const fetchUserData = async (isForce = false) => {
    if (!window.localStorage.getItem('token')) {
      return;
    }
    try {
      if ((loginState === null && userProfile === null) || isForce) {
        const userProfileRes = await getMyProfile();

        setUserProfile({ ...userProfileRes });

        setLoginState({
          isLoggedIn: true,
          nickname: userProfileRes.nickName,
          email: userProfileRes.email,
          id: userProfileRes.id
        });

        return;
      }

      if (
        loginState !== null &&
        userProfile === null &&
        loginState.isLoggedIn
      ) {
        const userProfileRes = await getMyProfile();

        setUserProfile(userProfileRes);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [loginState, state.timeGetCreditBalance]);

  useEffect(() => {
    fetchUserData(true);
  }, [state.timeGetCreditBalance]);

  useEffect(() => {
    const intervalFetchNotification = setInterval(() => {
      // fetchGiftNotifications();
      // fetchUploaderNotifications();
      fetchNewestNotifications();
    }, 5000);
    return () => {
      clearInterval(intervalFetchNotification);
    };
  }, []);

  return (
    <UserProfileContext.Provider value={userProfileValue}>
      <LoginStateContext.Provider value={loginState}>
        <AlertProvider>
          <BrowserRouter>
            <div id="App">
              <Header
                onLogOut={() => {
                  setLoginState(null);
                  setUserProfile(null);
                }}
              />
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <MainPage
                      loginState={loginState}
                      onFreeTrial={setUserProfile}
                    />
                  }
                />
                <Route
                  path="/auth"
                  element={<LogInForm onLogin={setLoginState} />}
                />
                <Route path="/find_pass" element={<FindPass />} />
                <Route path="/new_pass" element={<NewPass />} />
                <Route
                  path="/signup"
                  element={<SignUpForm onLogin={setLoginState} />}
                />
                <Route
                  path="/signup-sns"
                  element={<SignUpFormSNS onLogin={setLoginState} />}
                />
                <Route path="/pattern" element={<Pattern />}>
                  <Route path="list" element={<PatternPage />} />
                  <Route
                    path="patternId/:patternId"
                    element={<PatternDetails />}
                  />
                  <Route
                    path="uploaderId/:uploaderId"
                    element={<UploaderPage />}
                  />
                  <Route
                    path="uploaderId/:uploaderId/patternId/:patternId"
                    element={<PatternDetails isUploaderPage />}
                  />
                </Route>
                <Route path="/pricing" element={<Pricing />}>
                  <Route path="packs" element={<PricingCreditPacksWrap />} />
                  <Route
                    path="subscription"
                    element={
                      <PricingSubscription
                        updateProfileOnPayment={setUserProfile}
                      />
                    }
                  />
                </Route>
                <Route path="/support" element={<SupportPage />}>
                  <Route path="credit&sub" element={<CreditSubProblem />} />
                  <Route
                    path="billing&payment&receipt"
                    element={<BillingPaymentReceipt />}
                  />
                  <Route path="upload" element={<UploadSupport />} />
                  <Route
                    path="download&plotting"
                    element={<DownloadPlotting />}
                  />
                  <Route path="account" element={<AccountManagement />} />
                  <Route path="other" element={<OtherProblems />} />
                </Route>
                <Route path="/shop" element={<UltrafitShop />}>
                  <Route path="" element={<ShopMainPage />} />
                  <Route path="cart" element={<ShopCart />} />
                  <Route path="order" element={<ShopOrder />} />
                  <Route path=":productId" element={<ShopProductDetails />}>
                    <Route path="info" element={<ShopDetailsInfo />} />
                    <Route path="reviews" element={<ShopDetailsReviews />} />
                  </Route>
                </Route>
                <Route path="/sell" element={<SellPage />}>
                  <Route path="pattern" element={<SellPattern />}>
                    <Route
                      path="mypattern"
                      element={<MyPattern onProfileUpdate={setUserProfile} />}
                    />
                    <Route path="sales" element={<Sales />} />
                    <Route path="status" element={<ApprovalStatus />} />
                    <Route path="sponsor" element={<SponsorADItem />} />
                    <Route path="payout" element={<PayoutRequest />} />
                    <Route
                      path="transaction_history"
                      element={<TransactionHistory />}
                    />
                  </Route>
                  <Route
                    path="patternId/:patternId"
                    element={<PatternDetails isMyPatternsPage />}
                  />
                  <Route
                    path="edit/:patternId"
                    element={<UploadPattern editMode />}
                  />
                  <Route path="upload" element={<UploadPattern />} />
                  <Route path="register" element={<RegisterNewCar />} />
                  <Route
                    path="application"
                    element={
                      <UploadApplication updateUserProfile={setUserProfile} />
                    }
                  />
                </Route>
                <Route path="bookmark" element={<Bookmark />} />
                <Route path="myproject" element={<MyProject />}>
                  <Route path="main" element={<MyProjectMain />} />
                  <Route path="list/:id" element={<MyProjectList />} />
                </Route>
                <Route path="pattern_history" element={<PatternHistory />} />
                <Route path="order_history" element={<OrderHistory />} />
                <Route
                  path="/personal_information"
                  element={<PersonalInformation />}>
                  <Route
                    path="edit_personal_information"
                    element={
                      <EditPersonalInformation
                        onProfileUpdate={setUserProfile}
                      />
                    }
                  />
                  <Route
                    path="withdrawal"
                    element={
                      <Withdrawal
                        resetUserData={() => {
                          setLoginState(null);
                          setUserProfile(null);
                        }}
                      />
                    }
                  />
                </Route>
                <Route path="order_history" element={<OrderHistory />} />
                <Route path="shop_coupon" element={<ShopCoupon />} />
                <Route
                  path="editor_subscription_history"
                  element={<EditorSubscriptionHistory />}
                />
                <Route
                  path="bonus_credit"
                  element={
                    <BonusCredit
                      onBonusExcharge={(updatedUserProfile) =>
                        setUserProfile(updatedUserProfile)
                      }
                    />
                  }
                />
                <Route path="credit_history" element={<CreditHistory />} />
                <Route path="contact_us" element={<ContactUs />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="terms_and_policy" element={<TermsAndPolicy />}>
                  <Route path="policy" element={<PrivacyPolicy />} />
                  <Route path="terms" element={<TermsConditions />} />
                </Route>
              </Routes>
              <Footer />
            </div>
          </BrowserRouter>
          <ToastContainer />
        </AlertProvider>
      </LoginStateContext.Provider>
    </UserProfileContext.Provider>
  );
}

export default App;
