/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateMyProfile } from '../../../../../../api/profile';
import myPatternStyles from '../MyPattern.module.scss';
import profilePicture from '../../../../../../img/icon_account.svg';
import uploadsIcon from '../../../../../../img/icon_upload.svg';
import reviewsIcon from '../../../../../../img/icon_review.svg';
import downloadsIcon from '../../../../../../img/icon_download.svg';
import earningsIcon from '../../../../../../img/icon_earning.svg';
import enterArrow from '../../../../../../img/icon_enter.svg';
import { setRank, renderNickname } from '../../../../../../utils/function';

function MyPatternProfile({ profile, onProfileUpdate }) {
  const navigate = useNavigate();
  const [introBtnClicked, setIntroBtnClicked] = useState(false);
  const [introduction, setIntroduction] = useState('');

  const handleIntroBtnClick = async () => {
    if (introBtnClicked && introduction?.length <= 500) {
      const newUserData = await updateMyProfile({
        avatar: profile.avatar,
        introduction
      });
      onProfileUpdate(newUserData.data);
    }

    if (introduction?.length > 500) {
      alert('Characters length exceeded.');
      return;
    }

    setIntroBtnClicked(!introBtnClicked);
  };

  const handlePaymentRequestClick = () => {
    navigate('/sell/pattern/payout');
  };

  useEffect(() => {
    setIntroduction(profile?.introduction);
  }, []);

  // userInfo ?
  return (
    <div className={myPatternStyles.profile}>
      <div className={myPatternStyles.profile_main}>
        <div className={myPatternStyles.profile_img}>
          <img
            className={myPatternStyles.profile_pic}
            src={profile?.avatar || profilePicture}
            alt="pfp"
          />
          <img
            className={myPatternStyles.profile_rank}
            src={setRank(profile?.level || 'beginner')}
            alt="rank"
          />
        </div>
        <p className={myPatternStyles.profile_name}>
          {profile?.nickName ? renderNickname(profile?.nickName, 28) : ''}
        </p>
        <div className={myPatternStyles.profile_introduction}>
          <button
            onClick={() => handleIntroBtnClick()}
            className={myPatternStyles.profile_intro_btn}
            type="button"
            style={
              introBtnClicked
                ? { color: '#fff', background: '#0046a6', border: 'none' }
                : { color: '#222', background: '#fff' }
            }>
            {introBtnClicked ? 'Confirm' : 'Edit Seller Information'}
          </button>
        </div>
      </div>
      {profile?.introduction?.length && !introBtnClicked ? (
        <p className={myPatternStyles.profile_intro}>{profile.introduction}</p>
      ) : null}
      {introBtnClicked && (
        <textarea
          className={myPatternStyles.profile_intro_textarea}
          onChange={(e) => setIntroduction(e.target.value)}
          name="introduction"
          maxLength={500}
          placeholder="Please enter your information."
          value={introduction || ''}
        />
      )}
      <hr />
      <ul className={myPatternStyles.profile_info}>
        <li className={myPatternStyles.info_item}>
          <img
            className={myPatternStyles.item_icon}
            src={uploadsIcon}
            alt="uploads"
          />
          <p>My Uploads</p>
          <span>{[profile?.uploadTotal]}</span>
        </li>
        <li className={myPatternStyles.info_item}>
          <img
            className={myPatternStyles.item_icon}
            src={reviewsIcon}
            alt="reviews"
          />
          <p>Reviews</p>
          <span>{profile?.inReview}</span>
        </li>
        <li className={myPatternStyles.info_item}>
          <img
            className={myPatternStyles.item_icon}
            src={downloadsIcon}
            alt="downloads"
          />
          <p>My Sales</p>
          <span>{profile?.downloadTotal}</span>
        </li>
        <li className={myPatternStyles.info_item}>
          <div className={myPatternStyles.item_upper}>
            <img
              className={myPatternStyles.item_icon}
              src={earningsIcon}
              alt="earnings"
            />
            <button type="button" onClick={handlePaymentRequestClick}>
              Withdraw request <img src={enterArrow} alt="enter" />
            </button>
          </div>
          <p>My Profit</p>
          <span>{profile?.profitTotal || 0}</span>
        </li>
      </ul>
    </div>
  );
}

export default MyPatternProfile;
