/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-alert */
import { useMemo, useState } from 'react';
// import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import myPageStyles from '../MyPage.module.scss';
import bonusCreditStyles from './BonusCredit.module.scss';
import {
  checkActivationNumber,
  getBonusCredit
} from '../../../api/ultrafitShop';
import { getMyProfile } from '../../../api/profile';
import { useAlert } from '../../hooks/useAlert/useAlert';

// const dataEx = [
//   {
//     rollInch: 'Sq.ft',
//     X15M60: '250',
//     X30M48: '400',
//     X30M36: '300',
//     X30M24: '200'
//   },
//   {
//     rollInch: 'Bonus Credit (20%)',
//     X15M60: '50',
//     X30M48: '80',
//     X30M36: '60',
//     X30M24: '40'
//   }
// ];

function BonusCredit({ onBonusExcharge }) {
  const [message, setMessage] = useState({
    text: '',
    className: bonusCreditStyles.message
  });
  // const [entitledBonusCredits, setEntitledBonusCredits] = useState(0);
  const [productSerialNumber, setProductSerialNumber] = useState({
    first: '',
    second: '',
    third: '',
    fourth: ''
  });

  const { onAlert } = useAlert();

  const number = useMemo(
    () =>
      `${productSerialNumber?.first?.trim()}-${productSerialNumber?.second?.trim()}-${productSerialNumber?.third?.trim()}-${productSerialNumber?.fourth?.trim()}`,
    [productSerialNumber]
  );

  const handleConfirmCode = async () => {
    const confirmSerialNumberResponse = await checkActivationNumber(number);

    if (confirmSerialNumberResponse.errorStatus) {
      setMessage({
        text: 'The serial number does not exist.',
        className: `${bonusCreditStyles.message} ${bonusCreditStyles.error}`
      });
      return;
    }

    // setEntitledBonusCredits(confirmSerialNumberResponse.data);
    setMessage({
      text: 'The serial number exists.',
      className: `${bonusCreditStyles.message} ${bonusCreditStyles.certified}`
    });
  };

  const handleGetBonusCredit = async () => {
    if (message.text === 'The serial number exists.') {
      try {
        const getBonusResponse = await getBonusCredit(number);
        if (getBonusResponse?.errorStatus) {
          onAlert('', 'Okay', 'Something went wrong. Please try again.');
          return;
        }
      } catch (err) {
        onAlert('', 'Okay', 'Something went wrong. Please try again.');
      }

      const updatedUserProfile = await getMyProfile();

      onBonusExcharge(updatedUserProfile);

      onAlert('', 'Okay', 'Bonus redeemed successfully.');
      setMessage({
        text: '',
        className: bonusCreditStyles.message
      });
      setProductSerialNumber({
        first: '',
        second: '',
        third: '',
        fourth: ''
      });
      return;
    }

    onAlert('', 'Okay', 'Please enter and confirm Product Serial Number.');
  };

  return (
    <main className={myPageStyles.main}>
      <section className={myPageStyles.content}>
        <h1 className={bonusCreditStyles.title}>BONUS CREDITS</h1>
        <hr className={myPageStyles.upper_hr} />
        <div className={bonusCreditStyles.bonus_credit}>
          <div className={bonusCreditStyles.bonus_credit_box}>
            <label className={bonusCreditStyles.label_input} htmlFor="code">
              <span>Product Authentication Code</span>
              <div className={bonusCreditStyles.input_group}>
                <input
                  type="type"
                  name="code"
                  value={productSerialNumber.first}
                  onChange={(e) =>
                    setProductSerialNumber({
                      ...productSerialNumber,
                      first: e.target.value
                    })
                  }
                />
                <span>-</span>
                <input
                  type="type"
                  name="code"
                  value={productSerialNumber.second}
                  onChange={(e) =>
                    setProductSerialNumber({
                      ...productSerialNumber,
                      second: e.target.value
                    })
                  }
                />
                <span>-</span>
                <input
                  type="type"
                  name="code"
                  value={productSerialNumber.third}
                  onChange={(e) =>
                    setProductSerialNumber({
                      ...productSerialNumber,
                      third: e.target.value
                    })
                  }
                />
                <span>-</span>
                <input
                  type="type"
                  name="code"
                  value={productSerialNumber.fourth}
                  onChange={(e) =>
                    setProductSerialNumber({
                      ...productSerialNumber,
                      fourth: e.target.value
                    })
                  }
                />
              </div>
              <button
                type="button"
                className={bonusCreditStyles.input_btn}
                onClick={handleConfirmCode}>
                confirm
              </button>
            </label>
            <p className={bonusCreditStyles.data_title}>
              Please enter the 16-digit product authentication code attached to
              the ULTRAFIT product box.
            </p>
            <p className={message.className}>
              {message.text || <span>&nbsp; </span>}
            </p>
            <hr />
            {/* <div className={bonusCreditStyles.entitled_bonus_credits}>
              <p>
                <span>You are entitled to&nbsp;</span>
                <span>{entitledBonusCredits} Bonus Credits.</span>
              </p>
            </div> */}
            <div className={bonusCreditStyles.btn_confirm}>
              <button type="button" onClick={handleGetBonusCredit}>
                GET BONUS CREDITS
              </button>
            </div>
            {/* <div className={bonusCreditStyles.bonus_credit_table}>
              <h3 className={bonusCreditStyles.title_table}>
                Bonus Credits Table
              </h3>
              <table>
                <thead>
                  <tr>
                    <td>Roll Inch</td>
                    <td>60” X 15M</td>
                    <td>48” X 30M</td>
                    <td>36” X 30M</td>
                    <td>24” X 30M</td>
                  </tr>
                </thead>
                <tbody>
                  {dataEx.map((item, index) => (
                    <tr key={index}>
                      <td>{item.rollInch}</td>
                      <td>{item.X15M60}</td>
                      <td>{item.X30M48}</td>
                      <td>{item.X30M36}</td>
                      <td>{item.X30M24}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default withToast(BonusCredit);
