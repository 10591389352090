/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const loginByPass = ({ email, password }) =>
  customFetch(`${apiUrl}/loginByPass`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((loginData) => loginData);

export const loginViaGoogle = (data) =>
  customFetch(`${apiUrl}/loginViaGoogle`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const loginViaFacebook = (body = {}) =>
  customFetch(`${apiUrl}/loginViaFacebook`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const userRegister = (data) =>
  customFetch(`${apiUrl}/register`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (res) => {
    const response = res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response;
  });

export const userRegisterGoogle = (data) =>
  customFetch(`${apiUrl}/registerViaGoogle`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const userRegisterFacebook = (data) =>
  customFetch(`${apiUrl}/registerViaFacebook`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const changePass = (changePassData) =>
  customFetch(`${apiUrl}/changePass`, {
    method: 'POST',
    body: JSON.stringify(changePassData),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const sendCode = ({ email, type }) =>
  customFetch(`${apiUrl}/sendCode`, {
    method: 'POST',
    body: JSON.stringify({ email, type }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const verifyCode = ({ email, code, type }) =>
  customFetch(`${apiUrl}/verifyCode`, {
    method: 'POST',
    body: JSON.stringify({ email, verifyCode: code, type }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const checkWithdrawal = (body = {}) =>
  customFetch(`${apiUrl}/check-withdrawal`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());
