/* eslint-disable react/jsx-no-constructed-context-values */
import { useReducer } from 'react';
import PackContext from './Context';
import reducer, { initState } from './reducer';

function PackProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <PackContext.Provider value={[state, dispatch]}>
      {children}
    </PackContext.Provider>
  );
}

export default PackProvider;
