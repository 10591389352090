/* eslint-disable consistent-return */
// import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { getUserById, getUserPhotoById } from '../../api/api';
import profileImg from '../../img/icon_account.svg';
import { renderNickname } from '../../utils/function';
// import { generateImageSrc } from '../../utils/function';
import patternCardsStyles from './PatternCard.module.scss';
import PatternCardUserRank from './PatternCardUserRank';

function PatternCardUser({ user }) {
  const navigate = useNavigate();
  // const [userData, setUserData] = useState();
  // const [userPhoto, setUserPhoto] = useState();

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     const userByIdData = await getUserById(userId);
  //     const userPhotoByIdData = await getUserPhotoById(userId);

  //     await fetch(generateImageSrc(userPhotoByIdData)).then((res) => {
  //       if (res.status === 404) {
  //         setUserPhoto(profileImg);
  //         return;
  //       }

  //       return setUserPhoto(res.url);
  //     });
  //     setUserData(userByIdData);
  //   };

  //   fetchUserData();
  // }, []);

  const handleUploaderNavigate = () => {
    navigate(`/pattern/uploaderId/${user.ownerId}`);
  };

  return (
    <>
      <hr className={patternCardsStyles.lower_hr} />
      <button
        type="button"
        className={patternCardsStyles.card_creditsto}
        onClick={handleUploaderNavigate}>
        {/* {userData && (
          <>
            <div className={patternCardsStyles.card_author}>
              <img src={userPhoto || profileImg} alt="pfp" />
              <span className="card_username">by {userData.nick}</span>
            </div>
            <PatternCardUserRank rank={userData.level} />
          </>
        )} */}
        <div className={patternCardsStyles.card_author}>
          <img src={user?.avatar || profileImg} alt="pfp" />
          <span className="card_username">
            by {user?.nickName ? renderNickname(user?.nickName, 14) : ''}
          </span>
        </div>
        <PatternCardUserRank rank={user?.level} renderRankName />
      </button>
    </>
  );
}

export default PatternCardUser;
