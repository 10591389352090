import { setRank } from '../../utils/function';
import uploaderPageStyles from './UploaderPage.module.scss';

function UploaderRank({ rank }) {
  return (
    <img src={setRank(rank)} alt="rank" className={uploaderPageStyles.rank} />
  );
}

export default UploaderRank;
