/* eslint-disable react/jsx-no-constructed-context-values */
import { useReducer } from 'react';
import ShopOrderContext from './Context';
import reducer, { initState } from './reducer';

function ShopOrderProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <ShopOrderContext.Provider value={[state, dispatch]}>
      {children}
    </ShopOrderContext.Provider>
  );
}

export default ShopOrderProvider;
