import SupportPageTable from '../SupportPageTable/SupportPageTable';

function OtherProblems() {
  const topics = [
    {
      q: 'Why can’t I log in?',
      a: "If you have entered your login credentials correctly and still can’t log in, your internet browser may not be set to accept cookies. You can log in only if your browser is set to accept cookies. \n  To make sure you get the best browsing performance on PLOFIX, we recommend downloading the latest version of your favorite browser. \n If you still can't log in, please contact us (admin@plofix.com)"
    },
    {
      q: 'Troubleshooting Downloading and Internet Connection Issues',
      a: 'Check your Internet Connection and Bandwidth \n If you are using a wireless connection, move closer to your router or switch to a wired connection. Ensure that other services (ex. video and audio streaming or calling, online gaming or file sharing) are not running on your network at the same time. These services may be receiving bandwidth priority and may impact the speed of your connection to PLOFIX. You may also contact your internet service provider for assistance. \n Check for Blocked Access \n If access is blocked, it could be a proxy setting, a firewall or your internet service provider. If you are having a problem accessing our site or other domains, you may want to contact your IT help desk or your ISP.'
    },
    {
      q: 'Which browsers do Plofix.com and ‘Edit&Plot’ support?',
      a: 'Google Chrome, Safari.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default OtherProblems;
