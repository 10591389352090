import {
  GET_CART,
  INCREASE_CART,
  DECREASE_CART,
  UPDATE_QUANTITY
} from './constants/cart';
import { RECALL_GET_BALANCE, SET_CREDIT_BALANCE } from './constants/credit';

const initState = {
  cart: {
    length: 0,
    listCart: []
  },
  creditBalance: 0,
  timeGetCreditBalance: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    // cart
    case GET_CART:
      return {
        ...state,
        cart: action.payload
      };
    case INCREASE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          length: action.payload
        }
      };
    case DECREASE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          length: action.payload
        }
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          listCart: action.payload
        }
      };
    case SET_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: action.payload
      };
    case RECALL_GET_BALANCE:
      return {
        ...state,
        timeGetCreditBalance: +state.timeGetCreditBalance + 1
      };
    default:
      return state;
  }
};

export default reducer;
export { initState };
