/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { changePass } from '../../../api/auth';
import newPassStyles from '../Forms.module.scss';
import newPassScopedStyles from './NewPass.module.scss';
import iconPasswordShow from '../../../img/icon_password_show.svg';
import iconPasswordHide from '../../../img/icon_password_hide.svg';
import { useAlert } from '../../hooks/useAlert/useAlert';

function NewPass() {
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const { onAlert } = useAlert();

  // password validation reg exp
  const regExpLetters = /[a-zA-Z]/g;
  const regExpNumbers = /\d/;
  const regExpSymbs = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/g;

  const emailResetPass = sessionStorage.getItem('emailResetPass');
  const codeResetPass = sessionStorage.getItem('codeResetPass');

  const [isShowPw, setIsShowPw] = useState(false);
  const [isShowPwRetype, setIsShowPwRetype] = useState(false);

  // submit handler function
  const onSubmit = async (data) => {
    const changePassData = {
      email: emailResetPass,
      verifyCode: codeResetPass,
      password: data.password
    };

    clearErrors();
    const changePassRes = await changePass(changePassData);

    if (changePassRes.errorStatus) {
      onAlert('', 'OK', 'Something went wrong. PLease try again');
      navigate('/find_pass');
      return;
    }

    onAlert('', 'OK', 'Password has been changed successfully.');
    navigate('/');
    sessionStorage.removeItem('emailResetPass');
    sessionStorage.removeItem('codeResetPass');
  };

  return (
    <section className={newPassStyles.form_section}>
      <form onSubmit={handleSubmit(onSubmit)} className={newPassStyles.form}>
        <h1 className={newPassStyles.title} style={{ marginBottom: '10px' }}>
          Reset Password
        </h1>
        <p className={newPassScopedStyles.subtitle}>
          Please enter a&nbsp;mixture of&nbsp;one or&nbsp;more special
          characters, numbers, and alphanets.
        </p>
        <div className={newPassStyles.form_container}>
          <label
            className={newPassScopedStyles.pass_input}
            style={{ marginBottom: '30px' }}
            htmlFor="password">
            <p>Password</p>
            <input
              {...register('password', {
                required: 'Enter the password.',
                validate: (fieldValue) => {
                  if (
                    !regExpLetters.test(fieldValue) ||
                    !regExpNumbers.test(fieldValue)
                  ) {
                    return 'Your password should include letters and numbers.';
                  }
                  if (!regExpSymbs.test(fieldValue)) {
                    return 'Your password should include at least one special character.';
                  }
                  if (fieldValue !== getValues('retype')) {
                    return 'Passwords don’t match.';
                  }
                },
                minLength: {
                  value: 8,
                  message: 'Your password should include at least 8 characters.'
                }
              })}
              name="password"
              type={isShowPw ? 'text' : 'password'}
            />
            <img
              src={isShowPw ? iconPasswordShow : iconPasswordHide}
              onClick={() => setIsShowPw(!isShowPw)}
              alt="Eye Logo"
            />
          </label>
          <label
            className={newPassScopedStyles.pass_input}
            style={{ marginBottom: '80px' }}
            htmlFor="retype">
            <p>Retype Password</p>
            <input
              {...register('retype', { required: 'Retype is required' })}
              name="retype"
              type={isShowPwRetype ? 'text' : 'password'}
            />
            <img
              src={isShowPwRetype ? iconPasswordShow : iconPasswordHide}
              onClick={() => setIsShowPwRetype(!isShowPwRetype)}
              alt="Eye Logo"
            />
          </label>
          <div className={newPassStyles.warnings_holder}>
            <p className={newPassStyles.validation_warning}>
              {errors.retype?.message || errors.password?.message}
            </p>
          </div>
          <button
            className={newPassStyles.submit_btn}
            style={{ marginBottom: '0' }}
            type="submit">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
}

export default NewPass;
