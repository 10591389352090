/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import modalStyles from './OrderModal.module.scss';
import {
  addDecimalNumber,
  formatNumber,
  renderDate,
  roundDecimalNumber,
  subtractDecimalNumber
} from '../../../../utils/function';

function OrderModal({ setModalIsOpen, data }) {
  // const discountTotal = () =>
  //   Number(data?.discountAmount ?? 0) +
  //   Number(data?.totalCredit ?? 0) +
  //   Number(data?.totalProfit ?? 0);

  const itemSubtotal = () =>
    data?.orderDetail.reduce((subTotalPrice, order) => {
      subTotalPrice += order.options.amount;

      return subTotalPrice;
    }, 0);

  // const couponAmount = () => {
  //   if (data?.coupon?.benefit === 'free_shipping') {
  //     return Number(data?.shippingFee || 0);
  //   } else {
  //     return Number(data?.coupon?.discount) || 0;
  //   }
  // };

  // const discount = () =>
  //   subtractDecimalNumber(Number(data?.discountAmount), couponAmount());

  const grandTotal = () => {
    let total = subtractDecimalNumber(
      Number(itemSubtotal() || 0),
      Number(data?.discountAmount || 0)
    );
    total = subtractDecimalNumber(
      Number(total),
      Number(data?.totalCredit || 0)
    );
    total = subtractDecimalNumber(
      Number(total),
      Number(data?.totalProfit || 0)
    );

    return +total + +data.shippingFee;
  };

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>Order details</h1>
        <div className={modalStyles.modal_body}>
          <h3 className={modalStyles.title}>Order Details</h3>
          <div className={modalStyles.info}>
            <div className={modalStyles.info_row}>
              <p>Order Date</p>
              <p>{renderDate(data?.createdAt ?? '')}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Ship To</p>
              <p>{data?.shippingName ?? ''}</p>
            </div>
          </div>
          <h3 className={modalStyles.title}>Order Summary</h3>
          <div className={modalStyles.info}>
            <div className={modalStyles.info_row}>
              <p>Item Subtotal</p>
              <p>{`$ ${Number(itemSubtotal())}`}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Shipping Fee</p>
              <p>{`$ ${Number(data?.shippingFee ?? '')}`}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Discount</p>
              {/* <p>{`$ ${formatNumber(discountTotal() ?? '')}`}</p> */}
              <p>{`-$ ${data?.discountAmount}`}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Coupon</p>
              <p>{`-$ ${Number(data?.couponAmount ?? 0)}`}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Credits Used</p>
              <p>{`-$ ${Number(data?.totalCredit ?? '')}`}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Profit Used</p>
              <p>{`-$ ${Number(data?.totalProfit ?? '')}`}</p>
            </div>
          </div>
          <div className={modalStyles.grand_total}>
            <h2>Grand Total</h2>
            {/* <p>{`$ ${formatNumber(grandTotal() ?? '')}`}</p> */}
            {/* <p>{`$ ${roundDecimalNumber(Number(data?.totalAmount)
             ?? '')}`}</p> */}
            <p>{`$ ${grandTotal() ?? ''}`}</p>
          </div>
          <h3 className={modalStyles.title}>Order Summary</h3>
          <div className={modalStyles.info}>
            {data.orderDetail.map((item, index) => (
              <div className={modalStyles.product_row} key={index}>
                {item?.ulftrafits?.image && (
                  <img
                    className={modalStyles.product_row_img}
                    src={item?.ulftrafits?.image}
                    alt=""
                  />
                )}
                <div className={modalStyles.product_row_info}>
                  <span>{item?.ulftrafits?.name ?? ''}</span>
                  <span>{`Quarter : ${item?.quantity ?? 0} EA`}</span>
                </div>
                <span className={modalStyles.product_row_price}>
                  $ {Number(item.price ?? 0) * item.quantity}
                </span>
              </div>
            ))}
          </div>
          <h3 className={modalStyles.title}>Shipping address</h3>
          <div className={modalStyles.info}>
            <div className={modalStyles.info_row}>
              <p>Country</p>
              <p>{data?.shippingCountry ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Address 1</p>
              <p>{data?.shippingAddress1 ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Address 2</p>
              <p>{data?.shippingAddress2 ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Town / City</p>
              <p>{data?.shippingCity ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>Region</p>
              <p>{data?.shippingState ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>ZIP / Postal Code</p>
              <p>{data?.shippingZip ?? ''}</p>
            </div>
            <div className={modalStyles.info_row}>
              <p>VAT / TAX ID</p>
              <p>{data?.shippingVat ?? ''}</p>
            </div>
          </div>
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={() => setModalIsOpen(false)}
              className={modalStyles.btn_close}
              type="button">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderModal;
