import PackProvider from './Context/Provider';
import PricingCreditPacks from './PricingCreditPacks';

function PricingCreditPacksWrap({ authToken }) {
  return (
    <PackProvider>
      <PricingCreditPacks authToken={authToken} />
    </PackProvider>
  );
}

export default PricingCreditPacksWrap;
