import moment from 'moment';

/**
 * 안전히 다룰 수 있는 try catch 문으로 동기화 기능 실행
 * @constructor
 * @param {function():*} callback
 * @returns {Array}
 */
const execute = (callback) => {
  try {
    const res = callback();
    return [res, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 * 안전히 다룰 수 있는 try catch 문으로 비동기화 기능 실행
 * @constructor
 * @param {function():*} callback
 * @returns {Array}
 */
const executeAsync = async (callback) => {
  try {
    const res = await callback();
    return [res, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 * UUID 생성
 * @constructor
 * @param {number} digits
 * @returns {string}
 */
const generateUUID = (digits) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
  const uuid = [];
  for (let i = 0; i < digits; i++) {
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join('');
};

const isExpiredDate = (startDate, endDate) =>
  moment(endDate).isSameOrBefore(moment(startDate));

export { execute, executeAsync, generateUUID, isExpiredDate };
