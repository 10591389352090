/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import signUpFormStyles from '../Forms.module.scss';
import sighUpFormStyles2 from '../FindPass/FindPass.module.scss';
import signUpFormScopedStyles from './SignUpForm.module.scss';
import profileIcon from '../../../img/profile_signup.svg';
import countries from '../../../utils/countries';
import TermsAndServiceModal from './TermsAndServiceModal/TermsAndServiceModal';
import { uploadAvatar } from '../../../api/utils';
import { getPolicy } from '../../../api/termsAndPolicy';
import {
  checkWithdrawal,
  sendCode,
  userRegisterFacebook,
  userRegisterGoogle,
  verifyCode
} from '../../../api/auth';

import { useClickOutside } from '../../hooks/useClickOutside';
import { phoneNumberCodes } from '../../../utils/phoneNumberCodes';

import iconDown from '../../../img/icon_down1.svg';
import iconUp from '../../../img/icon_down.svg';
import { checkImageSize } from '../../../utils/function';
import { useAlert } from '../../hooks/useAlert/useAlert';

function SignUpFormSNS({ onLogin }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email = '', profilePicture = '', uid = '', type = '' } = state || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    setError
  } = useForm({
    defaultValues: {
      country: ''
    },
    mode: 'onChange'
  });

  const [emailInput, setEmailInput] = useState();
  const [verCode, setVerCode] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [verificationPassed, setVerificationPassed] = useState(false);
  const [verificationErrors, setVerificationErrors] = useState({
    email: '',
    code: ''
  });

  const [telCodeSelectIsOpen, setTelCodeSelectIsOpen] = useState(false);
  const [selectedTelCode, setSelectedTelCode] = useState('+82');

  const [country, setCountry] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [countrySelectIsOpen, setCountrySelectIsOpen] = useState(false);

  // states for input[type='file']
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(profileIcon);

  // states for modal
  const [membershipPolicyIsOpen, setMembershipPolicyIsOpen] = useState(false);
  const [termsIsOpen, setTermsIsOpen] = useState(false);

  // terms&policy
  const [privacyPolicy, setPrivacyPolicy] = useState({ __html: '' });
  const [terms, setTerms] = useState({ __html: '' });

  const { onAlert } = useAlert();

  // refs
  const phoneCodeRef = useRef(null);
  const countryRef = useRef(null);

  useClickOutside(phoneCodeRef, () => {
    setTelCodeSelectIsOpen(false);
  });

  useClickOutside(countryRef, () => {
    setCountrySelectIsOpen(false);
  });

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
    if (profilePicture) {
      setPreview(profilePicture);
    }
  }, [email, profilePicture]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      //   setPreview(profileIcon);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  // change selectedFile state on event
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    checkImageSize(e.target.files[0], 320, 320)
      .then((result) => {
        setSelectedFile(e.target.files[0]);
      })
      .catch((error) => {
        onAlert('', 'OK', error.message);
      });
  };

  const handleCountryFocus = () => {
    setCountrySelectIsOpen(true);
  };

  // const handleCountrySelectMouseLeave = () => {
  //   setCountrySelectIsOpen(false);
  // };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleSelectCountry = (e) => {
    setValue('country', e.target.value);
    setCountry(e.target.value);
    setSearchCountry('');
    setValue('country', e.target.value);
    setCountrySelectIsOpen(false);
  };

  const fetchTermsAndPolicy = async () => {
    const policyDataRes = await getPolicy('privacy', null);
    const termsDataRes = await getPolicy('terms', null);

    setPrivacyPolicy({ __html: policyDataRes });
    setTerms({ __html: termsDataRes });
  };

  const sendVerificationCode = async () => {
    const enteredEmail = getValues('email');

    if (!enteredEmail) {
      setVerificationErrors({ ...verificationErrors, email: 'Enter Email.' });
      return;
    }

    setVerificationErrors({ ...verificationErrors, email: '' });

    const checkWithdrawalRes = await checkWithdrawal({
      email: enteredEmail.trim()
    });

    if (checkWithdrawalRes.data === true) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already withdraw'
      });
      return;
    }

    const emailPlusCodeData = {
      email: enteredEmail,
      type: 'registration'
    };
    const sendCodeRes = await sendCode(emailPlusCodeData);

    if (sendCodeRes.errorStatus) {
      const errorMessage =
        sendCodeRes.message === 'Email is existed'
          ? 'This Email is already registered.'
          : 'Email must be a valid email address.';

      setVerificationErrors({
        ...verificationErrors,
        email: errorMessage
      });

      return;
    }

    if (!sendCodeRes.data) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already registered.'
      });
      return;
    }

    setCodeSent(true);
    setVerificationErrors({ ...verificationErrors, email: '' });
  };

  const verifyEmail = async () => {
    setVerificationErrors({ ...verificationErrors, code: '' });

    const emailPlusCodeData = {
      email: getValues('email'),
      code: getValues('code'),
      type: 'registration'
    };
    const activateEmailRes = await verifyCode(emailPlusCodeData);

    if (!activateEmailRes.data) {
      setVerificationPassed(false);
      setVerificationErrors({
        ...verificationErrors,
        code: 'Wrong verification code.'
      });
      return;
    }

    setCodeSent(false);
    setVerificationPassed(true);
    setVerificationErrors({ ...verificationErrors, code: '' });
  };

  const handleTelBtnClick = () => {
    setTelCodeSelectIsOpen(!telCodeSelectIsOpen);
  };

  const handleSelectedTelChange = (e) => {
    setSelectedTelCode(e.target.value);
    setTelCodeSelectIsOpen(false);
  };

  const registerSubmit = async (data) => {
    const checkWithdrawalRes = await checkWithdrawal({
      email: data.email.trim()
    });

    if (checkWithdrawalRes.data === true) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already withdraw.'
      });
      onAlert('', 'OK', 'Email is already withdraw');
      return;
    }
    // const registerData = {
    //   email: data.email,
    //   nickName: data.nickname,
    //   firstName: data.firstname,
    //   lastName: data.lastname,
    //   password: data.pass,
    //   country,
    //   address1: data.address1,
    //   address2: data.address2,
    //   postalCode: data.zipcode,
    //   city: data.town,
    //   stateRegion: data.state,
    //   companyName: data.company || null,
    //   phone: data.phone
    // };

    let avatarSrc = '';

    if (selectedFile) {
      try {
        const avatarFile = new FormData();
        avatarFile.append('file', selectedFile);
        const response = await uploadAvatar(avatarFile);
        avatarSrc = response;
      } catch (e) {
        console.log(e);
      }
    }

    let registerData = {
      firstName: data.firstname,
      lastName: data.lastname,
      country: data.country,
      foreignId: uid,
      email: data.email,
      address1: data.address1,
      address2: data.address2,
      postalCode: data.zipcode.split(' ').join(''),
      city: data.town,
      stateRegion: data.state,
      companyName: data.company || null,
      phoneNumber: `${selectedTelCode}${data.phone.trim()}`,
      nickName: data.nickname,
      avatar: avatarSrc || preview
    };

    if (data.code) {
      registerData = { ...registerData, code: data.code };
    }

    // console.log('registerData', registerData);
    // return;

    let registerRes = '';
    try {
      if (type === 'google') {
        registerRes = await userRegisterGoogle(registerData);
      } else {
        registerRes = await userRegisterFacebook(registerData);
      }
      if (registerRes.statusCode === 200) {
        // eslint-disable-next-line no-alert
        onAlert('', 'OK', 'Registration Complete!');
        const loginState = {
          isLoggedIn: true,
          nickname: registerRes.data.user.nickName,
          email: registerRes.data.user.email,
          id: registerRes.data.user.id
        };

        localStorage.setItem('token', registerRes.data.token);
        localStorage.setItem('refreshToken', registerRes.data.refreshToken);
        onLogin(loginState);
        navigate('/');
      } else {
        const { message = 'Error' } = registerRes;
        onAlert('', 'OK', message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchTermsAndPolicy();
  }, []);

  return (
    <section className={signUpFormStyles.form_section}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(registerSubmit)}
        className={signUpFormStyles.form}>
        <h1 className={signUpFormStyles.title}>Sign Up</h1>
        <p className={signUpFormScopedStyles.subtitle}>
          Welcome to your Ultrafit Registration
        </p>
        <div className={signUpFormStyles.form_container}>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p className={signUpFormScopedStyles.data_title}>Profile</p>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_upload}
                htmlFor="upload">
                <div className={signUpFormScopedStyles.profile_icon}>
                  <img
                    className={signUpFormScopedStyles.profile_icon}
                    src={preview}
                    alt="profile icon"
                  />
                </div>
                <div className={signUpFormScopedStyles.upload_container}>
                  <input
                    {...register('upload')}
                    onChange={onSelectFile}
                    className={signUpFormScopedStyles.input_upload}
                    type="file"
                    name="upload"
                    accept=".png, .jpg, .gif"
                  />
                  <p className={signUpFormScopedStyles.profile_desc}>
                    You can upload files by PNG, JPG, GIF.
                  </p>
                  <p className={signUpFormScopedStyles.profile_desc_info}>
                    Image size should be at least 320 x 320 pixels.
                  </p>
                </div>
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="nickname">
                <p>*Username</p>
                <input
                  {...register('nickname', {
                    required: 'Enter username.',
                    maxLength: 14
                  })}
                  className={signUpFormScopedStyles.profile_nickname}
                  type="text"
                  name="nickname"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 14) {
                      setValue('nickname', value);
                      clearErrors('nickname');
                    } else {
                      e.target.value = value.substring(0, 14);
                    }
                  }}
                />
              </label>
              {errors.nickname && errors.nickname.type === 'required' && (
                <p className={signUpFormScopedStyles.validation_warn}>
                  {errors.nickname?.message}
                </p>
              )}
              {errors.nickname && errors.nickname.type === 'maxLength' && (
                <p className={signUpFormScopedStyles.validation_warn}>
                  Up to 14 letters can be entered.
                </p>
              )}
            </fieldset>
          </div>
          <label
            className={signUpFormScopedStyles.label_input}
            style={{ marginBottom: '40px' }}
            htmlFor="email">
            <p className={signUpFormScopedStyles.data_title}>*Email</p>
            <input
              //   onInput={(e) => setEmail(e.target.value)}
              {...register('email', { required: 'Enter email.' })}
              type="email"
              name="email"
              onChange={(e) => {
                setCodeSent(false);
                if (e.target.value) {
                  clearErrors('email');
                } else {
                  setError('email', {
                    type: 'invalid',
                    message: 'Enter email.'
                  });
                }
              }}
              disabled={email}
            />
            {!email && (
              <>
                <button
                  onClick={sendVerificationCode}
                  type="button"
                  className={`${sighUpFormStyles2.input_btn}
              ${sighUpFormStyles2.btn_send}`}>
                  {codeSent ? 'resend' : 'send'}
                </button>
                <p className={signUpFormScopedStyles.validation_warn}>
                  {errors.email?.message}
                  {verificationErrors.email}
                </p>

                {codeSent && (
                  <p
                    className={signUpFormScopedStyles.validation_warn}
                    style={{ color: '#4bb3ff' }}>
                    Code sent to the entered email.
                  </p>
                )}
              </>
            )}
          </label>
          {!email && (
            <label
              className={signUpFormScopedStyles.label_input}
              style={{ marginBottom: '40px' }}
              htmlFor="code">
              <p className={signUpFormScopedStyles.data_title}>
                *Verification code
              </p>
              <input
                onInput={(e) => setVerCode(e.target.value)}
                {...register('code', {
                  required: 'Please confirm verification code.'
                })}
                type="text"
                name="code"
              />
              <button
                onClick={verifyEmail}
                type="button"
                className={`${sighUpFormStyles2.input_btn}
               ${sighUpFormStyles2.btn_verify}`}
                disabled={!codeSent || !getValues('code')}>
                Verify
              </button>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.code?.message}
                {getValues('code') && verificationErrors.code
                  ? verificationErrors.code
                  : ''}
              </p>
              {verificationPassed && (
                <p
                  className={signUpFormScopedStyles.validation_warn}
                  style={{ color: '#4bb3ff' }}>
                  Certified.
                </p>
              )}
            </label>
          )}
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p className={signUpFormScopedStyles.data_title}>*Name</p>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="firstname">
                <p>*First name</p>
                <input
                  {...register('firstname', { required: 'Enter first name.' })}
                  type="text"
                  name="firstname"
                />
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                style={{ borderTop: '1px solid #ededed' }}
                htmlFor="lastname">
                <p>*Last name</p>
                <input
                  {...register('lastname', { required: 'Enter last name.' })}
                  type="text"
                  name="lastname"
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.firstname?.message} {errors.lastname?.message}
              </p>
            </fieldset>
          </div>
          {/* <label className={signUpFormScopedStyles.label_input} htmlFor="phone">
            <p>Mobile phone</p>
            <input
              {...register('phone', { required: 'Enter mobile phone.' })}
              type="tel"
              name="phone"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.phone?.message}
            </p>
          </label> */}
          <fieldset
            className={`${signUpFormScopedStyles.mobile_fieldset} ${signUpFormScopedStyles.fieldset}`}>
            <p>*Mobile Phone</p>
            <div className={signUpFormScopedStyles.mobile_controls}>
              <div
                className={signUpFormScopedStyles.mobile_select}
                ref={phoneCodeRef}>
                <button
                  onClick={handleTelBtnClick}
                  className={signUpFormScopedStyles.custom_select_btn}
                  type="button">
                  <span>{selectedTelCode}</span>
                  <img
                    src={iconDown}
                    alt="arrow"
                    style={
                      telCodeSelectIsOpen ? { transform: 'rotate(180deg)' } : {}
                    }
                  />
                </button>
                <select
                  onChange={(e) => handleSelectedTelChange(e)}
                  className={signUpFormScopedStyles.custom_select}
                  name="mobile_code"
                  style={telCodeSelectIsOpen ? { display: 'flex' } : {}}
                  multiple>
                  {phoneNumberCodes.map((phoneCode) => (
                    <option key={phoneCode.id} value={phoneCode.value}>
                      {phoneCode.text.toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>
              <input
                {...register('phone', { required: 'Enter mobile phone.' })}
                className={signUpFormScopedStyles.mobile_input}
                type="text"
                name="phone"
                onInput={(e) => {
                  const phoneValue = e.target.value
                    .split('')
                    .filter((value) => Number.isInteger(+value))
                    .join('')
                    .replace(/\s/g, '');

                  setValue('phone', phoneValue);
                }}
                // onKeyDown={(e) => {
                //   if (
                //     /[0-9]/.test(e.key) ||
                //     e.key === 'Backspace' ||
                //     e.key === 'Delete'
                //   ) {
                //     return;
                //   }

                //   e.preventDefault();
                // }}
                onWheel={(e) => e.target.blur()}
              />
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.phone?.message}
              </p>
            </div>
          </fieldset>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="company">
            <p>Company name</p>
            <input {...register('company')} type="text" name="company" />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.company?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address1">
            <p>*Address 1</p>
            <input
              {...register('address1', { required: 'Enter address 1.' })}
              type="text"
              name="address1"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address1?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address2">
            <p>*Address 2</p>
            <input
              {...register('address2', { required: 'Enter address 2.' })}
              type="text"
              name="address2"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address2?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="town">
            <p>*Town / City</p>
            <input
              {...register('town', { required: 'Enter town / city' })}
              type="text"
              name="town"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.town?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="state">
            <p>*State / Province / Region</p>
            <input
              {...register('state', {
                required: 'Enter state / province / region.'
              })}
              type="text"
              name="state"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.state?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="zipcode">
            <p>*ZIP / Postal Code</p>
            <input
              {...register('zipcode', { required: 'Enter zip / postal code.' })}
              type="text"
              name="zipcode"
              onInput={(evt) => {
                let inputValue = evt.target.value;
                inputValue = inputValue.replace(/[^0-9]/g, '');
                evt.target.value = inputValue;
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.zipcode?.message}
            </p>
          </label>
          <fieldset
            className={`${signUpFormScopedStyles.country_fieldset} ${signUpFormScopedStyles.fieldset}`}
            htmlFor="country"
            ref={countryRef}>
            <p>*Country</p>
            <div className={signUpFormScopedStyles.dropdown}>
              <input
                {...register('country', { required: 'Choose country.' })}
                onClick={() => setCountrySelectIsOpen(!countrySelectIsOpen)}
                // onChange={handleCountryChange}
                onChange={(e) => setSearchCountry(e.target.value)}
                name="country"
                type="text"
                className={signUpFormScopedStyles.country_input}
                value={countrySelectIsOpen ? searchCountry : country}
                placeholder={
                  countrySelectIsOpen && !searchCountry
                    ? country
                    : 'Choose country'
                }
                style={
                  countrySelectIsOpen
                    ? {
                        outline: '1px solid #0046a6',
                        backgroundImage: `url(${iconUp})`
                      }
                    : {
                        backgroundImage: `url(${iconDown})`
                      }
                }
              />
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.country?.message && !country
                ? errors.country?.message
                : null}
            </p>
            {countrySelectIsOpen && (
              <select name="country" multiple onChange={handleSelectCountry}>
                {searchCountry.length > 0
                  ? countries
                      .filter((countryData) =>
                        countryData.name
                          .toLowerCase()
                          .includes(searchCountry.toLowerCase())
                      )
                      .map((countryData, index) => (
                        <option key={index} value={countryData.name}>
                          {countryData.name}
                        </option>
                      ))
                  : countries.map((countryData, index) => (
                      <option key={index} value={countryData.name}>
                        {countryData.name}
                      </option>
                    ))}
              </select>
            )}
          </fieldset>
          <label
            htmlFor="policy"
            className={`${signUpFormScopedStyles.label_input} ${signUpFormScopedStyles.label_checkbox}`}>
            <div className={signUpFormScopedStyles.policy_service}>
              <input
                {...register('policy', {
                  required: 'Check Privacy Policy.'
                })}
                type="checkbox"
              />
              <span>*Privacy Policy</span>
              <button
                type="button"
                onClick={() => setMembershipPolicyIsOpen(true)}>
                View
              </button>
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {!getValues().policy ? errors.policy?.message : ''}{' '}
            </p>
          </label>
          <label htmlFor="terms" className={signUpFormScopedStyles.label_input}>
            <div className={signUpFormScopedStyles.policy_service}>
              <input
                {...register('terms', {
                  required: 'Check Terms and Conditions.'
                })}
                type="checkbox"
              />
              <span>*Terms and Conditions</span>
              <button type="button" onClick={() => setTermsIsOpen(true)}>
                View
              </button>
            </div>

            <p className={signUpFormScopedStyles.validation_warn}>
              {!getValues().terms ? errors.terms?.message : ''}
            </p>
          </label>

          <div className={signUpFormScopedStyles.btn_container}>
            <Link
              to="/auth"
              className={signUpFormScopedStyles.cancel_btn}
              type="button">
              Cancel
            </Link>
            <button
              className={signUpFormScopedStyles.register_btn}
              type="submit">
              Register
            </button>
          </div>
        </div>
      </form>
      {membershipPolicyIsOpen && (
        <TermsAndServiceModal
          title="Privacy Policy"
          textInnerHTML={privacyPolicy}
          onClose={() => {
            setMembershipPolicyIsOpen(false);
            setValue('policy', true);
            clearErrors('policy');
          }}
        />
      )}
      {termsIsOpen && (
        <TermsAndServiceModal
          title="Terms and Conditions"
          textInnerHTML={terms}
          onClose={() => {
            setTermsIsOpen(false);
            setValue('terms', true);
            clearErrors('terms');
          }}
        />
      )}
    </section>
  );
}

export default SignUpFormSNS;
