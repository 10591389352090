/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
// /* eslint-disable no-return-assign */
import { useState, useEffect, useContext } from 'react';
import SalesInfo from './SalesInfo';
import salesStyles from './Sales.module.scss';
import uploadsIcon from '../../../../../img/icon_upload.svg';
import downloadsIcon from '../../../../../img/icon_download.svg';
import eraningsIcon from '../../../../../img/icon_earning.svg';
import SalesInquiryTable from './SalesInquiryTable';
import InquiryFilter from '../InquiryFilter/InquiryFilter';
import { getNumberOfSales } from '../../../../../api/transaction';
import Pagination from '../../../../Pagination/Pagination';
import { UserProfileContext } from '../../../../../store/Context';

function Sales() {
  const { userProfile } = useContext(UserProfileContext) || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });

  const [soldPatterns, setSoldPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchNumberOfSales = async (
    from = '',
    to = '',
    page = 1,
    limit = 20
  ) => {
    const numberOfSalesData = await getNumberOfSales({
      from,
      to,
      page,
      limit
    });

    setTotalCount(numberOfSalesData.count);
    setSoldPatterns(numberOfSalesData.data);
  };

  useEffect(() => {
    fetchNumberOfSales(filterDates.from, filterDates.to, currentPage);
  }, [filterDates, currentPage]);

  return (
    <>
      <div className={salesStyles.sales_info_container}>
        <SalesInfo
          infoIcon={uploadsIcon}
          infoCategory="My Uploads"
          infoAmount={userProfile?.uploadTotal || 0}
        />
        <SalesInfo
          infoIcon={downloadsIcon}
          infoCategory="My Sales"
          infoAmount={userProfile?.downloadTotal || 0}
        />
        <SalesInfo
          infoIcon={eraningsIcon}
          infoCategory="My Profit"
          infoAmount={
            userProfile?.profitTotal ? `$ ${userProfile?.profitTotal}` : '$ 0'
          }
        />
      </div>
      <div className={salesStyles.inquiry_container}>
        <h2 className={salesStyles.inquiry_title}>SALES HISTORY</h2>
        <InquiryFilter
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
        />
        <SalesInquiryTable tableData={soldPatterns} />
        {totalCount > 20 && (
          <Pagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalCount={totalCount}
            pageSize={20}
          />
        )}
      </div>
    </>
  );
}

export default Sales;
