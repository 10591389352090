/* eslint-disable no-alert */
import { useState } from 'react';
import close from '../../../img/close_new_pattern.svg';
import dropdownArrow from '../../../img/dropdown_arrow.svg';
import NewPatternDescription from './NewPatternDescription/NewPatternDescription';
import NewPatternDnd from './NewPatternDnD';
import NewPatternPPA from './NewPatternPPA/NewPatternPPA';
import NewPatternSubCats from './NewPatternSubCats/NewPatternSubCats';
import NewPatternTags from './NewPatternTags/NewPatternTags';
import uploadPatternStyles from './UploadPattern.module.scss';

function UploadNewPattern({
  id,
  defaultName,
  setPatternToRemove,
  subCategories,
  patternPricePerArea,
  newPatParams,
  setNewPatternsParams,
  editMode,
  selectedVehicleType
}) {
  const [detailsIsOpen, setDetailsIsOpen] = useState(true);

  // const changePatternName = (e) => {
  //   console.log(e.target.textContent);
  // };

  return (
    <form
      className={uploadPatternStyles.newpattern_form}
      onSubmit={(e) => e.preventDefault()}>
      <div
        className={uploadPatternStyles.newpattern_title}
        style={{ borderBottom: !detailsIsOpen ? 'none' : '1px solid #ededed' }}>
        <span
          // onInput={(e) => changePatternName(e)}
          className={uploadPatternStyles.newpattern_name}
          role="textbox">
          {defaultName}
        </span>
        {!editMode && (
          <>
            <button
              className={uploadPatternStyles.remove_btn}
              type="button"
              onClick={() => setPatternToRemove(id)}>
              <img src={close} alt="remove" />
            </button>
            <button
              onClick={() => setDetailsIsOpen(!detailsIsOpen)}
              className={uploadPatternStyles.arrow_btn}
              style={{ transform: detailsIsOpen ? 'rotate(180deg)' : '' }}
              type="button">
              <img src={dropdownArrow} alt="" />
            </button>
          </>
        )}
      </div>
      {detailsIsOpen && (
        <>
          {!editMode && (
            <NewPatternSubCats
              id={id}
              subCategories={subCategories}
              newPatParams={newPatParams}
              setNewPatternsParams={setNewPatternsParams}
              selectedVehicleType={selectedVehicleType}
            />
          )}
          {patternPricePerArea.length > 0 && (
            <NewPatternPPA
              id={id}
              patternPricePerArea={patternPricePerArea}
              newPatParams={newPatParams}
              setNewPatternsParams={setNewPatternsParams}
            />
          )}
          <div className={uploadPatternStyles.form_dnd_container}>
            <p className={uploadPatternStyles.form_subtitle}>File Upload</p>
            <div className={uploadPatternStyles.form_dnd}>
              <NewPatternDnd
                id={id}
                newPatParams={newPatParams}
                setNewPatternsParams={setNewPatternsParams}
                editMode={editMode}
              />
            </div>
          </div>
          <NewPatternDescription
            id={id}
            newPatParams={newPatParams}
            setNewPatternsParams={setNewPatternsParams}
          />
          <NewPatternTags
            id={id}
            newPatParams={newPatParams}
            setNewPatternsParams={setNewPatternsParams}
          />
        </>
      )}
    </form>
  );
}

export default UploadNewPattern;
