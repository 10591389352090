/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-return */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCategories } from '../../api/categories';
import {
  filterPatternsBylargeCategory,
  isObjectValueNull
} from '../../utils/function';

import Selector from './Selector';

import selectorFilterStyles from './SelectorFilter.module.scss';

import resetIcon from '../../img/icon_reset.svg';

// pattern, setPatterns
function SelectorFilter({
  setSelectedCategory,
  isMainPage,
  isPatternPage,
  isUploaderPage,
  isMyPatternsPage,
  isRedirect
}) {
  const navigate = useNavigate();

  const [largeCategories, setLargeCategories] = useState([]);
  const [largeCategoryFilterOptions, setLargeCategoryFilterOptions] = useState({
    // region: [],
    // year: [],
    // make: [],
    // model: [],
    // subModel: [],
    // series: []
  });

  // region: '',
  // year: '',
  // make: '',
  // model: '',
  // 'subModel': '',
  // series: ''
  const [storedSelectedFilterOptions, setStoredSelectedFilterOptions] =
    useState({});
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});

  const fetchPatternCategories = async () => {
    const patternCategoriesResponse = await getCategories();

    setLargeCategories(
      patternCategoriesResponse.filter(
        (category) =>
          category.column === 'region' && category.type === 'vehicle'
      )
    );
  };

  const handleSearchFilterClick = async (e) => {
    e.stopPropagation();
    if (
      isRedirect &&
      (Object.keys(selectedFilterOptions).length === 0 ||
        isObjectValueNull(selectedFilterOptions))
    ) {
      navigate('/pattern/list');
      return;
    }

    const selectedCategoryId = filterPatternsBylargeCategory(
      selectedFilterOptions
    );

    setSelectedCategory(selectedCategoryId);
  };

  const handleResetClick = () => {
    setSelectedFilterOptions({
      location: null,
      year: null,
      make: null,
      model: null,
      'sub-model': null,
      series: null
    });
    setSelectedCategory('');
  };

  useEffect(() => {
    fetchPatternCategories();

    if (isPatternPage) {
      setStoredSelectedFilterOptions(
        JSON.parse(localStorage.getItem('patternsPagePatternDetails'))
      );

      localStorage.removeItem('patternsPagePatternDetails');
    }

    if (isUploaderPage) {
      setStoredSelectedFilterOptions(
        JSON.parse(localStorage.getItem('uploaderPagePatternDetails'))
      );

      localStorage.removeItem('uploaderPagePatternDetails');
    }

    if (isMyPatternsPage) {
      setStoredSelectedFilterOptions(
        JSON.parse(localStorage.getItem('myPatternsPagePatternDetails'))
      );

      localStorage.removeItem('myPatternsPagePatternDetails');
    }
  }, []);

  useEffect(() => {
    if (storedSelectedFilterOptions) {
      setSelectedFilterOptions(storedSelectedFilterOptions);
    }
  }, [storedSelectedFilterOptions]);

  useEffect(() => {
    setLargeCategoryFilterOptions((currentState) => ({
      ...currentState,
      location: largeCategories
        .map((category) => ({
          id: category.id,
          value: category.category,
          subCategory: category.subCategory,
          order: category.order
        }))
        .sort((a, b) => a.order - b.order)
    }));
  }, [largeCategories]);

  const lagreCategoryFilterEffect = (option, parentValue) => {
    useEffect(() => {
      if (parentValue) {
        setLargeCategoryFilterOptions((currentState) => ({
          ...currentState,
          [option]: parentValue.subCategory.map((subCategory) => ({
            id: subCategory.id,
            value: subCategory.category,
            subCategory: subCategory.subCategory
          }))
        }));
      }
    }, [parentValue]);
  };

  lagreCategoryFilterEffect('year', selectedFilterOptions.location);
  lagreCategoryFilterEffect('make', selectedFilterOptions.year);
  lagreCategoryFilterEffect('model', selectedFilterOptions.make);
  lagreCategoryFilterEffect('sub-model', selectedFilterOptions.model);
  lagreCategoryFilterEffect('series', selectedFilterOptions['sub-model']);

  return (
    <form
      className={selectorFilterStyles.form}
      onClick={(e) => e.stopPropagation()}>
      <div className={selectorFilterStyles.selectors}>
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions.location}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="location"
          options={isMainPage ? '' : largeCategoryFilterOptions.location}
          isMainPage={isMainPage}
        />
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions.year}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="year"
          options={isMainPage ? '' : largeCategoryFilterOptions.year}
          disabled={
            isMainPage
              ? false
              : !largeCategoryFilterOptions?.year ||
                !selectedFilterOptions.location
          }
          isMainPage={isMainPage}
        />
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions.make}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="make"
          options={isMainPage ? '' : largeCategoryFilterOptions.make}
          disabled={
            isMainPage
              ? false
              : !largeCategoryFilterOptions?.make || !selectedFilterOptions.year
          }
          isMainPage={isMainPage}
        />
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions.model}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="model"
          options={isMainPage ? '' : largeCategoryFilterOptions.model}
          disabled={
            isMainPage
              ? false
              : !largeCategoryFilterOptions?.model ||
                !selectedFilterOptions.make
          }
          isMainPage={isMainPage}
        />
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions['sub-model']}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="sub-model"
          options={isMainPage ? '' : largeCategoryFilterOptions['sub-model']}
          disabled={
            isMainPage
              ? false
              : !largeCategoryFilterOptions['sub-model'] ||
                !selectedFilterOptions.model
          }
          isMainPage={isMainPage}
        />
        <Selector
          selectedValue={isMainPage ? '' : selectedFilterOptions.series}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="series"
          options={isMainPage ? '' : largeCategoryFilterOptions.series}
          disabled={
            isMainPage
              ? false
              : !largeCategoryFilterOptions?.series ||
                !selectedFilterOptions['sub-model']
          }
          isMainPage={isMainPage}
        />
      </div>
      <hr className={selectorFilterStyles.form_hr} />
      <div className={selectorFilterStyles.btn_container}>
        <button
          onClick={() => handleResetClick()}
          type="button"
          className={selectorFilterStyles.reset_btn}>
          <img src={resetIcon} alt="reset" />
          <span>Reset</span>
        </button>
        <button
          onClick={(e) => handleSearchFilterClick(e)}
          type="button"
          className={selectorFilterStyles.search_btn}>
          Search
        </button>
      </div>
    </form>
  );
}

export default SelectorFilter;
