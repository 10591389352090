/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
// import { getProfitBalance, getMyTransactions } from '../../../../../api/api';
import { renderDate } from '../../../../../utils/function';
import InquiryFilter from '../InquiryFilter/InquiryFilter';
import StatusFilter from '../StatusFilter/StatusFilter';
import Pagination from '../../../../Pagination/Pagination';
import historyStyles from './TransactionHistory.module.scss';
import attentionIcon from '../../../../../img/attention_icon.svg';
import { getBalance } from '../../../../../api/transaction';
import { getProfileProfitHistory } from '../../../../../api/profile';

function TransactionHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });
  const [selectedValue, setSelectedValue] = useState('');

  const [balance, setBalance] = useState(0);

  const [transactionsList, setTransactionList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async (
    from = '',
    to = '',
    activity = '',
    page = 1,
    limit = 20
  ) => {
    if (!balance) {
      const fetchedBalance = await getBalance('profit');
      setBalance(fetchedBalance);
    }

    const fetchedTransactionHistory = await getProfileProfitHistory({
      from,
      to,
      activity,
      page,
      limit
    });

    setTransactionList(fetchedTransactionHistory.data);
    setTotalCount(fetchedTransactionHistory.count);
  };

  const renderActivity = (dataActivity) => {
    if (dataActivity === 'earn_profit') return 'Earning';

    if (dataActivity === 'profit_ultrafit_shop_refund')
      return 'Place Order Refund';

    if (dataActivity === 'payout') return 'Payout';

    if (dataActivity === 'sponsor_ads') return 'Sponsor AD';

    if (dataActivity === 'profit_ultrafit_shop') return 'Place Order';
  };

  useEffect(() => {
    fetchData(filterDates.from, filterDates.to, selectedValue, currentPage);
  }, [filterDates, selectedValue, currentPage]);

  return (
    <div className={historyStyles.history_container}>
      <h2 className={historyStyles.history_title}>
        Current balance: $ {balance}
      </h2>
      <InquiryFilter
        onFilter={(from, to) => {
          setFilterDates({ from, to });
          setCurrentPage(1);
        }}
      />
      <StatusFilter
        listOptions={[
          { text: 'All', value: '' },
          { text: 'Earning', value: 'earn_profit' },
          { text: 'Place Order Refund', value: 'profit_ultrafit_shop_refund' },
          { text: 'Payout', value: 'payout' },
          { text: 'Sponsor AD', value: 'sponsor_ads' },
          { text: 'Place Order', value: 'profit_ultrafit_shop' }
        ]}
        onChange={(value) => {
          setSelectedValue(value);
          setCurrentPage(1);
        }}
      />
      <table className={historyStyles.history_table}>
        <thead>
          <tr>
            <td>Date</td>
            <td>Category</td>
            <td>Deposit</td>
            <td>Withdrawal</td>
            <td>Balance</td>
          </tr>
        </thead>
        <tbody>
          {transactionsList.length ? (
            transactionsList.map((trow, index) => (
              <tr key={index}>
                <td>{renderDate(trow?.createdAt) || 'Date is unavailable'}</td>
                <td className={historyStyles.td_category}>
                  {renderActivity(trow?.activity)}
                </td>
                <td className={historyStyles.td_deposit}>
                  {trow?.amount && trow?.type === 'deposit'
                    ? `${trow.amount} $`
                    : ''}
                </td>
                <td className={historyStyles.td_withdrawal}>
                  {trow?.amount && trow?.type === 'withdrawal'
                    ? `${trow.amount} $`
                    : ''}
                </td>
                <td>{trow.balance || 0} $</td>
              </tr>
            ))
          ) : (
            <tr className={historyStyles.tr_nodata}>
              <td className={historyStyles.td_nodata}>
                <img src={attentionIcon} alt="" />
                <p>There are no transaction history.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalCount > 20 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          totalCount={totalCount}
          pageSize={20}
        />
      )}
    </div>
  );
}

export default TransactionHistory;
