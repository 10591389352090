/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getBalance = (type) =>
  customFetch(`${apiUrl}/profile`)
    .then(async (res) => {
      const response = await res.json();

      if (response.errorStatus) {
        throw new Error(response.message);
      }

      return response.data;
    })
    .then((data) => {
      if (type === 'profit') return data.profitTotal;
      if (type === 'credit') return data.totalBalance;
      if (type === 'earnings') return data.creditEarnings;

      return 0;
    });

export const getTransactions = () =>
  customFetch(`${apiUrl}/transactions`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const postPayoutRequest = (data) =>
  customFetch(`${apiUrl}/transactions/withdrawal`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const buyPremium = (body) =>
  customFetch(`${apiUrl}/transactions/buyPremium`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const addPpOrder = (body) =>
  customFetch(`${apiUrl}/transactions/addPpOrder`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const getNumberOfSales = ({ from, to, page, limit }) =>
  customFetch(
    `${apiUrl}/transactions/numberofsale?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const postWithdrawalRequest = ({ email, amount }) =>
  customFetch(`${apiUrl}/transactions/withdrawal`, {
    method: 'POST',
    body: JSON.stringify({ email, amount }),
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getPremiumHistory = () =>
  customFetch(`${apiUrl}/transactions/premium/history`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const refundRequest = (transactionId, reason) =>
  customFetch(`${apiUrl}/transactions/refund`, {
    method: 'POST',
    body: JSON.stringify({ transactionId, reason }),
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => data);
