/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import PlofixCalendar from '../../PlofixCalendar/Plofixcalendar';

import { renderDate, formatDateUTC } from '../../../utils/function';
import { useClickOutside } from '../../hooks/useClickOutside';

import styles from './MyPageTitle.module.scss';

function MyPageTitle({
  title = '',
  onFilter,
  isMyProjectAssetListPage,
  isRequiredReset = false
}) {
  const { register, setValue, getValues } = useForm();

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dateFromCalendarIsOpened, setDateFromCalendarIsOpened] =
    useState(false);
  const [dateToCalendarIsOpened, setDateToCalendarIsOpened] = useState(false);

  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);

  useClickOutside(dateFromRef, () => setDateFromCalendarIsOpened(false));
  useClickOutside(dateToRef, () => setDateToCalendarIsOpened(false));

  const handleInquiryClick = () => {
    if (dateFrom && !dateTo) {
      onFilter(dateFrom, '');

      return;
    }

    if (!dateFrom && dateTo) {
      onFilter('', dateTo);

      return;
    }

    const dateStart = new Date(dateFrom);
    const dateEnd = new Date(dateTo);

    if (dateStart > dateEnd) {
      alert('End date must be greater then Start date.');
      setDateTo('');
      setValue('dateTo', '');
      return;
    }

    onFilter(dateFrom, dateTo);
  };

  const handleResetClick = () => {
    setDateFrom('');
    setDateTo('');
    setValue('dateTo', '');
    setValue('dateFrom', '');
    onFilter('', '');
  };

  useEffect(() => {
    const myProjectFilterDates = localStorage.getItem('myProjectFilterDates');

    if (myProjectFilterDates && myProjectFilterDates.length > 0) {
      const parsedDates = JSON.parse(myProjectFilterDates);

      if (parsedDates?.from !== undefined || parsedDates?.from !== null) {
        setDateFrom(formatDateUTC(parsedDates.from, 'from'));
        setValue('dateFrom', renderDate(parsedDates.from));
      }

      if (parsedDates?.to !== undefined || parsedDates?.to !== null) {
        setDateTo(formatDateUTC(parsedDates.to, 'to'));
        setValue('dateTo', renderDate(parsedDates.to));
      }
    }
  }, []);

  return (
    <section className={styles.mypage_title}>
      <h1>{title}</h1>

      <form className={styles.iquiry_date_form}>
        <label htmlFor="startDate" ref={dateFromRef}>
          <input
            {...register('dateFrom')}
            id="dateFrom"
            type="text"
            className="from"
            placeholder="Date"
            onClick={() => setDateFromCalendarIsOpened(true)}
          />
          {dateFromCalendarIsOpened && (
            <div
              style={{
                position: 'absolute',
                width: '340px',
                bottom: '-10px',
                left: '-1px'
              }}>
              <PlofixCalendar
                onChange={(value) => {
                  if (isMyProjectAssetListPage) {
                    setDateFrom(value);
                  } else {
                    setDateFrom(formatDateUTC(value, 'from'));
                  }

                  setValue('dateFrom', renderDate(value));
                  setDateFromCalendarIsOpened(false);
                }}
                value={getValues('dateFrom')}
              />
            </div>
          )}
        </label>
        <label htmlFor="endDate" ref={dateToRef}>
          <span>~</span>
          <input
            {...register('dateTo')}
            id="dateTo"
            type="text"
            className="to"
            placeholder="Date"
            onClick={() => setDateToCalendarIsOpened(true)}
          />
          {dateToCalendarIsOpened && (
            <div
              style={{
                position: 'absolute',
                width: '340px',
                bottom: '-10px',
                left: '207px'
              }}>
              <PlofixCalendar
                onChange={(value) => {
                  if (isMyProjectAssetListPage) {
                    setDateTo(value);
                  } else {
                    setDateTo(formatDateUTC(value, 'to'));
                  }

                  setValue('dateTo', renderDate(value));
                  setDateToCalendarIsOpened(false);
                }}
                value={getValues('dateTo')}
                minDate={new Date(getValues('dateFrom'))}
              />
            </div>
          )}
        </label>
        <button
          type="button"
          onClick={handleInquiryClick}
          className={styles.inquiry_button}>
          update
        </button>
        {isRequiredReset && (
          <button
            type="button"
            onClick={handleResetClick}
            className={styles.reset_button}>
            reset
          </button>
        )}
      </form>
    </section>
  );
}

export default MyPageTitle;
