/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cartStyles from './ShopCart.module.scss';
import attentionMark from '../../../img/attention_icon.svg';
import { formatDate, formatNumber } from '../../../utils/function';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { useStore } from '../../../store/hooks';
import {
  decCartItems,
  getCartItems,
  updateQuantity
} from '../../../store/actions/cart';
import {
  getMyCart,
  emptyCart,
  updateProductQuantity,
  removeProductFromCart
} from '../../../api/ultrafitShop';

function ShopCart({ showToast }) {
  const [state, dispatch] = useStore();
  const isProcess = useRef(false);

  const navigate = useNavigate();

  const [listChecked, setListChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [timeReRender, setTimeReRender] = useState(0);

  const fetchData = async () => {
    const response = await getMyCart();
    const carts = response?.orderDetail ?? [];

    let cartsLength = 0;
    if (carts?.length > 0) {
      cartsLength = carts?.reduce(
        (prevVal, currVal) => prevVal + currVal.quantity,
        0
      );
    }
    dispatch(
      getCartItems({
        length: cartsLength,
        listCart: carts
      })
    );
  };

  const handleChangeQuantity = async (id, inches, type = 'inc') => {
    const newMyCarts = state.cart.listCart.map((item) => {
      if (item.productId === id && item.options.inches === inches) {
        if (type === 'dec' && item.quantity > 1) {
          return {
            ...item,
            quantity: --item.quantity
          };
        }
        if (type === 'inc') {
          return {
            ...item,
            quantity: ++item.quantity
          };
        }
        return { ...item };
      }
      return { ...item };
    });

    // update to DB
    try {
      if (isProcess.current) return;
      isProcess.current = true;
      const { quantity } = newMyCarts.find(
        (item) => item.productId === id && item.options.inches === inches
      );
      await updateProductQuantity({
        productId: id,
        quantity,
        inches
      });
      await fetchData();
      // dispatch(updateQuantity(newMyCarts));
    } catch (error) {
      throw new Error(error);
    } finally {
      isProcess.current = false;
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const newListChecked = [];
      state.cart.listCart.forEach((item) => newListChecked.push(item.id));
      setListChecked([...newListChecked]);
      setCheckAll(true);
    } else {
      setListChecked([]);
      setCheckAll(false);
    }
  };

  const handleCheck = (e, idx) => {
    if (e.target.checked) {
      setListChecked([...listChecked, idx]);
    } else {
      const newListChecked = listChecked.filter((item) => item !== idx);
      setListChecked([...newListChecked]);
    }
  };

  const handleRemoveItems = async () => {
    if (!listChecked.length) {
      showToast(TOAST_TYPE.WARN, 'Please select item');
    }
    await Promise.all(
      // eslint-disable-next-line consistent-return
      listChecked.map(async (id) => {
        await removeProductFromCart({ productId: id });
      })
    ).catch((error) => {
      showToast(TOAST_TYPE.ERROR, error?.message ?? 'Error');
    });
    setTimeReRender((prevState) => prevState + 1);
    dispatch(decCartItems(state.cart.length - listChecked.length));
  };

  const handleDeleteCart = async () => {
    await emptyCart();
    setTimeReRender((prevState) => prevState + 1);
    setCheckAll(false);
    dispatch(decCartItems(0));
  };

  useEffect(() => {
    fetchData();
  }, [timeReRender]);

  useEffect(() => {
    if (
      state.cart.listCart.length === listChecked.length &&
      listChecked.length
    ) {
      setCheckAll(true);

      return;
    }

    setCheckAll(false);
  }, [listChecked]);

  const getPrice = (cartItem) => {
    const option = cartItem.ulftrafits.options.find(
      (item) => item.inches === cartItem.options.inches
    );
    return option?.amount || 0;
  };

  return (
    <section className={cartStyles.cart_section}>
      <div className={cartStyles.cart_title}>
        <h1>My Cart</h1>
        <div className={cartStyles.cart_count}>{state.cart.length}</div>
      </div>
      <hr />
      <table className={cartStyles.cart_table}>
        <thead>
          <tr>
            <td>
              <input
                name="all"
                type="checkbox"
                checked={checkAll}
                onChange={(e) => handleCheckAll(e)}
              />
            </td>
            <td>{'  '}</td>
            <td className={cartStyles.td_product}>Product</td>
            <td>Quantity</td>
            <td>Price</td>
          </tr>
        </thead>
        <tbody>
          {state.cart.length ? (
            state.cart.listCart.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={listChecked.includes(item.id)}
                    onChange={(e) => handleCheck(e, item.id)}
                  />
                </td>
                <td className={cartStyles.td_product_img}>
                  <img src={item?.ulftrafits?.image} alt="product" />
                </td>
                <td
                  className={cartStyles.td_product}
                  onClick={() => navigate(`/shop/${item.productId}/info`)}>
                  {item?.ulftrafits?.name} <br />{' '}
                  <span>{item?.ulftrafits?.category1?.category}</span>
                </td>
                <td className={cartStyles.td_quantity}>
                  <div className={cartStyles.quantity_container}>
                    <button
                      type="button"
                      onClick={() =>
                        handleChangeQuantity(
                          item.productId,
                          item.options.inches,
                          'dec'
                        )
                      }>
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      type="button"
                      onClick={() =>
                        handleChangeQuantity(
                          item.productId,
                          item.options.inches,
                          'inc'
                        )
                      }>
                      +
                    </button>
                  </div>
                </td>
                <td>
                  ${' '}
                  {/* {formatNumber(
                    (Number(item.price) + Number(item.option_price ?? 0)) *
                      item.quantity
                  )} */}
                  {formatNumber(getPrice(item) * item.quantity)}
                </td>
              </tr>
            ))
          ) : (
            <div className={cartStyles.shop_empty_list}>
              <img src={attentionMark} alt="attention!" />
              <p>Cart empty</p>
            </div>
          )}
        </tbody>
      </table>
      <div className={cartStyles.cart_controls}>
        <button
          type="button"
          onClick={handleRemoveItems}
          disabled={!state.cart.length}>
          remove selected items
        </button>
        <button
          type="button"
          onClick={handleDeleteCart}
          disabled={!state.cart.length}>
          empty cart
        </button>
      </div>
      <Link
        disabled
        to={`/shop/order?productIds=${listChecked.join(',')}`}
        className={
          listChecked.length && state.cart.length
            ? `${cartStyles.cart_order_link}`
            : `${cartStyles.cart_order_link} ${cartStyles.link_disable}`
        }
        onClick={(e) =>
          (!listChecked.length || !state.cart.length) && e.preventDefault()
        }
        type="button">
        place order
      </Link>
    </section>
  );
}

export default withToast(ShopCart);
