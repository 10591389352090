/* eslint-disable no-alert */
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import apiUrl from '../api/apiUrl';

import beginnerRankImg from '../img/icon_beginner.svg';
import advancedRankImg from '../img/icon_advanced.svg';
import masterRankImg from '../img/icon_master.svg';
import expertRankImg from '../img/icon_expert.svg';

export function formatDate(date) {
  if (!date) return '';
  const d = new Date(date);
  let month = `${d.getUTCMonth() + 1}`;
  let day = `${d.getUTCDate()}`;
  const year = d.getUTCFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [month, day, year].join('-');
}

export function formatDate2(date, type) {
  if (!date) return '';
  const d = new Date(date);
  let month = `${d.getUTCMonth() + 1}`;
  let day = `${d.getUTCDate()}`;
  const year = d.getUTCFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return type === 'from'
    ? `${[year, month, day].join('-')} 00:00:00`
    : `${[year, month, day].join('-')} 23:59:59`;
}

export const formatDateUTC = (date, type) => {
  if (!date) return '';
  const d = new Date(date);

  if (type === 'to') {
    d.setHours(23, 59, 59);
  } else {
    d.setHours(0, 0, 0, 0);
  }

  return d.toISOString().split('T').join(' ').replace('Z', '');
};

export const formatNumber = (number) => {
  if (!number) return 0;
  return number.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

export const addDay = (date, days = 30) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days); // Set now + 30 days as the new date
  return result;
};

export const generateImageSrc = (fileName) => `${apiUrl}/${fileName}`;

// ex string: Thu Jun 09 2011 00:00:00 GMT+0530
// return 2011-06-09
export const convertDate = (str, time = true) => {
  const date = new Date(str);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hour = `00${date.getHours()}`.slice(-2);
  const minute = `00${date.getMinutes()}`.slice(-2);
  const second = `00${date.getSeconds()}`.slice(-2);
  if (time) {
    return `${[date.getFullYear(), month, day].join('-')} ${[
      hour,
      minute,
      second
    ].join(':')}`;
  }
  return [date.getFullYear(), month, day].join('-');
};

export const startDate = (date) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);

  return start;
};

export const endDate = (date) => {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);

  return end;
};

// get Time start of today and end of today
export const getTimeFromToInToDay = () => {
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  const end = new Date();
  end.setHours(23, 59, 59, 999);

  return [start, end];
};

// from date-7 to date
export const startAndEndOfWeek = (date = new Date()) => {
  const end = new Date(date);
  // end.setHours(0, 0, 0, 0);
  const start = new Date(date);
  start.setDate(start.getDate() - 7);
  // start.setHours(23, 59, 59, 999);

  return [start, end];
};

// from date-30 to date
export const startAndEndOfMonth = (date = new Date()) => {
  const end = new Date(date);
  // end.setHours(0, 0, 0, 0);
  const start = new Date(date);
  start.setDate(start.getDate() - 30);
  // start.setHours(23, 59, 59, 999);

  return [start, end];
};

// from date-30*3 to date
export const startAndEndOf3Month = (date = new Date()) => {
  const end = new Date(date);
  // end.setHours(0, 0, 0, 0);
  const start = new Date(date);
  start.setDate(end.getDate() - 30 * 3);
  // start.setHours(23, 59, 59, 999);

  return [start, end];
};

export const listGroupBy = (list, key) =>
  list.reduce((group, item) => {
    group[item[key]] = [...(group[item[key]] || []), item];
    return group;
  }, {});

export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

export const sortByDate = (array, key, type = 'asc') =>
  array.sort((a, b) =>
    type === 'asc'
      ? new Date(b[key]) - new Date(a[key])
      : -1 * (new Date(b[key]) - new Date(a[key]))
  );

export const sortPatternsBy = (array, key, type = 'asc') =>
  array.sort((a, b) =>
    type === 'asc' ? b[key] - a[key] : -1 * (b[key] - a[key])
  );

export const filterPatternsBylargeCategory = (filterOptions) => {
  if (filterOptions.series?.id) return filterOptions.series?.id;
  if (filterOptions['sub-model']?.id) return filterOptions['sub-model']?.id;
  if (filterOptions.model?.id) return filterOptions.model?.id;
  if (filterOptions.make?.id) return filterOptions.make?.id;
  if (filterOptions.year?.id) return filterOptions.year?.id;
  if (filterOptions.location?.id) return filterOptions.location?.id;

  return 0;
};
// patterns.filter(
//   (pattern) =>
//     (filterOptions.location
//       ? filterOptions.location?.value === pattern.models[0].category
//       : true) &&
//     (filterOptions.year
//       ? filterOptions.year?.value === pattern.models[1].category
//       : true) &&
//     (filterOptions.make
//       ? filterOptions.make?.value === pattern.models[2].category
//       : true) &&
//     (filterOptions.model
//       ? filterOptions.model?.value === pattern.models[3].category
//       : true) &&
//     (filterOptions['sub-model']
//       ? filterOptions['sub-model']?.value === pattern.models[4].category
//       : true) &&
//     (filterOptions.series
//       ? filterOptions.series?.value === pattern.models[5].category
//       : true)
// ) || [];

export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

// renders update date
export const renderDate = (pureDate) => {
  if (!pureDate) return '';
  const date = new Date(pureDate);

  const dateMonth =
    date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

  const dateDay = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  return `${dateMonth}. ${dateDay}. ${date.getFullYear()}`;
};

export const setRank = (userRank) => {
  switch (userRank) {
    case 'begginer':
      return beginnerRankImg;
    case 'advanced':
      return advancedRankImg;
    case 'expert':
      return expertRankImg;
    case 'high':
      return masterRankImg;
    default:
      return beginnerRankImg;
  }
};

export const sortPatternsBySponsored = (list) => {
  const listSponsored = list.filter((item) => item.sponsorId !== null);
  const listNonSponsored = list.filter((item) => item.sponsorId === null);
  return [...listSponsored, ...listNonSponsored];
};

export const splitString = (str) =>
  str.length > 500 ? `${str.slice(0, 500)} ...` : str;

// eslint-disable-next-line default-param-last
export const isAllowReview = (orders = [], pId) => {
  let result = false;
  orders.forEach((order) => {
    const status = order.status === 'delivery completed';
    const { products } = order;
    // eslint-disable-next-line consistent-return
    products.forEach((product) => {
      if (Number(product.productId) === Number(pId) && status) {
        result = true;
      }
    });
  });
  return result;
};

export const findMissingId = (patternParamsArray) => {
  const arrLength = patternParamsArray.length;
  const expectedSum = ((arrLength + 1) * (2 + arrLength)) / 2;
  const actualSum = patternParamsArray.reduce((sum, { id }) => sum + id, 0);

  return expectedSum - actualSum; // 1 if arr.length = 0
};

export const filterByColumn = (arr, column) =>
  arr.reduce((category, item) => {
    if (item.column === column) {
      category = item.category;
    }

    return category;
  }, '');

export const genPatternSearch = (pattern) =>
  // eslint-disable-next-line no-unused-expressions
  `${
    filterByColumn(pattern.models, 'region').length
      ? `${filterByColumn(pattern.models, 'region')} >`
      : ''
  } ${
    filterByColumn(pattern.models, 'year').length
      ? `${filterByColumn(pattern.models, 'year')} >`
      : ''
  } ${
    filterByColumn(pattern.models, 'brand').length
      ? `${filterByColumn(pattern.models, 'brand')} >`
      : ''
  } ${
    filterByColumn(pattern.models, 'model').length
      ? `${filterByColumn(pattern.models, 'model')} >`
      : ''
  } ${
    filterByColumn(pattern.models, 'sub_model').length
      ? `${filterByColumn(pattern.models, 'sub_model')} >`
      : ''
  } ${
    filterByColumn(pattern.models, 'series').length
      ? `${filterByColumn(pattern.models, 'series')}`
      : ''
  }`;

export const couponIsExpired = (expirationDate) => {
  if (expirationDate === null) return false;
  const currentDate = new Date();
  const expireDate = new Date(expirationDate);

  return expireDate < currentDate;
};

export const urlToFile = async (url, filename, mimeType) => {
  if (url.startsWith('data:')) {
    const arr = url.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = window.atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }

  const res = await fetch(url);
  const buf = await res.blob();

  return new File([buf], filename, { type: mimeType });
};

// Round decimal Number
// If the last digit of 30.185 is 5 or less, it is 30.18
// If it is 30.186, will it be rounded to 30.19?
export function roundDecimalNumber(number) {
  // parse number to string
  const decimalString = number.toFixed(10).toString();

  // index of dot
  const decimalSeparatorIndex = decimalString.indexOf('.');

  // number before dot
  // eslint-disable-next-line radix
  const integerPart = parseInt(
    decimalString.substring(0, decimalSeparatorIndex)
  );

  // first, second, third decimal digit
  const firstDecimalDigit =
    decimalString.charAt(decimalSeparatorIndex + 1) || 0;
  let secondDecimalDigit = decimalString.charAt(decimalSeparatorIndex + 2) || 0;
  const thirdDecimalDigit =
    decimalString.charAt(decimalSeparatorIndex + 3) || 0;

  if (thirdDecimalDigit > 5) {
    secondDecimalDigit = Number(secondDecimalDigit) + 1;
  }

  return Number(`${integerPart}.${firstDecimalDigit}${secondDecimalDigit}`);
}

// using for SqFt to SqM
export const renderNumberToFixed = (value, multiplyBy = 1) => {
  if (value === null || value === undefined || !value) {
    return 0;
  }

  const numberValue = +value * multiplyBy;

  return numberValue.toFixed(2);
};

export function addDecimalNumber(a, b) {
  const bigA = new Big(a);
  const bigB = new Big(b);
  const result = bigA.plus(bigB);
  return result.toString();
}

export function subtractDecimalNumber(a, b = 0) {
  const bigA = new Big(a);
  const bigB = new Big(b);
  const result = bigA.minus(bigB);
  return result.toString();
}

export const renderNickname = (initialNickname, maxLength = 14) => {
  if (!initialNickname || !initialNickname.length) return '';
  if (initialNickname.length > maxLength)
    return `${initialNickname.slice(0, maxLength)}...`;
  return initialNickname;
};

export const mergeCouponList = (firstList, secondList) => {
  const result = firstList;

  const listIds = firstList.map((item) => item.couponId);

  for (let i = 0; i < secondList.length; i++) {
    if (!listIds.includes(secondList[i].id)) {
      result.push({
        couponId: secondList[i].id,
        coupon: secondList[i]
      });
    }
  }
  return result;
};

export function removeDuplicates(objects) {
  const seen = new Set();
  return objects.filter((obj) => {
    if (seen.has(obj.couponId)) {
      return false;
    }
    seen.add(obj.couponId);
    return true;
  });
}

export const calculateDate = (dateFrom, dateTo) => {
  const start = new Date(dateFrom);

  const end = new Date(dateTo);

  const milliseconds = end - start;

  const numberDate = milliseconds / (1000 * 60 * 60 * 24);

  return numberDate > 0 ? Math.round(numberDate) : 0;
};

export function checkImageSize(file, minWidth, minHeight) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const { width, height } = img;

        if (width >= minWidth && height >= minHeight) {
          resolve(true);
        } else {
          reject(
            new Error(
              `Image dimensions must be at least ${minWidth} x ${minHeight} pixels.`
            )
          );
        }
      };

      img.onerror = () => {
        reject(new Error('Unable to load image.'));
      };
    };

    reader.onerror = () => {
      reject(new Error('Unable to read image file.'));
    };

    reader.readAsDataURL(file);
  });
}

export function checkFileSize(file, maxSize = 5) {
  if ('size' in file) {
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB <= maxSize) {
      return true;
    }
  }

  return false;
}

export function IsNullPart(vehicle, section, part) {
  console.log('vehicle', vehicle, section, part);
  const stringCheck = `${
    vehicle ? `${vehicle?.trim?.()?.toLowerCase?.()}-` : ''
  }${section ? `${section?.trim?.()?.toLowerCase?.()}-` : ''}${
    part ? `${part?.trim?.()?.toLowerCase?.()}` : ''
  }`;

  const checkList = [
    'sedan-inner-inner',
    'coupe-inner-inner',
    'coupe-out side-pillar',
    'suv-inner-inner',
    'truck-inner-inner'
  ];

  if (checkList.includes(stringCheck)) {
    return true;
  }
  return false;
}

export const formatLink = (link) => {
  if (link.includes('http') || link.includes('https')) {
    return link;
  }
  return `https://${link}`;
};

export function openInNewTab(href) {
  if (!/^https?:\/\//i.test(href)) {
    href = `http://${href}`;
  }
  const link = document.createElement('a');
  link.href = href;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

export const isObjectValueNull = (object) => {
  let isNull = true;
  Object.keys(object).forEach((key) => {
    if (object[key]) isNull = false;
  });
  return isNull;
};

export const isValidTags = (tags) => {
  let isValid = true;
  tags.forEach((item) => {
    if (item.length < 3) isValid = false;
  });
  return isValid;
};

export const toFixedNumber = (number) =>
  Math.round(Number(number) * 1000) / 1000;

export const calculateTotalAmount = (
  shipping,
  itemSubtotal,
  discount,
  creditUsed,
  profitUsed
) => {
  let total = addDecimalNumber(shipping, itemSubtotal);
  total = subtractDecimalNumber(total, discount || 0);
  total = subtractDecimalNumber(total, creditUsed || 0);
  total = subtractDecimalNumber(total, profitUsed || 0);
  return total;
};
