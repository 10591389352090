const learnAndSupport = {
  'Upload your pattern': {
    'How to upload your pattern': {
      hash: '#how-to-upload-your-pattern',
      id: 'how-to-upload-your-pattern',
      heading: 'How to upload your pattern',
      list: [
        'Go to the SELL A PATTERN page and click the PATTERN UPLOAD.',
        'Choose your pattern description, including category, vehicle type, pattern detail, and unit price.',
        'You can also add a hashtag that describes your pattern.',
        'You can upload up to 5 files for the exact vehicle simultaneously and select the pattern’s unit price per area.'
      ]
    },
    'Need help finding your vehicle?': {
      hash: '#can-not-find-your-vehicle',
      id: 'can-not-find-your-vehicle',
      heading: 'Need help finding your vehicle?',
      text: 'If you can’t find the vehicle when you try to upload your pattern, the model is not in the system. Click the REGISTER A NEW VEHICLE MODEL button at the top of the vehicle category options on the PATTERN UPLOAD page. We will notify you when the new car is registered in the system. You can upload your pattern afterward.'
    },
    'Pattern Screening': {
      hash: '#pattern-screening',
      id: 'pattern-screening',
      heading: 'Pattern Screening',
      text: 'All  new  patterns  must  go  through  a  screening  process.  On  average,  it  takes  3-5  days  to  screen  and  approve  a  pattern. Sometimes the process may take longer based on the circumstances.'
    },
    'How to make your patterns more visible to downloaders?': {
      hash: '#want-to-make-patterns-more-visible',
      id: 'want-to-make-patterns-more-visible',
      heading: 'How to make your patterns more visible to downloaders?',
      title: 'Sponsor AD',
      text: 'If you purchase Sponsor AD for your patterns, they will appear at the top of the search category. Downloaders can see the sponsored patterns more often when they search for their desired vehicle.'
    },
    Profit: {
      hash: '#profit',
      id: 'profit',
      heading: 'Profit',
      text: 'The sellers are divided into four categories. Based on the category, royalties from the pattern can be anywhere from 30-45% of the original pattern price. Profit can be used in Sponsor AD payments and ULTRAFIT SHOP purchases.'
    },
    'Profit Withdraw': {
      hash: '#profit-withdraw',
      id: 'profit-withdraw',
      heading: 'Profit Withdraw',
      title: 'How do I withdraw my profit?',
      text: 'You can withdraw your profit if the accumulated profit amount exceeds 30 USD.',
      list: [
        'Go to the SELL A PATTERN page and click the Profit Withdraw Request.',
        'Check your profit balance.',
        'Fill out the amount you desire to withdraw and your PayPal email address.',
        'You can see an updated amount balance on the SELL A PATTERN page in a few days.'
      ]
    }
  },
  'Download a Pattern': {
    Credits: {
      hash: '#credit',
      id: 'credit',
      heading: 'Credits',
      text: 'When you log in, your current Credits show on the top of the PLOFIX home page. Credits can be used for making payments on the site and can be recharged as needed. The amount needed to do pattern plotting or shop at ULTRAFIT SHOP will be deducted from the Credits.'
    },
    'Loading Credits': {
      hash: '#loading-credit',
      id: 'loading-credit',
      heading: 'Loading Credits',
      text: 'If you have insufficient Credits to plot, the notification message will appear on the EDIT & PLOT program. Please make sure you recharge your Credit balance before starting plotting.'
    },
    'EDIT & PLOT Subscription Plan': {
      hash: '#edit-plot-monthly-plan',
      id: 'edit-plot-monthly-plan',
      heading: 'EDIT & PLOT Subscription Plan',
      text: 'You must purchase the EDIT & PLOT subscription plan to access the editing and plotting feature. If you purchase the plan, you will be charged a 30 USD subscription fee, whether you use the editor or not.',
      title: 'How to purchase an EDIT & PLOT Subscription Plan?',
      text2:
        'Choose your preferred plan: 1-month, 3-month, 6-month, and 12-month subscription plans. Once the plan expires it will not be renewed automatically. If you wish to continue using EDIT & PLOT, purchase the desired plan again.'
    },
    'Search for a Pattern': {
      hash: '#search-the-pattern',
      id: 'search-the-pattern',
      heading: 'Search for a Pattern',
      list: [
        'You can quickly look up the vehicle pattern through the search field at the top of the main screen.',
        'Find the seller’s pattern that you like to use and add to your favorite pattern.',
        'Collect the patterns you want to edit and plot in your favorite list.',
        'Use them on the EDIT & PLOT.'
      ]
    },
    'Bookmark Pattern': {
      hash: '#bookmarked-patterns',
      id: 'bookmarked-patterns',
      heading: 'Bookmark Pattern',
      list: [
        'Save the patterns by clicking the Bookmark icon on the pattern.',
        'You can check your saved patterns under the Bookmark list anytime.',
        'If you are using the EDIT & PLOT, it is easy to open multiple patterns by clicking the bookmarked patterns.'
      ]
    },
    'Loading Pattern': {
      hash: '#loading-pattern',
      id: 'loading-pattern',
      heading: 'Loading Pattern',
      list: [
        'You have two options: load patterns by multiple sellers in one canvas or load various patterns in each canvas.',
        'You can load a pattern by clicking "Open in Edit & Plot" on the seller’s pattern page.',
        'Saved patterns from the website can be loaded in the EDIT & PLOT.'
      ]
    },
    'Editing Pattern': {
      hash: '#editing-pattern',
      id: 'editing-pattern',
      heading: 'Editing Pattern',
      text: 'You can easily edit the desired pattern in the EDIT & PLOT. You can plot a pattern in its original design or edit it and plot after. You can save edited patterns under "My Project".'
    },
    'My Project': {
      hash: '#my-project',
      id: 'my-project',
      heading: 'My Project',
      text: 'You can go directly to My Project and load patterns to the page, or you can first go to the EDIT& PLOT and load a pattern from My Project.'
    },
    'Plotting with Credits': {
      hash: '#plotting-with-credit',
      id: 'plotting-with-credit',
      heading: 'Plotting with Credits',
      text: 'You can check the cost before you start plotting. The number of Credit needed to plot the pattern is calculated based on the area to be plotted.'
    },
    'Plotting History': {
      hash: '#plotting-history',
      id: 'plotting-history',
      heading: 'Plotting History',
      text: 'The website will automatically save every pattern you plot. You can go to PLOTTING HISTORY under your account name and check the patterns, or you can reload patterns directly from PLOTTING HISTORY in EDIT & PLOT.'
    },
    'Bonus Credit': {
      hash: '#bonus-credit',
      id: 'bonus-credit',
      heading: 'Bonus Credit',
      title1: 'Plotting History',
      text1:
        'You can leave your review on the pattern you have plotted and receive a 0.5 Bonus Credit.',
      title2: 'Verifying ULTRAFIT SHOP products',
      text2:
        'You can get a bonus credit by purchasing a ULTRAFIT SHOP film. Verify your ULTRAFITproduct and get a bonus credit.'
    }
  },
  'Using the EDIT & PLOT': {
    'EDIT & PLOT': {
      hash: '#edit-plot',
      id: 'edit-plot',
      heading: 'EDIT & PLOT',
      text: 'Edit & Plot is a web-based software that allows you to modify the pattern and plot. You can search for a pattern and open it directly or click the EDIT & PLOT and open the pattern from the top of the main page.'
    },
    'Setting Size': {
      hash: '#setting-size',
      id: 'setting-size',
      heading: 'Setting Size',
      text: 'When using EDIT & PLOT, you must manually enter the film measurements. Please make sure the measurements you enter are accurate.'
    },
    'Loading Pattern': {
      hash: '#loading-pattern',
      id: 'loading-pattern',
      heading: 'Loading Pattern',
      text: 'You can search and open a single pattern in a canvas or multiple patterns in the same canvas. Patterns can be loaded directly from Bookmark, My Project, and Plotting History.'
    },
    Editing: {
      hash: '#editing',
      id: 'editing',
      heading: 'Editing',
      text: 'EDIT & PLOT offers many features to edit a pattern, including pattern sorting, merging, drawing, cutting, inserting text, etc.'
    },
    'Saving Pattern': {
      hash: '#saving-pattern',
      id: 'saving-pattern',
      heading: 'Saving Pattern',
      text: 'Edited patterns can be saved under "My Project" on a website and EDIT & PLOT for easier installation in the future. Multiple patterns can be saved together and loaded in the EDIT & PLOT. You can choose patterns from the platform and edit and combine them to create a customized project.'
    },
    'Plot Testing': {
      hash: '#plot-testing',
      id: 'plot-testing',
      heading: 'Plot Testing',
      text: 'You can test your plotter before plotting a pattern. It will plot the word “TEST” to check if your plotter works with our EDIT & PLOT system.'
    },
    AutoNesting: {
      hash: '#autoNesting',
      id: 'autoNesting',
      heading: 'AutoNesting',
      text: 'AutoNesting is a feature used to minimize excessive margin loss while plotting the film. It will automatically reposition the pattern for better optimization. It is an optional feature and can be used when needed.'
    },
    Plotting: {
      hash: '#plotting',
      id: 'plotting',
      heading: 'Plotting',
      text: 'The system will automatically calculate your accumulated pattern area and show how much credit it will cost you to plot. Use AutoNesting if necessary. The pattern preview option is also available for you. You can print a pattern on regular paper. Check settings carefully and plot your pattern!'
    },
    'History Auto-saved': {
      hash: '#history-auto-saved',
      id: 'history-auto-saved',
      heading: 'History Auto-saved',
      text: 'After plotting, the pattern will be automatically saved to PLOTTING HISTORY under your account name. You can also load your previously plotted patterns on EDIT & PLOT. Patterns will remain saved in PLOTTING HISTORY only for a year. If you want to keep the pattern longer, please manually save it under My Project.'
    }
  }
};

export default learnAndSupport;
