/* eslint-disable max-len */
/* eslint-disable prefer-const */
import { useEffect, useState, useRef } from 'react';
import ShopCardList from '../ShopCardList/ShopCardList';
import ShopDropdownSort from '../ShopDropdownSort/ShopDropdownSort';
import ShopHero from '../ShopHero/ShopHero';
import ShopSearch from '../ShopSearch/ShopSearch';
import ShopSideFilter from '../ShopSideFilter/ShopSideFilter';
import shopStyles from './ShopMainPage.module.scss';
import withToast from '../../HOC/withToast';
// import { TOAST_TYPE } from '../../../utils/constants';
import { getUltrafits } from '../../../api/ultrafitShop';
import AdPopUp from '../../AdPopUp/AdPopUp';
import Pagination from '../../Pagination/Pagination';
// import { listGroupBy } from '../../../utils/function';

// { showToast, authToken }
function ShopMainPage() {
  const productsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const [ultrafits, setUltrafits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [selectedSort, setSelectedSort] = useState('popularity');

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  // const [bannerTop, setBannerTop] = useState([]);
  const allCategory = useRef(new Map());

  const allTitle =
    Array.from(
      new Set(selectedCategoryIds?.map((id) => allCategory.current.get(id)))
    )?.join(', ') || 'All Category';

  const onFilter = (selectedCategories) => {
    setCurrentPage(1);

    setSelectedCategoryIds(
      selectedCategories.reduce((selectedIds, category) => {
        if (category.isChecked) {
          selectedIds.push(category.id);
        }

        category?.depth2.forEach((depth2Category) => {
          if (depth2Category.isChecked) {
            selectedIds.push(depth2Category.id);
          }

          depth2Category?.depth3.forEach((depth3Category) => {
            if (depth3Category.isChecked) {
              selectedIds.push(depth3Category.id);
            }
          });
        });

        return selectedIds;
      }, [])
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const getUltrafitCardsRes = await getUltrafits({
        page: currentPage,
        limit: productsOnPage,
        name: searchValue.trim(),
        categoryIds: selectedCategoryIds.join(','),
        orderBy: selectedSort
      });

      setUltrafits(getUltrafitCardsRes.data);
      setTotalCount(getUltrafitCardsRes.count);
    };

    fetchData();
  }, [currentPage, searchValue, selectedCategoryIds, selectedSort]);

  return (
    <>
      <ShopHero />
      <ShopSearch
        value={searchValue}
        onSearch={(value) => {
          setCurrentPage(1);
          setSearchValue(value);
        }}
      />
      <ShopDropdownSort
        allTitle={allTitle}
        value={selectedSort}
        onSelect={(value) => {
          setCurrentPage(1);
          setSelectedSort(value);
        }}
      />
      <section className={shopStyles.shop_products}>
        <div className={shopStyles.shop_products_main}>
          <ShopSideFilter
            allCategory={allCategory}
            onChange={onFilter}
            setSelectedCategoryIds={setSelectedCategoryIds}
          />
          <ShopCardList ultrafits={ultrafits} />
        </div>
        {totalCount > productsOnPage && (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={productsOnPage}
            onPageChange={setCurrentPage}
          />
        )}
      </section>
      <AdPopUp popUpCategory="울트라핏샵" />
    </>
  );
}

export default withToast(ShopMainPage);
