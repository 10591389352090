import { renderDate, filterByColumn } from '../../../../../utils/function';

import attentionIcon from '../../../../../img/attention_icon.svg';
import tableStyles from './SalesInquiryTable.module.scss';

function SalesInquiryTable({ tableData }) {
  const renderNumberToFixed = (value, multiplyBy = 1) => {
    if (value === null || value === undefined || !value) {
      return 0;
    }

    const numberValue = +value * multiplyBy;

    return numberValue.toFixed(2);
  };

  return (
    <table className={tableStyles.inquiry_table}>
      <thead className={tableStyles.table_head}>
        <tr>
          <td className={tableStyles.td_date}>Date</td>
          <td className={tableStyles.td_asset}>Pattern</td>
          <td className={tableStyles.td_nickname}>Nickname</td>
          <td className={tableStyles.td_area}>Plotted area</td>
          <td className={tableStyles.td_earnings}>Profit</td>
        </tr>
      </thead>
      <tbody className={tableStyles.table_body}>
        {tableData.length ? (
          tableData.map((trow, index) => (
            <tr key={index}>
              <td className={tableStyles.td_date}>
                {renderDate(trow.createdAt)}
              </td>
              <td className={tableStyles.td_asset}>
                {trow?.models?.length > 0 ? (
                  <h4>
                    {filterByColumn(trow.models, 'region').length > 0
                      ? `${filterByColumn(trow.models, 'region')}`
                      : ''}{' '}
                    {filterByColumn(trow.models, 'year').length > 0
                      ? ` > ${filterByColumn(trow.models, 'year')}`
                      : ''}{' '}
                    {filterByColumn(trow.models, 'brand').length > 0
                      ? ` > ${filterByColumn(trow.models, 'brand')}`
                      : ''}{' '}
                    {filterByColumn(trow.models, 'model').length > 0
                      ? ` > ${filterByColumn(trow.models, 'model')}`
                      : ''}{' '}
                    {filterByColumn(trow.models, 'sub_model').length > 0
                      ? ` > ${filterByColumn(trow.models, 'sub_model')}`
                      : ''}{' '}
                    {filterByColumn(trow.models, 'series').length > 0
                      ? ` > ${filterByColumn(trow.models, 'series')}`
                      : ''}
                  </h4>
                ) : null}
                {trow.vehicles?.length > 0 ? (
                  <p>
                    {filterByColumn(trow.vehicles, 'part_section').length > 0
                      ? `${filterByColumn(trow.vehicles, 'part_section')}`
                      : ''}{' '}
                    {filterByColumn(trow.vehicles, 'pattern_part').length > 0
                      ? `> ${filterByColumn(trow.vehicles, 'pattern_part')}`
                      : ''}{' '}
                    {filterByColumn(trow.vehicles, 'sub_part').length > 0
                      ? `> ${filterByColumn(trow.vehicles, 'sub_part')}`
                      : ''}
                  </p>
                ) : null}
              </td>
              <td className={tableStyles.td_nickname}>{trow.nickName}</td>
              <td className={tableStyles.td_area}>
                {renderNumberToFixed(trow?.sqft)} Sq.ft <br /> (
                {renderNumberToFixed(trow?.sqft, 0.09290304)}
                Sq.m)
              </td>
              <td className={tableStyles.td_earnings}>
                {trow?.amount ? `$${Number(trow.amount).toPrecision(3)}` : '$0'}
              </td>
            </tr>
          ))
        ) : (
          <tr className={tableStyles.tr_nodata}>
            <td className={tableStyles.td_nodata}>
              <img src={attentionIcon} alt="" />
              <p>There are no sales records.</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default SalesInquiryTable;
