/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getMyProfile = () =>
  customFetch(`${apiUrl}/profile`).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response.data;
  });

export const getProfileById = (id) =>
  customFetch(`${apiUrl}/profile/${id}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const updateMyProfile = (body) =>
  customFetch(`${apiUrl}/profile`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response;
  });

export const getPremiumTime = () =>
  customFetch(`${apiUrl}/profile/premiumTime`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const updateProfileMerchantRole = () =>
  customFetch(`${apiUrl}/profile/merchant`, {
    method: 'PUT'
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getProfileCreditHistory = ({
  from = '',
  to = '',
  activity = '',
  page = 1,
  limit = 20
}) =>
  customFetch(
    `${apiUrl}/profile/credit/histories?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${activity ? `&activity=${activity}` : ''}${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getProfileProfitHistory = ({
  from = '',
  to = '',
  activity = '',
  page = '',
  limit = ''
}) =>
  customFetch(
    `${apiUrl}/profile/profit/histories?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${activity ? `&activity=${activity}` : ''}${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const deleteMyProfile = () =>
  customFetch(`${apiUrl}/profile`, {
    method: 'DELETE'
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        alert('Something went wrong. Try again later.');

        throw new Error(data.message);
      }

      return data;
    });

export const startFreeTrial = () =>
  customFetch(`${apiUrl}/profile/start-free-trial`, {
    method: 'POST'
  })
    .then((res) => res.json())
    .then((data) => data);
