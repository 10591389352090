/* eslint-disable no-alert */
import { useMemo, useState } from 'react';

// import Paypal from '../../../../Paypal/Paypal';

import sponsorModalStyles from './SponsorADItem.module.scss';

import iconClose from '../../../../../img/icon_close.svg';
import PaypalSponsorAD from '../../../../Paypal/PaypalSponsorAD/PaypalSponsorAD';
import { getSponsorshipPrice } from '../../../../../api/sponsors';
import { useAlert } from '../../../../hooks/useAlert/useAlert';

function AddSponsorModal({
  addSponsorModalState,
  setAddSponsorModalState,
  setProfitSponsorIsOpened,
  setTimeFetch
}) {
  const [sponsorShipPrice, setSponsorshipPrice] = useState(0);

  const { onAlert } = useAlert();

  const option = useMemo(() => {
    if (addSponsorModalState.option) {
      return {
        type: 'sponsor',
        patternId: addSponsorModalState.patternId,
        total() {
          return addSponsorModalState.option;
        }
      };
    }
    return {
      type: 'sponsor',
      total() {
        return 0;
      }
    };
  }, [addSponsorModalState.option]);

  const fetchSponsorshipPrice = async () => {
    const sponsorshipPriceData = await getSponsorshipPrice();

    setSponsorshipPrice(sponsorshipPriceData);
  };

  const handleProfitClick = () => {
    if (addSponsorModalState.option !== null) {
      setAddSponsorModalState((currentState) => ({
        ...currentState,
        isOpened: false
      }));
      setProfitSponsorIsOpened(true);
      return;
    }

    onAlert('', 'Okay', 'Please select a Sponsor AD option.');
  };

  useMemo(() => {
    fetchSponsorshipPrice();
  }, []);

  return (
    <div className={sponsorModalStyles.sponsor_modal}>
      <div className={sponsorModalStyles.modal_container}>
        <div className={sponsorModalStyles.modal_header}>
          <h2>ad sponsor ad</h2>
          <button
            type="button"
            onClick={() =>
              setAddSponsorModalState((currentState) => ({
                ...currentState,
                isOpened: false,
                option: null,
                patternId: null
              }))
            }>
            <img src={iconClose} alt="close" />
          </button>
        </div>
        <div
          className={sponsorModalStyles.modal_content}
          style={{ paddingTop: '40px' }}>
          <h3 className={sponsorModalStyles.modal_subtitle}>
            Would you like to display your patterns to more downloaders?
          </h3>
          <p>
            After you applying for a Sponsor AD the pattern will appear among
            the top 6 search results. However, if there are more than 6
            sponsored patterns registered in the system, patterns will appear on
            the top of the search page in randomized order.
          </p>
          <h4 className={sponsorModalStyles}>Sponsor AD</h4>
          <table className={sponsorModalStyles.modal_table}>
            <thead>
              <tr>
                <td>&nbsp;</td>
                <td className={sponsorModalStyles.modal_head_name_td}>Name</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="15days"
                    value={sponsorShipPrice}
                    onChange={(e) =>
                      setAddSponsorModalState((currentState) => ({
                        ...currentState,
                        option:
                          currentState.option === null ? +e.target.value : null
                      }))
                    }
                    checked={addSponsorModalState.option}
                  />
                </td>
                <td className={sponsorModalStyles.modal_name_td}>
                  15-DAY SPONSORSHIP
                </td>
                <td>$ {sponsorShipPrice}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div
          className={sponsorModalStyles.modal_content}
          style={{ paddingBottom: '20px' }}>
          <div className={sponsorModalStyles.modal_btns}>
            <button
              className={sponsorModalStyles.btn_profit}
              type="button"
              onClick={() => handleProfitClick()}>
              profit
            </button>
            <PaypalSponsorAD option={option} setTimeFetch={setTimeFetch} />
            <button className={sponsorModalStyles.btn_cancel} type="button">
              card payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSponsorModal;
