/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';

import { getPopUpList } from '../../../api/adminPopUp';

import heroStyles from './ShopHero.module.scss';
import { formatLink, openInNewTab } from '../../../utils/function';

function ShopHero() {
  const [ultrafitBanner, setUltrafitBanner] = useState('');

  const fetchBanners = async () => {
    const getBannersRes = await getPopUpList(
      'Ultrafit Shop, Top Banner',
      'banner'
    );

    if (getBannersRes.length > 0) setUltrafitBanner(getBannersRes[0]);
  };

  const handleNavigateLink = () => {
    if (ultrafitBanner?.linkTarget) {
      // window.open(formatLink(ultrafitBanner?.linkTarget), '_blank');
      openInNewTab(ultrafitBanner?.linkTarget);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <section
      onClick={handleNavigateLink}
      className={heroStyles.shop_hero}
      style={
        ultrafitBanner
          ? {
              backgroundImage: `url(${ultrafitBanner?.file}) `,
              backgroundRepeat: 'no-repeat',
              backgroundSize:
                ultrafitBanner?.option?.width && ultrafitBanner?.option?.height
                  ? `${ultrafitBanner?.option?.width}px ${ultrafitBanner?.option?.height}px`
                  : 'cover',
              backgroundPosition: 'center'
            }
          : {}
      }>
      <h1>Ultrafit Shop</h1>
    </section>
  );
}

export default ShopHero;
