import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import RegisterNewCarInput from './RegisterNewCarInput';
import registerNewCarStyles from './RegisterNewCar.module.scss';

import { registerNewCar } from '../../../api/patterns';
import RegisterCarModal from './RegisterCarModal/RegisterCarModal';

function RegisterNewCar() {
  const {
    register,
    handleSubmit
    // formState: { isDirty, isValid }
  } = useForm();

  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const submitNewCar = async (data) => {
    const registerNewCarData = [];
    const formData = Object.entries(data);
    for (let i = 0; i < formData.length; i++) {
      registerNewCarData.push({
        category: formData[i][1],
        column: formData[i][0]
      });
    }

    const registerResponse = await registerNewCar(registerNewCarData);

    setRegisterSuccess(true);

    if (registerResponse.errorStatus) {
      setRegisterSuccess(false);
    }

    setModalIsOpen(true);
  };

  return (
    <section className={registerNewCarStyles.container}>
      <h1 className={registerNewCarStyles.title}>
        REGISTER A NEW VEHICLE MODEL
      </h1>
      <form
        onSubmit={handleSubmit(submitNewCar)}
        className={registerNewCarStyles.newcar_form}>
        <h2 className={registerNewCarStyles.newcar_subtitle}>Category</h2>
        <div className={registerNewCarStyles.form_inputs}>
          <RegisterNewCarInput
            category="Country"
            name="region"
            type="text"
            register={{
              ...register('region', { required: true })
            }}
          />
          <RegisterNewCarInput
            category="Year"
            name="year"
            type="number"
            register={{
              ...register('year', { required: true })
            }}
          />
          <RegisterNewCarInput
            category="Make"
            name="brand"
            type="text"
            register={{
              ...register('brand', { required: true })
            }}
          />
          <RegisterNewCarInput
            category="Model"
            name="model"
            type="text"
            register={{
              ...register('model', { required: true })
            }}
          />
          <RegisterNewCarInput
            category="Sub Model"
            name="sub_model"
            type="text"
            register={{
              ...register('sub_model')
            }}
          />
          <RegisterNewCarInput
            category="Series"
            name="series"
            type="text"
            register={{
              ...register('series')
            }}
          />
        </div>
        <button
          className={registerNewCarStyles.register_btn}
          type="submit"
          // disabled={!isDirty || !isValid}
        >
          register
        </button>
      </form>
      {modalIsOpen && (
        <RegisterCarModal
          onConfirm={() => {
            setModalIsOpen(false);
            navigate('/sell/upload');
          }}
          registerSuccess={registerSuccess}
        />
      )}
    </section>
  );
}

export default RegisterNewCar;
