/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getPolicy = (type) =>
  customFetch(`${apiUrl}/policy?type=${type}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data[0].policy;
    });
