/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { UserProfileContext } from '../../store/Context';

import NotificationUnread from './NotificationCount/NotificationUnread';
import profileMenuStyles from './ProfileMenu.module.scss';
import profilePic from '../../img/icon_account.svg';
import bellIcon from '../../img/icon_alert.svg';
import { renderNickname } from '../../utils/function';

function ProfileMenu({ shown, onLogOut, close }) {
  const navigate = useNavigate();

  const { userProfile } = useContext(UserProfileContext) || {};

  const handleLogOut = () => {
    onLogOut();
    sessionStorage.removeItem('userProfile');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    close();
    navigate('/');
  };

  return shown ? (
    <div onClick={() => close()} className={profileMenuStyles.menu_backdrop}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={profileMenuStyles.menu}>
        <nav className={profileMenuStyles.menu_profile}>
          <Link
            to="personal_information/edit_personal_information"
            onClick={close}>
            <div className={profileMenuStyles.profile_pic}>
              <img src={userProfile?.avatar || profilePic} alt="pfp" />
            </div>

            <div className={profileMenuStyles.user}>
              <p className={profileMenuStyles.profile_username}>
                {renderNickname(userProfile?.nickName, 14) || 'No Data'}
              </p>
              <p className={profileMenuStyles.profile_email}>
                {userProfile?.email || 'No Data'}
              </p>
            </div>
          </Link>
        </nav>

        <Link
          to="/notifications"
          onClick={close}
          className={profileMenuStyles.menu_notifications}>
          <img src={bellIcon} alt="notification icon" />
          <p>Notification</p>
          <NotificationUnread />
        </Link>
        <ul className={profileMenuStyles.menu_list}>
          <li>
            <Link to="editor_subscription_history" onClick={close}>
              edit & plot subscription history
            </Link>
          </li>
          <li>
            <Link to="credit_history" onClick={close}>
              credit history
            </Link>
          </li>
          <li>
            <Link to="bonus_credit" onClick={close}>
              bonus credits
            </Link>
          </li>
          <li>
            <Link to="myproject/main" onClick={close}>
              my project
            </Link>
          </li>
          <li>
            <Link to="bookmark" onClick={close}>
              bookmark
            </Link>
          </li>
          <li>
            <Link to="pattern_history" onClick={close}>
              plotting history
            </Link>
          </li>
        </ul>
        <ul className={profileMenuStyles.menu_list}>
          <li>
            <Link to="order_history" onClick={close}>
              my orders
            </Link>
          </li>
          <li>
            <Link to="shop_coupon" onClick={close}>
              coupons
            </Link>
          </li>
        </ul>
        <button
          onClick={handleLogOut}
          type="button"
          className={profileMenuStyles.menu_logout}>
          logout
        </button>
      </div>
    </div>
  ) : null;
}

export default ProfileMenu;
