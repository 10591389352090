/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useEffect } from 'react';

// import { setCreditBalance } from '../../../store/actions/credit';
import { useStore } from '../../../store/hooks';
import { createSponsor } from '../../../api/sponsors';
import { roundDecimalNumber } from '../../../utils/function';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { useAlert } from '../../hooks/useAlert/useAlert';

function PaypalSponsorAD({ option, setTimeFetch = () => {}, showToast }) {
  const price = option && option?.total ? option?.total() : 0;
  const month = option && option?.month ? option?.month : 0;
  const type = option && option?.type ? option.type : '';
  const patternId = option && option?.patternId ? option.patternId : '';
  const paypal = useRef();
  const [state, dispatch] = useStore();
  const { onAlert } = useAlert();

  useEffect(() => {
    if (window.paypalBtn) window.paypalBtn.close();
    window.paypalBtn = window.paypal?.Buttons({
      style: {
        layout: 'horizontal',
        label: 'paypal',
        color: 'blue',
        shape: 'pill',
        height: 42,
        width: 217,
        tagline: 'false'
      },
      // onclick: async function (data, actions) {
      //   console.log('authToken', price);

      // },
      // onInit: function (data, actions) {
      //   // Disable the buttons
      //   // eslint-disable-next-line no-unused-expressions
      //   (!authToken || !price) && actions.disable();
      // },
      //   onClick: async function (data, actions) {
      //     // return actions.reject();
      //     // return false;
      //     if (type === 'sponsor') {
      //       try {
      //         const body = {
      //           amount: price,
      //           start: convertDate(new Date(), true),
      //           end: convertDate(addDay(new Date(), 30), true),
      //           patternId: patternId
      //         };
      //         const res = await buySponsorCredit(authToken, body);
      //         if (res?.status === 'ok') {
      //           return actions.reject();
      //         }
      //       } catch (error) {
      //         console.log(error?.message ?? 'Error');
      //         return actions.reject();
      //       }
      //     }
      //   },
      createOrder: (data, actions) => {
        const authToken = window.localStorage.getItem('token');
        if (!authToken) {
          throw new Error('Please Login');
        } else if (!price) {
          throw new Error('Please select a Sponsor AD option.');
        }
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: roundDecimalNumber(Number(price))
              }
            }
          ]
        });
      },
      onApprove: async function (data, actions) {
        const sponsorStartDate = new Date();
        const sponsorEndDate = new Date();
        sponsorEndDate.setDate(sponsorStartDate.getDate() + 15);

        const body = {
          price: price,
          start: sponsorStartDate,
          end: sponsorEndDate,
          patternId: patternId,
          type: 'paypal',
          orderId: data.orderID
        };
        // console.log('body', body);
        try {
          //   await buyPremium(authToken, body);
          await createSponsor(body);

          showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');
          // const balanceData = await getBalance(authToken);
          // if (balanceData === null) {
          //   dispatch(setCreditBalance(0));
          // } else {
          //   dispatch(setCreditBalance(balanceData));
          // }
          setTimeFetch((prevState) => prevState + 1);
        } catch (error) {
          throw new Error(error?.message ?? 'Error');
        }
      },
      onError: (error) => {
        onAlert('', 'Okay', error.message);
      }
    });
    window.paypalBtn.render(paypal.current);
    // .render(paypal.current);
  }, [price, type, patternId]);

  return <div ref={paypal}></div>;
}

export default withToast(PaypalSponsorAD);
