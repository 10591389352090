import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRecentPatterns } from '../../api/patterns';

import PatternCard from '../PatternCard/PatternCard';
import filmListStyles from './FilmList.module.scss';

// main component
export default function FilmList({ selectedMainCategoryId }) {
  const navigate = useNavigate();

  const [patterns, setPatterns] = useState([]);

  const handleLoadMore = () => {
    navigate('/pattern/list');
  };

  const fetchPatterns = async (catId) => {
    const patternsRes = await getRecentPatterns(catId);

    setPatterns(patternsRes.data);
  };

  useEffect(() => {
    fetchPatterns(selectedMainCategoryId);
  }, [selectedMainCategoryId]);

  return patterns.length ? (
    <section className={filmListStyles.films}>
      <div className={filmListStyles.container}>
        <h2 className={filmListStyles.title}>NEW PATTERNS LIST</h2>
        <div className={filmListStyles.cards_container}>
          {patterns.map((pattern) => (
            <PatternCard key={pattern.patternId} card={pattern} />
          ))}
        </div>
        <button
          onClick={handleLoadMore}
          className={filmListStyles.film_load_more}
          type="button">
          Load More
        </button>
      </div>
    </section>
  ) : null;
}
