import modalStyles from './ApprovalStatus.module.scss';

function RejectedModal({ setModalIsOpened, text }) {
  return (
    <div className={modalStyles.rejected_modal}>
      <div className={modalStyles.modal_container}>
        <h1>Rejected</h1>
        <p>{text}</p>
        <hr />
        <button onClick={() => setModalIsOpened(false)} type="button">
          confirm
        </button>
      </div>
    </div>
  );
}

export default RejectedModal;
