import { NavLink, Outlet } from 'react-router-dom';
import sellPageStyles from '../SellPage.module.scss';
import sellPatternStyles from './SellPattern.module.scss';

function SellPattern() {
  return (
    <section>
      <div className={sellPatternStyles.sell_container}>
        <h1 className={sellPageStyles.title}>Sell pattern</h1>
        <hr />
        <nav className={sellPatternStyles.nav}>
          <NavLink
            to="/sell/pattern/mypattern"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            My Pattern
          </NavLink>
          <NavLink
            to="/sell/pattern/sales"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Sales History
          </NavLink>
          <NavLink
            to="/sell/pattern/status"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Pattern Approval Status
          </NavLink>
          <NavLink
            to="/sell/pattern/sponsor"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Sponsor AD
          </NavLink>
          <NavLink
            to="/sell/pattern/payout"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Profit Withdraw Request
          </NavLink>
          <NavLink
            to="/sell/pattern/transaction_history"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Profit Transaction History
          </NavLink>
        </nav>
      </div>
      <Outlet />
    </section>
  );
}

export default SellPattern;
