/* eslint-disable no-alert */
import { IsNullPart } from '../../../../utils/function';
import { useAlert } from '../../../hooks/useAlert/useAlert';
import subCatStyles from './NewPatternSubCat.module.scss';

function NewPatternSubCatOption({
  id,
  subPartId,
  partType,
  subPartName,
  newPatParams,
  setNewPatternsParams,
  subCategoryList,
  selectedVehicleType
}) {
  const { onAlert } = useAlert();
  const handleChange = () => {
    setNewPatternsParams((currentParams) =>
      currentParams.map((paramObj) => {
        if (paramObj.id === id) {
          // if (
          //   IsNullPart(
          //     selectedVehicleType,
          //     paramObj.section.category,
          //     subPartName
          //   )
          // ) {
          //   onAlert('', 'OK', 'The part does not exist in the pattern.');
          //   return paramObj;
          // }
          return {
            ...paramObj,
            [partType]: {
              name: subPartName,
              id: subPartId,
              subCategory: subCategoryList
            }
          };
        }

        return paramObj;
      })
    );
  };

  return (
    <li className={subCatStyles.subcat_option}>
      <label htmlFor={subPartName}>
        <input
          onChange={handleChange}
          name={subPartName}
          type="checkbox"
          checked={
            newPatParams[partType] === subPartName ||
            newPatParams[partType]?.id === subPartId
          }
        />
        {subPartName}
      </label>
    </li>
  );
}

export default NewPatternSubCatOption;
