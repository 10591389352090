import { useState, memo } from 'react';
import filterStyles from './DropdownFilter.module.scss';
import arrowImg from '../../../img/dropdown_arrow.svg';

function DropdownFilter({ value, onSelect }) {
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  const handleFilterChange = (e) => {
    onSelect(e.target.value);
    setSelectorIsOpen(!selectorIsOpen);
  };

  return (
    <div className={filterStyles.filter_container}>
      <div className={filterStyles.filter_wrapper}>
        <button
          onClick={() => setSelectorIsOpen(!selectorIsOpen)}
          type="button">
          <p style={{ textTransform: 'uppercase' }}>{value}</p>
          <img
            src={arrowImg}
            alt="arrow"
            style={
              selectorIsOpen
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            }
          />
        </button>
        {selectorIsOpen && (
          <label htmlFor="filter">
            <select
              onChange={handleFilterChange}
              name="filter"
              // size="2"
              multiple>
              <option value="General">General</option>
              <option value="Seller">Seller</option>
              <option value="Downloader">Downloader</option>
              <option value="ULTRAFIT SHOP">ULTRAFIT SHOP</option>
            </select>
          </label>
        )}
      </div>
    </div>
  );
}

export default memo(DropdownFilter);
