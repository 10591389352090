function Coupe({ checkedParts }) {
  const style = `
  .cls-1, .cls-2, .cls-3 {
    fill: ${checkedParts?.fullPackage ? '#ff6900' : 'none'};
    stroke: #222;
    stroke-miterlimit: 10;
    stroke-width: .4px;
  }

  .cls-2 {
    isolation: isolate;
  }

  .cls-2, .cls-4 {
    opacity: .4;
  }

  .cls-3 {
    opacity: .4;
  }
  `;

  return (
    <svg
      id="_레이어_2"
      data-name="레이어 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 508.87 809.11"
      style={{ transform: 'rotate(90deg)' }}>
      <defs>
        <style>{style}</style>
      </defs>
      <g id="_레이어_2-2" data-name="레이어 2">
        <g>
          <path
            className="cls-2"
            d="m354.83,638.94c2.53.39,16.13-.61,18.5-.99,2.37-.39,5.39-3.91,6.77-6.28,1.38-2.37,2.86-9.25,1.82-10.95-1.05-1.71-3.58-2.26-7.98-2.04-4.4.22-12.19,1.39-15.36,2.04-2.35.48-3.63,3.19-4.51,5.06s-2.64,5.17-2.64,8.37.88,4.4,3.41,4.79h0Zm-1.1-7.38c.61-1.71,1.98-3.03,5.01-3.74,3.03-.72,14.86-2.53,18.28-2.2,3.41.33,1.98,3.19,1.38,5.5-.61,2.31-2.26,3.91-4.9,4.68-2.64.77-14.15,1.27-17.83,1.27-3.86,0-2.53-3.8-1.93-5.5h-.01Z"
            style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
          />
          <g className="cls-4">
            <path
              className="cls-1"
              d="m341.4,790.48c-1.05.28-3.58,3.25-2.92,3.25h26.15c.88,0,1.98-1.82,1.98-1.82,0,0-3.03-3.3-4.57-3.3s-19.6,1.6-20.64,1.87h0Z"
              style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m254.43,752.89c22.46,0,49.71-2.64,59.28-2.64l18.17-13.71s-43.27,1.65-77.45,1.65-77.45-1.65-77.45-1.65l18.17,13.71c9.58,0,36.83,2.64,59.28,2.64h0Z"
              style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m395.29,748.59s-1.98,1.65-4.13,4.29c-2.15,2.64-6.61,2.81-14.86,4.13-8.26,1.32-13.21,2.81-13.21,2.81,0,0-3.47-5.78-10.4-5.78s-8.26,3.14-11.72,3.14-11.72-6.44-11.72-6.44c0,0-1.16,0-6.94.99-5.78.99-8.09,3.3-8.09,3.3,7.44,4.55,13.05,12.22,13.05,12.22,0,0-8.59,7.76-20.81,7.76h-104.04c-12.22,0-20.81-7.76-20.81-7.76,0,0,5.61-7.67,13.05-12.22,0,0-2.31-2.31-8.09-3.3-5.78-.99-6.94-.99-6.94-.99,0,0-8.26,6.44-11.72,6.44s-4.79-3.14-11.72-3.14-10.4,5.78-10.4,5.78c0,0-4.95-1.49-13.21-2.81-8.26-1.32-12.72-1.49-14.86-4.13-2.15-2.64-4.13-4.29-4.13-4.29l-11.56,4.29s6.77,10.07,8.26,14.53c1.49,4.46,2.97,21.96,2.97,21.96,0,0,6.66,1.54,9.74,1.98,3.08.44,3.63.77,5.28-1.1,1.65-1.87,11.34-12.88,14.75-12.88s40.29,1.87,40.29,2.86-9.8.33-11.23.33,2.09,2.64,3.63,3.3,3.52,1.98,3.52,2.64-2.97,10.02-12.33,17.61h-23.34s-3.74-1.21-5.83-4.51c-2.09-3.3-4.95-7.6-6.39-7.27-1.44.33-4.73.21-6.84-.12-2.11-.33-5.45-1.07-5.37-.58s1.42,2.13,10.44,2.68c0,0,5.28,7.05,7.16,9.8,1.87,2.75,4.29,4.73,12,4.73s23.45.44,28.24-.77c4.79-1.21,7.21-2.64,7.21-2.64,0,0,2.42,1.76,5.17,1.87,2.75.11,3.41-1.21,4.07-2.2s4.18-6.61,4.18-8.26-3.63-3.96-3.63-5.72,2.86-6.39,3.41-6.17c.55.22-2.86,4.62-2.64,5.72.22,1.1,9.36.66,22.9.66h73.54c13.54,0,22.68.44,22.9-.66.22-1.1-3.19-5.5-2.64-5.72s3.41,4.4,3.41,6.17-3.63,4.07-3.63,5.72,3.52,7.27,4.18,8.26,1.32,2.31,4.07,2.2c2.75-.11,5.17-1.87,5.17-1.87,0,0,2.42,1.43,7.21,2.64,4.79,1.21,20.53.77,28.24.77s10.13-1.98,12-4.73c1.87-2.75,7.16-9.8,7.16-9.8,9.03-.55,10.36-2.19,10.44-2.68s-3.26.25-5.37.58c-2.11.33-5.41.45-6.84.12-1.43-.33-4.29,3.96-6.39,7.27-2.1,3.31-5.83,4.51-5.83,4.51h-23.34c-9.36-7.6-12.33-16.95-12.33-17.61s1.98-1.98,3.52-2.64,5.06-3.3,3.63-3.3-11.23.66-11.23-.33,36.88-2.86,40.29-2.86,13.1,11.01,14.75,12.88c1.65,1.87,2.2,1.54,5.28,1.1,3.08-.44,9.74-1.98,9.74-1.98,0,0,1.49-17.5,2.97-21.96,1.49-4.46,8.26-14.53,8.26-14.53l-11.56-4.29h.04Z"
              style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m170.37,793.73c.66,0-1.87-2.97-2.92-3.25s-19.1-1.87-20.64-1.87-4.57,3.3-4.57,3.3c0,0,1.1,1.82,1.98,1.82h26.15Z"
              style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
            />
          </g>
          <g className="cls-4">
            <path
              className="cls-1"
              d="m389.02,738.02c-1.32-.5-13.54-3.3-20.64-1.49-7.1,1.81-30.22,8.42-33.52,9.08-3.3.66-8.09,2.15-6.94,2.97,1.16.83,8.1,5.38,9.58,5.28,3.48-.22,21.8-.5,25.43-.99,3.63-.5,21.63-7.76,24.28-10.07,2.64-2.31,3.14-4.29,1.82-4.79h-.01Z"
              style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m174.01,745.62c-3.3-.66-26.42-7.27-33.52-9.08-7.1-1.81-19.32.99-20.64,1.49s-.83,2.48,1.82,4.79c2.64,2.31,20.64,9.58,24.28,10.07,3.63.5,21.95.77,25.43.99,1.47.1,8.42-4.46,9.58-5.28,1.16-.83-3.63-2.31-6.94-2.97h-.01Z"
              style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
            />
          </g>
          <path
            className="cls-2"
            d="m354.5,485.2c-1.65-2.97-7.27-8.26-9.91-6.61-2.64,1.65-7.76,9.58-13.38,10.98-5.61,1.4-11.89,3.47-14.61,3.63-2.72.17-6.03.74-8.34-.08-2.31-.82-2.56-2.15-5.78-2.15s-8.01,0-9.58.5c-1.57.5-3.14,2.81-3.63,6.44-.5,3.63-2.48,28.24-3.58,46.73-1.1,18.5-3.74,73.54-4.4,79.05-.66,5.5-5.06,15.63-8.59,21.14-3.52,5.5-5.28,6.17-9.25,6.17s-9.03-.22-9.03-.22c0,0-5.06.22-9.03.22s-5.72-.66-9.25-6.17c-3.52-5.5-7.93-15.63-8.59-21.14-.66-5.5-3.3-60.55-4.4-79.05-1.1-18.5-3.08-43.1-3.58-46.73s-2.06-5.95-3.63-6.44c-1.57-.5-6.36-.5-9.58-.5s-3.47,1.32-5.78,2.15c-2.31.83-5.61.25-8.34.08-2.72-.17-9-2.23-14.61-3.63-5.61-1.4-10.73-9.33-13.38-10.98-2.64-1.65-8.26,3.63-9.91,6.61-1.65,2.97-2.31,14.2-2.31,18.83s5.94,110.97,5.94,114.61.99,8.92,1.65,10.9c.66,1.98,15.52,16.51,21.48,19.16,5.96,2.65,29.71,5.61,73.31,5.61s67.35-2.97,73.31-5.61,20.82-17.17,21.48-19.16c.66-1.98,1.65-7.27,1.65-10.9s5.94-109.98,5.94-114.61-.66-15.85-2.31-18.83h.02Zm-134.42,136.16c-2.48.08-13.38-3.39-17.34-4.46-2.98-.81-5.7-3.96-6.36-5.28s-1.24-6.69-.91-9c.33-2.31,1.07-5.78,4.71-5.2,3.63.58,17.34,3.3,19.65,4.54s3.8,4.87,4.46,6.77.74,5.61.91,7.27c.17,1.65-2.64,5.28-5.12,5.37h0Zm92.39-9.75c-.66,1.32-3.38,4.48-6.36,5.28-3.96,1.07-14.86,4.54-17.34,4.46s-5.28-3.72-5.12-5.37.25-5.37.91-7.27,2.15-5.53,4.46-6.77,16.02-3.96,19.65-4.54c3.63-.58,4.38,2.89,4.71,5.2.33,2.31-.25,7.68-.91,9h0Z"
            style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-2"
            d="m128.76,631.68c1.38,2.37,4.4,5.89,6.77,6.28,2.37.39,15.96,1.38,18.5.99,2.53-.39,3.41-1.6,3.41-4.79s-1.76-6.5-2.64-8.37c-.88-1.87-2.16-4.58-4.51-5.06-3.17-.65-10.95-1.82-15.36-2.04-4.4-.22-6.94.33-7.98,2.04-1.05,1.71.44,8.59,1.82,10.95h-.01Zm3.08-6.06c3.41-.33,15.25,1.49,18.28,2.2,3.03.72,4.4,2.04,5.01,3.74.61,1.71,1.93,5.5-1.93,5.5s-15.19-.5-17.83-1.27-4.29-2.37-4.9-4.68-2.04-5.17,1.38-5.5h-.01Z"
            style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
          />
          <g className="cls-4">
            <path
              className="cls-1"
              d="m342.2,458.2c-2.81-2.06-9.99-5.2-13.46-5.78-3.47-.58-4.13-.99-5.12,0-.99.99-7.51,11.64-8.01,13.87-.5,2.23,1.49,5.7,3.72,5.94,2.23.25,4.13,1.24,9.83-1.24,3.76-1.63,12.8-6.85,14.53-8.34s1.32-2.39-1.49-4.46h0Z"
              style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m185.24,452.42c-.99-.99-1.65-.58-5.12,0-3.47.58-10.65,3.72-13.46,5.78-2.81,2.06-3.22,2.97-1.49,4.46s10.77,6.7,14.53,8.34c5.7,2.48,7.6,1.49,9.83,1.24s4.21-3.72,3.72-5.94-7.02-12.88-8.01-13.87h0Z"
              style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
            />
          </g>
          <path
            className="cls-2"
            d="m225.2,510.96c1.49,18.99,3.96,62.75,4.46,66.05.5,3.3,8.75,19.69,13.71,20.33,4.95.64,11.06,0,11.06,0,0,0,6.11.64,11.06,0s13.21-17.03,13.71-20.33,2.97-47.06,4.46-66.05c1.49-18.99,3.47-57.14,3.47-57.14,0,0-27.58,1.65-32.7,1.65s-32.7-1.65-32.7-1.65c0,0,1.98,38.15,3.47,57.14h0Z"
            style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-2"
            d="m283.66,510.96c-1.49,18.99-3.96,62.75-4.46,66.05-.5,3.3-8.75,19.69-13.71,20.33-4.95.64-11.06,0-11.06,0,0,0-6.11.64-11.06,0s-13.21-17.03-13.71-20.33-2.97-47.06-4.46-66.05c-1.27-16.2-2.89-46.31-3.35-54.83h-5.08s2.81,41.78,3.4,47.75,3.22,44.74,3.96,58.45c.74,13.71,3.05,29.38,6.19,35.01s9.91,15.34,10.73,16.33c.83.99,13.38.83,13.38.83,0,0,12.55.17,13.38-.83.83-.99,7.6-10.7,10.73-16.33,3.14-5.63,5.44-21.3,6.19-35.01.74-13.71,3.37-52.48,3.96-58.45.59-5.97,3.4-47.75,3.4-47.75h-5.08c-.45,8.51-2.08,38.63-3.35,54.83h0Z"
            style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-2"
            d="m326.1,347.97c0-5.45-.99-101.72-.99-101.72,0,0-15.36-8.75-70.68-8.75s-70.68,8.75-70.68,8.75c0,0-.99,96.28-.99,101.72s-2.31,21.14-2.31,21.14c0,0,8.45-.26,14.56.24s31.52,3.73,59.42,3.73,53.31-3.23,59.42-3.73,14.56-.24,14.56-.24c0,0-2.31-15.69-2.31-21.14h0Zm-71.67-79.32c-4.29,0-5.67-6.44-5.67-10.46s.88-7.38,5.67-7.38,5.67,3.36,5.67,7.38-1.38,10.46-5.67,10.46Z"
            style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
          />
          <g className="cls-4">
            <polygon
              className="cls-1"
              points="345.17 112.32 309.25 110.09 311.9 138.24 349.63 142.46 359.37 129.16 345.17 112.32"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <polygon
              className="cls-1"
              points="149.48 129.16 159.23 142.46 196.96 138.24 199.6 110.09 163.69 112.32 149.48 129.16"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
          </g>
          <g className="cls-4">
            <path
              className="cls-1"
              d="m287.04,31.24c.17.58,5.7,0,7.27-.41,0,0,1.16-1.07,2.89-3.14,1.73-2.07,2.97-2.23,5.53-2.56,2.56-.33,13.46-1.65,15.44-1.82,1.98-.17,2.72,2.97,4.62,3.06,1.9.08,17.26-2.06,17.5-2.64.25-.58-1.73-5.78-2.48-6.36s-3.3-.25-8.17.5c-4.87.74-14.28,2.81-21.72,3.8-7.43.99-13.62,1.49-14.7,1.98-1.07.5-6.36,7.02-6.19,7.6h.01Z"
              style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m130.08,68.48c2.97,1.07,26.09,17.75,26.09,17.75,5.78-4.21,6.85-5.2,15.28-5.86,8.42-.66,36.52-1.18,36.52-1.18v6.61s11.45-1.1,46.46-1.1,46.46,1.1,46.46,1.1v-6.61s28.1.52,36.52,1.18,9.5,1.65,15.28,5.86c0,0,23.12-16.68,26.09-17.75,2.97-1.07,43.18,0,43.18,0,0,0-5.04-7.18-7.43-11.89-2.39-4.71-4.71-11.39-4.71-11.39,0,0-36.91.5-41.45.5s-9.25,1.4-11.72,1.24c0,0,4.38-6.61,6.94-7.27s21.3-.41,29.48-.99c8.17-.58,15.44-1.32,15.44-1.32v-12.72c0-1.9-1.57-2.56-4.62-2.56s-12.3,2.89-17.26,3.47c-4.95.58-19.49,1.4-23.78,1.65s-8.92,2.81-15.44,7.84c-6.52,5.04-12.05,7.76-16.84,8.26s-18.99.41-24.85.41-7.18-3.05-9.25-3.22c-2.06-.17-42.03.17-42.03.17,0,0-39.96-.33-42.03-.17-2.06.17-3.39,3.22-9.25,3.22s-20.06.08-24.85-.41c-4.79-.5-10.32-3.22-16.84-8.26-6.52-5.04-11.15-7.6-15.44-7.84-4.29-.25-18.83-1.07-23.78-1.65s-14.2-3.47-17.26-3.47-4.62.66-4.62,2.56v12.72s7.27.74,15.44,1.32c8.17.58,26.92.33,29.48.99,2.56.66,6.94,7.27,6.94,7.27-2.48.17-7.18-1.24-11.72-1.24s-41.45-.5-41.45-.5c0,0-2.31,6.69-4.71,11.39-2.39,4.71-7.43,11.89-7.43,11.89,0,0,40.21-1.07,43.18,0h-.02Zm85.62-16.84c5.2-.99,19.98-.41,38.72-.41s33.52-.58,38.72.41c5.2.99,7.27,3.72,7.27,3.72,0,0-12.22,10.49-16.02,11.39-3.8.91-16.76.83-29.97.83s-26.17.08-29.97-.83c-3.8-.91-16.02-11.39-16.02-11.39,0,0,2.06-2.72,7.27-3.72Z"
              style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m186.06,26.37c1.9-.08,2.64-3.22,4.62-3.06,1.98.17,12.88,1.49,15.44,1.82,2.56.33,3.8.5,5.53,2.56s2.89,3.14,2.89,3.14c1.57.41,7.1.99,7.27.41s-5.12-7.1-6.19-7.6-7.27-.99-14.7-1.98-16.84-3.06-21.72-3.8c-4.87-.74-7.43-1.07-8.17-.5s-2.72,5.78-2.48,6.36c.25.58,15.61,2.72,17.5,2.64h0Z"
              style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m221.82,18.03c1.82,1.57,2.14,2.89,6.44,2.89s10.32.33,11.23-.41.74-1.32.74-4.46-.5-6.28.58-6.85c1.07-.58,4.71-.41,13.62-.41s12.55-.17,13.62.41c1.07.58.58,3.72.58,6.85s-.17,3.72.74,4.46,6.94.41,11.23.41,4.62-1.32,6.44-2.89c1.82-1.57,11.23-10.32,12.06-10.9.83-.58-3.39-3.63-8.09-4.95-4.7-1.32-18.41-1.73-23.45-1.9-5.04-.17-13.13,0-13.13,0,0,0-8.09-.17-13.13,0-5.04.17-18.74.58-23.45,1.9-4.71,1.32-8.92,4.38-8.09,4.95s10.24,9.33,12.06,10.9h0Z"
              style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
            />
          </g>
          <path
            className="cls-2"
            d="m103.71,92.17s7.93,6.5,8.37,9.47c.44,2.97,3.85,27.52,5.94,32.81,2.09,5.29,8.26,10.46,13.98,12.99,5.72,2.53,8.26,4.73,8.92,11.56s9.03,42.5,12.11,58.57c3.08,16.07,4.18,29.72,5.61,42.05,1.43,12.33,4.07,28.62,4.07,38.2s.11,35.34-.99,40.73c-1.1,5.39-2.42,22.46-5.06,24.66s-5.94,7.27-11.01,18.28c-5.07,11.01-15.63,34.79-18.5,39.41-2.86,4.62-4.84,8.37-5.72,7.05s-1.76-10.13-.88-11.67,24.66-50.86,25.76-53.94c1.1-3.08,8.15-18.5,7.49-41.17-.66-22.67-8.37-81.47-12.77-94.68-4.4-13.21-7.49-21.8-9.47-22.24-1.98-.44-12.11,7.71-13.21,11.89s-4.62,34.57-4.84,41.83c-.22,7.26-.36,11.23-1.18,13.21-.82,1.98-2.48,2.89-4.71,2.06-2.23-.83-8.67-3.63-17.83-3.39-9.17.25-16.35,5.2-25.84,10.16-9.5,4.95-19.57,11.81-20.89,11.39-1.32-.42-2.31-.99-2.39-6.44-.08-5.45-1.65-23.37-2.31-27.25-.66-3.88-2.15-8.67.58-10.65s15.28-8.17,19.4-15.36c4.13-7.18,10.98-18.66,10.32-29.89-.66-11.23-2.97-18.33-5.37-22.46-2.4-4.13-7.1-10.82-7.1-10.82,0,0,1.24-17.09,1.9-24.19.66-7.1,1.57-20.31,2.97-23.28,1.4-2.97,20.81-23.28,20.81-23.28,0,0,3.88,3.8,6.61,3.63s11.67-6.52,15.25-9.25h-.02Z"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-2"
            d="m347.13,338.56c1.1,5.39,2.42,22.46,5.06,24.66,2.64,2.2,5.94,7.27,11.01,18.28,5.07,11.01,15.63,34.79,18.5,39.41,2.86,4.62,4.84,8.37,5.72,7.05.88-1.32,1.76-10.13.88-11.67-.88-1.54-24.66-50.86-25.76-53.94-1.1-3.08-8.15-18.5-7.49-41.17.66-22.68,8.37-81.47,12.77-94.68,4.4-13.21,7.49-21.8,9.47-22.24,1.98-.44,12.11,7.71,13.21,11.89,1.1,4.18,4.62,34.57,4.84,41.83.22,7.27.36,11.23,1.18,13.21.83,1.98,2.48,2.89,4.71,2.06,2.23-.83,8.67-3.63,17.83-3.39,9.17.25,16.35,5.2,25.84,10.16,9.5,4.95,19.57,11.81,20.89,11.39,1.32-.42,2.31-.99,2.39-6.44.08-5.45,1.65-23.37,2.31-27.25s2.15-8.67-.58-10.65c-2.72-1.98-15.28-8.17-19.4-15.36-4.13-7.18-10.98-18.66-10.32-29.89.66-11.23,2.97-18.33,5.37-22.46,2.39-4.13,7.1-10.82,7.1-10.82,0,0-1.24-17.09-1.9-24.19s-1.57-20.31-2.97-23.28-20.81-23.28-20.81-23.28c0,0-3.88,3.8-6.61,3.63-2.72-.17-11.67-6.52-15.25-9.25,0,0-7.93,6.5-8.37,9.47-.44,2.97-3.85,27.52-5.94,32.81-2.09,5.29-8.26,10.46-13.98,12.99-5.72,2.53-8.26,4.73-8.92,11.56s-9.03,42.5-12.11,58.57c-3.08,16.07-4.18,29.72-5.61,42.05s-4.07,28.62-4.07,38.2-.11,35.34.99,40.73h.02Zm74.83-206.18c5.57,0,10.09,4.52,10.09,10.09s-4.52,10.09-10.09,10.09-10.09-4.52-10.09-10.09,4.52-10.09,10.09-10.09Z"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <g className="cls-4">
            <path
              className="cls-1"
              d="m335.02,192.14c-3.08-1.98-15.09-5.83-29.84-8.47-14.75-2.64-30.93-4.3-50.75-4.3s-36,1.66-50.75,4.3c-14.75,2.64-26.76,6.49-29.84,8.47-3.08,1.98-2.2,4.84-2.42,10.35-.22,5.51-1.1,17.39,2.64,23.34,3.74,5.95,9.91,13.87,13.87,13.87s7.05-6.17,12.33-10.13c5.28-3.96,24.88-8.81,54.17-8.81s48.88,4.84,54.17,8.81c5.28,3.96,8.37,10.13,12.33,10.13s10.13-7.93,13.87-13.87c3.74-5.94,2.86-17.83,2.64-23.34-.22-5.5.66-8.37-2.42-10.35h0Z"
              style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m334.8,178.48l-1.54-7.93s-22.9-2.2-78.83-2.2-78.83,2.2-78.83,2.2l-1.54,7.93s40.07-1.32,80.37-1.32,80.37,1.32,80.37,1.32Z"
              style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m339.56,164.01c-.74-2.81-5.45-11.72-25.6-17.75-20.15-6.03-43.93-7.43-59.53-7.43s-39.39,1.4-59.53,7.43c-20.14,6.03-24.85,14.94-25.6,17.75-.74,2.81.41,3.72,2.89,2.64,2.48-1.07,4.87-1.73,6.52-1.57,1.65.17,2.48,0,8.5-1.9,6.03-1.9,34.84-7.82,67.21-7.82s61.18,5.92,67.21,7.82c6.03,1.9,6.85,2.06,8.5,1.9,1.65-.17,4.05.5,6.52,1.57,2.48,1.07,3.63.17,2.89-2.64h.02Z"
              style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m204.39,138.08c1.32.66,39.88-.91,50.04-.91s48.72,1.57,50.04.91c1.32-.66,2.06-1.49.91-2.31s-1.24-2.97-1.24-6.61-.83-12.47-1.49-15.44-.52-5.04-5.54-6.52c-5.02-1.49-26.16-2.31-42.68-2.31s-37.65.83-42.68,2.31c-5.02,1.49-4.88,3.55-5.54,6.52-.66,2.97-1.49,11.81-1.49,15.44s-.08,5.78-1.24,6.61-.41,1.65.91,2.31h0Zm41.42-23.37c.87-.62,3.43-.7,5.45-.78,2.02-.08,6.77-.29,7.76.62.99.91.7,2.06.87,4.4.17,2.34.38,3.73-.25,4.1-1.11.66-11.06.29-12.18.08-1.11-.21-2.68-5.08-2.68-6.56,0-.91.17-1.24,1.03-1.86h0Zm-3.5-.58c.45.04.29,3.18.33,4.62.04,1.44.08,4.93-.14,5-.54.17-.62-2.68-.62-4.58s-.02-5.08.43-5.04Z"
              style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
            />
          </g>
          <g className="cls-4">
            <path
              className="cls-1"
              d="m475.49,457.24c-2.2.33-7.82,1.98-8.92,3.52-1.1,1.54-.77,12.22-.99,20.15-.22,7.93-1.1,15.85,0,16.62.78.54,8.7.33,9.47,0s.77-33.8,1.1-36c.33-2.2,1.54-4.62-.66-4.29h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m479.76,290.42c.17-4.54.91-30.39.83-34.6s-.74-6.69-2.06-6.85c-1.32-.17-6.11-.99-6.03.74s-.66,17.83-.98,23.45-1.25,17.75-1.25,17.75l7.68,7.27s1.65-3.22,1.82-7.76h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m479.02,309.49s-1.65.99-1.82,6.77c-.17,5.78-2.48,50.37-3.14,72.83s-3.15,64.9-3.15,64.9c0,0,3.14,0,5.87-.99,2.73-.99,4.21-1.65,4.21-1.65l5.45-135.08-7.43-6.77h.01Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m486.45,447.05c1.05-1.05,1.6-2.92,1.57-5.56s1.78-39.08,2.67-54.94c.88-15.85,2.86-36.33,3.3-47.56.44-11.23.39-10.51-1.76-14.64-2.15-4.13-4.46-7.43-4.46-7.43,0,0-1.32,33.03-1.98,47.23-.66,14.2-1.98,42.94-2.64,56.31-.66,13.38-.91,28.32-1.07,29.89-.17,1.57-.66,1.9.58,1.98,1.24.08,3.39-1.49,3.39-1.49,0,0-.63-2.75.41-3.8h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m508.58,268.13c-.33-1.16-5.2-.41-7.51-.83-2.31-.41-4.87-1.24-6.28-1.32-1.4-.08-1.9.41-2.06,3.22-.17,2.81-.33,14.37-.5,21.55-.17,7.18-.99,20.72-.99,22.13s-.41,2.06,1.24,3.72c1.65,1.66,3.39,5.12,3.8,8.17s-.5,16.43-.83,21.05c-.33,4.62-2.12,43.38-2.78,55.05-.66,11.67-1.84,27.03-2.01,32.56s-.5,9.5.08,9.74c.58.25,2.81.17,2.81,2.06s-.88,2.2-.88,2.2c-1.32,1.1-3.96,2.1-4.18,4.9-.22,2.8-.99,10.07-1.1,14.03s0,7.6,0,7.6c0,0-1.54,2.53-1.98,6.5-.44,3.96-.77,10.46,0,11.12s2.42,1.21,4.07,1.43c1.65.22,4.29.44,4.73-.66.44-1.1.77-6.28,0-7.49,0,0,.99-2.01,1.21-7.34.22-5.33.77-23.05,1.1-27.45.33-4.4,1.1-26.2,1.43-37.98.33-11.78,3.52-64.79,4.68-81.96,1.16-17.17,1.82-35.83,3.14-42.77,1.32-6.94,2.48-7.76,2.48-11.06s.66-7.02.33-8.17h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m465.97,450.19c.17-4.46,2.15-27.25,3.47-64.9,1.32-37.65,3.63-71.67,3.63-71.67,0,0-37.49-18.33-46.07-20.97-8.58-2.64-22.79,1.32-25.27,2.48-2.48,1.16-3.8,1.82-3.8,5.12s-1.32,39.14-2.31,54.66c-.99,15.52-4.29,61.6-4.29,61.6,0,0,6.44-.66,6.77,2.81.33,3.47-.5,8.59-3.3,11.06-2.81,2.48-4.46,2.64-4.95.83-.5-1.82-.99-1.36-1.49,1.22s0,6.71,0,6.71c0,0,1.65,4.79,7.93,9.91s12.39,7.76,15.19,8.17c2.81.42,35.5.24,38.15,0,2.64-.24,4.79-1.4,7.76-1.07,2.97.33,6.3,2.15,7.44,1.98,1.15-.17.98-3.47,1.15-7.93h-.01Zm-43.9-129.81c-.33,5.72-4.18,11.6-5.5,12.44-.83.52-1.65-.22-2.86-1.76-1.21-1.54-.99-2.97-.99-4.62s1.21-3.52,3.63-3.63,4.18.44,4.18.44c0,0,.33-2.53.66-5.5.33-2.97,0-7.16,0-7.16,0,0-3.3,2.64-4.73,2.42-1.43-.22-2.86-.99-3.08-2.53s-.11-5.39,1.98-7.71c2.09-2.31,3.08-2.09,5.06,1.32,1.98,3.41,1.98,10.57,1.65,16.29h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
          </g>
          <path
            className="cls-2"
            d="m389.4,467.03c5.06,3.63,4.07,11.56,3.85,18.72s-1.32,39.41-1.87,51.3-.22,35.78.55,44.04,9.14,27.96,9.14,27.96c1.21-.66,3.63-10.46,6.61-14.2,2.97-3.74,16.07-18.72,16.4-19.6s-2.86-4.18-4.07-6.5c-1.21-2.31-4.84-11.12-4.84-23.23s4.29-22.68,14.31-33.03c10.02-10.35,22.68-12.88,26.42-13.21,3.74-.33,7.71-.33,8.04-2.75.33-2.42,1.21-34.9.77-35.78s-1.43-2.09-8.15-1.87c-6.72.22-10.13,1.32-20.04,2.09-9.91.77-27.3-.11-32.81-2.09-5.5-1.98-15.19-13.21-15.63-13.1s-1.54,1.87-2.86,6.06c-1.32,4.19-4.4,9.03-4.4,9.03,0,0,3.52,2.53,8.59,6.17h-.01Zm34.84,15.84c-.58.66-1.9.78-4.24.81-2.34.03-3.81-.01-4.18-.65-.66-1.11-.43-9.84-.23-10.96s5.08-2.68,6.56-2.68c.91,0,1.24.17,1.86,1.03.62.87.7,3.43.78,5.45.08,2.02.33,5.98-.56,6.99h.01Zm-4.68-16.37c1.9,0,5.08-.02,5.04.43-.04.45-3.18.29-4.62.33-1.44.04-4.93.08-5-.14-.17-.54,2.68-.62,4.58-.62h0Z"
            style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-2"
            d="m44.92,496.54c.33,2.42,4.29,2.42,8.04,2.75,3.74.33,16.4,2.86,26.42,13.21,10.02,10.35,14.31,20.92,14.31,33.03s-3.63,20.92-4.84,23.23c-1.21,2.31-4.4,5.61-4.07,6.5.33.89,13.43,15.85,16.4,19.6,2.97,3.74,5.39,13.54,6.61,14.2,0,0,8.37-19.71,9.14-27.96s1.1-32.15.55-44.04c-.55-11.89-1.65-44.15-1.87-51.3-.22-7.16-1.21-15.08,3.85-18.72,5.06-3.63,8.59-6.17,8.59-6.17,0,0-3.08-4.84-4.4-9.03-1.32-4.19-2.42-5.94-2.86-6.06s-10.13,11.12-15.63,13.1c-5.5,1.98-22.9,2.86-32.81,2.09-9.91-.77-13.32-1.87-20.04-2.09-6.72-.22-7.71.99-8.15,1.87-.44.88.44,33.36.77,35.78h0Zm43.43-12.82c-1.9,0-5.08.02-5.04-.43.04-.45,3.18-.29,4.62-.33s4.93-.08,5,.14c.17.54-2.68.62-4.58.62h0Zm-4.68-16.38c.58-.66,1.9-.78,4.24-.81s3.81.01,4.18.65c.66,1.11.43,9.84.23,10.96s-5.08,2.68-6.56,2.68c-.91,0-1.24-.17-1.86-1.03-.62-.87-.7-3.43-.78-5.45-.08-2.02-.33-5.98.56-6.99h-.01Z"
            style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
          />
          <g className="cls-4">
            <path
              className="cls-1"
              d="m30.92,298.18l7.68-7.27s-.93-12.14-1.25-17.75c-.32-5.61-1.06-21.72-.98-23.45.08-1.73-4.71-.91-6.03-.74-1.32.17-1.98,2.64-2.06,6.85-.08,4.21.66,30.05.83,34.6.17,4.54,1.82,7.76,1.82,7.76h-.01Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m42.28,460.76c-1.1-1.54-6.72-3.19-8.92-3.52-2.2-.33-.99,2.09-.66,4.29.33,2.2.33,35.67,1.1,36,.77.33,8.69.54,9.47,0,1.1-.77.22-8.7,0-16.62-.22-7.93.11-18.61-.99-20.15h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m34.8,389.09c-.66-22.46-2.97-67.05-3.14-72.83-.17-5.78-1.82-6.77-1.82-6.77l-7.43,6.77,5.45,135.08s1.49.66,4.21,1.65c2.73.99,5.87.99,5.87.99,0,0-2.49-42.44-3.15-64.9h.01Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m25.71,420.47c-.66-13.38-1.98-42.11-2.64-56.31-.66-14.2-1.98-47.23-1.98-47.23,0,0-2.31,3.3-4.46,7.43-2.15,4.13-2.2,3.41-1.76,14.64s2.42,31.71,3.3,47.56,2.69,52.29,2.67,54.94c-.02,2.65.53,4.51,1.57,5.56,1.05,1.05.41,3.8.41,3.8,0,0,2.15,1.57,3.39,1.49,1.24-.08.74-.41.58-1.98-.17-1.57-.41-16.51-1.07-29.89h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m21.48,473.97s.11-3.63,0-7.6c-.11-3.96-.88-11.23-1.1-14.03-.22-2.8-2.86-3.8-4.18-4.9,0,0-.88-.3-.88-2.2s2.23-1.82,2.81-2.06c.58-.25.25-4.21.08-9.74-.17-5.53-1.35-20.89-2.01-32.56-.66-11.67-2.45-50.42-2.78-55.05-.33-4.62-1.24-18-.83-21.05s2.15-6.52,3.8-8.17c1.65-1.65,1.24-2.31,1.24-3.72s-.83-14.94-.99-22.13c-.17-7.18-.33-18.74-.5-21.55-.17-2.81-.66-3.3-2.06-3.22-1.4.08-3.96.91-6.28,1.32-2.31.41-7.18-.33-7.51.83-.33,1.16.33,4.87.33,8.17s1.16,4.13,2.48,11.06c1.32,6.94,1.98,25.6,3.14,42.77,1.16,17.17,4.35,70.18,4.68,81.96s1.1,33.58,1.43,37.98c.33,4.4.88,22.13,1.1,27.45.22,5.33,1.21,7.34,1.21,7.34-.77,1.21-.44,6.39,0,7.49.44,1.1,3.08.88,4.73.66,1.65-.22,3.3-.77,4.07-1.43s.44-7.16,0-11.12c-.44-3.96-1.98-6.5-1.98-6.5h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
            <path
              className="cls-1"
              d="m120.5,432.42c-.5-2.58-.99-3.04-1.49-1.22s-2.15,1.65-4.95-.83c-2.81-2.48-3.63-7.6-3.3-11.06.33-3.47,6.77-2.81,6.77-2.81,0,0-3.3-46.07-4.29-61.6-.99-15.52-2.31-51.36-2.31-54.66s-1.32-3.96-3.8-5.12c-2.48-1.16-16.68-5.12-25.27-2.48-8.59,2.64-46.07,20.97-46.07,20.97,0,0,2.31,34.02,3.63,71.67,1.32,37.65,3.3,60.44,3.47,64.9.17,4.46,0,7.76,1.15,7.93,1.15.17,4.47-1.65,7.44-1.98,2.97-.33,5.12.83,7.76,1.07,2.64.24,35.34.42,38.15,0,2.81-.42,8.92-3.05,15.19-8.17,6.27-5.12,7.93-9.91,7.93-9.91,0,0,.5-4.13,0-6.71h-.01Zm-25.9-101.34c-1.21,1.54-2.04,2.28-2.86,1.76-1.32-.84-5.17-6.72-5.5-12.44-.33-5.72-.33-12.88,1.65-16.29,1.98-3.41,2.97-3.63,5.06-1.32,2.09,2.31,2.2,6.17,1.98,7.71-.22,1.54-1.65,2.31-3.08,2.53-1.43.22-4.73-2.42-4.73-2.42,0,0-.33,4.18,0,7.16.33,2.97.66,5.5.66,5.5,0,0,1.76-.55,4.18-.44s3.63,1.98,3.63,3.63.22,3.08-.99,4.62h0Z"
              style={checkedParts?.door ? { fill: '#ff6900' } : {}}
            />
          </g>
        </g>
        <g>
          <path
            className="cls-3"
            d="m116.14,307.32c-.45,1.03-.74,11.64,0,12.14s8.26.74,8.67,0,.08-11.89-.91-12.14-7.48-.65-7.76,0Z"
            // style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <path
            className="cls-3"
            d="m392.74,307.32c.45,1.03.74,11.64,0,12.14s-8.26.74-8.67,0-.08-11.89.91-12.14,7.48-.65,7.76,0Z"
            // style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
        </g>
      </g>
    </svg>
  );
}

export default Coupe;
