/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getCategories = () =>
  customFetch(`${apiUrl}/categories`).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response.data;
  });

export const getPatternsCategoriesCount = ({ uploaderId = '', status = '' }) =>
  customFetch(
    `${apiUrl}/patterns/count?${uploaderId ? `&uploaderId=${uploaderId}` : ''}${
      status ? `&status=${status}` : ''
    }`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const getUltrafitCategoriesCount = () =>
  customFetch(`${apiUrl}/ultrafit-shop/count`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });
