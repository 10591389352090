/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const submitContactUs = (contactUsBody) =>
  customFetch(`${apiUrl}/contact-us`, {
    method: 'POST',
    body: JSON.stringify(contactUsBody),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        alert(
          'Something went wrong submiting Contact Us. Please try again later.'
        );
        throw new Error(data.message);
      }
    });
