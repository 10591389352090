import { Outlet } from 'react-router-dom';
import shopStyles from './UltrafitShop.module.scss';

function UltrafitShop() {
  return (
    <main className={shopStyles.shop_main}>
      <Outlet />
    </main>
  );
}

export default UltrafitShop;
