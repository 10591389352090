import { Outlet, NavLink } from 'react-router-dom';
import personalStyles from './PersonalInformation.module.scss';

function PersonalInformation() {
  return (
    <section className={personalStyles.personal_section}>
      <div className={personalStyles.personal_container}>
        <h1 className={personalStyles.personal_title}>MY ACCOUNT MANAGEMENT</h1>
        <hr />
        <nav className={personalStyles.nav}>
          <NavLink
            className={personalStyles.nav_packs}
            to="/personal_information/edit_personal_information"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Edit personal information
          </NavLink>
          <NavLink
            className={personalStyles.nav_subscription}
            to="/personal_information/withdrawal"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Delete my account
          </NavLink>
        </nav>
        <Outlet />
      </div>
    </section>
  );
}

export default PersonalInformation;
