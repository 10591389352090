function SUV({ checkedParts }) {
  const style = `	
   .st0{opacity:0.4;}
   .st1{fill:${
     checkedParts?.fullPackage ? '#ff6900' : 'none'
   };stroke:#222222;stroke-width:0.4;stroke-miterlimit:10;}
   .st2{fill:${checkedParts?.fullPackage ? '#ff6900' : 'none'};}
   .st3{fill:#222222;}
   .st4{opacity:0.4;fill:${
     checkedParts?.fullPackage ? '#ff6900' : 'none'
   };stroke:#222222;stroke-width:0.4;stroke-miterlimit:10;enable-background:new;}
   .st5{fill:${
     checkedParts?.fullPackage ? '#ff6900' : 'none'
   };stroke:#222222;stroke-miterlimit:10;}`;

  return (
    <svg
      version="1.1"
      id="_레이어_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 427.2 801.6"
      style={{
        enableBackground: 'new 0 0 427.2 801.6',
        transform: 'rotate(90deg)'
      }}
      xmlSpace="preserve">
      <style type="text/css">{style}</style>
      <g id="_레이어_2-2_00000010995225082588022390000003675840703751185301_">
        <g id="_레이어_2-2">
          <g>
            <g className="st0">
              <path
                className="st1"
                d="M312.1,698.2L312.1,698.2c-2.9-0.8-6-0.3-14.7,2.2c-8.7,2.5-30.6,4.9-32.8,5.2c-2.2,0.3-1.8,1.3-1.1,2.8
					c0.7,1.5,2.3,3.4,5.5,4.2c3.2,0.7,18.5,1.1,22,0.9s9.8-3.2,15.9-6c6.2-2.8,8.7-4.8,9-6.3C316.2,699.6,314.7,698.9,312.1,698.2z"
                style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M162.2,705.7c-2.2-0.3-24.1-2.7-32.8-5.2c-8.7-2.5-11.8-3.1-14.7-2.2h0c-2.5,0.7-4.1,1.4-3.8,3
					c0.3,1.5,2.8,3.5,9,6.3c6.2,2.8,12.4,5.8,15.9,6s18.7-0.1,21.9-0.9c3.2-0.7,4.8-2.6,5.5-4.2C164,706.9,164.4,706,162.2,705.7z"
                style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="st0">
              <path
                className="st2"
                d="M220.6,737.5c0,0.1,0,0.2,0.1,0.3C220.6,737.7,220.6,737.6,220.6,737.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M226.7,733.8c0,0,0.1-0.1,0.1-0.1C226.7,733.7,226.7,733.7,226.7,733.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M198.6,734.5c0,0,0.1,0.1,0.1,0.1C198.7,734.6,198.7,734.5,198.6,734.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M198.1,734.1c0,0,0.1,0,0.1,0.1C198.2,734.2,198.2,734.1,198.1,734.1z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M218.8,734.3c0,0,0.1,0,0.1,0C218.8,734.4,218.8,734.3,218.8,734.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M243.1,734.8c0,0.1,0,0.3,0.1,0.4C243.1,735,243.1,734.9,243.1,734.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M218.5,734.2c0,0,0.1,0,0.1,0C218.5,734.3,218.5,734.2,218.5,734.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.7,738c0,0.1,0,0.2,0.1,0.3C220.7,738.2,220.7,738.1,220.7,738z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M182.5,735.8c0-1.2,0.5-1.7,1.2-1.9C183,734.1,182.5,734.6,182.5,735.8v11.5h-2c2,0.2,3.7,0.4,3.7,0.4
					l-1.6-0.9v-1.9c0-1.2,0.6-1.9,5.8-1.9h3.2v-7.2L182.5,735.8L182.5,735.8z"
              />
              <path
                className="st2"
                d="M174.8,745.7c0,0.6,2.1,1,4.3,1.3v-13.2C177.5,737.8,174.8,744.8,174.8,745.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M183.9,733.8c0.1,0,0.2,0,0.2,0C184,733.8,183.9,733.8,183.9,733.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M197.7,733.9C197.7,733.9,197.7,733.9,197.7,733.9C197.7,733.9,197.7,733.9,197.7,733.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M226.5,734c0-0.1,0.1-0.1,0.1-0.2C226.6,733.9,226.5,734,226.5,734z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.8,738.4c0,0.1,0,0.2,0.1,0.4C220.8,738.7,220.8,738.6,220.8,738.4z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M219.5,734.9c0,0,0.1,0.1,0.1,0.1C219.6,735,219.6,734.9,219.5,734.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M227,733.5c0,0,0.1,0,0.1,0C227,733.4,227,733.5,227,733.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M241.8,733.5c0.1,0,0.1,0,0.2,0C242,733.5,241.9,733.5,241.8,733.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M226.8,733.6C226.8,733.6,226.9,733.5,226.8,733.6C226.9,733.5,226.9,733.6,226.8,733.6z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M219.7,735.2c0,0,0.1,0.1,0.1,0.2C219.8,735.3,219.8,735.2,219.7,735.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M219,734.5c0,0,0.1,0,0.1,0.1C219.1,734.5,219.1,734.5,219,734.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M242.2,733.6c0.1,0,0.1,0.1,0.2,0.1C242.4,733.7,242.3,733.6,242.2,733.6z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M242.9,734.4c0,0.1,0.1,0.2,0.1,0.3C243,734.6,243,734.5,242.9,734.4z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M242.8,734.1c0.1,0.1,0.1,0.2,0.1,0.2C242.9,734.2,242.8,734.1,242.8,734.1z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M219.3,734.7c0,0,0.1,0.1,0.1,0.1C219.4,734.7,219.3,734.7,219.3,734.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M242.6,733.8c0.1,0,0.1,0.1,0.2,0.2C242.7,733.9,242.6,733.8,242.6,733.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M199.2,735c0.1,0.1,0.1,0.1,0.2,0.2C199.3,735.2,199.3,735.1,199.2,735z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M218.7,751c-0.2-0.2-0.3-0.5-0.3-0.5h-10.1c0,0-0.2,0.3-0.4,0.6c1.1,0.2,2.7,0.3,4.9,0.3
					C215.6,751.5,217.4,751.3,218.7,751L218.7,751z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M226,734.8c0.1-0.1,0.1-0.2,0.2-0.3C226.2,734.5,226.1,734.7,226,734.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M226.3,734.4c0.1-0.1,0.1-0.2,0.1-0.2C226.4,734.2,226.3,734.3,226.3,734.4z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.2,736.2c0,0.1,0.1,0.2,0.1,0.2C220.3,736.4,220.3,736.3,220.2,736.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.8,738.9c0,0.1,0,0.3,0.1,0.4C220.9,739.2,220.9,739,220.8,738.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M225.5,735.8c0.2-0.3,0.4-0.6,0.5-0.9c-1.8,3.1-5,10.4-5,10.4s0.2-3-0.1-5.9l0,0c0,0-2.4-2.8-3.7-2.8h-7.6
					c-1.6,0-4.3,2.8-4.3,2.8v-5.1l0,0v10.7c-0.1-1.8-3.1-6.5-5.5-9.1l0,0h-6.3v7.2h7.9c0.1,0,0.2,0,0.4,0.1c0,0,0.1,0,0.1,0
					c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.4,0.3l0,0c0.8,0.7,1.6,1.8,2.1,2.1c0.7,0.4,3.5,0,3.5,0v1.1h10.5v-1.1
					c0,0,2.9,0.4,3.5,0c0.6-0.4,1.6-2,2.6-2.5l0,0l0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1h7.8v-7.2L225.5,735.8
					L225.5,735.8z"
              />
              <path
                className="st2"
                d="M220.1,735.9c0,0.1,0.1,0.1,0.1,0.2C220.1,736,220.1,735.9,220.1,735.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M206.8,758.8h13.5c2,0,4.1-1.6,3.2-4.7c-1.8-1.6-3.8-2-4.2-2.3c-0.2-0.2-0.5-0.5-0.7-0.8
					c-1.3,0.3-3.1,0.5-5.9,0.5c-2.2,0-3.8-0.1-4.9-0.3c-0.2,0.2-0.4,0.5-0.6,0.6c-0.4,0.3-2.2,0.6-3.9,2
					C203.2,755.1,203.8,758.8,206.8,758.8L206.8,758.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M243.1,735.2c0,0.2,0,0.3,0,0.5H235v7.2h3.2c2.6,0,4,0.2,4.8,0.5v-7.5C243.1,735.6,243.1,735.4,243.1,735.2
					z"
              />
              <path
                className="st2"
                d="M224.1,686.3c-1.2,0-2.4-0.1-3.4,0C221.6,686.3,222.7,686.3,224.1,686.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.4,736.7c0,0.1,0.1,0.2,0.1,0.2C220.4,736.8,220.4,736.7,220.4,736.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M219.9,735.5c0,0.1,0.1,0.1,0.1,0.2C220,735.6,219.9,735.6,219.9,735.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M206.6,686.3c-0.9,0-2.1,0-3.3,0C204.7,686.3,205.8,686.3,206.6,686.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st2"
                d="M220.5,737.1c0,0.1,0.1,0.2,0.1,0.3C220.6,737.3,220.5,737.2,220.5,737.1z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M179.4,703.2c1.1,0,2.1-1.5,1.2-2.9c-0.9-1.4-4.7-5.1-4.9-17.1s0.2-17.2,3.9-18.5c3.7-1.3,7.9-1.4,11.4,0.7
					c3.5,2.1,17.9,11.6,19.5,12.2c1.6,0.6,2.2-0.1,2.6-0.8s0-2.6,0-2.6s-12.4-9.1-19.1-12.2c-6.6-3.1-11.3-2.9-14.9-1.6
					s-7.2,4.1-8.4,11.9c-1.2,7.8,0.3,19.9,2,23.7C174.4,699.9,178.3,703.2,179.4,703.2L179.4,703.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M206.6,686.3c1.8-0.1,2.1-0.5,2.1-2c0-2.3-0.4-2.9-1.9-2.9h-22.2c-4.8,0-5.8,1.3-6.5,2.7
					c-0.4,0.7-0.9,2.2,0.2,2.2s18.7,0,24.2,0c0.2,0,0.5,0,0.7,0C204.5,686.3,205.6,686.2,206.6,686.3L206.6,686.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M168.3,750.6c-2.4-1.7-0.3-4.8,1.9-8.7s6.2-12.8,6.2-12.8l-8,1.2c0,0-5.1,7.9-9.5,15
					c-4.4,7.1-6.6,7.5-11.2,7.5s-8.3-0.1-8.8,1.1c-0.5,1.2,4.8,5.3,9.5,8.6s18.1,2.5,21.9,2.2s4.8-5.3,5.1-8.7
					C175.5,752.3,170.7,752.3,168.3,750.6L168.3,750.6z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M121.8,751.9h8.1c0,0,1.8-14.5,4-19.7c2.2-5.2,3.4-6.4,6.8-6.1s13.9,1.1,21.2,1.1c4,0,6.4,1.5,10.1,1.3
					s5.5-1,3.2-4s-8.2-8.2-8.1-11.2c0.1-3,3.6-5.6,2.9-7.1s-4.1-0.3-5,0.8c-0.9,1.1-1.8,4.5-5,5.9c-3.2,1.4-18.2,2.4-25,1.7
					c-6.8-0.7-22.5-9.1-22.5-9.1l-6.3,5.9c0,0,10.6,9.1,12.8,15.2C121.1,732.5,121.8,751.9,121.8,751.9L121.8,751.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M129.9,752.8h-8.3c0,0-4.3,2.8-5.2,6.7s0.5,4.8,0.5,4.8s0.5-3.2,2.4-3.2s2.3,3.2,2.3,3.2h14.6L129.9,752.8
					L129.9,752.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M147,769.8c-6.2-0.5-11.8-1-14.6-1.3c-4.7-0.5-8.1,0.2-8.1,3.4v21.5c0,2,2.1,2.6,3.7,1.7s2.5-2.4,2.5-5.9
					s-0.9-4.9-2.1-5c-1.2-0.1-1.1-1,0.7-1c1.8,0,2.9-2.5,2.9-4.7s-1.9-4.6-1.9-4.6s1.6,1.8,4.1,1.8s9.3-0.9,11.4-0.7
					c2.1,0.2,6.7,0.3,7.4,0.7s2.1,1.7,2.1,3.5s1.6-2.2,1.3-4.8S153.2,770.3,147,769.8L147,769.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M150.9,775.9c-2.8,0.1-4.8,1.5-4.9,2.1c-0.1,0.6,1.5,3.8,1,4c-0.5,0.2-1.5-4.4-2.4-4.1
					c-0.9,0.3-5.7,3-9.3,4.1c-3.6,1.1-2.5,1.6-0.4,2.6s3.1,3.8,2.8,5.7c-0.4,1.9-2.2,2.8-2,4.4s1.8,2.5,2.8,1.9
					c1-0.6,9.2-8.2,10.2-9.2c1-0.9,2.7-2.4,2.7-3.6s0.4,1.1,0.4,2.2s1.1,2,1.1,2s1-3.4,1.6-6.1C155.1,779.3,153.7,775.8,150.9,775.9
					L150.9,775.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M149.9,790c-1.3-0.4-4.8,4.8-6.1,5.6s-2.4,1.9-4.8,2.1c-2.4,0.3-9.7-0.1-9,1.6c0.7,1.9,9.8,0.8,13,0.8
					s8.6-5.3,9.4-6.2C153.2,793.2,151.2,790.4,149.9,790L149.9,790z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M216.6,677.7c1.6-0.6,16-10.1,19.5-12.2c3.5-2.1,7.7-2,11.4-0.7c3.7,1.3,4.1,6.5,3.9,18.5s-4,15.7-4.9,17.1
					c-0.9,1.4,0.1,2.9,1.2,2.9s5-3.3,6.7-7.1c1.7-3.8,3.2-15.9,2-23.7c-1.2-7.8-4.8-10.6-8.4-11.9s-8.3-1.5-14.9,1.6
					c-6.6,3.1-19.1,12.2-19.1,12.2s-0.4,1.9,0,2.6S215,678.3,216.6,677.7L216.6,677.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M224.1,686.3c0.2,0,0.4,0,0.5,0c5.5,0,23.1,0,24.2,0s0.6-1.5,0.2-2.2c-0.7-1.4-1.7-2.7-6.5-2.7h-22.2
					c-1.5,0-1.9,0.6-1.9,2.9c0,1.5,0.3,1.9,2.2,2C221.7,686.2,222.9,686.3,224.1,686.3L224.1,686.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M203,718.8h-7.6c-3.2,0-7.1-3.2-9.4-5.4c-2.3-2.2-5.4-5.4-5.4-5.4s0,3.2,2.5,7.6s3.8,5.9,3.8,5.9h53.7
					c0,0,1.3-1.5,3.8-5.9c2.5-4.4,2.5-7.6,2.5-7.6s-3.1,3.2-5.4,5.4s-6.2,5.4-9.4,5.4h-7.9c-6,0-8.2-2.5-8.9-4.7
					c-0.6-2-0.7-5.1-1.5-5.9h-0.4c-0.8,0.7-0.8,3.9-1.5,5.9C211.3,716.3,209.1,718.8,203,718.8L203,718.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M288.5,754.8c-0.5-1.2-4.1-1.1-8.8-1.1s-6.8-0.4-11.2-7.5c-4.4-7.1-9.5-15-9.5-15l-8-1.2c0,0,4,9,6.2,12.8
					c2.1,3.9,4.3,7,1.9,8.7s-7.2,1.7-7,5.2c0.3,3.5,1.2,8.4,5.1,8.7s17.3,1,22-2.2C283.7,760.2,289.1,756,288.5,754.8L288.5,754.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M314.6,706.5c0,0-15.7,8.4-22.5,9.1c-6.8,0.7-21.8-0.3-25-1.7s-4.1-4.8-5-5.9c-0.9-1.1-4.3-2.3-5-0.8
					s2.8,4.1,2.9,7.1s-5.8,8.2-8.1,11.2s-0.5,3.8,3.2,4s6.1-1.3,10.1-1.3c7.2,0,17.8-0.8,21.2-1.1s4.6,0.9,6.8,6.1
					c2.2,5.2,4,19.7,4,19.7h8.1c0,0,0.7-19.4,2.8-25.4s12.8-15.2,12.8-15.2L314.6,706.5L314.6,706.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M305.7,753.8h-8.3l-6.3,11.5h14.6c0,0,0.4-3.2,2.3-3.2s2.4,3.2,2.4,3.2s1.4-0.9,0.5-4.8
					C310,756.6,305.7,753.8,305.7,753.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M294.8,769.5c-2.8,0.3-8.4,0.8-14.6,1.3c-6.2,0.5-9.2,2.1-9.5,4.7s1.4,6.6,1.3,4.8s1.3-3,2.1-3.5
					s5.4-0.5,7.4-0.7c2.1-0.2,8.9,0.7,11.4,0.7s4.1-1.8,4.1-1.8s-1.9,2.4-1.9,4.6s1.1,4.7,2.9,4.7s1.9,0.8,0.7,1
					c-1.2,0.1-2.1,1.5-2.1,5s0.9,5,2.5,5.9c1.6,0.9,3.7,0.3,3.7-1.7V773C302.9,769.7,299.5,769.1,294.8,769.5L294.8,769.5z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M275.8,784.9c0,1.2,1.7,2.7,2.7,3.6c1,0.9,9.2,8.6,10.2,9.2c1,0.6,2.6-0.3,2.8-1.9c0.2-1.6-1.6-2.5-2-4.4
					c-0.4-1.9,0.7-4.7,2.8-5.7c2.1-1,3.2-1.5-0.4-2.6c-3.6-1.1-8.4-3.8-9.3-4.1s-1.9,4.3-2.4,4.1c-0.5-0.2,1.1-3.4,1-4
					s-2.1-2-4.9-2.1c-2.8-0.1-4.2,3.4-3.6,6.1c0.6,2.7,1.6,6.1,1.6,6.1s1.1-0.9,1.1-2S275.7,783.7,275.8,784.9L275.8,784.9z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M288.2,798.7c-2.4-0.3-3.5-1.3-4.8-2.1c-1.3-0.8-4.7-6-6.1-5.6s-3.4,3.2-2.5,4c0.8,0.8,6.2,6.2,9.4,6.2
					s12.3,1.1,13-0.8C297.9,798.6,290.6,799,288.2,798.7L288.2,798.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M179.3,686.3c-2.1,0-2.1,3.5-1.5,5.7s2.3,3.2,5.5,3.2s18.4,0.4,19.3-0.5c0.9-0.9,1.9-3.2,2.4-2.9
					s-0.6,3.7,0.3,6.3c0.8,2.2,1.9,4.7,3,4c0,0-2.4-5.6-2.4-8.1s2.4-4.3,2.9-6.2c0.3-1.1-0.7-1.4-2.3-1.5c-0.8,0-1.9,0-3.3,0
					c-0.3,0-0.6,0-0.9,0L179.3,686.3L179.3,686.3z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M224.8,694.7c0.9,0.9,16.1,0.5,19.3,0.5s4.9-1,5.5-3.2s0.6-5.7-1.5-5.7H225c-0.3,0-0.6,0-0.9,0
					c-1.5,0-2.6,0-3.4,0c-1.5,0.1-2.5,0.4-2.2,1.5c0.6,1.9,2.9,3.7,2.9,6.2s-2.4,8.1-2.4,8.1c1.1,0.7,2.2-1.8,3-4
					c0.9-2.6-0.2-6,0.3-6.3S223.9,693.8,224.8,694.7L224.8,694.7z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M241,722.3c-0.8-1-1.9-0.7-3.9-0.7h-41.3c-7.2,0-8.8-0.3-9.9,0.5s-4.6,5.6-4.6,5.6h64
					C245.3,727.8,241.8,723.3,241,722.3L241,722.3z M192.6,726.8c-0.6,0-1.1-0.5-1.1-1s0.5-1,1.1-1s1.1,0.5,1.1,1
					S193.1,726.8,192.6,726.8z M234.1,726.8c-0.6,0-1.1-0.5-1.1-1s0.5-1,1.1-1s1.1,0.5,1.1,1S234.6,726.8,234.1,726.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M179.1,733.8V747c0.5,0.1,1,0.1,1.4,0.2h2v-11.5c0-1.2,0.5-1.7,1.2-1.9c0.1,0,0.1,0,0.2-0.1s0.2,0,0.2,0
					c0.7-0.1,1.4,0,2.1,0h5.4v-3.7h1.9v3.7h3.8c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0.1,0,0.1,0.1
					c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.2,0.3,0.3,0.5,0.5
					c2.4,2.7,5.4,7.3,5.5,9.1v-10.7l0,0c0,0,9.1,0,12.8,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0
					c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
					c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2s0.1,0.1,0.1,0.2s0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
					c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0.1,0.2
					c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1
					c0,0.1,0,0.2,0.1,0.4c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4l0,0l0,0c0.3,2.9,0.1,5.9,0.1,5.9s3.2-7.3,5-10.4c0,0,0,0,0-0.1
					c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
					c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.5,0
					c1,0,3.2,0,5.5,0v-3.4h1.9v3.4c2.9,0,5.6,0,6.3,0c0.2,0,0.4,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1
					c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0,0.1
					c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6v7.5c0.8,0.3,1,0.8,1,1.4l0,0h2.4
					v-12.1c0-2.1-1.2-4.8-1.2-4.8h-64c0,0-1.1,1.8-1.8,3.6c0.4-0.3,0.6-0.5,0.6-0.5S179.7,732.1,179.1,733.8L179.1,733.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M224.7,743.1c0.1,0,0.1-0.1,0.2-0.1C224.9,743,224.8,743,224.7,743.1z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M202.1,743.2c0.1,0.1,0.2,0.2,0.4,0.3C202.4,743.4,202.3,743.3,202.1,743.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M246.6,731c0,0,5.4,13.4,5.4,14.6s-9.5,1.9-9.5,1.9l1.6-0.9v-1.9h-1v-1.4c-0.8-0.3-2.3-0.5-4.8-0.5h-11.4v7
					h-2.1v-6.9c-1,0.5-2.1,2.1-2.6,2.5c-0.7,0.4-3.5,0-3.5,0v1.1h-10.5v-1.1c0,0-2.9,0.4-3.5,0c-0.4-0.3-1.3-1.4-2.1-2.1v6.5h-2.1
					v-7h-12c-5.3,0-5.8,0.7-5.8,1.9v1.9l1.6,0.9c0,0-1.7-0.1-3.7-0.4h-1.4V747c-2.2-0.3-4.3-0.8-4.3-1.3c0-0.9,2.7-7.9,4.3-11.9v0
					c0-0.7,0.2-1.5,0.5-2.3c-1,0.8-3.2,2.6-4.6,4.7c-1.9,2.9-6.2,11-6.2,11s1.8,1.5,4.2,2.8s10.1,2.8,10.1,2.8s-2,0.3-2.3,0.8
					s0,9.6,0.6,10s14.5,0.2,15.5-0.3s3.1-5.1,5.1-7.8c0.5-0.6,1-1.1,1.5-1.5l0,0l0,0c1.7-1.3,3.5-1.7,3.9-2c0.2-0.2,0.4-0.4,0.6-0.6
					c-2.6-0.5-2.9-1.2-3.7-1.2h-18.6h18.6c0.9,0,1.1,0.8,3.7,1.2c0.2-0.3,0.4-0.6,0.4-0.6h10.1c0,0,0.1,0.2,0.3,0.5
					c2.2-0.5,2.8-1.1,3.5-1.1h19.5h-19.5c-0.8,0-1.4,0.6-3.5,1.1c0.2,0.3,0.4,0.6,0.7,0.8c0.4,0.3,2.4,0.7,4.2,2.3c0,0,0,0,0,0
					c0,0,0,0,0,0.1c0.4,0.3,0.8,0.7,1.1,1.2c2,2.7,4.1,7.3,5.1,7.8c1,0.5,14.9,0.7,15.5,0.3s0.9-9.6,0.6-10s-2.3-0.8-2.3-0.8
					s7.7-1.5,10.1-2.8c2.4-1.2,4.2-2.8,4.2-2.8s-4.3-8.2-6.2-11C249.9,733.3,246.6,731,246.6,731L246.6,731z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M201.8,743c0,0,0.1,0,0.1,0C201.9,743,201.8,743,201.8,743z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M179.1,733.8c0.6-1.6,1.1-2.8,1.1-2.8s-0.2,0.2-0.6,0.5C179.3,732.3,179.1,733.1,179.1,733.8L179.1,733.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M179.1,747.2h1.4c-0.5-0.1-1-0.1-1.4-0.2V747.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M243.1,743.4v1.4h1C244.1,744.2,244,743.7,243.1,743.4z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M220.4,758.8h-13.5c-3.1,0-3.6-3.7-3.4-5c0,0,0,0,0,0c-0.1,0.1-1.6,2.2-2.2,3.5c-0.6,1.2-1.4,3-2,5.6
					s0.2,4.5,1.9,4.5h5.7c1.2,0,2.1-2.6,2.1-2.6s1.1,2.6,2.3,2.6h5.1c1.8,0,2.2-2.5,2.2-2.5s1,2.5,2.1,2.5h6c1,0,1-1.4,0.6-5.3
					c-0.4-3.6-3.1-7.5-3.5-8c0,0,0,0,0,0C224.5,757.2,222.3,758.8,220.4,758.8L220.4,758.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="st1"
                points="203.5,753.8 203.4,753.8 203.5,753.8 				"
              />
              <path
                className="st1"
                d="M223.6,754.1C223.6,754.1,223.6,754.1,223.6,754.1C223.6,754.1,223.6,754.1,223.6,754.1
					C223.6,754.1,223.6,754.1,223.6,754.1L223.6,754.1z"
              />
              <path
                className="st1"
                d="M225,743c0.1,0,0.2-0.1,0.3-0.1C225.2,742.9,225.1,742.9,225,743z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M225,743c-0.1,0-0.1,0.1-0.2,0.1l0,0v6.9h2.1v-7h-1.5C225.2,742.9,225.1,742.9,225,743L225,743z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st1"
                d="M201.9,743c0.1,0,0.2,0.1,0.2,0.1C202.1,743.1,202,743.1,201.9,743z"
              />
              <path
                className="st1"
                d="M201.4,742.9c0.1,0,0.2,0,0.4,0.1C201.7,742.9,201.5,742.9,201.4,742.9z"
              />
              <path
                className="st1"
                d="M202.1,743.2c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.1h-1v7h2.1v-6.5l0,0
					C202.4,743.3,202.3,743.3,202.1,743.2L202.1,743.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path className="st3" d="M233.1,735.8v7.2h1.9v-7.2H233.1z" />
              <path
                className="st3"
                d="M235.1,730.1h-1.9v3.4c0.6,0,1.3,0,1.9,0V730.1z"
              />
              <path className="st3" d="M191.6,735.8v7.2h1.9v-7.2H191.6z" />
              <path className="st3" d="M193.5,730.1h-1.9v3.7h1.9V730.1z" />
              <path
                className="st3"
                d="M192.6,724.7c-0.6,0-1.1,0.5-1.1,1s0.5,1,1.1,1s1.1-0.5,1.1-1S193.2,724.7,192.6,724.7z"
              />
              <circle className="st3" cx="234.1" cy="725.8" r="1" />
              <path
                className="st3"
                d="M198.8,734.6c0.1,0.1,0.3,0.3,0.5,0.4C199.1,734.9,198.9,734.7,198.8,734.6z"
              />
              <path
                className="st3"
                d="M197.8,733.9c0.1,0.1,0.2,0.1,0.4,0.2C198,734,197.9,734,197.8,733.9z"
              />
              <path
                className="st3"
                d="M199.4,735.2c0.1,0.2,0.3,0.3,0.5,0.5C199.7,735.6,199.6,735.4,199.4,735.2z"
              />
              <path
                className="st3"
                d="M198.3,734.2c0.1,0.1,0.2,0.2,0.4,0.3C198.5,734.4,198.4,734.3,198.3,734.2z"
              />
              <path
                className="st3"
                d="M183.6,733.9c0.1,0,0.1,0,0.2-0.1C183.8,733.8,183.7,733.9,183.6,733.9z"
              />
              <path
                className="st3"
                d="M197.3,733.8c0.1,0,0.2,0,0.3,0.1C197.6,733.8,197.4,733.8,197.3,733.8h-3.8H197.3z"
              />
              <path
                className="st3"
                d="M186.2,733.8h5.4H186.2c-0.7,0-1.4-0.1-2.1,0C184.8,733.7,185.5,733.8,186.2,733.8z"
              />
              <path
                className="st1"
                d="M199.9,735.8c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.3-0.5-0.4
					c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0,0-0.1,0-0.1,0
					c-0.1,0-0.2-0.1-0.3-0.1h-11.1c-0.7,0-1.4-0.1-2.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.7,0.2-1.2,0.7-1.2,1.9
					L199.9,735.8L199.9,735.8L199.9,735.8z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st3"
                d="M233.1,733.4c-2.4,0-4.5,0-5.5,0C228.6,733.4,230.8,733.4,233.1,733.4L233.1,733.4z"
              />
              <path
                className="st3"
                d="M242.4,733.7c0,0,0.1,0,0.1,0.1C242.5,733.8,242.5,733.7,242.4,733.7z"
              />
              <path
                className="st3"
                d="M241.3,733.5c0.2,0,0.4,0,0.5,0C241.7,733.5,241.5,733.5,241.3,733.5c-0.7,0-3.4,0-6.3,0l0,0
					C237.9,733.5,240.7,733.5,241.3,733.5z"
              />
              <path
                className="st3"
                d="M242,733.5c0.1,0,0.1,0,0.2,0.1C242.2,733.6,242.1,733.6,242,733.5z"
              />
              <path
                className="st3"
                d="M226,734.8C226,734.8,226,734.8,226,734.8C226,734.8,226,734.8,226,734.8z"
              />
              <path
                className="st3"
                d="M226.2,734.5C226.2,734.5,226.3,734.4,226.2,734.5C226.3,734.4,226.2,734.4,226.2,734.5z"
              />
              <path
                className="st3"
                d="M226.4,734.1C226.4,734.1,226.5,734.1,226.4,734.1C226.5,734.1,226.4,734.1,226.4,734.1z"
              />
              <path
                className="st3"
                d="M226.8,733.7C226.8,733.7,226.8,733.6,226.8,733.7C226.8,733.6,226.8,733.6,226.8,733.7z"
              />
              <path
                className="st3"
                d="M226.6,733.9C226.6,733.9,226.6,733.8,226.6,733.9C226.6,733.8,226.6,733.8,226.6,733.9z"
              />
              <path
                className="st3"
                d="M226.9,733.5C226.9,733.5,226.9,733.5,226.9,733.5C226.9,733.5,226.9,733.5,226.9,733.5z"
              />
              <path
                className="st3"
                d="M243.1,735.2C243.1,735.2,243.1,735.2,243.1,735.2C243.1,735.2,243.1,735.2,243.1,735.2z"
              />
              <path
                className="st3"
                d="M243,734.7C243,734.7,243,734.8,243,734.7C243.1,734.8,243.1,734.7,243,734.7z"
              />
              <path
                className="st3"
                d="M242.9,734.3C242.9,734.3,242.9,734.4,242.9,734.3C242.9,734.4,242.9,734.3,242.9,734.3z"
              />
              <path
                className="st3"
                d="M242.7,734C242.7,734,242.8,734,242.7,734C242.8,734,242.8,734,242.7,734z"
              />
              <path
                className="st1"
                d="M243.1,735.2C243.1,735.2,243.1,735.2,243.1,735.2c0-0.2,0-0.3-0.1-0.4c0,0,0,0,0-0.1
					c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1-0.1
					c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.5,0c-0.7,0-3.4,0-6.3,0c-0.6,0-1.3,0-1.9,0
					c-2.4,0-4.5,0-5.5,0c-0.3,0-0.4,0-0.5,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
					c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1-0.1,0.1
					c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1c-0.2,0.3-0.3,0.6-0.5,0.9h17.6C243.1,735.6,243.1,735.4,243.1,735.2L243.1,735.2z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="st3"
                d="M235.1,733.4L235.1,733.4c-0.6,0-1.3,0-1.9,0l0,0C233.8,733.4,234.4,733.4,235.1,733.4z"
              />
              <path
                className="st3"
                d="M209.6,736.5h7.6c1.2,0,3.7,2.8,3.7,2.8l0,0l0,0c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
					c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3
					c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
					c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.2
					c0,0-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
					c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0h-12.8v4.1
					C205.4,738.3,208,736.5,209.6,736.5L209.6,736.5z"
              />
              <path
                className="st3"
                d="M220,735.7c0,0.1,0.1,0.1,0.1,0.2C220.1,735.8,220,735.7,220,735.7z"
              />
              <path
                className="st3"
                d="M220.7,737.8c0,0,0,0.1,0,0.2C220.7,737.9,220.7,737.9,220.7,737.8z"
              />
              <path
                className="st3"
                d="M220.8,738.3c0,0,0,0.1,0,0.1C220.8,738.4,220.8,738.4,220.8,738.3z"
              />
              <path
                className="st3"
                d="M220.6,737.4c0,0,0,0.1,0,0.2C220.6,737.5,220.6,737.4,220.6,737.4z"
              />
              <polygon
                className="st3"
                points="220.9,739.3 220.9,739.3 220.9,739.3 				"
              />
              <path
                className="st3"
                d="M220.8,738.8C220.8,738.8,220.8,738.9,220.8,738.8C220.8,738.9,220.8,738.8,220.8,738.8z"
              />
              <path
                className="st3"
                d="M218.5,734.2c-0.1,0-0.2,0-0.3,0h-12.8c0,0,9.1,0,12.8,0C218.3,734.2,218.4,734.2,218.5,734.2z"
              />
              <path
                className="st3"
                d="M220.5,736.9c0,0.1,0,0.1,0.1,0.2C220.5,737,220.5,737,220.5,736.9z"
              />
              <path
                className="st3"
                d="M219.8,735.3c0,0.1,0.1,0.1,0.1,0.2C219.9,735.5,219.9,735.4,219.8,735.3z"
              />
              <path
                className="st3"
                d="M219.1,734.5c0,0,0.1,0.1,0.1,0.1C219.2,734.6,219.2,734.6,219.1,734.5z"
              />
              <path
                className="st3"
                d="M218.9,734.4c0.1,0,0.1,0,0.2,0.1C219,734.4,218.9,734.4,218.9,734.4z"
              />
              <path
                className="st3"
                d="M219.4,734.8c0,0,0.1,0.1,0.1,0.1C219.5,734.9,219.4,734.8,219.4,734.8z"
              />
              <path
                className="st3"
                d="M220.2,736.1c0,0.1,0.1,0.1,0.1,0.2C220.2,736.2,220.2,736.1,220.2,736.1z"
              />
              <path
                className="st3"
                d="M220.3,736.5c0,0.1,0,0.1,0.1,0.2C220.4,736.6,220.3,736.5,220.3,736.5z"
              />
              <path
                className="st3"
                d="M218.6,734.3c0.1,0,0.1,0,0.2,0.1C218.7,734.3,218.6,734.3,218.6,734.3z"
              />
              <path
                className="st3"
                d="M219.6,735c0,0,0.1,0.1,0.1,0.2C219.7,735.1,219.7,735.1,219.6,735z"
              />
            </g>
          </g>
        </g>
      </g>
      <g className="st0">
        <path
          className="st1"
          d="M70.3,497.8c-14-2.8-26,0-26,0v5.2c0,0,13.9-1.5,28.4,3.5s25.3,13.8,31.2,26.1c5.9,12.3,4.4,24.4,3.1,29
		s-1.8,6.9-1.3,8.8c0.5,1.9,3.2,3.9,4.3,1.5c0,0,4.7-8.6,5-17.1c0.3-8.6-0.5-22.5-10.3-33.7C95,509.8,84.4,500.6,70.3,497.8
		L70.3,497.8L70.3,497.8z"
          style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M144,478.2c-1.1-0.8-0.5-4.9-0.6-5.5c-0.1-0.6-3.9-3.7-4.9-3.8c-0.9-0.1-6.7,6.5-9,7.5
		c-2.3,1-6.5,2.7-12.1,2.7s-25.4,1.4-31.4,1.5c-6,0.1-10.6-0.1-11.6-0.1s-1.5,1.3-1.5,4s0.4,5.3,0.9,7c0.5,1.7,0.7,2.8,3.3,2.8
		s8.5-0.1,17.5,2.3c9,2.5,15.2,8.5,18.1,13.3c2.9,4.8,4.7,12.5,4.4,20.9c-0.3,8.4-0.7,11.1-0.5,12.8s0.5,1.5,4.2,3.2
		c3.6,1.7,9.1,5.3,10.8,9c1.7,3.6,2,7.2,2.5,9.6c0.5,2.4,2.3,4.4,2.3,4.4s3.2-8,3.4-25.5c0.2-17.5-0.8-50.2,0-54
		c0.8-3.8,3.8-8.3,4.7-9.1C145.3,480.5,145,479.1,144,478.2L144,478.2L144,478.2z"
          style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M356.4,497.8c-14,2.8-24.6,12-34.4,23.3c-9.8,11.2-10.6,25.2-10.3,33.7c0.3,8.6,5,17.1,5,17.1
		c1.2,2.4,3.8,0.4,4.3-1.5c0.5-1.9,0-4.1-1.3-8.8c-1.3-4.7-2.8-16.7,3.1-29c5.9-12.3,16.7-21.1,31.2-26.1c14.5-5,28.4-3.5,28.4-3.5
		v-5.2C382.4,497.8,370.5,495,356.4,497.8L356.4,497.8L356.4,497.8z"
          style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M282.8,478.2L282.8,478.2c-1,0.8-1.3,2.3-0.4,3.1c0.9,0.8,3.9,5.3,4.7,9.1c0.8,3.8-0.2,36.5,0,54
		c0.2,17.5,3.4,25.5,3.4,25.5s1.7-2,2.3-4.4c0.5-2.4,0.8-6,2.5-9.6c1.7-3.6,7.2-7.2,10.8-9c3.6-1.7,4-1.5,4.2-3.2
		c0.1-1.7-0.3-4.4-0.5-12.8c-0.3-8.4,1.5-16.1,4.4-20.9s9.1-10.8,18.1-13.3c9-2.5,15-2.3,17.5-2.3s2.7-1.1,3.3-2.8
		c0.5-1.7,0.9-4.3,0.9-7s-0.5-4-1.5-4s-5.6,0.2-11.6,0.1c-6-0.1-25.8-1.5-31.4-1.5c-5.7,0-9.8-1.7-12.1-2.7c-2.3-1-8.1-7.6-9-7.5
		c-1,0.1-4.8,3.2-4.9,3.8C283.3,473.3,283.8,477.5,282.8,478.2L282.8,478.2z"
          style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
        />
      </g>
      <g>
        <g className="st0">
          <polygon
            className="st1"
            points="147.3,461.4 149.3,461.4 170.9,404.6 167.7,404.6 		"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <polygon
            className="st1"
            points="259,404.6 255.8,404.6 277.5,461.4 279.5,461.4 		"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
        </g>
      </g>
      <g className="st0">
        <path
          className="st1"
          d="M213.4,606c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9S214.9,606,213.4,606z"
        />
      </g>
      <g className="st0">
        <path
          className="st1"
          d="M213.4,507.1c-28.5,0-38.8-3.5-45.2-8.3c0,0-9.8-8-11.1-8c-1,0-5.2,8-5.2,8c-0.5,1.1-0.8,2.1-0.9,3
		c-0.5,3.5-0.5,27.8,1.3,53c1.9,25.2,1.5,28.9,6.9,34.5c5.4,5.6,18.5,15.3,21.2,15.3s6.4-2.4,13.9-1.3s10.6,1.5,12.3,2.8
		s1.9,3.1,6.8,3.1s5.1-1.7,6.8-3.1s4.8-1.7,12.3-2.8s11.2,1.3,13.9,1.3s15.8-9.6,21.2-15.3c5.4-5.6,5-9.4,6.9-34.5
		c1.9-25.1,1.9-49.5,1.3-53c-0.1-0.8-0.5-1.9-0.9-3c0,0-4.2-8-5.2-8c-1.3,0-11.1,8-11.1,8C252.1,503.6,241.9,507.1,213.4,507.1
		L213.4,507.1z"
          style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
        />
      </g>
      <g className="st0">
        <path
          className="st1"
          d="M82,462.2c-1.7,0.1-2.6,2.7-1.7,4.1c0.9,1.4,8.6,3.5,11.1,4.7c2.5,1.1,7.8,3.7,8.9,3.6s1.2-1.8,1.2-3.6
		s-0.5-2.4-1.4-3.5s-4.4-5.3-5.3-5.8C93.9,461.1,83,462.2,82,462.2L82,462.2z"
          style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M331.9,461.6c-0.9,0.5-4.4,4.7-5.3,5.8c-0.9,1.1-1.4,1.8-1.4,3.5s0.1,3.5,1.2,3.6c1.1,0.1,6.4-2.5,8.9-3.6
		c2.5-1.1,10.2-3.2,11.1-4.7c0.9-1.4,0-4-1.7-4.1C343.8,462.2,332.8,461.1,331.9,461.6C331.9,461.6,331.9,461.6,331.9,461.6z"
          style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
        />
      </g>
      <g>
        <path className="st4" d="M227.4,93.9h-28" />
        <g className="st0">
          <path
            className="st1"
            d="M26.3,249.9c0,0-3,0.6-4.3,0.7c-1.3,0.1-3.4-0.1-4.8-1.8c-1.4-1.7-3.7-5.5-5.1-4.7s5.5,7.7,3.9,7.7
			s-5.2,0.2-6.8,0.3c-1.6,0.1-2.3,0.9-0.1,1s2.9,1.8,2.9,4.1c0,2.3-1.2,2.9-2.8,3.3s-1.1,4.1-1.1,5.5s2.3,2.1,2.4,4.3
			c0.1,2.2-0.8,3.5-1.6,3.5s-0.5,2.4-1,10.6s-1.1,13.6-2.3,14.9c-1.2,1.2-1.5,3-1.9,7.2s0,3.9,1.6,5s2,7.4,0.6,7.6s-2.5,1.4-2.6,3.9
			c-0.1,2.5-0.9,8.9,0,9.1s3,1.6,3,4.6s-3.6,3.6-4,4.2c-0.4,0.6-0.6,11.6-0.6,13.2s3,2.1,3,5.2s-1.3,3.7-2.9,4.4s-1.3,7.8,0.1,8.5
			c1.4,0.7,3,2.8,3,4.9S4.1,380,2.8,381c-1.3,1-3.4,1.7-2.2,3.3c1.2,1.6,3.4,4.4,3.4,6.4v4c-1.5,1.5-1.7,2.8-1.8,4.9
			c-0.1,2.1-0.1,3.8-1.5,5c-1.4,1.2-0.4,2.6,2.3,5.3s9.8,5.5,11.8,4.5s2.8-1.8,2.8-4.8v-18.5l4-4.4c0.6-18.9,2.6-59.6,4.8-77.7
			c2.2-18.1,3.4-33.5,3.4-41s-0.8-5.7-2-9.3C26.5,255.1,26.3,249.9,26.3,249.9L26.3,249.9L26.3,249.9z M10.7,401.6
			c0,3.1-2.7,1.1-2.7,0v-10.3c0-1.2,2.7,1.6,2.7,2.9V401.6z"
          />
          <path
            className="st1"
            d="M426.2,404.6c-1.4-1.2-1.4-2.9-1.5-5s-0.3-3.4-1.8-4.9v-4c0-2,2.2-4.8,3.4-6.4c1.2-1.6-0.9-2.3-2.2-3.3
			c-1.3-1-2.1-1.4-2.1-3.5s1.6-4.2,3-4.9c1.4-0.7,1.7-7.8,0.1-8.5s-2.9-1.3-2.9-4.4s3-3.6,3-5.2s-0.2-12.6-0.6-13.2s-4-1.2-4-4.2
			s2.1-4.4,3-4.6s0.1-6.6,0-9.1s-1.2-3.7-2.6-3.9s-1-6.5,0.6-7.6c1.6-1.1,2-0.8,1.6-5c-0.4-4.2-0.7-6-1.9-7.2
			c-1.2-1.2-1.8-6.6-2.3-14.9s-0.2-10.6-1-10.6s-1.7-1.3-1.6-3.5c0.1-2.2,2.4-2.9,2.4-4.3s0.5-5.1-1.1-5.5c-1.6-0.4-2.8-1-2.8-3.3
			c0-2.3,0.7-4,2.9-4.1c2.2-0.1,1.5-0.9-0.1-1c-1.6-0.1-5.2-0.3-6.8-0.3s5.3-6.9,3.9-7.7s-3.7,3-5.1,4.7s-3.5,1.9-4.8,1.8
			c-1.3-0.1-4.3-0.7-4.3-0.7s-0.2,5.2-1.4,8.8c-1.2,3.6-2,1.8-2,9.3s1.2,23,3.4,41c2.2,18.1,4.2,58.8,4.8,77.7l4,4.4v18.5
			c0,3,0.8,3.8,2.8,4.8s9.1-1.9,11.8-4.5S427.6,405.8,426.2,404.6L426.2,404.6L426.2,404.6z M418.8,401.5c0,1.1-2.7,3.1-2.7,0v-7.4
			c0-1.3,2.7-4.1,2.7-2.9V401.5z"
          />
        </g>
        <g className="st0">
          <polygon
            className="st1"
            points="107,336.6 143.4,336.6 144.8,331.4 107,331.4 		"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M107,326.1c8.6-0.8,39-5.6,39-5.6c0.5-2.1,0.9-4.4,1.1-6.4l-40.1,3.4L107,326.1L107,326.1L107,326.1z"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M147.3,307.7H107v9.6L147,314C147.4,310.5,147.3,307.7,147.3,307.7z"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <polygon
            className="st1"
            points="283.4,336.6 319.9,336.6 319.9,331.4 282.1,331.4 		"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M279.5,307.7c0,0,0,2.8,0.3,6.3l40.1,3.4v-9.6H279.5z"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M280.9,320.5c0,0,30.4,4.8,39,5.6v-8.7l-40.1-3.4C280,316.1,280.3,318.3,280.9,320.5L280.9,320.5L280.9,320.5
			z"
            style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
          />
        </g>
        <g>
          <g className="st0">
            <path
              className="st5"
              d="M120,121.3c0.3,0.1,0.7,0.3,1.2,0.4C120.8,121.5,120.4,121.4,120,121.3z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st5"
              d="M138.6,123.2h-0.9H138.6z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st5"
              d="M95.7,125c0.6-0.5,1.2-1.2,1.7-1.8C97,123.9,96.3,124.5,95.7,125z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M97.5,123.2c0.7-0.8,1.1-1.6,1.1-2.2c0-1.8-3.5-7.2-5.7-8.5c-0.3-0.2-0.6-0.3-0.8-0.3
				c-1.9,0-4.8,0.8-6.5,2.1c-1.7,1.3-7.3,5.8-7.4,7.2c0,3.1,5.1,4.8,8.5,5.2c3.4,0.4,7,0,7,0s0.7-0.5,1.5-1.2
				c0.1-0.1,0.3-0.2,0.4-0.4C96.4,124.5,97,123.9,97.5,123.2L97.5,123.2L97.5,123.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M143.8,116.6c-0.3-1.3-1-1.3-3.9-1.4s-15.1-1.7-17.2-2c-2.1-0.3-4.8-0.7-5.1,0c-0.3,0.7,0.4,1.8,0.7,3.4
				c0.3,1.7,0,3.6,0,3.6s0.3,0.4,1.3,0.8c0.1,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.7,0.3,1.2,0.4c0.2,0.1,0.5,0.1,0.7,0.2
				c0.5,0.1,1.1,0.3,1.7,0.4c1,0.2,2.1,0.3,3.4,0.4c1.7,0.1,3.4,0.2,5.1,0.3c2,0.1,3.9,0.2,5.6,0.2h0.9c1.8,0,3.2-0.2,3.9-0.5
				C144.3,121.7,144.1,117.9,143.8,116.6L143.8,116.6L143.8,116.6z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M137.7,123.2c-1.6,0-3.5-0.1-5.6-0.2c-1.6-0.1-3.4-0.2-5.1-0.3c-1.3-0.1-2.4-0.2-3.4-0.4
				c-0.6-0.1-1.2-0.2-1.7-0.4c-0.2-0.1-0.5-0.1-0.7-0.2c-0.4-0.1-0.8-0.3-1.2-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c-1-0.4-1.3-0.8-1.3-0.8
				s1,3.8-1.6,5.2s-2.3,1.7-0.8,2.9c1.5,1.2,3.1,1.7,3.1,1.7c1.6,1.4,15.5-0.1,18.8-0.7c3.3-0.6,4.1-0.9,4.4-3
				c0.3-2.1,0.3-3.7,0.3-3.7c-0.7,0.3-2.1,0.5-3.9,0.5H137.7L137.7,123.2L137.7,123.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M137.7,123.2c-1.6,0-3.5-0.1-5.6-0.2C134.1,123.1,136,123.2,137.7,123.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M121.9,121.9c0.5,0.1,1.1,0.3,1.7,0.4C123,122.1,122.4,122,121.9,121.9z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M132.1,123c-1.6-0.1-3.4-0.2-5.1-0.3c-1.3-0.1-2.4-0.2-3.4-0.4c1,0.2,2.1,0.3,3.4,0.4
				C128.7,122.8,130.5,122.9,132.1,123z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M118,123c0.1-0.8,0.2-1.7,0.3-2.5h-10.4c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2
				s1.2,0.5,1.2,1.2v0h10.4c0.1-0.8,0-1.5-0.3-2.3c-0.6-1.6-1.6-2.6-3.4-2.7c-1.8-0.1-6.3-3.3-6.3-3.3s-12-0.3-16.1,0
				c0.2,0,0.5,0.1,0.8,0.3c2.2,1.3,5.7,6.8,5.7,8.5c0,0.6-0.5,1.4-1.1,2.2c-0.5,0.6-1.2,1.2-1.7,1.8c-0.2,0.1-0.3,0.3-0.4,0.4
				c-0.8,0.7-1.5,1.2-1.5,1.2s1.8,0.8,4.8,1.5c2.5,0.6,2.7-0.5,2.6-1.1c0.5,1.9,4.8,0.7,7.7,0.3C112,127.1,117.9,124.7,118,123
				C118,123.1,118,123.1,118,123z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M106.7,119.3c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2h-1h1v0
				C107.9,119.8,107.3,119.3,106.7,119.3z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
          </g>
          <g className="st0">
            <path
              className="st5"
              d="M330.7,124.6c-0.5-0.4-0.9-0.9-1.3-1.4C329.8,123.7,330.2,124.2,330.7,124.6z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st5"
              d="M289.2,123.2h-0.9H289.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st5"
              d="M306.8,121.3c-0.3,0.1-0.7,0.3-1.2,0.4C306.1,121.5,306.5,121.4,306.8,121.3z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M341.2,114.3c-1.7-1.3-4.5-2.1-6.5-2.1c-0.2,0-0.5,0.1-0.8,0.3c-2.2,1.3-5.7,6.8-5.7,8.5
				c0,0.6,0.5,1.4,1.1,2.2c0.4,0.5,0.8,0.9,1.3,1.4c0.3,0.3,0.6,0.6,0.9,0.8c0.9,0.7,1.5,1.2,1.5,1.2s3.6,0.4,7,0
				c3.4-0.4,8.5-2.1,8.5-5.2C348.6,120.1,342.9,115.6,341.2,114.3L341.2,114.3L341.2,114.3z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M289.2,123.2c1.6,0,3.5-0.1,5.6-0.2c1.6-0.1,3.4-0.2,5.1-0.3c1.3-0.1,2.4-0.2,3.4-0.4
				c0.6-0.1,1.2-0.2,1.7-0.4c0.2-0.1,0.5-0.1,0.7-0.2c0.4-0.1,0.8-0.3,1.2-0.4c0.2-0.1,0.3-0.1,0.5-0.2c1-0.4,1.3-0.8,1.3-0.8
				s-0.3-1.9,0-3.6c0.3-1.7,1.1-2.8,0.7-3.4s-2.9-0.3-5.1,0c-2.1,0.3-14.3,1.9-17.2,2s-3.6,0.1-3.9,1.4c-0.3,1.3-0.5,5.1,1.3,6
				c0.7,0.3,2,0.5,3.9,0.5L289.2,123.2L289.2,123.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M310.2,125.4c-2.6-1.4-1.6-5.2-1.6-5.2s-0.4,0.4-1.3,0.8c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0.1-0.7,0.3-1.2,0.4
				c-0.2,0.1-0.5,0.1-0.7,0.2c-0.5,0.1-1.1,0.3-1.7,0.4c-1,0.2-2.1,0.3-3.4,0.4c-1.7,0.1-3.5,0.2-5.1,0.3c-2,0.1-4,0.2-5.6,0.2h-0.9
				c-1.8,0-3.2-0.2-3.9-0.5c0,0,0,1.6,0.3,3.7c0.3,2.1,1.1,2.4,4.4,3c3.3,0.6,17.2,2.1,18.8,0.7c0,0,1.6-0.5,3.1-1.7
				C312.5,127.2,312.8,126.9,310.2,125.4z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M304.9,121.9c-0.5,0.1-1.1,0.3-1.7,0.4C303.9,122.1,304.4,122,304.9,121.9z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M294.8,123c-2,0.1-4,0.2-5.6,0.2C290.8,123.2,292.7,123.1,294.8,123z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M294.8,123c1.6-0.1,3.4-0.2,5.1-0.3c1.3-0.1,2.4-0.2,3.4-0.4c-1,0.2-2.1,0.3-3.4,0.4
				C298.1,122.8,296.4,122.9,294.8,123z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M329.4,123.2c-0.6-0.8-1.1-1.6-1.1-2.2c0-1.8,3.5-7.2,5.7-8.5c0.3-0.2,0.6-0.3,0.8-0.3
				c-4.1-0.3-16.1,0-16.1,0s-4.5,3.2-6.3,3.3c-1.8,0.1-2.8,1.1-3.4,2.7c-0.3,0.8-0.3,1.5-0.3,2.3H319v0c0-0.7,0.5-1.2,1.2-1.2
				s1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2h-10.4c0.1,0.8,0.2,1.7,0.3,2.5c0.1,1.6,6,4,9.1,4.4c2.9,0.4,7.2,1.6,7.7-0.3
				c-0.1,0.6,0.1,1.7,2.6,1.1c3-0.8,4.8-1.5,4.8-1.5s-0.7-0.5-1.5-1.2c-0.3-0.2-0.6-0.5-0.9-0.8
				C330.2,124.2,329.8,123.7,329.4,123.2C329.4,123.2,329.4,123.2,329.4,123.2z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
            <path
              className="st1"
              d="M320.2,121.7c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v0h1h-1
				C319,121.2,319.5,121.7,320.2,121.7z"
              style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
            />
          </g>
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M180.6,98.6c7.2-1.7,12.9-2,15.1-2.2c1.9-0.2,3.8,0,3.8-1.9s-0.1-2.3-1.1-2.9s-7.1-0.6-10,0
			c-2.9,0.6-10.1,2.3-13.4,4.9c-2.5,1.9-3.7,4.6-3.7,4.6C170.7,102,173.4,100.2,180.6,98.6L180.6,98.6L180.6,98.6z"
            style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M231.2,96.4c2.2,0.2,8,0.5,15.1,2.2s9.9,3.4,9.4,2.4c0,0-1.1-2.6-3.7-4.6c-3.4-2.5-10.5-4.3-13.4-4.9
			s-9.1-0.5-10,0c-0.9,0.5-1.1,0.9-1.1,2.9S229.2,96.2,231.2,96.4L231.2,96.4L231.2,96.4z"
            style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M164.3,126.4c1.6,1.1,6.2,5.2,7.6,5.5s36.3,0,41.5,0s40,0.3,41.5,0c1.5-0.3,6-4.4,7.6-5.5s1.7-1.7,1.1-3.6
			c-0.7-1.9-5.9-10.7-9.5-14.3s-4.8-3.3-9.4-4.8c-4.6-1.5-12.4-2.4-31.3-2.4s-26.8,0.9-31.3,2.4s-5.8,1.2-9.4,4.8
			s-8.8,12.4-9.5,14.3S162.7,125.3,164.3,126.4L164.3,126.4L164.3,126.4z"
            style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M261.4,147.1c0,0-4.5,2.1-5.7,3c-1.2,0.9-1,2.6-2.8,2.7s-13.6,0-14.6,0s-1.2-1.1-1.2-1.1h-47.4
			c0,0-0.2,1.1-1.2,1.1s-12.8,0.1-14.6,0c-1.8-0.1-1.6-1.8-2.8-2.7c-1.2-0.9-5.7-3-5.7-3s-4.4,3.7-4.4,5.2v9.6
			c0,3.2,3.4,13.2,4.4,14.2s5.7,0.6,13.4,0.6H248c7.7,0,12.5,0.4,13.5-0.6s4.4-11,4.4-14.2v-9.6
			C265.8,150.9,261.4,147.1,261.4,147.1L261.4,147.1L261.4,147.1z"
            style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M168.5,147.4c1.5,0.9,3.3,2.3,3.6,1.9s0.3-1.1,3.7-1.1h11c1.8,0,3,1.1,3,3h47.3c0-1.9,1.1-3,3-3h11
			c3.4,0,3.4,0.7,3.7,1.1c0.3,0.4,2.1-1,3.6-1.9s6.2-2.1,7-3.8s0.4-6.4,0-8.2s0.1-2.7-2.5-2.7H164c-2.7,0-2.1,0.9-2.5,2.7
			c-0.4,1.7-0.8,6.6,0,8.2C162.3,145.3,167,146.5,168.5,147.4L168.5,147.4z M203.9,142.1c0-1.1,0.9-2,2-2h15c1.1,0,2,0.9,2,2v0.8
			c0,1.1-0.9,2-2,2h-15c-1.1,0-2-0.9-2-2V142.1z"
            style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M213.4,225.2L213.4,225.2c0.5,0,2.4-2.9,3.6-5.7s0.8-8.1,0.7-9s-3.4-4.3-4.3-4.3s-4.2,3.4-4.3,4.3
			c-0.1,0.9-0.5,6.2,0.7,9S212.9,225.2,213.4,225.2L213.4,225.2L213.4,225.2z"
            style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M260.4,352.7c-0.5-3.2-3.2-36.9-4.3-59.1c-1.1-22.2-1.6-88.3-1.6-90.2s-0.5-2.7-1.9-2.7h-78.4
			c-1.3,0-1.9,0.8-1.9,2.7s-0.5,68-1.6,90.2c-1.1,22.2-3.8,55.9-4.3,59.1c-0.5,3.2-1.9,5.4,1.9,6.4c3.8,1.1,16.6,4,45.1,4
			s41.4-2.9,45.1-4C262.3,358,260.9,355.9,260.4,352.7z M209.1,210.5c0.1-0.9,3.4-4.3,4.3-4.3s4.2,3.4,4.3,4.3
			c0.1,0.9,0.5,6.2-0.7,9s-3.1,5.7-3.6,5.7l0,0c-0.5,0-2.4-2.9-3.6-5.7S209,211.4,209.1,210.5L209.1,210.5L209.1,210.5z
			 M249.4,334.2c0,4.8-0.3,5.6-2.7,8c-2.4,2.4-12.3,3.2-33.3,3.2s-30.9-0.8-33.3-3.2s-2.7-3.2-2.7-8v-83.8c0-2.9,1.9-5.2,5.2-5.2
			h61.6c3.4,0,5.2,2.3,5.2,5.2V334.2z"
            style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M244.2,245.2h-61.6c-3.4,0-5.2,2.3-5.2,5.2v83.8c0,4.8,0.3,5.6,2.7,8c2.4,2.4,12.3,3.2,33.3,3.2
			s30.9-0.8,33.3-3.2s2.7-3.2,2.7-8v-83.8C249.4,247.5,247.6,245.2,244.2,245.2L244.2,245.2L244.2,245.2z"
            style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
          />
        </g>
        <g className="st0">
          <path
            className="st5"
            d="M201.7,28.4c-0.1,0-0.1-0.1-0.2-0.1C201.6,28.4,201.6,28.4,201.7,28.4z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st5"
            d="M225.3,28.3c0,0-0.1,0.1-0.2,0.1C225.2,28.4,225.3,28.4,225.3,28.3z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M170.7,11.4c3.8,0.8,13.1,1.2,15.1,2.6s6,7.2,8,7.2h39.3c2,0,6-5.8,8-7.2s11.2-1.8,15.1-2.6
			c3.8-0.8,7.2-1.8,7.2-4.4V0c0,0-2.2,2.2-6,3.8c-3.8,1.6-16.7,1-44,1s-40.1,0.6-44-1s-6-3.8-6-3.8v7
			C163.5,9.6,166.9,10.6,170.7,11.4L170.7,11.4L170.7,11.4z M213.4,12.1c1.8,0,3.2,1.4,3.2,3.2s-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2
			S211.7,12.1,213.4,12.1z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M201.7,28.4c0.7,0.3,2.5-0.1,3,0.9s0.4,2.5,3.2,2.5h11c2.8,0,2.7-1.4,3.2-2.5s2.3-0.6,3-0.9
			c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.2-0.4-0.6-1.1-1.2-1.6c-0.7-0.7-2.9-0.5-10.7-0.5s-10-0.2-10.7,0.5
			c-0.6,0.6-1.4,1.2-1.2,1.6c0,0,0,0,0,0C201.6,28.4,201.6,28.4,201.7,28.4L201.7,28.4L201.7,28.4z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M266.8,22c-1,0-1.7,1.7-5,3s-7.8,3.1-13.2,3.1c-3.1,0-14.3,0.1-23.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0-0.1,0.1-0.2,0.1c-0.7,0.3-2.5-0.1-3,0.9s-0.4,2.5-3.2,2.5h-11c-2.8,0-2.7-1.4-3.2-2.5s-2.3-0.6-3-0.9c-0.1,0-0.1-0.1-0.2-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c-8.9,0-20.2-0.1-23.2-0.1c-5.4-0.1-9.9-1.8-13.2-3.1c-3.3-1.3-4-3-5-3s-0.9,1.5-0.4,4.2
			c0.5,2.7,1.1,13.3,1.9,18.6c0.8,5.2,4.4,9.2,6.1,10.3c1.7,1.1,1.5-2.1,1.5-4.2c-0.1-2.1,0-6.3,0.3-9.4s1-7.4,1-8.2
			s1.7-0.4,5.6-0.3s26.5,0.3,37.4,0.3s33.5-0.3,37.4-0.3s5.6-0.5,5.6,0.3s0.7,5.1,1,8.2s0.4,7.4,0.3,9.4s-0.3,5.3,1.5,4.2
			c1.7-1.1,5.3-5.1,6.1-10.3s1.3-15.9,1.9-18.6C267.8,23.4,267.8,22,266.8,22L266.8,22z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M201.5,28.3C201.5,28.3,201.5,28.3,201.5,28.3C201.5,28.3,201.5,28.3,201.5,28.3z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M225.3,28.3C225.3,28.3,225.3,28.3,225.3,28.3C225.3,28.3,225.3,28.3,225.3,28.3z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M213.4,18.6c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2S211.7,18.6,213.4,18.6z"
            style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M150.4,325.9c2.4-14.9,3.6-56.9,2.6-79.8c-1-22.9-0.8-23.7-5-28.9s-11.4-13.4-15.5-13.4s-5.8,3.7-8,8
			s-4.8,18.7-5.6,20.9s1.6,4.4,3-0.8c1.4-5.2,2.8-17.3,7-20.3c4.2-3,7.4-1.4,9.6,1.2s10.2,9.6,11,13.9s1.6,21.1,1.8,29.5
			c0.2,8.4-0.2,24.1-0.8,36.7c-0.6,12.6-1.6,26.1-4.2,36.9s-2.3,18.9-16.1,37.1c-15.6,20.7-25.5,27.7-30.5,31.7c-5,4-8.2,6-7.6,7.4
			c0,0,22.5-14,38.7-37.6C147,344.8,148,340.8,150.4,325.9L150.4,325.9L150.4,325.9z"
          />
          <path
            className="st1"
            d="M101,313.1h2c1.2,0,1.1-0.9,3.5-23.6s6.4-56.5,6.4-56.5s-2.1-0.6-2.5,0c-0.4,0.6-1.5,9.3-2,13.9
			c-1.2,12.3-2.6,16.3-3.8,28.7C103.4,288.1,101,313.2,101,313.1L101,313.1L101,313.1z"
          />
          <path
            className="st1"
            d="M44.8,242.7l4.6,7.2c0,0,6.4-2.6,16.1-10s14.1-15.7,15.7-25.5c1.6-9.8,0.6-20.7-1-27.9
			c-1.6-7.2-6.8-13.6-11-17.3s-7.2-7-9.4-7.2c-2.2-0.2-6,5.6-6,5.6s7,4.4,13.5,10.8c6.4,6.4,11.2,21.5,3.4,41.1
			C62.7,239.3,44.8,242.7,44.8,242.7L44.8,242.7z"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M100,335c1.2-9.4,2-17.7,2-17.7h-3.4c0,0-3.6,38.3-4.2,44.4c-0.6,6-4,39.1-3.8,39.8c0.2,0.6,2.2-2.4,3-7.2
			S98.8,344.5,100,335z"
          />
          <path
            className="st1"
            d="M133.9,244.6c1.6,0,3-1.2,3.2-2.8l1.6-12.1c0.2-1.9-1.2-3.6-3.2-3.6h-6.1c-1.6,0-3,1.2-3.2,2.8l-1.6,12.1
			c-0.2,1.9,1.2,3.6,3.2,3.6H133.9z"
          />
          <path
            className="st1"
            d="M161,191.3c-1.6-1.6-5.2-1.6-9.2-6.4c-4-4.8-13.4-17.7-16.3-21.7c-2.8-4-4-5.8-3.2-8.2l0.8-2.4l-5.6-7.6
			c0,0-4,3.8-5,10.6c-1,6.8-2.2,15.3-4.8,18.9s-5.2,4.2-8.2,0.2c-3-4-5.2-6.2-7.2-4.4c-2,1.8-7.6,6.4-10.4,13.4
			c-2.8,7-5,10.4-5,10.4s2.8,5.4,2.6,9S89,215.8,88,218.4s2.2,4.8,3.8,3.8c1.6-1,8.8-3.2,16.3-3.4s13.7,1.4,13.7,1.4
			s2-14.6,3.7-20.7c1.7-6,3.7-8.2,6.7-9.8s4.4,0,5.4,0.8s11.4,11.2,13.9,15.1c2.4,3.8,4.6,9.2,5,17.5c0.4,8.2,0.6,13.1,0,14.4
			c-0.6,1.4-2.8,0.8-2.8,0.8s1.2,35.1,0.8,42.1c-0.4,7-1.4,32.7-2.4,40.1s-0.4,17.1-8.4,31.3s-23.5,34.5-23.5,34.5l4.6,4.8
			c0,0,28.9-37.3,31.7-49.4s7.6-53.3,7.6-96.6S162.6,192.9,161,191.3L161,191.3L161,191.3z M115.1,192.7l-1,12.1
			c-0.2,1.6-1.6,2.8-3.2,2.8h-6.1c-1.9,0-3.4-1.7-3.2-3.6l1-12.1c0.2-1.6,1.6-2.8,3.2-2.8h6.1C113.8,189.1,115.3,190.8,115.1,192.7z
			"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M73.5,319c-9.8-0.6-37.2-6.2-38.4-3.8c0,0-2.2,0.9-2.4,15s-3.4,54.8-3.6,61s-1.6,9,3,9.8
			c4.6,0.8,16.5,2,31.7,3.2c15.3,1.2,22.9-0.8,24.7-2l8.4-84.7C97,317.6,83.4,319.6,73.5,319L73.5,319L73.5,319z M87.2,329.8
			c-0.2,0.8-0.9,1.2-1.5,1.2c-0.6,0-1.7-1.6-1.7-1.6s-0.5,2.3-0.6,3.9s-0.2,3.1-0.2,3.1s0.8-0.2,1.7,0c1,0.2,1.3,1.3,1.2,2.2
			s-0.2,1.7-0.8,2.5c-0.6,0.8-1.5,0.2-1.8-0.2c-0.5-0.5-1.1-3-0.8-6.2s0.9-7.1,2-8.9s1.5-1.8,2.2-0.4S87.4,328.9,87.2,329.8
			L87.2,329.8L87.2,329.8z"
            style={checkedParts?.door ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M38.5,311.7c4.8,0.5,25.4,3.5,39.9,3.5s21.7-2.7,21.7-2.7s1.6-18.2,3.2-39.1c1.6-20.9,5.9-41.5,5.9-41.5
			s-2.4-4.6-5.6-4.8s-19-0.3-23.3,2.4c-4.3,2.7-4,2.9-4.6,5.6s-2.9,12-13.4,17.7c-10.4,5.6-19.8,4.5-21.4,4.8
			c-1.6,0.3-2.4,3.5-2.7,7.8s-3.2,36.4-2.9,39.5C35.6,308,33.7,311.2,38.5,311.7C38.5,311.7,38.5,311.7,38.5,311.7z M94.1,246.3
			c0.3-3.2,0.9-7.1,2-8.9c1.1-1.8,1.5-1.8,2.2-0.4c0.7,1.4,0.4,3.5,0.2,4.4s-0.9,1.2-1.5,1.2s-1.7-1.6-1.7-1.6s-0.5,2.3-0.6,3.9
			s-0.2,3.1-0.2,3.1s0.8-0.2,1.7,0c1,0.2,1.3,1.3,1.2,2.2s-0.2,1.7-0.8,2.5c-0.6,0.8-1.5,0.2-1.8-0.2
			C94.4,252,93.7,249.5,94.1,246.3L94.1,246.3L94.1,246.3z"
            style={checkedParts?.door ? { fill: '#ff6900' } : {}}
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M274.9,320.6c-1-7.4-2-33.1-2.4-40.1s0.8-42.1,0.8-42.1s-2.2,0.6-2.8-0.8c-0.6-1.4-0.4-6.2,0-14.4
			s2.6-13.6,5-17.5s12.9-14.2,13.9-15.1c1-0.8,2.4-2.4,5.4-0.8s5,3.8,6.7,9.8c1.7,6,3.7,20.7,3.7,20.7s6.2-1.6,13.6-1.4
			s14.6,2.4,16.3,3.4c1.6,1,4.8-1.2,3.8-3.8s-1.2-11.6-1.4-15.3c-0.2-3.6,2.6-9,2.6-9s-2.2-3.4-5-10.4s-8.4-11.6-10.4-13.4
			s-4.2,0.4-7.2,4.4c-3,4-5.6,3.4-8.2-0.2c-2.6-3.6-3.8-12-4.8-18.9c-1-6.8-5-10.6-5-10.6l-5.6,7.6l0.8,2.4c0.8,2.4-0.4,4.2-3.2,8.2
			S279,180.1,275,184.9s-7.6,4.8-9.2,6.4c-1.6,1.6-3,10.6-3,53.9s4.8,84.6,7.6,96.6c2.8,12,31.7,49.4,31.7,49.4l4.6-4.8
			c0,0-15.5-20.3-23.5-34.5C275.2,337.7,275.8,328,274.9,320.6L274.9,320.6L274.9,320.6z"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M327,398.7c-5-4-14.9-11-30.5-31.7c-13.7-18.2-13.5-26.3-16.1-37.1s-3.6-24.3-4.2-36.9
			c-0.6-12.6-1-28.3-0.8-36.7c0.2-8.4,1-25.3,1.8-29.5c0.8-4.2,8.8-11.2,11-13.9c2.2-2.6,5.4-4.2,9.6-1.2s5.6,15.1,7,20.3
			c1.4,5.2,3.8,3,3,0.8c-0.8-2.2-3.4-16.6-5.6-20.9c-2.2-4.3-4-8-8-8s-11.2,8.2-15.5,13.4s-4,6-5,28.9c-1,22.9,0.2,64.9,2.6,79.8
			c2.4,14.9,3.4,18.9,19.5,42.6c16.2,23.7,38.7,37.6,38.7,37.6C335.3,404.7,332.1,402.7,327,398.7L327,398.7L327,398.7z"
          />
          <path
            className="st1"
            d="M318.4,246.9c-0.5-4.6-1.6-13.2-2-13.9c-0.4-0.6-2.5,0-2.5,0s4,33.7,6.4,56.5s2.3,23.6,3.5,23.6h2
			c0,0-2.4-25.1-3.6-37.5C321,263.2,319.7,259.2,318.4,246.9L318.4,246.9L318.4,246.9z"
          />
          <path
            className="st1"
            d="M361.4,239.9c9.6,7.4,16.1,10,16.1,10l4.6-7.2c0,0-17.9-3.4-25.7-23.1c-7.8-19.7-3-34.7,3.4-41.1
			s13.5-10.8,13.5-10.8s-3.9-5.8-6-5.6c-2.2,0.2-5.2,3.6-9.4,7.2c-4.2,3.6-9.4,10-11,17.3c-1.6,7.2-2.6,18.1-1,27.9
			S351.7,232.5,361.4,239.9L361.4,239.9z"
            style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M328.2,317.4h-3.4c0,0,0.8,8.2,2,17.7c1.2,9.4,5.6,54.4,6.4,59.2c0.8,4.8,2.8,7.8,3,7.2
			c0.2-0.6-3.2-33.7-3.8-39.8C331.9,355.7,328.2,317.4,328.2,317.4L328.2,317.4L328.2,317.4z"
          />
          <path
            className="st1"
            d="M397.7,391.2c-0.2-6.2-3.4-47-3.6-61c-0.2-14-2.4-15-2.4-15c-1.2-2.5-28.5,3.1-38.4,3.8
			c-9.8,0.6-23.5-1.4-23.5-1.4l8.4,84.7c1.8,1.2,9.4,3.2,24.7,2c15.3-1.2,27.1-2.4,31.7-3.2S397.9,397.5,397.7,391.2L397.7,391.2
			L397.7,391.2z M343.2,340.9c-0.3,0.3-1.2,0.9-1.8,0.2c-0.6-0.8-0.6-1.6-0.8-2.5c-0.1-0.9,0.2-2,1.2-2.2c1-0.2,1.7,0,1.7,0
			s-0.1-1.4-0.2-3.1s-0.6-3.9-0.6-3.9s-1.1,1.6-1.7,1.6c-0.6,0-1.2-0.4-1.5-1.2c-0.2-0.8-0.5-3,0.2-4.4c0.7-1.4,1.1-1.3,2.2,0.4
			c1.1,1.8,1.6,5.7,2,8.9C344.3,337.9,343.7,340.3,343.2,340.9L343.2,340.9L343.2,340.9z"
            style={checkedParts?.door ? { fill: '#ff6900' } : {}}
          />
          <path
            className="st1"
            d="M351.1,235.2c-0.5-2.7-0.3-2.9-4.5-5.6s-20.1-2.7-23.3-2.4c-3.2,0.3-5.6,4.8-5.6,4.8s4.3,20.6,5.9,41.5
			c1.6,20.9,3.2,39.1,3.2,39.1s7.2,2.7,21.7,2.7s35.1-2.9,39.9-3.5s2.9-3.8,3.2-6.8c0.3-3.1-2.7-35.2-2.9-39.5s-1.1-7.5-2.7-7.8
			c-1.6-0.3-11,0.8-21.4-4.8C354.1,247.3,351.7,237.9,351.1,235.2L351.1,235.2L351.1,235.2z M332,252.5c-0.3,0.3-1.2,0.9-1.8,0.2
			c-0.6-0.8-0.6-1.6-0.8-2.5c-0.1-0.9,0.2-2,1.2-2.2c1-0.2,1.7,0,1.7,0s-0.1-1.4-0.2-3.1s-0.6-3.9-0.6-3.9s-1.1,1.6-1.7,1.6
			s-1.2-0.4-1.5-1.2s-0.5-2.9,0.2-4.4c0.7-1.4,1.1-1.3,2.2,0.4c1.1,1.8,1.6,5.7,2,8.9C333.1,249.5,332.5,252,332,252.5L332,252.5
			L332,252.5z"
            style={checkedParts?.door ? { fill: '#ff6900' } : {}}
          />
        </g>
      </g>
    </svg>
  );
}

export default SUV;
