import { useEffect, useState } from 'react';

import popUpStyles from './AdPopUp.module.scss';

import { getPopUpList } from '../../api/adminPopUp';
import AdPopUpModal from './AdPopUpModal';

// isMainPage, isPatternPage, IsUltrafitPage
function AdPopUp({ popUpCategory }) {
  const [popups, setPopups] = useState([]);

  const fetchPopUp = async (category) => {
    const popupsDataRes = await getPopUpList(category, 'popup');

    setPopups(
      popupsDataRes.map((popup) => {
        const currentDate = new Date();
        const popupLastShownDate = localStorage.getItem(
          `dontShowPopup${popup.id}`
        );

        popup.isOpen =
          popupLastShownDate !==
          `${currentDate.getDate()}.${currentDate.getMonth()}.${currentDate.getFullYear()}`;
        return popup;
      })
    );
  };

  useEffect(() => {
    fetchPopUp(popUpCategory);
  }, []);

  return popups.filter((popup) => popup.isOpen).length ? (
    <div className={popUpStyles.popup_container}>
      {popups.map((popup) => (
        <AdPopUpModal key={popup.id} popup={popup} setPopups={setPopups} />
      ))}
    </div>
  ) : (
    ''
  );
}

export default AdPopUp;
