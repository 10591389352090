import listStyles from './ShopCardList.module.scss';
import ShopCard from './ShopCard/ShopCard';
import attentionMark from '../../../img/attention_icon.svg';

function ShopCardList({ ultrafits }) {
  return (
    <section>
      {ultrafits.length > 0 ? (
        <div className={listStyles.shop_cards_container}>
          {ultrafits.map((ultrafit) => (
            <ShopCard key={ultrafit.id} ultrafit={ultrafit} />
          ))}
        </div>
      ) : (
        <div className={listStyles.shop_empty_list}>
          <img src={attentionMark} alt="attention!" />
          <p>No products yet</p>
        </div>
      )}
    </section>
  );
}

export default ShopCardList;
