/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import payoutStyles from './PayoutRequest.module.scss';
// import { getProfitBalance } from '../../../../../api/api';
import { postPayoutRequest, getBalance } from '../../../../../api/transaction';
import { useAlert } from '../../../../hooks/useAlert/useAlert';
import { toFixedNumber } from '../../../../../utils/function';

function PayoutRequest() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();

  const amount = watch('amount');

  const urlParam = new URL(window.location);
  const [balance, setBalance] = useState(0);

  const [amountIsFocused, setAmountIsFocused] = useState(false);

  const { onAlert, onConfirmAlert } = useAlert();

  const fetchBalance = async () => {
    const balanceData = await getBalance('profit');

    console.log(balanceData);
    setBalance(balanceData);
  };

  const handlePayoutRequest = async (bodyRequest) => {
    if (!bodyRequest) {
      return;
    }
    const transactionRes = await postPayoutRequest(bodyRequest);

    if (transactionRes.statusCode === 200) {
      onAlert('', 'Okay', 'Request successfully created!');

      reset();
      await fetchBalance();
    } else {
      onAlert('', 'Okay', 'Something went wrong. Please try again.');
      return;
    }

    if (urlParam.search === '?fromProfileWithdrawal') {
      navigate('/personal_information/withdrawal');
    }
  };

  const formSubmit = async (data) => {
    const payoutRequestBody = {
      email:
        data.email && data.emailTag
          ? `${data.email}@${data.emailTag}`
          : data.emailDirect,
      amount: +data.amount
    };
    onConfirmAlert(
      '',
      () => handlePayoutRequest(payoutRequestBody),
      'Yes',
      'No',
      `Are you sure this is the email you're going to withdraw?`,
      `${data.email}@${data.emailTag}`
    );
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  return (
    <div className={payoutStyles.payout_container}>
      <h2 className={payoutStyles.payout_title}>profit withdraw details</h2>
      <form
        className={payoutStyles.payout_form}
        onSubmit={handleSubmit(formSubmit)}>
        <div className={payoutStyles.form_content}>
          <h3
            className={`${payoutStyles.form_balance} ${payoutStyles.form_subtitle}`}>
            <span className={payoutStyles.balance_title}>Current balance</span>
            <span className={payoutStyles.balance_amount}>
              $ <span>{balance}</span>
            </span>
          </h3>
          <label
            className={`${payoutStyles.form_amount_label} ${payoutStyles.form_label}`}
            htmlFor="amount">
            <h3 className={payoutStyles.form_subtitle}>Amount</h3>
            <div
              className={payoutStyles.form_amount}
              style={
                amountIsFocused
                  ? {
                      borderColor: '#0046a6'
                    }
                  : {}
              }>
              <span>$</span>
              <input
                {...register('amount', {
                  required: 'Please enter amount.',
                  min: {
                    value: 30,
                    message:
                      'You cannot request a withdrawal for amounts less than $30.'
                  }
                })}
                autoComplete="off"
                onFocus={() => setAmountIsFocused(true)}
                onBlur={() => setAmountIsFocused(false)}
                onChange={(e) => setValue('amount', e.target.value)}
                type="number"
                name="amount"
                step={0.01}
              />
            </div>
          </label>
          {balance >= 0 && balance - +amount >= 0 ? (
            <p className={payoutStyles.form_remaining}>
              remaining balance: $ {toFixedNumber(balance - +amount)}
            </p>
          ) : (
            <p className={payoutStyles.form_remaining} style={{ color: 'red' }}>
              Your balance is not enough
            </p>
          )}
          <label
            className={`${payoutStyles.form_email_label} ${payoutStyles.form_label}`}
            htmlFor="email">
            <h3 className={payoutStyles.form_subtitle}>PayPal email</h3>
            <div className={payoutStyles.form_email_container}>
              <input
                {...register('email', { required: 'Please enter email.' })}
                autoComplete="off"
                type="text"
                name="email"
              />
              <span>@</span>
              <input
                autoComplete="off"
                className={payoutStyles.form_emailtag}
                {...register('emailTag')}
                type="text"
                name="emailTag"
              />
            </div>
          </label>
        </div>
        <hr />
        <div className={payoutStyles.form_content}>
          <label
            className={payoutStyles.form_checkbox_label}
            htmlFor="policy"
            style={{ marginBottom: '14px' }}>
            <input
              {...register('policy', {
                required: 'Please agree to PayPal policy.'
              })}
              name="policy"
              type="checkbox"
            />
            I agree to PayPal Policy
          </label>
          <label
            className={payoutStyles.form_checkbox_label}
            htmlFor="personalInfo"
            style={{ marginBottom: '30px' }}>
            <input
              {...register('personalInfo', {
                required:
                  'Please agree to collect and use of personal information.'
              })}
              name="personalInfo"
              type="checkbox"
            />
            I agree to use of personal information
          </label>
          <p className={payoutStyles.form_descr}>
            ※ Payment will be sent to your PayPal account. If you do not own a
            PayPal account please create one. Please note that the recipient is
            responsible for all PayPal fees.
          </p>
        </div>
        <hr />
        <div
          className={`${payoutStyles.form_content} ${payoutStyles.form_submit_content}`}
          style={{ alignItems: 'center' }}>
          <p className={payoutStyles.form_validation}>
            {Object.values(errors).map((value) => `${value.message} `)[0]}
          </p>
          <button className={payoutStyles.form_submit_btn} type="submit">
            withdrawal request
          </button>
        </div>
      </form>
    </div>
  );
}
export default PayoutRequest;
