/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
import { NavLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';

import { useStore } from '../../store/hooks';

import ProfileMenu from '../ProfileMenu/ProfileMenu';

import headerStyles from './Header.module.scss';

// import {
//   getBalance,
//   getCart,
//   getPremiumTime,
//   getUserPhoto
// } from '../../api/api';
// import { formatNumber } from '../../utils/function';
import { getCartItems } from '../../store/actions/cart';
import withToast from '../HOC/withToast';
import logo from '../../img/logo.svg';
import iconAccount from '../../img/icon_account.svg';
import cartIcon from '../../img/icon_cart.svg';
import { getMyCart } from '../../api/ultrafitShop';
// import { TOAST_TYPE } from '../../utils/constants';
// import { setCreditBalance } from '../../store/actions/credit';

import { editorUrl } from '../../utils/editorURL';
import { LoginStateContext, UserProfileContext } from '../../store/Context';
import ModalPaymentEP from './ModalPaymentEP/ModalPaymentEP';
import { useAlert } from '../hooks/useAlert/useAlert';
// import InstallCuttingPopup from '../InstallCuttingPopup/InstallCuttingPopup';

function Header({ onLogOut }) {
  const navigate = useNavigate();
  const [state, dispatch] = useStore();

  const loginState = useContext(LoginStateContext);
  const { userProfile } = useContext(UserProfileContext) || {};
  const { onAlert } = useAlert();
  // const [shouldDisplay, setShouldDisplay] = useState(false);

  const pathName = window.location.pathname.toLocaleLowerCase();

  const isShopPage =
    pathName.includes('shop') ||
    pathName.includes('order_history') ||
    pathName.includes('credit_history');

  // states for Profile Menu display
  const [profileMenuShown, toggleProfileMenu] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCharge = () => {
    navigate('/pricing');
  };

  // handle profile button click
  const handleProfileClick = () => {
    if (loginState?.isLoggedIn) {
      toggleProfileMenu(!profileMenuShown);
      return;
    }

    navigate('/auth');
  };

  const handleCartClick = () => {
    navigate('/shop/cart');
  };

  // handle 'EDIT & PLOT' button click
  const handleEditorClick = async () => {
    const authToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    if (loginState?.isLoggedIn) {
      const currentDate = new Date();
      const subscriptionEndDate = new Date(userProfile?.premiumTime);

      if (currentDate > subscriptionEndDate) {
        // const subscribe = confirm('Subscribe to “Edit & Plot”');

        // if (subscribe) {
        //   navigate('/pricing/subscription');
        //   return;
        // }

        // setModalIsOpen(true);

        onAlert('', 'Okay', 'Subscribe to EDIT & PLOT');

        return;
      }

      window.open(
        `${editorUrl}?fromUserWeb&token=${authToken}&refreshToken=${refreshToken}`,
        '_blank'
      );

      return;
    }

    navigate('/auth');
  };

  // const handleCheckShowPopup = () => {
  //   // Check if the popup was closed today
  //   const lastClosedTimestamp = localStorage.getItem('popupClosedTimestamp');
  //   if (lastClosedTimestamp) {
  //     const today = new Date().toLocaleDateString();
  //     if (lastClosedTimestamp === today) {
  //       setShouldDisplay(false);
  //       handleEditorClick();
  //     } else {
  //       setShouldDisplay(true);
  //     }
  //   } else {
  //     setShouldDisplay(true);
  //   }
  // };

  // handle 'SELL PATTERN' button click
  const handleSellClick = () => {
    if (loginState?.isLoggedIn) {
      if (userProfile?.role === 'merchant') {
        navigate('/sell/pattern/mypattern');
        return;
      }

      navigate('/sell/application');
      return;
    }

    navigate('/auth');
  };

  const fetchCartsLength = async () => {
    try {
      const myCartRes = await getMyCart();

      const carts = myCartRes?.orderDetail ?? [];
      let cartsLength = 0;
      if (carts?.length > 0) {
        cartsLength = carts?.reduce(
          (prevVal, currVal) => prevVal + currVal.quantity,
          0
        );
      }
      dispatch(
        getCartItems({
          length: cartsLength,
          listCart: carts
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // const closePopup = (dontShowPopup) => {
  //   // Save the timestamp when the popup was closed
  //   if (dontShowPopup) {
  //     const today = new Date().toLocaleDateString();
  //     localStorage.setItem('popupClosedTimestamp', today);
  //   }
  //   setShouldDisplay(false);
  //   // handleEditorClick();
  // };

  useEffect(() => {
    if (isShopPage) {
      fetchCartsLength();
    }
  }, [isShopPage]);

  return (
    <header id="header">
      <div className={headerStyles.container}>
        <NavLink to="/" className={headerStyles.logo}>
          <img src={logo} alt="Logo" />
        </NavLink>
        <nav className={headerStyles.nav}>
          <NavLink to="/pattern/list">Patterns</NavLink>
          <NavLink to="/pricing/packs">Pricing</NavLink>
          <NavLink to="/support">Support</NavLink>
          <NavLink to="/shop">Ultrafit shop</NavLink>
        </nav>
        {loginState?.isLoggedIn && (
          <div className={headerStyles.credit_holder}>
            <p className={headerStyles.credit_text}>
              Credit balance:{' '}
              <span className={headerStyles.credit_count}>
                {userProfile?.totalBalance || 0}
              </span>
            </p>
            <button
              type="button"
              className={headerStyles.credit_charge}
              onClick={handleCharge}>
              Recharge
            </button>
          </div>
        )}
        <div className={headerStyles.buttons}>
          <button
            type="button"
            onClick={handleEditorClick}
            className={headerStyles.edit_btn}>
            Edit & Plot
          </button>
          <button
            type="button"
            onClick={handleSellClick}
            className={headerStyles.sell_btn}>
            Sell A Pattern
          </button>
        </div>
        {isShopPage && loginState?.isLoggedIn && (
          <button
            onClick={handleCartClick}
            className={headerStyles.header_cart}
            type="button">
            <div className={headerStyles.cart_notify}>{state.cart.length}</div>
            <img src={cartIcon} alt="cart" />
          </button>
        )}
        <button
          type="button"
          onClick={handleProfileClick}
          className={headerStyles.header_login}>
          <img src={userProfile?.avatar || iconAccount} alt="Log In/Sign In" />
        </button>
      </div>
      {profileMenuShown && (
        <ProfileMenu
          shown={profileMenuShown}
          onLogOut={onLogOut}
          close={() => toggleProfileMenu(false)}
        />
      )}
      {/* {shouldDisplay && <InstallCuttingPopup closeClick={closePopup} />} */}
      {/* {modalIsOpen && (
        <ModalPaymentEP
          setModalIsOpen={setModalIsOpen}
          action={() => {
            setModalIsOpen(false);
            navigate('/pricing/subscription');
          }}
        />
      )} */}
    </header>
  );
}

export default withToast(Header);
