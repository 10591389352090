/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getNotifications = ({ limit, page }) =>
  customFetch(
    `${apiUrl}/profile/notifications?${limit ? `&limit=${limit}` : ''}${
      page ? `&page=${page}` : ''
    }`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();
        throw new Error(data.message);
      }

      return data;
    });

export const getNotificationsUnread = () =>
  customFetch(`${apiUrl}/profile/notifications?status=unread`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();
        throw new Error(data.message);
      }

      return data.count;
    });

export const setSeenNotification = (notificationId) =>
  customFetch(`${apiUrl}/profile/notifications/seen/${notificationId}`, {
    method: 'PUT'
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();

        throw new Error(data.message);
      }

      return data;
    });

export const getGiftNotification = () =>
  customFetch(`${apiUrl}/profile/notifications/gift`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getUploaderNotification = () =>
  customFetch(`${apiUrl}/profile/notifications/uploader`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getNewestNotification = () =>
  customFetch(`${apiUrl}/profile/notifications/newest`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });
