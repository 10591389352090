/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { getPatternsApprovalStatus } from '../../../../../api/patterns';
import approvalStyles from './ApprovalStatus.module.scss';
import RejectedModal from './RejectedModal';
import StatusFilter from '../StatusFilter/StatusFilter';
import Pagination from '../../../../Pagination/Pagination';
import {
  renderDate,
  filterByColumn,
  renderNumberToFixed
} from '../../../../../utils/function';
import attentionIcon from '../../../../../img/attention_icon.svg';

function ApprovalStatus() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [myPatterns, setMyPatterns] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  const [rejectModalIsOpened, setRejectModalIsOpened] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const setTrowStatus = (pattern) => {
    switch (pattern.status) {
      case 'waiting_approve':
        return 'In process';
      case 'approved':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      default:
        return 'In process';
    }
  };

  const handleRejectClick = (rejectReasonText) => {
    setRejectModalIsOpened(true);
    setRejectReason(rejectReasonText);
  };

  const fetchPatterns = async (status = '', page = 1, limit = 20) => {
    const patternsApprovalStatusRes = await getPatternsApprovalStatus({
      status,
      page,
      limit
    });

    setTotalCount(patternsApprovalStatusRes.count);
    setMyPatterns(patternsApprovalStatusRes.data);
  };

  useEffect(() => {
    fetchPatterns(selectedValue, currentPage);
  }, [selectedValue, currentPage]);

  return (
    <section className={approvalStyles.approval_section}>
      <StatusFilter
        onChange={(value) => {
          setSelectedValue(value);
          setCurrentPage(1);
        }}
        listOptions={[
          { text: 'All', value: '' },
          { text: 'In process', value: 'waiting_approve' },
          { text: 'Approved', value: 'approved' },
          { text: 'Rejected', value: 'reject' }
        ]}
      />
      <table className={approvalStyles.approval_table}>
        <thead>
          <tr>
            <td className={approvalStyles.td_pattern}>{'  '}</td>
            <td className={approvalStyles.td_product}>Pattern</td>
            <td className={approvalStyles.td_date}>Upload date</td>
            <td className={approvalStyles.td_credit}>Price</td>
            <td className={approvalStyles.td_status}>Status</td>
          </tr>
        </thead>
        <tbody>
          {myPatterns.length ? (
            myPatterns.map((pattern, index) => (
              <tr key={index}>
                <td className={approvalStyles.td_pattern}>
                  <img src={pattern.svg} alt="pattern" />
                </td>
                <td className={approvalStyles.td_product}>
                  {pattern?.models?.length > 0 ? (
                    <h4>
                      {filterByColumn(pattern.models, 'region').length > 0
                        ? `${filterByColumn(pattern.models, 'region')}`
                        : ''}{' '}
                      {filterByColumn(pattern.models, 'year').length > 0
                        ? ` > ${filterByColumn(pattern.models, 'year')}`
                        : ''}{' '}
                      {filterByColumn(pattern.models, 'brand').length > 0
                        ? ` > ${filterByColumn(pattern.models, 'brand')}`
                        : ''}{' '}
                      {filterByColumn(pattern.models, 'model').length > 0
                        ? ` > ${filterByColumn(pattern.models, 'model')}`
                        : ''}{' '}
                      {filterByColumn(pattern.models, 'sub_model').length > 0
                        ? ` > ${filterByColumn(pattern.models, 'sub_model')}`
                        : ''}{' '}
                      {filterByColumn(pattern.models, 'series').length > 0
                        ? ` > ${filterByColumn(pattern.models, 'series')}`
                        : ''}
                    </h4>
                  ) : null}
                  {pattern.vehicles?.length > 0 ? (
                    <p>
                      {filterByColumn(pattern.vehicles, 'part_section').length >
                      0
                        ? `${filterByColumn(pattern.vehicles, 'part_section')}`
                        : ''}{' '}
                      {filterByColumn(pattern.vehicles, 'pattern_part').length >
                      0
                        ? `> ${filterByColumn(
                            pattern.vehicles,
                            'pattern_part'
                          )}`
                        : ''}{' '}
                      {filterByColumn(pattern.vehicles, 'sub_part').length > 0
                        ? `> ${filterByColumn(pattern.vehicles, 'sub_part')}`
                        : ''}
                    </p>
                  ) : null}
                </td>
                <td className={approvalStyles.td_date}>
                  {renderDate(pattern.createdAt)}
                </td>
                <td className={approvalStyles.td_credit}>
                  {pattern.price
                    ? `${renderNumberToFixed(pattern.price)} Credits`
                    : ''}
                </td>
                {(pattern.status !== 'reject' && (
                  <td className={approvalStyles.td_status}>
                    {setTrowStatus(pattern)}
                  </td>
                )) || (
                  <td className={approvalStyles.td_status_rejected}>
                    <button
                      onClick={() => handleRejectClick(pattern?.reason)}
                      type="button">
                      Rejected
                    </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr className={approvalStyles.tr_nodata}>
              <td className={approvalStyles.td_nodata}>
                <img src={attentionIcon} alt="" />
                <p>Data empty.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalCount > 20 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          totalCount={totalCount}
          pageSize={20}
        />
      )}
      {rejectModalIsOpened && (
        <RejectedModal
          setModalIsOpened={setRejectModalIsOpened}
          text={rejectReason}
        />
      )}
    </section>
  );
}

export default ApprovalStatus;
