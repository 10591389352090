/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import tagsStyles from './NewPatternTags.module.scss';
import uploadPatternStyles from '../UploadPattern.module.scss';
import NewPatternTag from './NewPatternTag';
import { useAlert } from '../../../hooks/useAlert/useAlert';

export default function NewPatternTags({
  id,
  newPatParams,
  setNewPatternsParams
}) {
  const maxTags = 5;
  const maxTagInputLength = 20;
  const [tags, setTags] = useState(newPatParams.tags || []);
  const [newTag, setNewTag] = useState('');

  const { onAlert } = useAlert();

  useEffect(() => {
    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return {
            ...paramObj,
            tags
          };
        }

        return paramObj;
      })
    );
  }, [tags]);

  const handleTagInput = (e) => {
    const tagsValue = e.target.value;
    if (tagsValue.length > maxTagInputLength - 1) {
      setNewTag(tagsValue.split('').splice(0, maxTagInputLength).join(''));
      return;
    }

    setNewTag(tagsValue);
  };

  const addNewTag = () => {
    if (newTag.length === 0) {
      return;
    }

    const suitableTags = [];
    const tagsToAdd = newTag.trim().split(/\s+/);

    for (const tag of tagsToAdd) {
      if (tags.includes(tag)) {
        onAlert('', 'Okay', `Tag "${tag}" is already exists.`);
        return;
      }

      if (tag.length < 3) {
        onAlert('', 'Okay', `Tag must contain more than 3 symbols`);
        return;
      }

      suitableTags.push(tag);
    }

    const availableTagsSlots = maxTags - tags.length;

    if (suitableTags.length > availableTagsSlots || availableTagsSlots === 0) {
      onAlert('', 'Okay', `Tags limit exceeded.`);
    }

    if (availableTagsSlots > 0) {
      const whiteListedTags = suitableTags.slice(0, availableTagsSlots);

      setTags((prevTags) => [...prevTags, ...whiteListedTags]);
    }

    setNewTag('');
  };

  return (
    <div className={tagsStyles.tags}>
      <p className={uploadPatternStyles.form_subtitle}>Tag</p>
      <span>*Up to 5 tags can be registered.</span>
      <label className={tagsStyles.tag_label} htmlFor="tag">
        <input
          type="text"
          name="tag"
          onChange={handleTagInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addNewTag();
            }
          }}
          value={newTag}
          // maxLength="20"
        />
        <button onClick={addNewTag} type="button">
          add
        </button>
      </label>
      <div className={tagsStyles.tags_container}>
        {tags.map((tag, index) => (
          <NewPatternTag
            key={index}
            tagname={tag}
            tags={tags}
            setTags={setTags}
          />
        ))}
      </div>
    </div>
  );
}
