/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
// import { useForm } from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import OrderForm from './OrderForm';
import orderStyles from './ShopOrder.module.scss';
import withQuery from '../../HOC/withQuery';
import { getMyCart, getUltrafitById } from '../../../api/ultrafitShop';
import { getBalance } from '../../../api/transaction';

import withToast from '../../HOC/withToast';
import { TOAST_TYPE } from '../../../utils/constants';
import ShopOrderProvider from './Context/Provider';
import {
  getMyCoupons,
  getMyCouponsAutomatic,
  getMyCouponsAvailable,
  getUserCoupons
} from '../../../api/coupons';
import ModalNoticeDelivery from './ModalNoticeDelivery/ModalNoticeDelivery';
import { isExpiredDate } from '../../../utils/common';
import { mergeCouponList, removeDuplicates } from '../../../utils/function';

const countOrderQuantity = (listProduct) =>
  listProduct.reduce((count, orderProduct) => {
    count += orderProduct.quantity || 1;

    return count;
  }, 0);

const countOrderSubTotal = (listProduct) =>
  listProduct.reduce((subTotal, orderProduct) => {
    subTotal +=
      orderProduct.optionSelected.amount * (orderProduct.quantity || 1);

    return subTotal;
  }, 0);

function ShopOrder({ searchParams, showToast }) {
  const countryRef = useRef(null);
  const countryShipRef = useRef(null);
  const navigate = useNavigate();

  const [listProduct, setListProduct] = useState([]);
  const [listCoupon, setListCoupon] = useState([]);
  const [partPrice, setPartPrice] = useState({
    shipping: 250,
    itemSubtotal: 0,
    coupon: 0,
    creditUsed: 0,
    profitUsed: 0
  });
  const [credit, setCredit] = useState(0);
  const [profit, setProfit] = useState(0);

  const fetchData = async () => {
    const products = [];
    // get product
    if (searchParams?.productId) {
      const product = await getUltrafitById(searchParams?.productId);
      const price = Number(product?.price ?? 0);
      let option = {};
      if (searchParams.optionInch) {
        option = product.options.find(
          (item) => item.inches === searchParams.optionInch
        );
      }

      products.push({ ...product, price, optionSelected: option });
    }

    if (searchParams?.productIds) {
      const listIdx = searchParams?.productIds
        .split(',')
        .map((item) => Number(item));

      const myCart = await getMyCart();

      myCart.orderDetail.forEach((product, index) => {
        const option = product.ulftrafits.options.find(
          (item) => item.inches === product.options.inches
        );
        if (listIdx.includes(product.id)) {
          products.push({
            ...product.ulftrafits,
            optionSelected: option,
            quantity: product.quantity
          });
        }
      });
    }

    // get credit
    const balanceData = await getBalance('credit');

    // get profit
    const profitData = await getBalance('profit');

    setCredit(balanceData);
    setProfit(profitData);

    setListProduct([...products]);
  };

  const fetchCoupons = async (products) => {
    // get coupon
    try {
      const response = await getMyCouponsAvailable();
      const responseCouponAuto = await getMyCouponsAutomatic();
      // const coupons = response?.data ?? [];

      const coupons = mergeCouponList(
        response?.data ?? [],
        responseCouponAuto?.data ?? []
      );

      const couponsFilter = [
        ...coupons.filter((couponItem) => {
          const { coupon } = couponItem;
          const { start, end } = coupon;

          if (start && end) {
            if (isExpiredDate(start, end)) {
              return false;
            }
          }
          console.log('coupon', coupon.name);
          console.log('SubTotal(products)', countOrderSubTotal(products));
          console.log('Quantity(products)', countOrderQuantity(products));

          if (
            coupon.greaterThanAmount !== null &&
            coupon.greaterThanQuantity !== null
          ) {
            return !!(
              coupon.greaterThanAmount <= countOrderSubTotal(products) &&
              coupon.greaterThanQuantity <= countOrderQuantity(products)
            );
          }

          if (
            coupon.greaterThanAmount === null &&
            coupon.greaterThanQuantity !== null
          ) {
            return !!(
              coupon.greaterThanQuantity <= countOrderQuantity(products)
            );
          }

          if (
            coupon.greaterThanAmount !== null &&
            coupon.greaterThanQuantity === null
          ) {
            return !!(coupon.greaterThanAmount <= countOrderSubTotal(products));
          }

          return true;
        })
      ];

      setListCoupon(removeDuplicates(couponsFilter));
    } catch (error) {
      setListCoupon([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams?.productId]);

  console.log('listProduct', listProduct);

  useEffect(() => {
    if (listProduct.length) {
      setPartPrice((prevState) => ({
        ...prevState,
        itemSubtotal: listProduct.reduce((priceSum, product) => {
          priceSum += product.price;

          return priceSum;
        }, 0),
        grandTotal:
          prevState.shipping +
          listProduct.reduce((priceSum, product) => {
            priceSum += product.price;

            return priceSum;
          }, 0)
      }));

      fetchCoupons(listProduct);
    }
  }, [listProduct]);

  return (
    <ShopOrderProvider>
      <section className={orderStyles.order_section}>
        <h1 className={orderStyles.order_title}>order</h1>
        <form
          className={orderStyles.order_content}
          // onSubmit={handleSubmit(onSubmit)}
          id="hook-form-banner">
          <OrderForm
            // {...{ errors, register, setValue, getValues,
            // setError, clearErrors }}
            // errors={errors}
            // setErrors={setErrors}
            // values={values}
            // setValues={setValues}
            // countryRef={countryRef}
            // countryShipRef={countryShipRef}
            // listProduct={listProduct}
            listCoupon={listCoupon}
            // setPartPrice={setPartPrice}
            // partPrice={partPrice}
            credit={credit}
            profit={profit}
          />
          <OrderDetails
            listProduct={listProduct}
            partPrice={partPrice}
            // values={values}
            // handleValidate={handleValidate}
          />
        </form>
        <ModalNoticeDelivery />
      </section>
    </ShopOrderProvider>
  );
}

export default withToast(withQuery(ShopOrder));
