// import { useState, useEffect } from 'react';
import subCatOptionsStyles from './NewPatternSubCat.module.scss';
import NewPatternSubCatOption from './NewPatternSubCatOption';

export default function NewPatternSubCatOptions({
  id,
  options,
  subOptions,
  newPatParams,
  setNewPatternsParams,
  selectedVehicleType
}) {
  return (
    <>
      {options.length ? (
        <ul
          className={subCatOptionsStyles.subcats_options}
          style={
            subOptions.length
              ? {
                  borderBottom: '1px solid #ededed'
                }
              : {}
          }>
          {options.map((subCategory) => (
            <NewPatternSubCatOption
              id={id}
              key={subCategory.id}
              subPartId={subCategory.id}
              partType="part"
              subCategoryList={subCategory.subCategory}
              subPartName={subCategory.category}
              newPatParams={newPatParams}
              setNewPatternsParams={setNewPatternsParams}
              selectedVehicleType={selectedVehicleType}
            />
          ))}
        </ul>
      ) : undefined}

      {subOptions.length ? (
        <ul className={subCatOptionsStyles.subcats_options}>
          {subOptions.map((subOption) => (
            <NewPatternSubCatOption
              id={id}
              key={subOption.id}
              subPartId={subOption.id}
              partType="subPart"
              subPartName={subOption.category}
              newPatParams={newPatParams}
              setNewPatternsParams={setNewPatternsParams}
            />
          ))}
        </ul>
      ) : undefined}
    </>
  );
}
