/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getUltrafits = ({ page, limit, name, categoryIds, orderBy }) =>
  customFetch(
    `${apiUrl}/ultrafit-shop?${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }${name ? `&name=${name}` : ''}${
      categoryIds ? `&categoryIds=${categoryIds}` : ''
    }${orderBy ? `&orderBy=${orderBy}` : ''}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getUltrafitById = (ultrafitId) =>
  customFetch(`${apiUrl}/ultrafit-shop/${ultrafitId}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const getUltrafitRating = (ultrafitId) =>
  customFetch(`${apiUrl}/ratings?ultrafitId=${ultrafitId}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const postUltrafitRating = ({ ultrafitId, review }) =>
  customFetch(`${apiUrl}/ratings`, {
    method: 'POST',
    body: JSON.stringify({ ultrafitId, review }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        alert(
          data?.message ??
            'Something went wrong posting review. Try again later'
        );
        throw new Error(data.message);
      }

      alert('Review posted!');
      return data;
    });

export const postUltrafitRating2 = ({ ultrafitId, review, orderId }) =>
  customFetch(`${apiUrl}/ratings`, {
    method: 'POST',
    body: JSON.stringify({ ultrafitId, review, orderId }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }
      return data;
    });

export const updateUltrafitRating = (id, { ultrafitId, review, orderId }) =>
  customFetch(`${apiUrl}/ratings/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ultrafitId, review, orderId }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }
      return data;
    });

export const cancelOrder = (orderId) =>
  customFetch(`${apiUrl}/orders/status/${orderId}`, {
    method: 'POST',
    body: JSON.stringify({ status: 'cancellation_pending' }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }
      return data;
    });

export const addToCart = ({ productId, quantity, price, inches }) =>
  customFetch(`${apiUrl}/orders/addToCart`, {
    method: 'POST',
    body: JSON.stringify({ productId, quantity, price, inches }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getMyCart = () =>
  customFetch(`${apiUrl}/orders/cart`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const emptyCart = () =>
  customFetch(`${apiUrl}/orders/emptyCart`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const updateProductQuantity = ({ productId, quantity, inches }) =>
  customFetch(`${apiUrl}/orders/updateProduct`, {
    method: 'POST',
    body: JSON.stringify({ productId, quantity, inches }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        // alert('Something went wrong updating product quantity. Try again.');
        throw new Error(data.message);
      }

      return data;
    });

export const removeProductFromCart = ({ productId }) =>
  customFetch(`${apiUrl}/orders/removeProduct`, {
    method: 'POST',
    body: JSON.stringify({ orderDetailId: productId }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        // alert('Something went wrong removing product. Try again.');
        throw new Error(data.message);
      }

      return data;
    });

export const checkActivationNumber = (activationNumber) =>
  customFetch(`${apiUrl}/ultrafit-shop/user/check/activation-numbers`, {
    method: 'POST',
    body: JSON.stringify({ code: activationNumber }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((bonusCreditData) => bonusCreditData);

export const getBonusCredit = (activationNumber) =>
  customFetch(`${apiUrl}/ultrafit-shop/user/activation-numbers`, {
    method: 'POST',
    body: JSON.stringify({ code: activationNumber }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((bonusCreditData) => {
      if (bonusCreditData.errorStatus) {
        window.location.reload();
        throw new Error(bonusCreditData.message);
      }

      return bonusCreditData.data.amount;
    });
