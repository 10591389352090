import modalStyles from './RegisterCarModal.module.scss';

function RegisterCarModal({ registerSuccess, onConfirm }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h1>{registerSuccess ? 'Sent to admin' : 'FAILED'}</h1>
        <p>
          {registerSuccess
            ? 'A message will appear on the notification when the registration is done!'
            : 'Something went wrong. Please try again later.'}
        </p>
        <div className={modalStyles.group_btn}>
          <button
            className={modalStyles.btn_confirm}
            onClick={onConfirm}
            type="button">
            confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterCarModal;
