/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import fAQTableStyles from '../FAQTable/FAQTable.module.scss';

function FAQTableRow({ index, row }) {
  const [answerIsOpened, setAnswerIsOpened] = useState(false);

  useEffect(() => {
    setAnswerIsOpened(false);
  }, [row]);

  const hadnleAnswerExposureClick = () => {
    setAnswerIsOpened(!answerIsOpened);
  };

  return (
    <>
      <div
        className={fAQTableStyles.table_row}
        style={answerIsOpened ? { borderBottom: 'none' } : {}}>
        <div className={fAQTableStyles.table_cell}>{index + 1}</div>
        <div className={fAQTableStyles.table_cell}>
          <button type="button" onClick={hadnleAnswerExposureClick}>
            {row.q}
          </button>
        </div>
      </div>
      {answerIsOpened && (
        <div className={fAQTableStyles.table_row} style={{ paddingTop: 0 }}>
          <div className={fAQTableStyles.table_cell}> </div>
          <div className={fAQTableStyles.table_cell}>
            {row.a.map((item, idx) => {
              const objectKeyLength = Object.keys(item).length;
              const objectKeys = Object.keys(item);
              if (objectKeyLength === 1 && objectKeys.includes('text')) {
                return (
                  <p
                    key={idx}
                    style={
                      item.text === 'Suggestion:' ? { fontWeight: 'bold' } : {}
                    }>
                    {item.text}
                  </p>
                );
              }
              if (
                objectKeyLength === 2 &&
                objectKeys.includes('text') &&
                objectKeys.includes('link')
              ) {
                return (
                  <p key={idx}>
                    {item.text}{' '}
                    <a href={item.link.url} target="_blank" rel="noreferrer">
                      {item.link.text}
                    </a>
                  </p>
                );
              }
              if (objectKeyLength === 1 && objectKeys.includes('list')) {
                return (
                  <ul key={idx}>
                    {item.list.map((listItem, indx) => (
                      <li key={indx}>{listItem}</li>
                    ))}
                  </ul>
                );
              }
              if (
                objectKeyLength === 5 &&
                objectKeys.includes('text') &&
                objectKeys.includes('link1') &&
                objectKeys.includes('link2') &&
                objectKeys.includes('text2') &&
                objectKeys.includes('text3')
              ) {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.link1.text}</span>
                    {item.text2}{' '}
                    <a href={item.link2.url} target="_blank" rel="noreferrer">
                      {item.link2.text}
                    </a>
                    {item.text3}{' '}
                  </p>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default FAQTableRow;
