import ppaStyles from './NewPatternPPA.module.scss';
import uploadPatternStyles from '../UploadPattern.module.scss';

export default function NewPatternPPA({
  id,
  patternPricePerArea,
  newPatParams,
  setNewPatternsParams
}) {
  const handlePPAClick = (ppa) => {
    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return {
            ...paramObj,
            pricePerArea: +ppa
          };
        }

        return paramObj;
      })
    );
  };

  return (
    <div className={ppaStyles.price_per_area}>
      <p className={uploadPatternStyles.form_subtitle}>Price per Sq.ft</p>
      <ul>
        {patternPricePerArea
          .sort((price1, price2) => price1.variable - price2.variable)
          .map((price) => (
            <li key={price.id}>
              <button
                onClick={() => handlePPAClick(price.variable)}
                style={
                  newPatParams.pricePerArea === price.variable
                    ? { backgroundColor: '#0046a6', color: '#fff' }
                    : {}
                }
                type="button">
                {price.variable} Credits
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
