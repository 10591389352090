import sidebarLinksStyles from './SupportPageSidebarLinks.module.scss';

function SupportPageSidebarLinks({ items = {}, selected, onSelect }) {
  function scrollToCenter(itemId) {
    const element = document.getElementById(itemId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  }
  return (
    <div className={sidebarLinksStyles.support_sidebar_containder}>
      {/* <a href="#uploader">What is an Uploader?</a>
      <a href="#approval">Pattern approval</a>
      <a href="#management">Pattern management</a>
      <a href="#ads">Pattern advertising</a>
      <a href="#revenue">Pattern revenue</a>
      <a href="#withdrawal">Withdrawal request</a> */}
      {Object.keys(items).length > 0
        ? Object.keys(items).map((item, index) => (
            <span
              // href={`/support${items[item].hash}`}
              key={index}
              style={{
                color: selected === item ? '#0046a6' : 'inherit'
              }}
              tabIndex="0"
              aria-hidden="true"
              role="button"
              onClick={() => {
                onSelect(item);
                scrollToCenter(items[item].id);
              }}>
              {item}
            </span>
          ))
        : null}
    </div>
  );
}

export default SupportPageSidebarLinks;
