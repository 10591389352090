/* eslint-disable default-param-last */
import { SELECT_PACKAGE } from './constants';

const initState = [];

const selectPack = (payload) => ({
  type: SELECT_PACKAGE,
  payload
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SELECT_PACKAGE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
export { initState, selectPack };
