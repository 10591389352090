/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getCreditPacks = () =>
  customFetch(`${apiUrl}/credits`).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response.data;
  });
