import FAQTableRow from '../FAQTableRow/FAQTableRow';
import fAQTableStyles from './FAQTable.module.scss';

function FAQTable({ tableRows }) {
  return tableRows ? (
    <div className={fAQTableStyles.support_table}>
      <div className={fAQTableStyles.table_head}>
        <div className={fAQTableStyles.table_row}>
          <div className={fAQTableStyles.table_cell}>№</div>
          <div className={fAQTableStyles.table_cell}>Title</div>
        </div>
      </div>
      <div className={fAQTableStyles.table_body}>
        {tableRows.map((row, index) => (
          <FAQTableRow key={index} row={row} index={index} />
        ))}
      </div>
    </div>
  ) : null;
}

export default FAQTable;
