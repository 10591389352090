/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { useState, useContext, useEffect, useRef } from 'react';
import arrowImg from '../../../img/dropdown_arrow.svg';
import orderFormStyles from './ShopOrder.module.scss';
import countries from '../../../utils/countries';
import ShopOrderContext from './Context/Context';
import {
  changeMultiValues,
  changePartPrice,
  changeValues,
  handleValidate
} from './Context/reducer';
import { UserProfileContext } from '../../../store/Context';

import { phoneNumberCodes } from '../../../utils/phoneNumberCodes';

import iconUp from '../../../img/icon_down.svg';
import iconDown from '../../../img/icon_down1.svg';
import { useClickOutside } from '../../hooks/useClickOutside';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { calculateTotalAmount } from '../../../utils/function';

function OrderForm(props) {
  const {
    // errors,
    // values,
    // setValues,
    // setPartPrice,
    // countryRef,
    // countryShipRef,
    showToast,
    listCoupon,
    credit,
    profit
    // partPrice
  } = props;

  const [state, dispatch] = useContext(ShopOrderContext);
  const { userProfile } = useContext(UserProfileContext) || {};

  const { values, errors, partPrice } = state;

  // console.log('errors', errors);
  // console.log('values', values);

  // console.log('state', state);

  const countryCustomerRef = useRef(null);
  const countryShipRef = useRef(null);
  const customerPhoneCodeRef = useRef(null);
  const shippingPhoneCodeRef = useRef(null);

  const [sameInfo, setSameInfo] = useState(false);
  const [saveShipInfo, setSaveShipInfo] = useState(false);
  const [useAllProfit, setUseAllProfit] = useState(false);
  const [useAllCredit, setUseAllCredit] = useState(false);
  const [searchCusCountry, setSearchCusCountry] = useState('');
  const [searchShipCountry, setSearchShipCountry] = useState('');

  const [customerTelCodeSelectIsOpen, setCustomerTelCodeSelectIsOpen] =
    useState(false);
  const [shippingTelCodeSelectIsOpen, setShippingTelCodeSelectIsOpen] =
    useState(false);

  const [openCountryCustomer, setOpenCountryCustomer] = useState(false);
  const [openCountryShip, setOpenCountryShip] = useState(false);
  const [openCoupon, setOpenCoupon] = useState(false);

  useClickOutside(customerPhoneCodeRef, () =>
    setCustomerTelCodeSelectIsOpen(false)
  );
  useClickOutside(shippingPhoneCodeRef, () =>
    setShippingTelCodeSelectIsOpen(false)
  );
  useClickOutside(countryCustomerRef, () => setOpenCountryCustomer(false));
  useClickOutside(countryShipRef, () => setOpenCountryShip(false));

  const handleChangePhoneNumber = (name, value) => {
    dispatch(changeValues({ name, value }));
    dispatch(handleValidate());
  };

  const handleChangeCountryCustomer = (e) => {
    dispatch(changeValues({ name: 'customerCountry', value: e.target.value }));
    dispatch(handleValidate());
    setSearchCusCountry('');
    setOpenCountryCustomer(!openCountryCustomer);
  };

  const handleChangeCountryShip = (e) => {
    dispatch(changeValues({ name: 'shippingCountry', value: e.target.value }));
    dispatch(handleValidate());
    setSearchShipCountry('');
    setOpenCountryShip(!openCountryShip);
  };

  const handleChangeCoupon = (e) => {
    if (listCoupon.length) {
      const optionSelected = listCoupon.find(
        (item) => item.couponId === Number(e.target.value)
      );
      // setValue('couponPrice', Number(optionSelected.benifitAmount));
      // setValue('coupon', optionSelected.name);
      dispatch(
        changeMultiValues({
          couponSelected: Number(e.target.value),
          couponName: optionSelected.coupon.name
        })
      );
    }

    setOpenCoupon(false);
  };

  const handleApplyCoupon = () => {
    dispatch(changeValues({ name: 'couponId', value: values.couponSelected }));
  };

  const handleCheckSameInformation = (e) => {
    if (e.target.checked) {
      setSameInfo(true);
      // setValues({
      //   ...values,
      //   shippingName: values.customerName,
      //   shippingEmail: values.customerEmail,
      //   shippingPhone: values.customerPhone,
      //   shippingCountry: values.customerCountry,
      //   shippingAddress1: values.customerAddress
      // });
      dispatch(
        changeMultiValues({
          shippingName: values?.customerName,
          shippingEmail: values?.customerEmail,
          shippingPhoneCode: values?.customerPhoneCode,
          shippingPhone: values?.customerPhone,
          shippingCountry: values?.customerCountry,
          shippingAddress: values?.customerAddress,
          // shippingAddress2: values?.customerAddress2,
          shippingZip: userProfile?.postalCode,
          shippingCity: userProfile?.city,
          shippingState: userProfile?.stateRegion
        })
      );
      dispatch(handleValidate());
    } else {
      setSameInfo(false);
    }
  };

  const handleSaveShipInfo = (e) => {
    if (e.target.checked) {
      setSaveShipInfo(true);
      const data = {
        shippingName: values.shippingName,
        shippingEmail: values.shippingEmail,
        shippingPhoneCode: values.shippingPhoneCode,
        shippingPhone: values.shippingPhone,
        shippingAddress: values.shippingAddress,
        shippingCountry: values.shippingCountry,
        shippingCity: values.shippingCity,
        shippingState: values.shippingState,
        shippingZip: values.shippingZip,
        shippingVat: values.shippingVat
      };
      localStorage.setItem('savedShipInfo', JSON.stringify(data));
    } else {
      setSaveShipInfo(false);
    }
  };

  const handleCheckCredit = (e) => {
    let value = credit;
    if (e.target.checked) {
      setUseAllCredit(true);
      // setValue('credit', credit);
      // setPartPrice({
      //   ...values,
      //   creditUsed: credit
      // });

      const totalAmount = calculateTotalAmount(
        partPrice.shipping,
        partPrice.itemSubtotal,
        partPrice?.discount,
        0,
        partPrice?.profitUsed
      );

      if (totalAmount >= 0 && credit > totalAmount) {
        value = totalAmount;
      }

      if (totalAmount <= 0) {
        value = 0;
      }

      dispatch(changePartPrice({ name: 'creditUsed', value }));
    } else {
      setUseAllCredit(false);
    }
  };

  const handleChangeValuesCredit = (value) => {
    // setPartPrice({
    //   ...values,
    //   creditUsed: e.target.value
    // });

    if (+value > credit) {
      value = credit;
    }
    // const totalAmount = calculateTotalAmount(
    //   partPrice.shipping,
    //   partPrice.itemSubtotal,
    //   partPrice?.discount,
    //   +value,
    //   partPrice?.profitUsed
    // );

    // if (totalAmount < 0) {
    // }

    dispatch(changePartPrice({ name: 'creditUsed', value }));
  };

  const handleCheckProfit = (e) => {
    let value = profit;
    if (e.target.checked) {
      setUseAllProfit(true);
      // setValue('profit', profit);
      // setPartPrice({
      //   ...values,
      //   profitUsed: profit
      // });

      const totalAmount = calculateTotalAmount(
        partPrice.shipping,
        partPrice.itemSubtotal,
        partPrice?.discount,
        partPrice?.creditUsed,
        0
      );
      if (totalAmount >= 0 && value > totalAmount) {
        value = totalAmount;
      }

      if (totalAmount <= 0) {
        value = 0;
      }

      dispatch(changePartPrice({ name: 'profitUsed', value }));
    } else {
      setUseAllProfit(false);
    }
  };

  const handleChangeValuesProfit = (value) => {
    // setPartPrice({
    //   ...values,
    //   profitUsed: e.target.value
    // });

    if (+value > profit) {
      value = profit;
    }

    // const totalAmount = calculateTotalAmount(
    //   partPrice.shipping,
    //   partPrice.itemSubtotal,
    //   partPrice?.discount,
    //   partPrice?.creditUsed,
    //   +value
    // );
    // if (totalAmount < 0) {
    // }

    dispatch(changePartPrice({ name: 'profitUsed', value }));
  };

  // console.log('getValues', getValues());

  const handleChangeValues = (event) => {
    // setValues({
    //   ...values,
    //   [event.target.name]: event.target.value
    // });
    dispatch(
      changeValues({ name: event.target.name, value: event.target.value })
    );
    dispatch(handleValidate());
  };

  useEffect(() => {
    if (userProfile !== null) {
      dispatch(
        changeMultiValues({
          customerName:
            userProfile?.nickName ||
            `${userProfile?.firstName} ${userProfile?.lastName}`,
          customerEmail: userProfile?.email || '',
          customerPhone: userProfile?.phoneNumber || '',
          customerCountry: userProfile?.country,
          customerAddress: userProfile?.address1
          // customerAddress2: userProfile?.address2
        })
      );
    }
  }, [userProfile]);

  useEffect(() => {
    const savedShipInfo = JSON.parse(localStorage.getItem('savedShipInfo'));
    if (savedShipInfo) {
      const data = {
        shippingName: savedShipInfo.shippingName || '',
        shippingEmail: savedShipInfo.shippingEmail || '',
        shippingPhoneCode: savedShipInfo.shippingPhoneCode || '',
        shippingPhone: savedShipInfo.shippingPhone || '',
        shippingAddress: savedShipInfo.shippingAddress || '',
        shippingCountry: savedShipInfo.shippingCountry || '',
        shippingCity: savedShipInfo.shippingCity || '',
        shippingState: savedShipInfo.shippingState || '',
        shippingZip: savedShipInfo.shippingZip || '',
        shippingVat: savedShipInfo.shippingVat || ''
      };
      dispatch(changeMultiValues(data));
    }
  }, []);

  const onSaveAddress = () => {
    if (values.customerAddress) {
      localStorage.setItem('BILLING_DETAIL_ADDR', values.customerAddress);
      showToast(TOAST_TYPE.SUCCESS, 'Address was saved!');
    }
  };

  const onGetAddress = () => {
    const addr = localStorage.getItem('BILLING_DETAIL_ADDR');
    if (addr) {
      dispatch(changeValues({ name: 'customerAddress', value: addr }));
      dispatch(handleValidate());
    }
  };

  return (
    <div className={orderFormStyles.order_form}>
      <h2
        className={`${orderFormStyles.content_title} ${orderFormStyles.content_title_container}`}>
        Billing Details
      </h2>
      <div className={orderFormStyles.form_content}>
        <label className={orderFormStyles.form_label} htmlFor="fullname">
          <p>Name</p>
          <input
            name="customerName"
            type="text"
            // {...register('name', { required: 'Enter name' })}
            onChange={handleChangeValues}
            value={values.customerName}
            readOnly
          />
          {errors?.customerName ? <p>{errors?.customerName}</p> : <p>&nbsp;</p>}
        </label>
        <label className={orderFormStyles.form_label} htmlFor="email">
          <p>Email</p>
          <input
            name="customerEmail"
            type="email"
            // {...register('email', {
            //   required: 'Enter email',
            //   pattern: {
            //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     message: 'Invalid email'
            //   }
            // })}
            value={values.customerEmail}
            onChange={handleChangeValues}
            readOnly
          />
          {errors?.customerEmail ? (
            <p>{errors?.customerEmail}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label>
        {/* <label className={orderFormStyles.form_label} htmlFor="phone">
          <p>Mobile Phone</p>
          <input
            name="customerPhone"
            type="tel"
            value={values.customerPhone}
            // {...register('phone', { required: 'Enter phone' })}
            onChange={handleChangeValues}
          />
          {errors?.customerPhone ? (
            <p>{errors?.customerPhone}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label> */}
        <fieldset
          className={`${orderFormStyles.mobile_fieldset} ${orderFormStyles.fieldset}`}>
          <p>Mobile Phone</p>
          <div className={orderFormStyles.mobile_controls}>
            <div
              className={orderFormStyles.mobile_select}
              ref={customerPhoneCodeRef}>
              <button
                className={orderFormStyles.custom_select_btn}
                type="button">
                <span>{values?.customerPhoneCode}</span>
                <img
                  src={iconDown}
                  alt="arrow"
                  style={
                    customerTelCodeSelectIsOpen
                      ? { transform: 'rotate(180deg)' }
                      : {}
                  }
                />
              </button>
              <select
                onChange={(e) => {
                  handleChangeValues(e);
                  setCustomerTelCodeSelectIsOpen(false);
                }}
                className={orderFormStyles.custom_select}
                value={values?.customerPhoneCode}
                name="customerPhoneCode"
                style={customerTelCodeSelectIsOpen ? { display: 'flex' } : {}}
                multiple
                readOnly>
                {phoneNumberCodes.map((phoneCode) => (
                  <option key={phoneCode.id} value={phoneCode.value}>
                    {phoneCode.text.toLowerCase()}
                  </option>
                ))}
              </select>
            </div>
            <input
              className={orderFormStyles.mobile_input}
              type="text"
              name="customerPhone"
              value={values?.customerPhone}
              readOnly
              onInput={(e) => {
                const phoneValue = e.target.value
                  .split('')
                  .filter((value) => Number.isInteger(+value))
                  .join('')
                  .replace(/\s/g, '');

                handleChangePhoneNumber('customerPhone', phoneValue);
              }}
              onWheel={(e) => e.target.blur()}
            />
            <p className={orderFormStyles.validation_warn}>
              {errors.phone?.message}
            </p>
          </div>
        </fieldset>
        <fieldset
          className={`${orderFormStyles.country_fieldset} ${orderFormStyles.fieldset}`}
          htmlFor="country"
          ref={countryCustomerRef}>
          <p>Country</p>
          <div className={orderFormStyles.dropdown}>
            <input
              // {...register('shipping_country', {
              //   validate: (value) => value.length > 0
              // })}
              onClick={() => {
                setOpenCountryCustomer(!openCountryCustomer);
              }}
              onChange={(e) => setSearchCusCountry(e.target.value)}
              name="country"
              type="text"
              className={orderFormStyles.country_input}
              placeholder={
                openCountryCustomer && !searchCusCountry
                  ? values?.customerCountry
                  : ''
              }
              value={
                openCountryCustomer ? searchCusCountry : values?.customerCountry
              }
              style={
                openCountryCustomer
                  ? {
                      outline: '1px solid #0046a6',
                      backgroundImage: `url(${iconUp})`
                    }
                  : {
                      backgroundImage: `url(${iconDown})`
                    }
              }
            />
          </div>
          <p className={orderFormStyles.validation_warn}>
            {errors?.customerCountry && !values?.customerCountry
              ? errors?.customerCountry
              : null}
          </p>
          {openCountryCustomer && (
            <select
              name="country"
              multiple
              onChange={handleChangeCountryCustomer}>
              {searchCusCountry.length > 0
                ? countries
                    .filter((countryData) =>
                      countryData.name
                        .toLowerCase()
                        .includes(searchCusCountry.toLowerCase())
                    )
                    .map((countryData, index) => (
                      <option key={index} value={countryData.name}>
                        {countryData.name}
                      </option>
                    ))
                : countries.map((countryData, index) => (
                    <option key={index} value={countryData.name}>
                      {countryData.name}
                    </option>
                  ))}
            </select>
          )}
        </fieldset>
        <label className={orderFormStyles.form_label} htmlFor="address">
          <p>Address </p>
          <input
            type="text"
            name="customerAddress"
            value={values.customerAddress}
            onChange={handleChangeValues}
            // readOnly
          />
          {errors?.customerAddress ? (
            <p>{errors?.customerAddress}</p>
          ) : (
            <p>&nbsp;</p>
          )}
          <div className={orderFormStyles.buttons}>
            <button
              type="button"
              onClick={onSaveAddress}
              className={orderFormStyles.edit_btn}>
              Save address
            </button>
            <button
              type="button"
              onClick={onGetAddress}
              className={orderFormStyles.sell_btn}>
              Get address
            </button>
          </div>
        </label>
        {/* <label className={orderFormStyles.form_label} htmlFor="address">
          <p>Address 2</p>
          <input
            type="text"
            name="customerAddress"
            value={values.customerAddress2}
            onChange={handleChangeValues}
            readOnly
          />
          {errors?.customerAddress2 ? (
            <p>{errors?.customerAddress2}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label> */}
      </div>
      <div className={orderFormStyles.content_title_container}>
        <h2 className={orderFormStyles.content_title}>Shipping Details</h2>
        <label className={orderFormStyles.same_info_label} htmlFor="same_info">
          <input
            type="checkbox"
            name="same_info"
            checked={sameInfo}
            onChange={handleCheckSameInformation}
          />
          <p>Same as Billing Details</p>
        </label>
      </div>
      <div className={orderFormStyles.form_content}>
        <label className={orderFormStyles.form_label} htmlFor="shipping_name">
          <p>Name</p>
          <input
            type="text"
            name="shippingName"
            value={values.shippingName}
            onChange={handleChangeValues}
          />
          {errors?.shippingName ? <p>{errors?.shippingName}</p> : <p>&nbsp;</p>}
        </label>
        <label className={orderFormStyles.form_label} htmlFor="shipping_email">
          <p>Email</p>
          <input
            type="email"
            name="shippingEmail"
            value={values.shippingEmail}
            // {...register('shipping_email', {
            //   required: 'Enter email',
            //   pattern: {
            //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     message: 'Invalid email'
            //   }
            // })}
            onChange={handleChangeValues}
          />
          {errors?.shippingEmail ? (
            <p>{errors?.shippingEmail}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label>
        <fieldset
          className={`${orderFormStyles.mobile_fieldset} ${orderFormStyles.fieldset}`}>
          <p>Mobile Phone</p>
          <div className={orderFormStyles.mobile_controls}>
            <div
              className={orderFormStyles.mobile_select}
              ref={shippingPhoneCodeRef}>
              <button
                onClick={() =>
                  setShippingTelCodeSelectIsOpen(!shippingTelCodeSelectIsOpen)
                }
                className={orderFormStyles.custom_select_btn}
                type="button">
                <span>{values?.shippingPhoneCode}</span>
                <img
                  src={iconDown}
                  alt="arrow"
                  style={
                    shippingTelCodeSelectIsOpen
                      ? { transform: 'rotate(180deg)' }
                      : {}
                  }
                />
              </button>
              <select
                onChange={(e) => {
                  handleChangeValues(e);
                  setShippingTelCodeSelectIsOpen(false);
                }}
                className={orderFormStyles.custom_select}
                name="shippingPhoneCode"
                value={values?.shippingPhoneCode}
                style={shippingTelCodeSelectIsOpen ? { display: 'flex' } : {}}
                multiple>
                {phoneNumberCodes.map((phoneCode) => (
                  <option key={phoneCode.id} value={phoneCode.value}>
                    {phoneCode.text.toLowerCase()}
                  </option>
                ))}
              </select>
            </div>
            <input
              className={orderFormStyles.mobile_input}
              type="text"
              name="shippingPhone"
              value={values?.shippingPhone}
              onInput={(e) => {
                const phoneValue = e.target.value
                  .split('')
                  .filter((value) => Number.isInteger(+value))
                  .join('')
                  .replace(/\s/g, '');

                handleChangePhoneNumber('shippingPhone', phoneValue);
              }}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              fontWeight: 600,
              marginTop: 4
            }}>
            {errors?.shippingPhone || errors?.shippingPhoneCode || null}
          </p>
        </fieldset>
        <fieldset
          className={`${orderFormStyles.country_fieldset} ${orderFormStyles.fieldset}`}
          htmlFor="shippingCountry"
          ref={countryShipRef}>
          <p>Country</p>
          <div className={orderFormStyles.dropdown}>
            <input
              // {...register('shipping_country', {
              //   validate: (value) => value.length > 0
              // })}
              onClick={() => setOpenCountryShip(!openCountryShip)}
              onChange={(e) => setSearchShipCountry(e.target.value)}
              name="shippingCountry"
              type="text"
              className={orderFormStyles.country_input}
              placeholder={
                openCountryShip && !searchShipCountry
                  ? values?.shippingCountry
                  : ''
              }
              value={
                openCountryShip ? searchShipCountry : values?.shippingCountry
              }
              style={
                openCountryShip
                  ? {
                      outline: '1px solid #0046a6',
                      backgroundImage: `url(${iconUp})`
                    }
                  : {
                      backgroundImage: `url(${iconDown})`
                    }
              }
            />
          </div>
          <p className={orderFormStyles.validation_warn}>
            {errors?.shippingCountry && !values?.shippingCountry
              ? errors?.shippingCountry
              : null}
          </p>
          {openCountryShip && (
            <select
              name="shippingCountry"
              multiple
              onChange={handleChangeCountryShip}>
              {searchShipCountry.length > 0
                ? countries
                    .filter((countryData) =>
                      countryData.name
                        .toLowerCase()
                        .includes(searchShipCountry.toLowerCase())
                    )
                    .map((countryData, index) => (
                      <option key={index} value={countryData.name}>
                        {countryData.name}
                      </option>
                    ))
                : countries.map((countryData, index) => (
                    <option key={index} value={countryData.name}>
                      {countryData.name}
                    </option>
                  ))}
            </select>
          )}
        </fieldset>
        <label className={orderFormStyles.form_label} htmlFor="address1">
          <p>Address</p>
          <input
            type="text"
            name="shippingAddress"
            value={values.shippingAddress}
            onChange={handleChangeValues}
          />
          {errors?.shippingAddress ? (
            <p>{errors?.shippingAddress}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label>
        {/* <label className={orderFormStyles.form_label} htmlFor="address2">
          <p>Address 2</p>
          <input
            type="text"
            name="shippingAddress2"
            value={values.shippingAddress2}
            onChange={handleChangeValues}
          />
        </label> */}
        <label className={orderFormStyles.form_label} htmlFor="city">
          <p>Town / City</p>
          <input
            type="text"
            name="shippingCity"
            value={values.shippingCity}
            onChange={handleChangeValues}
          />
          {errors?.shippingCity ? <p>{errors?.shippingCity}</p> : <p>&nbsp;</p>}
        </label>
        <label className={orderFormStyles.form_label} htmlFor="region">
          <p>State / Province / Region</p>
          <input
            type="text"
            name="shippingState"
            // {...register('region', {
            //   required: 'Enter State / Province / Region'
            // })}
            value={values.shippingState}
            onChange={handleChangeValues}
          />
          {errors?.shippingState ? (
            <p>{errors?.shippingState}</p>
          ) : (
            <p>&nbsp;</p>
          )}
        </label>
        <label className={orderFormStyles.form_label} htmlFor="zip">
          <p>ZIP / Postal Code</p>
          <input
            type="number"
            name="shippingZip"
            value={values.shippingZip}
            onChange={handleChangeValues}
          />
          {errors?.shippingZip ? <p>{errors?.shippingZip}</p> : <p>&nbsp;</p>}
        </label>
        <label className={orderFormStyles.form_label} htmlFor="tax">
          <p>
            VAT / TAX ID <span>(Optional)</span>
          </p>
          <input type="text" name="shippingVat" onChange={handleChangeValues} />
        </label>
        <label
          className={orderFormStyles.form_label_checkbox}
          htmlFor="saveAddress">
          <input
            type="checkbox"
            name="saveAddress"
            checked={saveShipInfo}
            onChange={handleSaveShipInfo}
          />
          <p>Save as main address</p>
        </label>
      </div>
      <div
        className={orderFormStyles.form_content}
        style={{ marginBottom: '0' }}>
        <label
          className={`${orderFormStyles.form_label} ${orderFormStyles.form_coupon_label}`}
          htmlFor="coupon">
          <p>Coupon</p>
          <div
            className={`${orderFormStyles.form_label_select} ${orderFormStyles.form_coupon_select}`}
            style={{ marginBottom: 0 }}>
            <button type="button" onClick={() => setOpenCoupon(!openCoupon)}>
              <p>{values.couponName || 'No Redeem Coupon'}</p>
              <img src={arrowImg} alt="" />
            </button>
            {openCoupon && (
              <select
                name="coupon"
                multiple
                style={{ top: '55px' }}
                onChange={handleChangeCoupon}>
                {listCoupon.length
                  ? listCoupon.map((item, index) => (
                      <option key={index} value={item.couponId}>
                        {item?.coupon?.name ?? ''}
                      </option>
                    ))
                  : null}
              </select>
            )}
          </div>
          <button
            className={orderFormStyles.form_coupon_apply}
            type="button"
            onClick={handleApplyCoupon}
            disabled={
              values?.couponName.length === 0 ||
              values?.couponName === 'No Redeem Coupon'
            }>
            apply
          </button>
        </label>
        <div className={orderFormStyles.form_currency}>
          <label className={orderFormStyles.currency_label} htmlFor="credit">
            <p>
              Credit&nbsp;
              <span>
                (My Credits: <span>{credit}</span>)
              </span>
            </p>
            <input
              type="text"
              name="credit"
              value={partPrice.creditUsed}
              onInput={(e) => {
                const creditValue = e.target.value?.trim();
                const lengthValue = creditValue?.length;
                const lastChar = creditValue[lengthValue - 1];
                if (lastChar) {
                  const arrayCreditValue = creditValue?.split('');
                  if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
                  if (arrayCreditValue.filter((c) => c === '.').length > 1)
                    return;
                  if (!/^[.\d]$/.test(lastChar)) return;
                }
                handleChangeValuesCredit(creditValue);
              }}
              onFocus={(e) => {
                if ([0, '0'].includes(e.target.value)) {
                  handleChangeValuesCredit(partPrice.creditUsed || '');
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') handleChangeValuesCredit(0);
              }}
            />
          </label>
          <label
            className={orderFormStyles.currency_all_label}
            htmlFor="credit_all">
            <input
              name="credit_all"
              type="checkbox"
              checked={useAllCredit}
              onChange={handleCheckCredit}
            />
            <p>Use all</p>
          </label>
        </div>
        <div
          className={orderFormStyles.form_currency}
          style={{ marginTop: '40px' }}>
          <label className={orderFormStyles.currency_label} htmlFor="profit">
            <p>
              Profit&nbsp;
              <span>
                (My Profit: <span>{profit}</span>)
              </span>
            </p>
            <input
              type="text"
              name="profit"
              value={partPrice.profitUsed}
              onInput={(e) => {
                const profitValue = e.target.value?.trim();
                const lengthValue = profitValue?.length;
                const lastChar = profitValue[lengthValue - 1];
                if (lastChar) {
                  const arrayProfitValue = profitValue?.split('');
                  if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
                  if (arrayProfitValue.filter((c) => c === '.').length > 1)
                    return;
                  if (!/^[.\d]$/.test(lastChar)) return;
                }
                handleChangeValuesProfit(profitValue);
              }}
              onFocus={(e) => {
                if ([0, '0'].includes(e.target.value)) {
                  handleChangeValuesProfit(partPrice.profitUsed || '');
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') handleChangeValuesProfit(0);
              }}
            />
          </label>
          <label
            className={orderFormStyles.currency_all_label}
            htmlFor="profit_all">
            <input
              name="credit_all"
              type="checkbox"
              checked={useAllProfit}
              onChange={handleCheckProfit}
            />
            <p>Use all</p>
          </label>
        </div>
      </div>
    </div>
  );
}

export default withToast(OrderForm);
