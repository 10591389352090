import modalStyles from './ModalPaymentEP.module.scss';

function ModalPaymentEP({ setModalIsOpen, action }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h2 className={modalStyles.modal_title}>‘EDIT & PLOT’ subscription</h2>
        <p className={modalStyles.modal_text}>
          You need to subscribe to the EDIT & PLOT package to use
        </p>
        <hr />
        <div className={modalStyles.modal_controls}>
          <button
            type="button"
            className={modalStyles.modal_confirm}
            onClick={() => action()}>
            Okay
          </button>
          <button
            type="button"
            className={modalStyles.modal_cancel}
            onClick={() => setModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalPaymentEP;
