import modalStyles from '../../../../../MyPage/MyProject/MyProjectConfirmDeleteModal/MyProjectConfirmDeleteModal.module.scss';

function StopSellingModal({ reason, onClose }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h2 className={modalStyles.modal_title}>stop selling</h2>
        <p className={modalStyles.modal_text}>{reason}</p>
        <hr />
        <div className={modalStyles.modal_controls}>
          <button
            type="button"
            className={modalStyles.modal_confirm}
            onClick={onClose}>
            confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default StopSellingModal;
