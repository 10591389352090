import modalStyles from './RefundNoticeModal.module.scss';

function RefundNoticeModal({ setModalIsOpened, refundSuccess }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h1>{refundSuccess ? 'COMPLETE' : 'FAILED'}</h1>
        <p>
          {refundSuccess
            ? 'Please check your PayPal account for the refunded amount.'
            : 'Something went wrong while creating a refund request. Please try again later.'}
        </p>
        <div className={modalStyles.group_btn}>
          <button
            className={modalStyles.btn_confirm}
            onClick={() => setModalIsOpened(false)}
            type="button">
            confirm
          </button>
        </div>
      </div>
    </div>
  );
}
export default RefundNoticeModal;
