import { useState, useEffect } from 'react';
import { getNotifications } from '../../api/notifications';
import Pagination from '../Pagination/Pagination';
import Notification from './Notification/Notification';
import notificationsStyles from './Notifications.module.scss';

function Notifications() {
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsData, setNotificationsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const itemsOnPage = 5;

  const getNotificationsData = async () => {
    const notificationsDataRes = await getNotifications({
      limit: itemsOnPage,
      page: currentPage
    });

    setTotalCount(notificationsDataRes.count);
    setNotificationsData(notificationsDataRes.data);
  };

  useEffect(() => {
    getNotificationsData();
  }, [currentPage]);

  return (
    <main className={notificationsStyles.notifications_main}>
      <div className={notificationsStyles.notifications_content}>
        <h1>NOTIFICATION</h1>
        {notificationsData?.length ? (
          <>
            <div className={notificationsStyles.notifications_holder}>
              {notificationsData?.length &&
                notificationsData.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    setNotificationsData={setNotificationsData}
                  />
                ))}
            </div>
            {totalCount > 5 && (
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={itemsOnPage}
                onPageChange={setCurrentPage}
              />
            )}
          </>
        ) : null}
      </div>
    </main>
  );
}

export default Notifications;
