/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';

import { getPatternHistory } from '../../../api/patterns';
import { formatDate } from '../../../utils/function';

import MyPageTitle from '../MyPageTitle/MyPageTitle';
import PatternListItem from '../PatternListItem/PatternListItem';
// import MyPageReviewModal from '../MyPageReviewModal/MyPageReviewModal';
import PatternHistoryPopUp from './PatternHistoryPopUp/PatternHistoryPopUp';
import Pagination from '../../Pagination/Pagination';

import historyStyles from '../MyPage.module.scss';

function PatternHistory() {
  const patternsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });

  const [cardsData, setCardsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [timeRecall, setTimeRecall] = useState(0);

  const fetchData = async ({
    from = '',
    to = '',
    page = 1,
    limit = patternsOnPage
  }) => {
    const patternHistoryRes = await getPatternHistory({
      from,
      to,
      page,
      limit
    });

    setCardsData(patternHistoryRes.patterns);
    setTotalCount(patternHistoryRes.count);
  };

  useEffect(() => {
    fetchData({
      from: filterDates.from,
      to: filterDates.to,
      page: currentPage
    });
  }, [currentPage, filterDates, timeRecall]);

  return (
    <main className={historyStyles.main}>
      {popupIsOpen && <PatternHistoryPopUp setPopupIsOpen={setPopupIsOpen} />}
      <div className={historyStyles.content}>
        <MyPageTitle
          title="PLOTTING HISTORY"
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
          isRequiredReset
        />
        <hr className={historyStyles.upper_hr} />
        <p style={{ fontSize: '20px', fontWeight: '600', color: '#808080' }}>
          ※ Your plotting history will remain stored in the system for a year.
        </p>
        <div
          style={{
            marginTop: '60px',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px'
          }}>
          {cardsData?.length ? (
            cardsData.map((item, index) => (
              <PatternListItem
                key={index}
                pattern={item}
                timeRecall={timeRecall}
                setTimeRecall={setTimeRecall}
                isPlottingPage
              />
            ))
          ) : (
            <div> </div>
          )}
          {totalCount > patternsOnPage && (
            <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={patternsOnPage}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      </div>
    </main>
  );
}

export default PatternHistory;
