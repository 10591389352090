import { useEffect, useState } from 'react';
import AdPopUp from '../AdPopUp/AdPopUp';
import Banner from '../Banner/Banner';
import CreditPacks from '../CreditPacks/CreditPacks';
import Description from '../Description/Description';
import FilmList from '../FilmList/FilmList';
import Hero from '../Hero/Hero';

export default function MainPage({ onFreeTrial, loginState }) {
  // const [bannerTop, setBannerTop] = useState([]);
  // const [bannerLow, setBannerLow] = useState([]);

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <main>
      <Hero setSelectedCategory={setSelectedMainCategoryId} />
      <FilmList selectedMainCategoryId={selectedMainCategoryId} />
      <Banner onFreeTrial={onFreeTrial} />
      <Description />
      <CreditPacks homePage loginState={loginState} />
      <AdPopUp popUpCategory="메인페이지" />
    </main>
  );
}
