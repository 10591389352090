// import { useState, useEffect } from 'react';

function Sedan({ checkedParts }) {
  const style = `.st0{opacity:0.4}
	.st1{fill:${
    checkedParts?.fullPackage ? '#ff6900' : 'none'
  };stroke:#222222;stroke-width:0.4;stroke-miterlimit:10;}
	.st2{opacity:0.4;fill:${
    checkedParts?.fullPackage ? '#ff6900' : 'none'
  };stroke:#222222;stroke-width:0.4;stroke-miterlimit:10;enable-background:new;}`;
  return (
    <svg
      version="1.1"
      id="_레이어_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 357.1 643.4"
      style={{
        enableBackground: 'new 0 0 357.1 643.4',
        transform: 'rotate(90deg)'
      }}
      xmlSpace="preserve">
      <style type="text/css">{style}</style>
      <g className="st0">
        <polygon
          className="st1"
          points="86.1,267.9 117,261.4 117,258.7 86.5,262.8 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
        <polygon
          className="st1"
          points="86.8,258.2 86.5,262.8 117,258.7 117,254.9 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
        <polygon
          className="st1"
          points="117,252.5 87.1,253.2 86.8,258.2 117,254.9 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
      </g>
      <polygon className="st2" points="78.6,334.2 78.1,343.5 87,332.5 " />
      <g className="st0">
        <path
          className="st1"
          d="M69.6,265.4c1.1-0.7,1.9-4.4,2.1-6.7s-0.2-4.4-0.6-4.9c-0.4-0.5-1.3-1-1.7-0.6c-0.6,0.6-0.5,1.6-0.6,4.9
		c0,0.5-0.1,0.9-0.1,1.4l0,0c-0.3,2.6-1.1,4.5-1.1,5.5C67.6,266.1,68.6,266.1,69.6,265.4z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M67.2,282.9c0.9,0.2,1.4-1.3,1.5-2.5c0.1-1.2-0.1-3.3-0.4-3.7c-0.4-0.5-1.2-0.4-1.3-0.2c0,0-0.6,0.7-0.6,1.6
		C66.4,279.4,66.2,282.7,67.2,282.9L67.2,282.9L67.2,282.9z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M72.6,240.3c0.3-1.1,0.4-3.2-0.4-3.4c-0.7-0.2-1.1,0.3-2,1.9s-0.6,4.2-0.6,4.2
		C70.9,242.6,72.3,241.4,72.6,240.3L72.6,240.3L72.6,240.3z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M88.9,187.7c-1.5-0.2-14.6-3.5-20.7-4.6s-11.4,0.8-19.2,2c-7.8,1.2-22.7,0-22.7,0l-0.9,1.6
		c0,0-2.3,21-3.6,33.7c-0.7,7.1-1.9,21.5-3,34.9c0,0,19.2,4,31.8,4.2c12.6,0.2,17.9,0,17.9,0c0.1-0.4,0.1-0.9,0.1-1.4
		c0.1-3.3,0-4.3,0.6-4.9l0.3-10.3c0,0-0.3-2.6,0.6-4.2c0.9-1.6,1.2-2.2,2-1.9c0.7,0.2,0.7,2.3,0.4,3.4c-0.3,1.1-1.7,2.4-3,2.7
		l-0.3,10.3c0.4-0.4,1.4,0.1,1.7,0.6c0.4,0.5,0.8,2.6,0.6,4.8c-0.2,2.2-1,5.9-2.1,6.7c-1.1,0.7-2.1,0.7-2.1-0.4c0,0-0.1,2.9,0,5
		s-0.6,6.6-0.6,6.6c0.2-0.3,0.9-0.4,1.3,0.2c0.3,0.4,0.4,2.5,0.4,3.7c-0.1,1.2-0.5,2.7-1.5,2.5s-0.8-3.5-0.8-4.7
		c0-1,0.6-1.6,0.6-1.6s0.6-4.5,0.6-6.6c-0.1-2.1,0-5,0-5c0-1,0.8-2.9,1.1-5.5c-0.1,0-5.3,0.2-17.9,0c-12.6-0.2-31.8-4.2-31.8-4.2
		c-0.8,10.6-1.6,20.6-2,26.1c-1,12.3-4.1,51.6-4.3,54.9c-0.2,3.4-1.4,7.2-1.4,7.2s1.5,2.6,15.2,4.6s36.3,3.2,39.4,3
		c3.1-0.1,3.3-0.2,4.8-1.5s5.2-4.6,5.2-4.6c1-4.9,2.2-17.3,3.2-29.8c1.1-12.6,2-25.4,2.4-30.9c0.8-10.9,3.5-47.9,5.1-61.5
		c1.5-13.6,3-30.1,3.5-31.8C90.4,189.5,90.4,187.8,88.9,187.7L88.9,187.7L88.9,187.7z M60.4,267.4c-0.7,0-1.2-0.6-1.2-1.2
		s0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2S61.1,267.4,60.4,267.4z M76.9,317.8c-0.3,0.4-3.1,0.6-3.7-0.3s-0.7-6.3-0.7-7
		c-0.1-0.7,0-1.3,0.7-1.7c0.9-0.5,3.9,1.9,4,2.7C77.3,312.2,77.2,317.4,76.9,317.8L76.9,317.8L76.9,317.8z M71.6,259.5
		c0,0,9.9-0.4,11.8-0.9C81.5,259.1,71.6,259.5,71.6,259.5z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M73.2,308.7c-0.7,0.4-0.8,1-0.7,1.7c0.1,0.7,0.1,6.1,0.7,7s3.4,0.7,3.7,0.3s0.4-5.6,0.3-6.4
		C77.1,310.6,74,308.2,73.2,308.7L73.2,308.7L73.2,308.7z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M60.4,264.9c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S61.1,264.9,60.4,264.9z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
      </g>
      <path
        className="st2"
        d="M7.9,358.1c0,0-2.9,0.2-4.6-0.4s-3.1-0.9-3.1-0.9S2,341,3.4,325c1.4-16,13.2-146.2,13.2-146.2s0.1-0.7,2.4-0.5
	s5,0.1,5.3,0.6s-0.2,5.4-0.5,9.2S7.9,358.1,7.9,358.1C7.9,358.1,7.9,358.1,7.9,358.1z"
        style={checkedParts?.door ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M136.4,223.2c-0.4-18.1-1.3-43.7-2.1-50.6c-0.7-6.9-2.4-14.5-2.4-14.5s-5,0.9-5.9,0.4s-3.7-4.8-5.6-11.2
	c-1.9-6.4-8.5-26.1-10.1-34.9c-1.6-8.8-1.3-18.2-0.8-24c0.5-5.8,0.7-16.7,0.6-19c-0.1-2.3-3.3-9.5-4.8-13.6
	c-1.5-4.1-2.3-8.8-3.5-8.7s-6.1,5-9,7.6c-2.9,2.6-9,8-10,9.3c-1,1.2-2.1,2.4-2.1,2.4l2.1,2.2c0,0,6-6.2,9.1-9.4
	c3.1-3.2,4.6-5.1,5.7-4.9s2.1,6.3,2.4,8.7c0.3,2.4,1.4,8.5,0.6,11.6c-0.8,3.1-6.4,13.9-7.4,15s-1.8-0.1-2.8-1.1s-7.6-6.5-8.8-8.1
	s-3.1-3.2-2.9-4.8s1.2-2.7,0.8-3.2c-0.3-0.6-2.2-0.8-2.4-0.5s-2.9,8.6-5.6,20.8s-4.6,19.3-5.9,21.7s-5.2,8.8-5.2,8.8s5.9,7.7,7.1,14
	s0.9,16-1.6,21.5S57.3,172,50.1,175c-7.3,3-21.8,3.1-22.6,3s-1.1,0.7-1.1,2.3s0.4,2.9,1.2,3.1c0.7,0.2,13.8,0.1,19.3-0.7
	c5.5-0.8,17.2-1.9,20.8-1.3c3.6,0.6,22.2,4.9,22.6,4.7c0.4-0.2,1.6-6.6,2.2-16c0.7-9.4,1.7-18.4,2.5-18.6c0.8-0.2,7.5,8.4,9.3,10.7
	c1.7,2.2,13.7,16.2,16,21.7c2.3,5.5,3.2,10.6,4.2,26.4c1,15.9,1.8,56,0.3,66.2s-7.4,28.8-12.7,41.5s-11.4,28.3-11.4,28.8
	s0.5,3.8,0.8,6c0.3,2.1,0.5,6,1.5,6s4.3-4.6,5.3-5.1s0.5-1.5-0.1-2.2s-1.7-3.6-0.9-6.2c0.8-2.6,9.1-25.6,11.2-32.1
	c2.2-6.5,5.2-15.3,8-16.8c2.8-1.5,4.9,0.6,4.9,0.6c0.4-0.1,2.1-4.5,3.1-13.5c1-9,1.4-14.1,1.4-18S136.8,241.3,136.4,223.2
	L136.4,223.2L136.4,223.2z M93.2,148.9c0,1.1-0.9,2-2,2H82c-1.1,0-2.1-1-2-2.1l0.8-12.7c0.1-1.1,1-1.9,2.1-1.9h8.4c1.1,0,2,0.9,2,2
	V148.9L93.2,148.9L93.2,148.9z"
        style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M51.6,134.2h7.7c1.8,0,3.2,1.4,3.2,3.2v10.3c0,1.8-1.4,3.2-3.2,3.2h-7.7c-1.8,0-3.2-1.4-3.2-3.2v-10.3
	C48.4,135.7,49.8,134.2,51.6,134.2z"
      />
      <polygon
        className="st2"
        points="120.9,198.8 95.2,187.3 95,188.8 121.1,200.5 "
        style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
      />
      <polygon
        className="st2"
        points="95,189.9 94.7,195.9 121.3,205.6 121.1,201.6 "
        style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M111.5,399.7c0,0-1.4-1.1-1.6-3.5c-0.2-2.4,0-11,0-11s-4.8,5.3-6.3,6.1c-1.5,0.7-5.6,0.6-7.9,1.1
	S69.2,393,61.6,393s-11.2-0.4-12.4-0.9s-3.1-0.5-3.1-0.5v6.7c0,4.5-0.1,10.3,1,11.4c1.1,1.1,5,1.4,10.5,2.7
	c5.6,1.4,13.9,5.1,20.8,12.1c6.9,7.1,7.7,15.4,7.9,22.7c0.2,7.3-1.7,7.6-1.7,8.8s2.4,1.9,4.3,4.1s4,6.9,6.3,15
	c2.4,8.1,1.9,10.4,3.8,12.5c2,2.1,7.8,9.8,8.9,10.3c1.1,0.5,2.1-4.6,2.9-11.3s1.1-24.5,1.2-49C112.2,413.3,111.5,399.7,111.5,399.7
	L111.5,399.7L111.5,399.7z M77.4,400.3h-9.7c-0.8,0-1.4-0.6-1.4-1.4v-1.2c0-0.8,0.6-1.4,1.4-1.4l9.3-0.4c0.7,0,1.3,0.5,1.5,1.2
	l0.3,1.7C78.9,399.5,78.2,400.3,77.4,400.3L77.4,400.3L77.4,400.3z"
        style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M203.4,45.5c0-2-1.1-4.2-1.2-4.9s-0.4-1.1-0.9-1.2c-0.4-0.1-13.7-0.4-22.8-0.4s-22.4,0.4-22.8,0.4
	s-0.8,0.5-0.9,1.2s-1.2,2.8-1.2,4.9s1,2.4,1.7,3.7s0.7,2.8,0.7,5.6s-0.4,3.5-0.6,5.5c-0.2,2,0,4.5,0,4.5h46.1c0,0,0.2-2.5,0-4.5
	c-0.2-2-0.6-2.6-0.6-5.5s0-4.3,0.7-5.6S203.4,47.6,203.4,45.5L203.4,45.5L203.4,45.5z M194.7,60.7h-32.3v-8.2h32.3V60.7z"
      />
      <path
        className="st2"
        d="M219.2,272.7c-0.9-11.7-1.6-115.9-1.6-115.9s-20.3-3.7-39.1-3.7s-39.1,3.7-39.1,3.7s-0.7,104.3-1.6,115.9
	c-0.9,11.7-2,23.2-2,23.2s23.4,2.9,42.7,2.9s42.7-2.9,42.7-2.9S220.1,284.4,219.2,272.7L219.2,272.7z"
        style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M181.4,166.4c-0.4-0.5-1-0.8-2.8-0.8s-2.4,0.3-2.8,0.8c-0.4,0.5-0.7,0.8-0.7,2.2s0.1,6.1,0.3,7.4
	s1.6,5.8,3.3,5.8s3.1-4.5,3.3-5.8s0.3-6.1,0.3-7.4S181.8,166.8,181.4,166.4L181.4,166.4L181.4,166.4z"
      />
      <g className="st0">
        <polygon
          className="st1"
          points="271,267.9 240,261.4 240,258.7 270.6,262.8 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
        <polygon
          className="st1"
          points="270.3,258.2 270.6,262.8 240,258.7 240,254.9 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
        <polygon
          className="st1"
          points="240,252.5 270,253.2 270.3,258.2 240,254.9 	"
          style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
        />
      </g>
      <polygon className="st2" points="278.5,334.2 279,343.5 270,332.5 " />
      <g className="st0">
        <path
          className="st1"
          d="M287.8,253.3c-0.4-0.4-1.4,0.1-1.7,0.6c-0.4,0.5-0.8,2.6-0.6,4.9s1,5.9,2,6.7c1,0.7,2,0.7,2.1-0.4
		c0-1-0.8-2.9-1.1-5.5l0,0c0-0.4-0.1-0.9-0.1-1.4C288.3,254.9,288.4,253.9,287.8,253.3L287.8,253.3L287.8,253.3z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M288.8,276.7c-0.3,0.4-0.5,2.5-0.4,3.7c0.1,1.2,0.5,2.7,1.5,2.5s0.8-3.5,0.8-4.7c0-1-0.6-1.6-0.6-1.6
		C290,276.3,289.2,276.2,288.8,276.7L288.8,276.7L288.8,276.7z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M286.8,238.8c-0.9-1.6-1.2-2.2-2-1.9c-0.7,0.2-0.7,2.3-0.4,3.4c0.3,1.1,1.7,2.4,3,2.7
		C287.5,243,287.8,240.4,286.8,238.8L286.8,238.8z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M344.6,336.3c-0.2-3.4-3.4-42.7-4.3-54.9c-0.4-5.4-1.2-15.5-2-26.1c0,0-19.2,4-31.8,4.2
		c-12.6,0.2-17.9,0-17.9,0c0.3,2.6,1.1,4.5,1.1,5.5c0,0,0.1,2.9,0,5s0.5,6.6,0.5,6.6s0.6,0.7,0.6,1.6c0,1.2,0.1,4.5-0.8,4.7
		s-1.4-1.3-1.5-2.5s0.1-3.3,0.4-3.7c0.4-0.5,1.2-0.4,1.3-0.2c0,0-0.6-4.5-0.5-6.6c0.1-2.1,0-5,0-5c0,1.1-1,1.2-2.1,0.4
		c-1-0.7-1.9-4.4-2-6.7s0.2-4.4,0.6-4.9c0.4-0.5,1.3-1,1.7-0.6l-0.3-10.3c-1.2-0.4-2.7-1.6-3-2.7s-0.4-3.2,0.4-3.4s1,0.3,2,1.9
		c0.9,1.6,0.6,4.2,0.6,4.2l0.3,10.3c0.6,0.6,0.5,1.6,0.6,4.9c0,0.5,0.1,0.9,0.1,1.4c0,0,5.4,0.2,17.9,0c12.6-0.2,31.8-4.2,31.8-4.2
		c-1.1-13.4-2.2-27.8-3-34.9c-1.3-12.7-3.6-33.7-3.6-33.7l-0.9-1.6c0,0-15,1.2-22.7,0c-7.8-1.2-13.1-3.1-19.2-2s-19.2,4.5-20.7,4.6
		c-1.5,0.2-1.5,1.8-1,3.5s2,18.2,3.5,31.8s4.3,50.6,5,61.5c0.4,5.5,1.3,18.3,2.4,30.9c1.1,12.5,2.3,24.9,3.2,29.8
		c0,0,3.7,3.4,5.2,4.6s1.7,1.4,4.8,1.5c3.1,0.1,25.7-1,39.4-3c13.8-2,15.2-4.6,15.2-4.6S344.8,339.7,344.6,336.3L344.6,336.3
		L344.6,336.3z M284.7,310.5c-0.1,0.7-0.1,6.1-0.7,7s-3.4,0.7-3.7,0.3s-0.4-5.6-0.3-6.4c0.1-0.8,3.2-3.2,4-2.7
		C284.6,309.1,284.7,309.7,284.7,310.5L284.7,310.5L284.7,310.5z M273.7,258.6c1.9,0.5,11.8,0.9,11.8,0.9S275.6,259.1,273.7,258.6z
		 M296.7,267.4c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2S297.4,267.4,296.7,267.4z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M283.9,308.7c-0.9-0.5-3.9,1.9-4,2.7c-0.1,0.8,0,6,0.3,6.4s3.1,0.6,3.7-0.3s0.7-6.3,0.7-7
		C284.7,309.7,284.6,309.2,283.9,308.7L283.9,308.7L283.9,308.7z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
        <path
          className="st1"
          d="M296.7,264.9c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S297.4,264.9,296.7,264.9z"
          style={checkedParts?.door ? { fill: '#ff6900' } : {}}
        />
      </g>
      <path
        className="st2"
        d="M349.2,358.1c0,0,2.9,0.2,4.5-0.4s3.1-0.9,3.1-0.9S355,341,353.6,325s-13.2-146.2-13.2-146.2s-0.1-0.7-2.4-0.5
	s-5,0.1-5.3,0.6s0.2,5.4,0.5,9.2S349.2,358.1,349.2,358.1L349.2,358.1z"
        style={checkedParts?.door ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M329.7,177.9c-0.8,0.1-15.4,0-22.6-3s-13.3-10.9-15.8-16.4c-2.5-5.5-2.8-15.2-1.6-21.5s7.1-14,7.1-14
	s-3.9-6.4-5.2-8.8s-3.1-9.6-5.9-21.7c-2.7-12.2-5.4-20.4-5.6-20.8s-2.1-0.1-2.4,0.5s0.7,1.7,0.8,3.2c0.2,1.6-1.6,3.2-2.9,4.8
	c-1.2,1.6-7.9,7.1-8.9,8.1s-1.8,2.2-2.8,1.1c-1-1.1-6.5-11.9-7.4-15c-0.8-3.1,0.2-9.2,0.6-11.6c0.3-2.4,1.3-8.4,2.4-8.7
	c1.1-0.2,2.6,1.7,5.7,4.9c3.1,3.2,9.1,9.4,9.1,9.4l2.1-2.2c0,0-1.1-1.2-2.1-2.4s-7.1-6.7-10-9.3s-7.9-7.5-9-7.6s-2,4.5-3.5,8.7
	s-4.7,11.3-4.8,13.6c-0.1,2.3,0.1,13.2,0.6,19c0.5,5.8,0.7,15.2-0.8,24s-8.2,28.4-10.1,34.9s-4.6,10.8-5.6,11.2
	c-1,0.5-5.9-0.4-5.9-0.4s-1.6,7.5-2.4,14.5c-0.7,6.9-1.6,32.5-2.1,50.6c-0.4,18.1,0.5,37.9,0.5,41.8s0.4,9,1.4,18
	c1,9,2.6,13.4,3.1,13.5c0,0,2.1-2.1,4.9-0.6c2.8,1.5,5.8,10.4,8,16.8s10.5,29.5,11.2,32.1c0.8,2.6-0.3,5.5-0.9,6.2
	c-0.6,0.7-1.1,1.7-0.1,2.2s4.3,5.1,5.3,5.1s1.2-3.8,1.5-6c0.3-2.1,0.8-5.5,0.8-6s-6.1-16-11.4-28.8c-5.3-12.7-11.2-31.2-12.7-41.5
	c-1.5-10.2-0.7-50.4,0.3-66.2s1.9-21,4.2-26.4c2.3-5.5,14.3-19.4,16-21.7s8.4-10.8,9.3-10.7c0.8,0.2,1.8,9.2,2.5,18.6
	s1.8,15.9,2.2,16s18.9-4.1,22.6-4.7s15.3,0.5,20.8,1.3c5.5,0.8,18.6,0.9,19.3,0.7s1.2-1.4,1.2-3.1S330.5,177.9,329.7,177.9
	L329.7,177.9L329.7,177.9z"
        style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M265.3,59.2c-3.1-3.2-4.6-5.1-5.7-4.9s-2.1,6.3-2.4,8.7s-1.4,8.5-0.6,11.6c0.8,3.1,6.4,13.9,7.4,15
	s1.8-0.1,2.8-1.1s7.6-6.5,8.9-8.1c1.2-1.6,3.1-3.2,2.9-4.8c-0.1-0.9-0.5-1.6-0.7-2.2c-0.5-1.3-2.3-3.6-3.5-4.8
	C274.4,68.7,268.3,62.5,265.3,59.2z"
        style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M91.8,59.2c3.1-3.2,4.6-5.1,5.7-4.9s2.1,6.3,2.4,8.7s1.4,8.5,0.6,11.6c-0.8,3.1-6.4,13.9-7.4,15
	s-1.8-0.1-2.8-1.1s-7.6-6.5-8.8-8.1s-3.1-3.2-2.9-4.8c0.1-0.9,0.4-1.6,0.7-2.2c0.5-1.3,2.3-3.6,3.4-4.8
	C82.7,68.7,88.8,62.5,91.8,59.2z"
        style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M229.2,101.1c-1-7.3-3.7-19.3-4.8-26.4c-1.1-7-1.2-17.6-1.7-18.8c-0.4-1.2-3.5-3.5-6.5-5.3s-7.9-2.2-7.9-2.2
	v5.5c0,0-1.5,0.7-2.9,2.5c-1.4,1.8-2.2,3.5-2.8,5.7c-0.6,2.2-0.8,8.7-0.7,9.3c0.1,0.6,3.5,0.7,4.1,0.8c0.7,0.1,1.4,0.9,0.9,1.9
	c-0.4,1-2.4,0.5-5.9,0.2c-2.9-0.3-13.1-0.4-19.2-0.4c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2c-6.2,0-16.4,0.2-19.2,0.4
	c-3.5,0.3-5.5,0.8-5.9-0.2c-0.4-1,0.2-1.8,0.9-1.9c0.7-0.1,4.1-0.2,4.1-0.8s-0.2-7.1-0.7-9.3s-1.4-3.9-2.8-5.7
	c-1.4-1.8-2.9-2.5-2.9-2.5v-5.5c0,0-4.9,0.4-7.9,2.2c-3,1.9-6.1,4.1-6.5,5.3c-0.4,1.2-0.6,11.8-1.7,18.8c-1.1,7-3.8,19.1-4.8,26.4
	c-1,7.3-2,13.8-2,15.5v11.8c0,0,1.7,0.6,3.7,0c2-0.6,13.5-5.1,23.8-5.9c10.3-0.9,25.1-1,25.1-1s14.8,0.1,25.1,1
	c10.3,0.9,21.8,5.3,23.8,5.9c2,0.6,3.7,0,3.7,0v-11.8C231.2,114.9,230.2,108.4,229.2,101.1L229.2,101.1L229.2,101.1z"
        style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
      />
      <polygon
        className="st2"
        points="236.1,198.8 261.9,187.3 262,188.8 236,200.5 "
        style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
      />
      <polygon
        className="st2"
        points="262,189.9 262.4,195.9 235.7,205.6 236,201.6 "
        style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M311,391.6c0,0-1.9,0-3.1,0.5c-1.2,0.5-4.7,0.9-12.4,0.9s-31.8-0.1-34.1-0.6c-2.4-0.5-6.4-0.4-7.9-1.1
	c-1.5-0.7-6.3-6.1-6.3-6.1s0.2,8.7,0,11c-0.2,2.4-1.6,3.5-1.6,3.5s-0.7,13.6-0.6,38.1s0.4,42.3,1.1,49s1.8,11.8,2.9,11.3
	c1.1-0.5,6.9-8.2,8.9-10.3c2-2.1,1.5-4.5,3.8-12.5c2.4-8.1,4.3-12.8,6.3-15s4.3-2.9,4.3-4.1s-2-1.5-1.7-8.8c0.2-7.3,1-15.6,7.9-22.7
	c6.9-7.1,15.2-10.8,20.8-12.1s9.4-1.6,10.5-2.7c1.1-1.1,1-6.9,1-11.4L311,391.6L311,391.6L311,391.6z M290.8,398.9
	c0,0.8-0.6,1.4-1.4,1.4h-9.7c-0.9,0-1.6-0.8-1.4-1.7l0.3-1.7c0.1-0.7,0.8-1.2,1.5-1.2l9.4,0.4c0.8,0,1.4,0.7,1.4,1.4L290.8,398.9
	L290.8,398.9L290.8,398.9z"
        style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M136.5,581.2c0,0,5.2,1.1,6.2,1.2s2.1,0,2.1,0s0.9,10.3,1.4,15.8s0.9,16.5,1.1,17.8c0.1,0.6-2.1,2-2.1,2
	s0.3-5.2-0.2-11.7c-0.6-6.5-1.2-10.4-3.6-13.1c-2.4-2.7-3.1-1.5-3.4-3.2S136.5,581.2,136.5,581.2L136.5,581.2L136.5,581.2z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M134.3,580.2l-30.3-4.4c0,0,0.6,4.7,1.5,6.8c0.9,2.1,2.1,3.7,5.7,4c3.6,0.2,10.2,1.1,14.8,1.9s9.8,1.7,9.8,1.7
	S135.9,584.8,134.3,580.2L134.3,580.2L134.3,580.2z"
        style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M144.1,607.1c-0.3-3.2-3.1-11.1-4.5-13.6c-1.4-2.5-2.3-2.2-3.7-2.2s-23.9-0.1-25.7-0.1s-2.8-1.1-4.6-4.2
	c-1.7-3.1-2.7-5-2.7-5l-5.9-0.2c-5.9-0.2-16,0-20.9,1.1s-8.2,4.5-8.2,4.5s6.5,4.5,10.7,9.4c4.1,4.9,5.5,10.4,6.9,16.9
	c1.3,6.5,0.5,11.7,0.7,13c0.2,1.2,1.1,1.2,1.7,2.6c0.7,1.4,0.5,3.8,0.5,3.8s5.7-0.1,8.1-0.7c2.4-0.6,4.6-2,7.2-4.2
	c2.6-2.2,3-4.2,3.6-9.3c0.7-5.1,0.3-11.8,0.3-11.8s1.2-0.7,1.5-1.3s-0.4-1.1-1-1.8s-0.8-1,0.4-0.6c1.2,0.4,22.4,0.1,25.5,0.1
	s6.2,3.6,6.2,3.6s-4.3-2.5-5.2-2.8c-0.9-0.2-6.9-0.1-12.7-0.1S111,605,111,605l3.2,1.5l-5,0.4c0,0,0.1,2,1.1,3.9s2.1,3.8,5.6,5.2
	c3.5,1.3,16.5,2.2,23.1,2.3c4,0.1,5.4,0,5.4,0l0-0.7C144.3,617.6,144.5,610.3,144.1,607.1L144.1,607.1L144.1,607.1z M86.2,605.7
	c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8S86.7,605.7,86.2,605.7z M117.6,612.4c-0.4,0-0.8-0.3-0.8-0.8
	s0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8S118.1,612.4,117.6,612.4z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M96.3,572.7c2.1-0.3,5.1-1.3,5.1-3.2l-12.2-0.2v2.5c0,1,0.6,1.5,1.8,1.5S94.2,573,96.3,572.7L96.3,572.7
	L96.3,572.7z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M104,562.4c0,0,0.1-3.2-0.9-5.5c-1.1-2.3-1.7-3.6-1.7-3.6H89.8c-1.2,0-4.6,3.1-4.6,3.1s2.3,3.4,5,4.6
	s4.5,1.4,5.5,1.4C95.8,562.4,104,562.4,104,562.4z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M83,563.3c0,0-0.7,0.7-0.7,2.5s1.3,3.3,1.3,3.3l2.9,0c0.1-0.6,0.4-2.1,0.9-2.3c0.4-0.2,0.9,1.4,1.2,2.3
	l15.3,0.2v-6.1L83,563.3L83,563.3L83,563.3z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M112.7,626c0,0,1.6,3.2,3,4s10.2,1.7,15.1,1.9c4.9,0.2,11.7-0.3,13.6-0.5s7.6-1.9,7.6-2.4s-3.4-0.5-12.4-0.5
	s-14.5-0.3-18-0.6S113.5,625.4,112.7,626C112.7,626,112.7,626,112.7,626z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M117.7,618.8c0,0-1.6,0.5-1.6,1.6s1.7,1,3,1h22.5c1.2,0,1.3-0.7,1.3-1.3s-1.8-0.7-3-0.9
	S119.5,618.8,117.7,618.8L117.7,618.8L117.7,618.8z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M206.7,593.4c-0.8-0.8-1.1-0.6-1.6,0c-0.6,0.6-1.9,1-6.2,1.7c-4.3,0.7-13.8,0.5-20.3,0.5s-16,0.2-20.3-0.5
	c-4.3-0.7-5.6-1.1-6.2-1.7s-0.8-0.8-1.6,0c-0.8,0.8-0.9,1.4-0.7,2.2s1.6,2,4.1,2.4c2.5,0.4,12.1,0.9,24.8,0.9s22.3-0.5,24.8-0.9
	c2.5-0.4,3.8-1.6,4.1-2.4S207.6,594.3,206.7,593.4L206.7,593.4L206.7,593.4z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M178.5,614.5c-17.9,0-29.4,0.5-29.4,0.5l0.4,2.6c2.8-0.7,29-1.5,29-1.5s26.2,0.8,29,1.5l0.4-2.6
	C207.9,615,196.5,614.5,178.5,614.5L178.5,614.5L178.5,614.5z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M220.6,581.2c0,0-5.2,1.1-6.2,1.2s-2.1,0-2.1,0s-0.9,10.3-1.4,15.8c-0.5,5.5-0.9,16.5-1.1,17.8
	c-0.1,0.6,2.1,2,2.1,2s-0.3-5.2,0.2-11.7c0.6-6.5,1.2-10.4,3.6-13.1c2.4-2.7,3.1-1.5,3.4-3.2S220.6,581.2,220.6,581.2L220.6,581.2
	L220.6,581.2z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M222.8,580.2l30.3-4.4c0,0-0.6,4.7-1.5,6.8c-0.9,2.1-2.1,3.7-5.7,4s-10.2,1.1-14.8,1.9s-9.8,1.7-9.8,1.7
	S221.2,584.8,222.8,580.2L222.8,580.2L222.8,580.2z"
        style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M212.8,617.6l0,0.7c0,0,1.4,0.1,5.4,0c6.6-0.1,19.6-1,23.1-2.3s4.6-3.3,5.6-5.2c1-1.9,1.1-3.9,1.1-3.9l-5-0.4
	l3.2-1.5c0,0-5.8-0.9-11.5-0.9s-11.7-0.1-12.6,0.1c-0.9,0.2-5.2,2.8-5.2,2.8s3.1-3.6,6.2-3.6s24.3,0.3,25.5-0.1s1-0.1,0.4,0.6
	s-1.2,1.2-1,1.8s1.5,1.3,1.5,1.3s-0.3,6.7,0.4,11.8c0.7,5.1,1.1,7.1,3.6,9.3c2.6,2.2,4.8,3.6,7.2,4.2c2.4,0.6,8.1,0.7,8.1,0.7
	s-0.2-2.4,0.5-3.8c0.7-1.4,1.6-1.3,1.7-2.6c0.2-1.2-0.7-6.5,0.7-13c1.3-6.5,2.7-12,6.9-16.9c4.1-4.9,10.7-9.4,10.7-9.4
	s-3.2-3.4-8.2-4.5s-15-1.2-20.9-1.1l-5.9,0.2c0,0-1,1.9-2.7,5c-1.7,3.1-2.7,4.2-4.6,4.2s-24.3,0.1-25.7,0.1s-2.3-0.3-3.7,2.2
	c-1.4,2.5-4.1,10.5-4.5,13.6S212.7,617.6,212.8,617.6L212.8,617.6L212.8,617.6z M270.1,604.9c0-0.4,0.4-0.8,0.8-0.8s0.8,0.3,0.8,0.8
	s-0.4,0.8-0.8,0.8S270.1,605.3,270.1,604.9z M238.6,611.6c0-0.4,0.4-0.8,0.8-0.8s0.8,0.3,0.8,0.8s-0.4,0.8-0.8,0.8
	S238.6,612,238.6,611.6z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M178.5,600.9h-29.4v12.2h58.7v-12.2H178.5L178.5,600.9L178.5,600.9z M155.5,607.8c-0.4,0-0.8-0.3-0.8-0.8
	s0.4-0.8,0.8-0.8s0.8,0.3,0.8,0.8S156,607.8,155.5,607.8z M201.6,607.8c-0.4,0-0.8-0.3-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.3,0.8,0.8
	S202,607.8,201.6,607.8z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M260.8,572.7c-2.1-0.3-5.1-1.3-5.1-3.2l12.1-0.2v2.5c0,1-0.6,1.5-1.8,1.5S262.9,573,260.8,572.7L260.8,572.7z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M253.1,562.4c0,0-0.1-3.2,0.9-5.5s1.6-3.6,1.6-3.6h11.7c1.2,0,4.5,3.1,4.5,3.1s-2.3,3.4-5,4.6s-4.5,1.4-5.5,1.4
	H253.1L253.1,562.4L253.1,562.4z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M274.1,563.3c0,0,0.7,0.7,0.7,2.5s-1.3,3.3-1.3,3.3l-2.9,0c-0.1-0.6-0.4-2.1-0.9-2.3c-0.4-0.2-0.9,1.4-1.2,2.3
	l-15.4,0.2v-6.1L274.1,563.3L274.1,563.3L274.1,563.3z"
        style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M246.8,638.6c-0.7-2.3-1.3-1.8-4.6-2.8s-21.7-2.2-26.3-2.7c-4.6-0.6-13.4,0-37.4,0s-32.7-0.6-37.4,0
	c-4.6,0.6-23,1.7-26.3,2.7c-3.3,1-4,0.5-4.6,2.8c-0.7,2.3-1.5,4.5-1.5,4.5s4-1.7,12.6-2.8s34.7-1.8,41.6-1.7s15.6,0.1,15.6,0.1
	s8.8,0.1,15.6-0.1s33,0.5,41.6,1.7c8.6,1.2,12.6,2.8,12.6,2.8S247.5,640.9,246.8,638.6L246.8,638.6z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M244.3,626c0,0-1.6,3.2-3,4c-1.4,0.7-10.2,1.7-15.1,1.9s-11.7-0.3-13.6-0.5s-7.6-1.9-7.6-2.4s3.4-0.5,12.4-0.5
	s14.5-0.3,18-0.6S243.6,625.4,244.3,626L244.3,626L244.3,626z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M239.4,618.8c0,0,1.6,0.5,1.6,1.6s-1.7,1-3,1h-22.5c-1.2,0-1.3-0.7-1.3-1.3s1.8-0.7,3-0.9
	C218.4,619.1,237.6,618.8,239.4,618.8L239.4,618.8L239.4,618.8z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M178.5,565.3c-9.4,0-31.2,3-31.2,3v26.8c0,0,1.3-0.7,2.4-1.9c1.1-1.2,3.3-0.8,3.6-1.6s-1.1-1.2-1.1-1.2
	s0.4-11.5,0.2-14c-0.1-1.5-1.1-2.6-1.9-3.2c0.7,0.3,1.8,0.6,3.7,0.7c3.8,0.1,24.2,0,24.2,0s20.4,0.1,24.2,0c1.9,0,3-0.4,3.7-0.7
	c-0.8,0.6-1.8,1.7-1.9,3.2c-0.2,2.5,0.2,14,0.2,14s-1.4,0.4-1.1,1.2c0.3,0.7,2.6,0.3,3.6,1.6c1.1,1.2,2.4,1.9,2.4,1.9v-26.8
	C209.8,568.3,188,565.3,178.5,565.3L178.5,565.3L178.5,565.3z M180.6,571.6c0,0.8-0.6,1.4-1.4,1.4h-1.2c-0.8,0-1.4-0.6-1.4-1.4v-3.8
	c0-0.8,0.6-1.4,1.4-1.4h1.2c0.8,0,1.4,0.6,1.4,1.4V571.6z"
        style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M296.8,33L296.8,33c-0.5-3.2-3.4-13.6-4.8-17.2s-2.4-8.4-2.4-8.4s-34.9-3.1-39.4-2.8c-4.5,0.3-7.6,0.2-8.2,0.7
	c-0.6,0.6-3.9,5-4.8,8.6c-0.9,3.6-3,10.4-3.7,11.1c-0.6,0.7-2.1,0.4-3.1,0.4s-4.8-0.3-6.8-0.3s-2-0.3-2-0.3s5-0.2,7.2-0.4
	s4.3-1.2,4.4-1.9s-1.4-8.5-1.6-11.9c-0.2-3.4-0.3-10.5-0.3-10.5H125.8c0,0-0.1,7.1-0.3,10.5s-1.8,11.3-1.7,11.9
	c0.1,0.6,2.2,1.7,4.4,1.9s7.2,0.4,7.2,0.4s-0.1,0.3-2,0.3s-5.7,0.3-6.8,0.3s-2.5,0.3-3.1-0.4c-0.6-0.7-2.8-7.5-3.7-11.1
	s-4.2-8.1-4.8-8.6c-0.6-0.6-3.7-0.4-8.2-0.7S67.5,7.4,67.5,7.4s-0.9,4.8-2.4,8.4S60.8,29.9,60.3,33c-0.6,3.2-4.8,7.9-4.3,8.2
	c0.5,0.3,8.1,1.7,11.7,1.9c3.5,0.2,14.3,0.1,22.9-0.9s17.5-2.7,25.5-4.3s19-1.8,20.3-1.9c1.3-0.1,1.7,0.3,2.3,1.2s1.1,0.2,1.6-0.5
	c0.5-0.7,4.2-2.4,5.5-2.7s21.1,0,32.8,0s31.5-0.4,32.8,0s5,2,5.5,2.7c0.5,0.7,1,1.4,1.6,0.5c0.6-0.9,1-1.2,2.3-1.2
	c1.3,0.1,12.3,0.3,20.3,1.9s16.8,3.3,25.5,4.3c8.7,1,19.4,1.1,22.9,0.9s11.2-1.5,11.7-1.9S297.4,36.2,296.8,33L296.8,33z
	 M229.8,19.8c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S229.2,19.8,229.8,19.8z M127.3,21.6c-0.5,0-0.9-0.4-0.9-0.9
	s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S127.8,21.6,127.3,21.6z M100.4,28.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
	S100.9,28.7,100.4,28.7z M142,14.2c0,0.5-0.4,0.9-0.9,0.9h-2.4c-0.5,0-0.9-0.4-0.9-0.9v-3.5c0-0.5,0.4-0.9,0.9-0.9h2.4
	c0.5,0,0.9,0.4,0.9,0.9V14.2z M158.1,22.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S158.6,22.5,158.1,22.5z
	 M199,22.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S199.5,22.5,199,22.5z M256.7,28.7c-0.5,0-0.9-0.4-0.9-0.9
	s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S257.2,28.7,256.7,28.7z"
        style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
      />
      <path
        className="st2"
        d="M178.5,509.5h30.9l0.1,1.4c0.1,1.4,2,2.6,3,2.1c1-0.5,21.5-13.1,22.8-14.1s4-32.5,5.3-55.1
	c1.4-22.6,2.6-44.1,3-44.3c1.2,0,1-1.1,1.2-2.5c0.2-1.4-0.6-2.5-2.1-4s-4.5-4-4.5-4s-3.6,3.3-8.6,5.3c-5,2-21,9.4-51.3,9.4
	s-46.3-7.4-51.3-9.4c-5-2-8.6-5.3-8.6-5.3s-3,2.5-4.5,4s-2.4,2.6-2.1,4c0.2,1.4,0,2.5,1.2,2.5c0.3,0.2,1.6,21.7,3,44.3
	c1.4,22.6,4,54.1,5.3,55.1s21.8,13.6,22.8,14.1c1,0.5,2.9-0.7,3-2.1l0.1-1.4H178.5z M178.5,506c-2.7,0-3.2-3.5-3.4-4.9
	c-0.2-1.3,1.2-2.3,3.4-2.3s3.6,1,3.4,2.3C181.8,502.4,181.3,506,178.5,506z"
        style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
      />
    </svg>
  );
}

export default Sedan;
