/* eslint-disable import/prefer-default-export */
export const TOAST_TYPE = Object.freeze({
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARN: 'WARN',
  INFO: 'INFO'
});

export const firebaseConfig = {
  apiKey: 'AIzaSyA_OFns61xx7gN1MMtVXf6gSD-xJqhF_lA',
  authDomain: 'plofix-3af7f.firebaseapp.com',
  projectId: 'plofix-3af7f',
  storageBucket: 'plofix-3af7f.appspot.com',
  messagingSenderId: '892251072131',
  appId: '1:892251072131:web:5499a5d526dd931b691db6',
  measurementId: 'G-M28GH9BBBT'
};
