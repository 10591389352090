/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { getBalance } from '../../../../../api/transaction';
import { createSponsor } from '../../../../../api/sponsors';
import sponsorModalStyles from './SponsorADItem.module.scss';
import { toFixedNumber } from '../../../../../utils/function';

function ProfitSponsorModal({
  addSponsorModalState,
  setProfitSponsorIsOpened,
  onSuccess
}) {
  const [profitBalance, setProfitBalance] = useState(0);
  const [amount, setAmount] = useState(addSponsorModalState.option);
  const sponsorStartDate = new Date();
  const sponsorEndDate = new Date();
  sponsorEndDate.setDate(sponsorStartDate.getDate() + 15);

  useEffect(() => {
    const getBalanceData = async () => {
      const balanceData = await getBalance('profit');

      if (balanceData === null) {
        setProfitBalance(0);
        return;
      }

      setProfitBalance(balanceData);
    };

    getBalanceData();
  }, []);

  const handlePaymentClick = async () => {
    const paymentParams = {
      price: amount,
      start: sponsorStartDate,
      end: sponsorEndDate,
      patternId: addSponsorModalState.patternId,
      type: 'profit'
    };

    const paymentResponse = await createSponsor(paymentParams);

    if (paymentResponse.errorStatus) {
      alert('Something went wrong buying sponsor.');
      setProfitSponsorIsOpened(false);
      return;
    }

    alert('Sponsor added successfully.');
    setProfitSponsorIsOpened(false);
    onSuccess();
  };

  return (
    <div className={sponsorModalStyles.sponsor_modal}>
      <div
        className={sponsorModalStyles.modal_container}
        style={{ background: '#f6f7f9' }}>
        <div className={sponsorModalStyles.modal_header}>
          <h2>pay with earnings</h2>
        </div>
        <div
          className={sponsorModalStyles.modal_content}
          style={{ paddingTop: '40px' }}>
          <h3 className={sponsorModalStyles.sponsor_balance}>
            Current balance: {profitBalance || 0} $
          </h3>
          <h4>Amount</h4>
          <label className={sponsorModalStyles.modal_label} htmlFor="amount">
            <input
              name="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(+e.target.value)}
            />{' '}
            <span>$</span>
          </label>
          {profitBalance >= 0 && profitBalance - amount >= 0 ? (
            <p className={sponsorModalStyles.modal_remaining}>
              remaining balance: {toFixedNumber(profitBalance - amount)} $
            </p>
          ) : (
            <p
              className={sponsorModalStyles.modal_remaining}
              style={{ color: 'red' }}>
              Your balance is not enough
            </p>
          )}
        </div>
        <hr />
        <div
          className={sponsorModalStyles.modal_content}
          style={{ paddingBottom: '20px' }}>
          <div className={sponsorModalStyles.modal_btns}>
            <button
              className={sponsorModalStyles.btn_cancel}
              type="button"
              onClick={() => setProfitSponsorIsOpened(false)}>
              cancel
            </button>
            <button
              onClick={() => handlePaymentClick()}
              disabled={
                amount !== addSponsorModalState.option ||
                Number(amount) > profitBalance
              }
              className={sponsorModalStyles.btn_profit}
              type="button">
              payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfitSponsorModal;
