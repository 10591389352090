import { useState, useEffect } from 'react';

import { getPatternRating } from '../../../api/patterns';

import Review from './Review';

import reviewsStyles from './PatternReviews.module.scss';

function PatternReviews({ patternId }) {
  const [reviews, setReviews] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [maxReviews, setMaxReviews] = useState(10);

  const fetchReviews = async () => {
    const reviewsData = await getPatternRating(patternId);

    setReviews(reviewsData.data);
    setTotalCount(reviewsData.count);
  };

  const handleLoadMoreClick = () => {
    setMaxReviews(maxReviews + 10);
  };

  useEffect(() => {
    fetchReviews();
  }, [patternId]);

  return (
    <div className={reviewsStyles.reviews}>
      {reviews?.length ? (
        <>
          <h2 className={reviewsStyles.reviews_title}>
            Reviews ({totalCount})
          </h2>
          <div className={reviewsStyles.reviews_container}>
            {reviews
              .map((review) => (
                <div key={review.id} className={reviewsStyles.review_holder}>
                  <Review review={review} />
                </div>
              ))
              .slice(0, maxReviews)}
          </div>

          {reviews.length > 10 && maxReviews < reviews.length && (
            <button
              type="button"
              className={reviewsStyles.load_more_btn}
              onClick={handleLoadMoreClick}>
              load more
            </button>
          )}
        </>
      ) : (
        <div> </div>
      )}
    </div>
  );
}

export default PatternReviews;
