/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
import { useState } from 'react';
import notificationStyles from './Notification.module.scss';
import arrow from '../../../img/dropdown_arrow.svg';
import { renderDate } from '../../../utils/function';
import { setSeenNotification } from '../../../api/notifications';

function Notification({ notification }) {
  const [descrIsOpen, setDescrIsOpen] = useState(false);
  const [isSeen, setIsSeen] = useState(() => notification?.status !== 'unread');

  const handleOpenDescription = async () => {
    setDescrIsOpen(!descrIsOpen);

    const setSeenNotificationRes = await setSeenNotification(notification.id);

    if (setSeenNotificationRes?.errorStatus) {
      alert(
        'Something went wrong checking this notification. Try again later.'
      );

      throw new Error(setSeenNotificationRes.message);
    }

    setIsSeen(true);
  };

  return (
    <div className={notificationStyles.notification_container}>
      <div
        onClick={handleOpenDescription}
        className={notificationStyles.notification_upper}
        style={
          descrIsOpen
            ? {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                borderBottom: 'none'
              }
            : { borderRadius: '10px' }
        }>
        <div className={notificationStyles.notification_title}>
          <div className={notificationStyles.notification_details}>
            <span>{renderDate(notification?.createdAt)}</span>
            <span className={notificationStyles.notification_details_status}>
              {isSeen ? '' : 'New'}
            </span>
          </div>
          <h3>PLOFIX</h3>
        </div>
        <p className={notificationStyles.upper_descr}>{notification?.title}</p>
        <button type="button">
          <img
            src={arrow}
            alt="arrow"
            style={descrIsOpen ? { transform: 'rotate(180deg)' } : {}}
          />
        </button>
      </div>
      {descrIsOpen && (
        <div className={notificationStyles.notification_lower}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${notification?.content}`
            }}
          />
          {notification?.file !== null && notification?.file.length > 0 ? (
            <a href={notification.file} target="_blank" rel="noreferrer">
              Attached files
            </a>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Notification;
