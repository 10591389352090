/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';

import { getPopUpList } from '../../api/adminPopUp';

import SelectorFilter from '../SelectorFilter/SelectorFilter';

import heroStyles from '../Hero/Hero.module.scss';
import patternHeroStyles from './PatternPageHero.module.scss';
import { formatLink, openInNewTab } from '../../utils/function';

function PatternPageHero({ setSelectedCategory }) {
  const [patternPageBanner, setPatternPageBanner] = useState('');

  const fetchBanners = async () => {
    const getBannersRes = await getPopUpList(
      '패턴페이지, Top Banner',
      'banner'
    );

    setPatternPageBanner(getBannersRes[0]);
  };

  const handleNavigateLink = () => {
    // if (heroBanner?.linkTarget) {
    //   window.open(
    //     `${heroBanner?.linkTarget}`,
    //     '_blank',
    //     'rel=noopener noreferrer'
    //   );
    // }
    if (patternPageBanner?.linkTarget) {
      // window.open(formatLink(patternPageBanner?.linkTarget), '_blank');
      openInNewTab(patternPageBanner?.linkTarget);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <section
      onClick={handleNavigateLink}
      className={patternHeroStyles.pattern_hero}
      style={
        patternPageBanner
          ? {
              backgroundImage: `url(${patternPageBanner?.file})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize:
                patternPageBanner?.option?.width &&
                patternPageBanner?.option?.height
                  ? `${patternPageBanner?.option?.width}px ${patternPageBanner?.option?.height}px`
                  : 'cover',
              backgroundPosition: 'center'
            }
          : {}
      }>
      <div className={heroStyles.container}>
        <h1 className={patternHeroStyles.title}>Pattern</h1>
        <SelectorFilter
          setSelectedCategory={setSelectedCategory}
          isPatternPage
        />
      </div>
    </section>
  );
}

export default PatternPageHero;
