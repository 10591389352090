import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import selectorFilterStyles from './SelectorFilter.module.scss';

function Selector({
  selectedValue,
  setSelectedFilterOptions,
  category,
  options,
  disabled,
  isMainPage
}) {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);

  const setSelectorInlineStyles = (optionsArray) => {
    const singleOptionHeight = 18;
    const padding = 20;

    if (optionsArray.length > 4) {
      return { height: '172px' };
    }

    return {
      height: `${
        optionsArray.length * singleOptionHeight +
        (optionsArray.length + 1) * padding
      }px`,
      overflow: 'hidden'
    };
  };

  const handleSelectButtonClick = (e) => {
    e.stopPropagation();

    if (isMainPage) {
      navigate('/pattern/list');
      return;
    }

    setIsOpened(!isOpened);
  };

  const handleChange = (e) => {
    const currentValue = JSON.parse(e.target.value);

    setSelectedFilterOptions((current) => ({
      ...current,
      [category]: {
        ...currentValue,
        changed: !!current[category]?.value
      },
      ...(category === 'location' && {
        year: null,
        make: null,
        model: null,
        'sub-model': null,
        series: null
      }),
      ...(category === 'year' && {
        make: null,
        model: null,
        'sub-model': null,
        series: null
      }),
      ...(category === 'make' && {
        model: null,
        'sub-model': null,
        series: null
      }),
      ...(category === 'model' && {
        'sub-model': null,
        series: null
      }),
      ...(category === 'sub-model' && {
        series: null
      })
    }));
  };

  return (
    <label htmlFor={category}>
      <button
        type="button"
        onClick={(e) => handleSelectButtonClick(e)}
        disabled={disabled}>
        <h4>{category}</h4>
        {selectedValue && selectedValue.value ? (
          <p className={selectorFilterStyles.sublabel_selected}>
            {selectedValue.value}
          </p>
        ) : (
          <p className={selectorFilterStyles.sublabel}>
            {`Select ${category}`}
          </p>
        )}
        {isOpened && options.length > 0 && (
          <select
            multiple
            name={category}
            onChange={handleChange}
            style={setSelectorInlineStyles(options)}>
            {options
              .sort((optionA, optionB) => {
                if (category === 'location') {
                  return optionA.order - optionB.order;
                }
                const optionValueA = optionA.value.toUpperCase();
                const optionValueB = optionB.value.toUpperCase();
                if (optionValueA < optionValueB) {
                  return -1;
                }
                if (optionValueA > optionValueB) {
                  return 1;
                }

                return 0;
              })
              .map((option) => (
                <option key={option.id} value={JSON.stringify(option)}>
                  {option.value}
                </option>
              ))}
          </select>
        )}
      </button>
    </label>
  );
}

export default Selector;
