import React, { useState } from 'react';
import imgDown from '../../../img/icon_down.png';

function SelectDropdown({
  selectedValue,
  setSelectedFilterOptions,
  category,
  options,
  disabled
}) {
  const [isOpened, setIsOpened] = useState(false);

  const handleChange = (e) => {
    const currentValue = JSON.parse(e.target.value);

    setSelectedFilterOptions((current) => ({
      ...current,
      [category]: currentValue
    }));

    setIsOpened(false);
  };

  return (
    <label htmlFor="option">
      <button
        onClick={() => setIsOpened(!isOpened)}
        type="button"
        disabled={disabled}>
        {/* <p>{category}</p> */}
        {selectedValue && selectedValue.value ? (
          <p>{selectedValue.value}</p>
        ) : (
          <p>{`Select ${category}`}</p>
        )}
        <img
          src={imgDown}
          alt="arrow"
          style={
            isOpened
              ? { transform: 'rotate(180deg)' }
              : { transform: 'rotate(0deg)' }
          }
        />
      </button>
      {isOpened && (
        <select
          style={{
            height: `${40 + 17 * options.length || 1}px`
          }}
          onChange={handleChange}
          name="option"
          multiple>
          {options.map((option) => (
            <option key={option.id} value={JSON.stringify(option)}>
              {option.value}
            </option>
          ))}
        </select>
      )}
    </label>
  );
}

export default SelectDropdown;
