/* eslint-disable consistent-return */
// import { useState, useEffect } from 'react';
import { Rating } from 'react-simple-star-rating';

import { renderDate, renderNickname } from '../../../utils/function';

import PatternCardUserRank from '../../PatternCard/PatternCardUserRank';

import reviewStyles from './PatternReviews.module.scss';

import profileImg from '../../../img/icon_account.svg';

function Review({ review }) {
  return (
    <div>
      <div className={reviewStyles.review_upper}>
        <div className={reviewStyles.review_profile}>
          <img src={review?.user?.avatar || profileImg} alt="pfp" />
          <span className="review_username">
            by{' '}
            {review?.user?.nickName
              ? renderNickname(review?.user?.nickName, 14)
              : ''}
          </span>
        </div>
        <PatternCardUserRank
          className={reviewStyles.user_rank}
          rank={review?.user?.level}
        />
        <div className={reviewStyles.review_info}>
          <span className={reviewStyles.review_date}>
            {review?.updatedAt
              ? renderDate(review?.updatedAt)
              : renderDate(review?.createdAt)}
          </span>
          <div className={reviewStyles.review_rating}>
            <Rating
              ratingValue={review?.star ? +review.star * 20 : 0}
              size={20}
              readonly
              emptyColor="#ededed"
              fillColor="#ffd500"
            />
          </div>
        </div>
      </div>
      <p className={reviewStyles.review_msg}>{review?.review}</p>
    </div>
  );
}

export default Review;
