/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import {
  getMyProjectPatterns,
  deleteMyProjectPattern,
  deleteMyProjectById
} from '../../../api/patterns';
import { formatDateUTC, renderDate } from '../../../utils/function';

import Pagination from '../../Pagination/Pagination';
import MyPageTitle from '../MyPageTitle/MyPageTitle';
import PatternCard from '../../PatternCard/PatternCard';
import PatternCollection from '../../PatternCard/PatternCollection/PatternCollection';
import MyProjectDeleteModal from './MyProjectConfirmDeleteModal/MyProjectConfirmDeleteModal';

import myProjectStyles from '../MyPage.module.scss';

function MyProjectMain() {
  const projectsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
  const [deletePatternParams, setDeletePatternParams] = useState({});

  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });

  const [timeRecall, setTimeRecall] = useState(0);

  const [myProjectData, setMyProjectData] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);

  const fetchData = async ({
    from = '',
    to = '',
    page = 1,
    limit = projectsOnPage
  }) => {
    const myProjectPatternsRes = await getMyProjectPatterns({
      from,
      to,
      page,
      limit
    });

    setMyProjectData(myProjectPatternsRes.data);
    setTotalProjects(myProjectPatternsRes.count);
  };

  const handleDeleteClick = (projectId, patternId) => {
    setDeletePatternParams({ projectId });
    setDeleteConfirmIsOpen(true);
  };

  const handleDeleteConfirm = async () => {
    await deleteMyProjectById(deletePatternParams);

    setDeletePatternParams({});
    setDeleteConfirmIsOpen(false);

    await fetchData({
      from: filterDates.from,
      to: filterDates.to,
      page: currentPage
    });
  };

  const hanldeDeleteCancel = () => {
    setDeletePatternParams({});
    setDeleteConfirmIsOpen(false);
  };

  useEffect(() => {
    fetchData({
      from: filterDates.from,
      to: filterDates.to,
      page: currentPage
    });
  }, [timeRecall, currentPage, filterDates]);

  useEffect(() => {
    const myProjectFilterDates = localStorage.getItem('myProjectFilterDates');

    if (myProjectFilterDates && myProjectFilterDates.length > 0) {
      const parsedDates = JSON.parse(myProjectFilterDates);

      setFilterDates({
        from: formatDateUTC(parsedDates.from, 'from'),
        to: formatDateUTC(parsedDates.to, 'to')
      });

      localStorage.removeItem('myProjectFilterDates');
    }
  }, []);

  return (
    <main className={myProjectStyles.main}>
      <div className={myProjectStyles.content}>
        <MyPageTitle
          title="my project"
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
          isRequiredReset
        />
        <hr className={myProjectStyles.upper_hr} />
        <div className={myProjectStyles.cards_container}>
          {myProjectData.length
            ? myProjectData.map((project) => (
                <div key={project.id}>
                  {project?.projectPatterns.length > 1 ? (
                    <PatternCollection
                      project={project}
                      setTimeRecall={setTimeRecall}
                      onDelete={handleDeleteClick}
                    />
                  ) : (
                    <PatternCard
                      card={project?.projectPatterns[0]?.pattern}
                      projectId={project?.id}
                      patternId={project?.projectPatterns[0]?.patternId}
                      myProjectPage
                      setTimeRecall={setTimeRecall}
                      onDelete={handleDeleteClick}
                    />
                  )}
                  <h4
                    style={{
                      maxWidth: '380px',
                      lineClamp: 1,
                      WebkitLineClamp: 1
                    }}>
                    {project?.name}
                  </h4>
                  <p>Saved date: {renderDate(project?.updatedAt)}</p>
                </div>
              ))
            : null}
        </div>
        {totalProjects > projectsOnPage && (
          <Pagination
            currentPage={currentPage}
            totalCount={totalProjects}
            pageSize={projectsOnPage}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
      {deleteConfirmIsOpen && (
        <MyProjectDeleteModal
          onCancel={hanldeDeleteCancel}
          onDelete={handleDeleteConfirm}
        />
      )}
    </main>
  );
}

export default MyProjectMain;
