/* eslint-disable import/prefer-default-export */
import {
  GET_CART,
  INCREASE_CART,
  DECREASE_CART,
  UPDATE_QUANTITY
} from '../constants/cart';

export const getCartItems = (payload) => ({
  type: GET_CART,
  payload
});

export const incCartItems = (payload) => ({
  type: INCREASE_CART,
  payload
});

export const decCartItems = (payload) => ({
  type: DECREASE_CART,
  payload
});

export const updateQuantity = (payload) => ({
  type: UPDATE_QUANTITY,
  payload
});
