// import { useEffect } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import termsAndPolicyStyles from './TermsAndPolicy.module.scss';

function TermsAndPolicy() {
  return (
    <section className={termsAndPolicyStyles.terms_and_policy_section}>
      <div className={termsAndPolicyStyles.terms_and_policy_container}>
        <h1 className={termsAndPolicyStyles.terms_and_policy_title}>
          Privacy Policy & Terms and Conditions
        </h1>
        <nav className={termsAndPolicyStyles.nav}>
          <NavLink
            className={termsAndPolicyStyles.nav_item1}
            to="/terms_and_policy/policy"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Privacy Policy
          </NavLink>
          <NavLink
            className={termsAndPolicyStyles.nav_item2}
            to="/terms_and_policy/terms"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Terms and Conditions
          </NavLink>
        </nav>
        <Outlet />
      </div>
    </section>
  );
}

export default TermsAndPolicy;
