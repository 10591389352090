/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect, useMemo } from 'react';
import { getCreditPacks } from '../../../api/creditPacks';
import CreditPack from '../../CreditPacks/CreditPack';
import pricingCreditStyles from '../Pricing.module.scss';
import pricingCreditScopedStyles from './PricingCreditPacks.module.scss';
import PackContext from './Context/Context';
import PaypalPricingCreditPacks from '../../Paypal/PaypalPricingCreditPacks/PaypalPricingCreditPacks';

import { LoginStateContext } from '../../../store/Context';

const CREDIT_PRODUCT_CATEGORY = {
  STANDARD: 'STANDARD',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
  GOLD: 'Gold'
};

const orderCredit = [
  CREDIT_PRODUCT_CATEGORY.GOLD,
  CREDIT_PRODUCT_CATEGORY.PREMIUM,
  CREDIT_PRODUCT_CATEGORY.PLUS,
  CREDIT_PRODUCT_CATEGORY.STANDARD
];

const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
};

function PricingCreditPacks() {
  const navigate = useNavigate();
  const loginState = useContext(LoginStateContext);

  const [packs, setPacks] = useState([]);
  const [total, setTotal] = useState(0);
  const [state, dispatch] = useContext(PackContext);
  const [options, setOptions] = useState([]);

  const fetchCredit = async () => {
    const crPacks = await getCreditPacks();

    const packsData = mapOrder(crPacks, orderCredit, 'name');

    setPacks(packsData);
  };

  useEffect(() => {
    fetchCredit();
  }, []);

  useEffect(() => {
    const sumCost = state.reduce((result, item) => result + item.cost, 0);
    const items = state.map((item) => ({
      total: +item.cost,
      productId: item.id
    }));
    setTotal(sumCost);
    setOptions(items);
  }, [state]);

  const handleByWithCardClick = () => {
    if (!loginState?.isLoggedIn) {
      navigate('/auth');
      return;
    }

    if (!state.length) {
      alert('Please select one package');
      return;
    }

    alert('Payment is in development!');
    // TODO: Card Payment
  };

  const option = useMemo(
    () => ({
      total,
      productId: options.map((item) => item.productId)
    }),
    [options, total]
  );

  return (
    <div className={pricingCreditStyles.content}>
      <h2 className={pricingCreditStyles.content_title}>CREDIT PACKAGES</h2>
      <div className={pricingCreditScopedStyles.packs_container}>
        {packs
          .map((pack) => <CreditPack key={pack.id} pack={pack} />)
          .reverse()}
      </div>
      <p className={pricingCreditScopedStyles.descr}>
        Please select the desired credit package.
      </p>
      <p className={pricingCreditScopedStyles.total}>Total : ${total || 0}</p>
      <div className={pricingCreditScopedStyles.payment_controls}>
        <PaypalPricingCreditPacks option={option} />
        <button
          type="button"
          onClick={handleByWithCardClick}
          className={pricingCreditScopedStyles.payment_credit}>
          CARD PAYMENT
        </button>
      </div>
    </div>
  );
}

export default PricingCreditPacks;
