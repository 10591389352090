/* eslint-disable no-alert */
// import { useState } from 'react';
import modalStyles from './CreditRefundReasonModal.module.scss';

function CreditRefundReasonModal({ onRefund, onClose, reason, setReason }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>Credits refund</h1>
        <div className={modalStyles.modal_body}>
          <p>Please state the reason for refund</p>
          <textarea
            type="text"
            placeholder="Enter..."
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={() => onClose()}
              className={modalStyles.btn_close}
              type="button">
              Back
            </button>
            <button
              onClick={() => onRefund()}
              className={modalStyles.btn_confirm}
              type="button">
              sent
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditRefundReasonModal;
