import { SET_CREDIT_BALANCE, RECALL_GET_BALANCE } from '../constants/credit';
// eslint-disable-next-line import/prefer-default-export
export const setCreditBalance = (payload) => ({
  type: SET_CREDIT_BALANCE,
  payload
});

export const recallGetBalance = () => ({
  type: RECALL_GET_BALANCE
});
