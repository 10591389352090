const faq = {
  General: {
    'Manage Your Account': [
      {
        q: 'Can I log in to my account on multiple devices simultaneously?',
        a: [
          {
            text: 'Yes, logging in to your account on multiple devices simultaneously is possible.'
          }
        ]
      },
      {
        q: 'How do I update my account information?',
        a: [
          {
            text: 'You can edit account information on MY ACCOUNT MANAGEMENT by clicking your account name.'
          }
        ]
      },
      {
        q: 'I forgot my password. How do I find it?',
        a: [
          {
            text: "If you signed up with your PLOFIX account, you can click ‘Forgot Password?' on the login page. You will receive a temporary password to your email. Please use the verification number to reset your password."
          },
          {
            text: ' If you registered with your Google or Facebook account, go to the Google or Facebook website to find your password.'
          }
        ]
      },
      {
        q: 'How do I delete my PLOFIX account?',
        a: [
          {
            text: 'You can delete your account on MY ACCOUNT MANAGEMENT by clicking your account name. Please note that you cannot re-register using the same deleted email address.'
          }
        ]
      }
    ],
    'Refund Policy': [
      {
        q: 'What is the refund policy?',
        a: [
          {
            text: 'You can click the',
            link: {
              text: 'Terms and Conditions',
              url: 'https://plofix.com/terms_and_policy/terms'
            }
          },
          {
            text: 'Credit refund is available only one time and within the first 7 days after the payment is made.'
          },
          {
            text: 'The Monthly Plan fee is nonrefundable.'
          }
        ]
      }
    ],
    'Payment & Billing': [
      {
        q: 'Which payment options are available to recharge Credit or purchase the EDIT & PLOT subscription plan?',
        a: [
          {
            text: 'Users can pay via PayPal or debit/credit card. '
          }
        ]
      },
      {
        q: 'How do I get the receipt?',
        a: [
          {
            text: 'You can check your PayPal payment history for the receipt.'
          },
          {
            text: ' You can check your payment history on the EDIT & PLOT SUBSCRIPTION and CREDIT HISTORY under your account name.'
          }
        ]
      }
    ],
    'Other Issues': [
      {
        q: 'Why can’t I log in?',
        a: [
          {
            text: 'If you have entered your login details correctly and still can’t log in, your internet browser may not be set to accept cookies. You can log in only if your browser is set to accept cookies.'
          },
          {
            text: 'To ensure you get the best browsing performance on PLOFIX, we recommend downloading the latest version of your favorite browser.'
          },
          {
            text: 'If you still can’t log in, please get in touch with us at',
            link: {
              text: 'admin@plofix.com',
              url: 'to:admin@plofix.com'
            }
          }
        ]
      },
      {
        q: 'Troubleshooting Downloading and Internet Connection Issues?',
        a: [
          {
            text: 'Check your Internet Connection and Bandwidth.'
          },
          {
            text: 'If you use a wireless connection, move closer to your router or switch to a wired connection. Ensure that other services. (ex. Video and audio streaming or calling, online gaming, or file sharing) are not running on your network at the same time. These services may impact the speed of your connection with PLOFIX. You may also contact your internet service provider for assistance.'
          },
          {
            text: 'Check for Blocked Access.'
          },
          {
            text: 'If access is blocked, it could be a proxy setting, a firewall, or your internet service provider. If you are having a problem accessing our website or other domains, please contact your IT help and your ISP.'
          }
        ]
      },
      {
        q: 'Which browsers do Plofix.com and EDIT & PLOT support?',
        a: [
          {
            text: 'Plofix.com and EDIT & PLOT support commonly used browsers like Microsoft Edge, Google Chrome, Mozilla Firefox, and Safari.'
          }
        ]
      },
      {
        q: 'EDIT & PLOT is not synchronized with my plotter. Is there a way to solve this?',
        a: [
          {
            text: 'Please contact your plotting device company.'
          }
        ]
      }
    ]
  },
  Seller: {
    Seller: [
      {
        q: 'What are the required qualifications for becoming a seller?',
        a: [
          {
            text: 'Sellers who provide a pattern file without technical errors can qualify to become a seller.'
          }
        ]
      },
      {
        q: 'What number of patterns can I simultaneously upload for the same vehicle?',
        a: [
          {
            text: 'You can upload a maximum of 5 patterns for the same vehicle at a time.'
          }
        ]
      },
      {
        q: 'How should I price my pattern?',
        a: [
          {
            text: 'You can set your price per square meter. You can choose a set price between 0.5, 1.0, 1.5, and 2.0 credits per square meter.'
          }
        ]
      },
      {
        q: 'Does the seller need to use EDIT & PLOT?',
        a: [
          {
            text: 'There is no need to use EDIT & PLOT to upload a pattern if you plan only to upload files. However, if you plan to edit and plot other seller’s patterns, you must subscribe for EDIT & PLOT.'
          }
        ]
      },
      {
        q: 'Can I upload a file and use EDIT & PLOT to edit it?',
        a: [
          {
            text: 'No, you have to upload the file in its original form and cannot use  EDIT & PLOT to edit it. '
          }
        ]
      },
      {
        q: 'Can uploaded files be edited or deleted?',
        a: [
          {
            text: 'Uploaded patterns cannot be edited. However, you can edit the unit price or file description or delete the file from the system.'
          }
        ]
      },
      {
        q: 'The car model I have a pattern for is not in the category. What can I do?',
        a: [
          {
            text: 'Register a new vehicle model on the PATTERN UPLOAD page under the SELL A PATTERN. We will verify the vehicle and add it to the list. You will be notified when the models are approved, and the list is updated. You can upload the new pattern afterward.'
          }
        ]
      },
      {
        q: 'What is pattern screening?',
        a: [
          {
            text: 'All new patterns must go through a screening process. On average, it takes 3-5 days to screen and approve a pattern. Sometimes the process may take longer based on the circumstances.'
          }
        ]
      },
      {
        q: 'I uploaded the pattern. Why isn’t it showing in the category section?',
        a: [
          {
            text: 'Your pattern is under the screening process. Please wait until the process is done.'
          },
          {
            text: 'You can check the pattern status on the Pattern Approval Status page under the SELL A PATTERN.'
          }
        ]
      },
      {
        q: 'My pattern is in the search field, but why is no one plotting it?',
        a: [
          {
            text: 'The pattern is probably not showing on the top of the search categories. Sellers can register patterns for Sponsor AD to give it better exposure.'
          },
          {
            text: 'You can lower your unit price. One way of increasing your pattern visibility is by putting a competitive price on it.'
          }
        ]
      }
    ],
    Profit: [
      {
        q: 'How do I make a profit from PLOFIX?',
        a: [
          {
            text: 'Register and upload the pattern that you own.'
          },
          { text: 'Downloaders will use your pattern for plotting.' },
          {
            text: 'You will receive royalties every time someone plots your pattern. The royalties can total 30-45% of the original pattern price.'
          }
        ]
      },
      {
        q: 'Where can I see my profit?',
        a: [{ text: 'Go to the SELL A PATTERN to check your profit.' }]
      },
      {
        q: 'How do I withdraw my Profit?',
        a: [
          {
            text: 'Sellers can withdraw their profit by going to the Profit Withdrawal Request under the SELL A PATTERN. Payment can be processed if the total accumulated profit exceeds 30 USD. The amount shall be in your PayPal account in a few days.'
          },
          {
            text: 'Sellers must have a PayPal account to be able to withdraw their profit.'
          }
        ]
      },
      {
        q: 'What happens to my profit when I delete my account?',
        a: [
          {
            text: 'Your profit will not be used or withdrawn after deleting your account. You must withdraw the remaining profit in advance.'
          }
        ]
      }
    ],
    'Sponsor AD': [
      {
        q: 'What is Sponsor AD on PLOFIX?',
        a: [
          {
            text: 'If  you  purchase  the  Sponsor  AD  for  your  patterns,  your  patterns  will  show  up  at  the  top  of  the  search category. Downloaders can see the sponsored patterns more often when they search for their desired vehicle.'
          },
          {
            text: 'If more than six sponsored patterns are registered in the system, patterns will appear on the search page in a randomized order.'
          }
        ]
      },
      {
        q: 'How can I register a Sponsor AD for my patterns?',
        a: [
          { text: 'I. Go to Sponsor AD Item on the SELL A PATTERN.' },
          {
            text: 'II. Select the registered pattern you want to display on the main page.'
          },
          {
            text: 'III. Purchase Sponsor AD, which will automatically apply to the patterns.'
          },
          {
            text: 'IV. You can also buy Sponsor AD using your Profit, PayPal or Credit Card.'
          }
        ]
      }
    ]
  },
  Downloader: {
    Downloader: [
      {
        q: 'How can I find patterns?',
        a: [
          {
            text: 'Search your desired vehicle using the search field at the top of the main page.'
          }
        ]
      },
      {
        q: 'Can I save the edited pattern on my computer?',
        a: [
          {
            text: 'No, patterns cannot be saved on the local drive. They can only be saved and held on the PLOFIX cloud drive.'
          }
        ]
      },
      {
        q: 'What is the difference between My Project and Bookmark?',
        a: [
          {
            text: 'You can keep the patterns saved in EDIT & PLOT under My Project. Multiple patterns can be saved under one project.'
          },
          {
            text: 'Bookmark is a list of the patterns you click the Bookmark button. You can go back and check on the bookmarked patterns anytime.'
          }
        ]
      },
      {
        q: 'Where do I find the pattern? I saved it under EDIT & PLOT.',
        a: [
          {
            text: 'You can find your saved pattern under My Project and load your bookmarked patterns in EDIT & PLOT. Please note that you can edit your patterns only in EDIT & PLOT.'
          }
        ]
      },
      {
        q: 'I edited patterns, but I don’t want to plot yet. Can I save the patterns and plot them later?',
        a: [
          {
            text: 'You can save edited patterns by clicking the Save button in the top right corner. Patterns will be saved in My Project. You can reload the saved pattern in EDIT & PLOT if you want to continue editing.'
          }
        ]
      },
      {
        q: 'What if I didn’t save the pattern that I plotted? Can I see what I plotted earlier?',
        a: [
          {
            text: 'Yes, you can check your plotted patterns in PLOTTING HISTORY under your account name. However, each pattern will remain in the PLOTTING HISTORY for a year only.'
          }
        ]
      },
      {
        q: 'How can I open multiple patterns in EDIT & PLOT?',
        a: [
          {
            text: 'If EDIT & PLOT is open, you can click the ’Open in Editor‘ on the desired pattern page. It will ask you to choose whether you want to work on the same canvas or open a new canvas for each pattern.'
          },
          {
            text: 'Suggestion:'
          },
          {
            list: [
              'Go to the desired pattern page and mark it as Bookmark. Save as many Bookmarks as you wish to edit together.',
              'Open the EDIT & PLOT and click Load in the top right corner.',
              'Select multiple patterns under the Bookmark category.',
              'Edit and Plot!'
            ]
          }
        ]
      },
      {
        q: 'Can I re-edit the saved patterns?',
        a: [
          {
            text: 'You can edit your saved files in EDIT & PLOT multiple times. You should save the edited file as a New Project.'
          }
        ]
      },
      {
        q: 'I no longer use EDIT & PLOT. Can I cancel the Subscription Plan?',
        a: [
          {
            text: 'You cannot cancel the Subscription Plan. It will automatically terminate when it expires. The planning fee is nonrefundable.'
          }
        ]
      },
      {
        q: 'What if I do not have a sufficient credit balance to start plotting in EDIT & PLOT?',
        a: [
          {
            text: 'If your Credit balance is insufficient to plot, the notification message will appear on the screen. You should reload your Credits before starting plotting.'
          }
        ]
      },
      {
        q: 'I want to leave a review of the pattern I plotted. Where can I do it?',
        a: [
          {
            text: 'The reviews can be written only by the downloaders who have plotted the pattern. Reviews can be right next to your plotted pattern on the PLOTTING HISTORY under your account name.'
          }
        ]
      },
      {
        q: 'Do I get any benefits if I leave a review for the pattern I plotted?',
        a: [
          {
            text: 'You will get a 0.5 Bonus Credit per review. You can check your bonus credit on the CREDIT HISTORY under your account name.'
          }
        ]
      }
    ],
    'EDIT & PLOT SUBSCRIPTION': [
      {
        q: 'How to purchase an EDIT & PLOT subscription?',
        a: [
          {
            text: 'Go to the PRICING page and choose your preferred plan. Once you choose and pay for the subscription plan, you can start using editing and plotting features. We offer 1-month, 3-month, 6-month, and 12-month subscription  plans  for  you  to  choose  from.  Once  the  plan  expires,  it  will  not  renew  automatically.  To continue with the subscription plan, you must purchase the desired plan again.'
          }
        ]
      },
      {
        q: 'Where can I see my subscription plan expiry date?',
        a: [
          {
            text: 'You can quickly check the plan expiration date on the EDIT & PLOT SUBSCRIPTION HISTORY under your account name, or on the EDIT & PLOT page.'
          }
        ]
      },
      {
        q: 'How do I cancel my EDIT & PLOT subscription plan?',
        a: [
          {
            text: 'The subscription plan will end when the validity period of the EDIT & PLOT subscription expires.'
          }
        ]
      }
    ],
    Credit: [
      {
        q: 'What is the difference between a Credit fee and an EDIT & PLOT fee?',
        a: [
          {
            text: 'Credits can be used as a currency. You can reload your Credits and use it for plotting a pattern, product purchase, etc. The EDIT & PLOT fee is a subscription fee for the possibility of using the EDIT & PLOT that allows you to edit patterns and connect the software with the plotter.'
          }
        ]
      },
      {
        q: 'How do I use my Credit to edit and plot patterns?',
        a: [
          { text: 'Credit is used for the plotting on the EDIT & PLOT.' },
          {
            text: 'Credit is also used for buying protection film at the ULTRAFIT SHOP.'
          }
        ]
      },
      {
        q: 'What happens if I don’t have enough Credit available to plot?',
        a: [
          {
            text: 'If your Credit is insufficient to proceed with plotting, the notification message will appear on the EDIT & PLOT page. You should reload your Credit before starting plotting.'
          }
        ]
      },
      {
        q: 'How do I get Bonus Credits?',
        a: [
          {
            text: 'You can earn bonus credit by purchasing ULTRAFIT PPF Films on ULTRAFIT SHOP. You will need to verify the product on the website.'
          },
          {
            text: 'After you plot a pattern, it will become available for review. Write a review to earn extra credits. (0.5 credits per review).'
          }
        ]
      },
      {
        q: 'How do I verify the product authentication code for Bonus Credit?',
        a: [
          {
            text: 'Go to BONUS Credit under your account name.'
          },
          {
            text: 'Enter  the  product  authentication  code  attached  to  the  ULTRAFIT  product  box.  It  will  be  automatically applied to your account. You can check your Credit on the CREDIT HISTORY under your account name.'
          }
        ]
      },
      {
        q: 'What happens to the Credit when I delete my account?',
        a: [
          {
            text: 'You will lose your Credit balance if you delete the account, and your Credit will not be refunded.'
          }
        ]
      },
      {
        q: 'How to get my Credit refunded?',
        a: [
          {
            text: 'Credit is refundable only to first-time users and within the first seven days. After seven days, credit becomes nonrefundable.'
          }
        ]
      }
    ],
    Plotting: [
      {
        q: 'What Plotters do you support for EDIT & PLOT?',
        a: [
          {
            text: 'GRAPHTEC, GCC and ROLAND.'
          }
        ]
      },
      {
        q: 'Do I have to pay if I do not plot?',
        a: [
          {
            text: 'No, if you don’t plot from the EDIT & PLOT page, your Credit will not be deducted.'
          }
        ]
      },
      {
        q: 'An error message pops up when I try to plot. What should I do?',
        a: [
          {
            text: 'Make  sure  you  have  tested  your  plotter  from  EDIT  &  PLOT.  If  your  plotter  does  not  work  with  other plotting programs, contact your device company.'
          },
          {
            text: 'Please note that we are not responsible for any errors in measurements. Please test the EDIT & PLOT function before using it on a pattern.'
          }
        ]
      },
      {
        q: 'What is AutoNesting?',
        a: [
          {
            text: 'AutoNesting is a rearranging system for minimizing excessive margin loss while plotting the film. It will automatically reposition the pattern for better optimization. Auto nesting is an optional feature and can be used when needed.'
          }
        ]
      },
      {
        q: 'The page shows the increased credit total required for plotting. Why did the amount change?',
        a: [
          {
            text: 'The EDIT & PLOT will calculate the final size of the edited pattern right before plotting. Therefore, the final price after editing may differ from the original price shown on the screen.'
          }
        ]
      }
    ]
  },
  'ULTRAFIT SHOP': {
    Account: [
      {
        q: 'Do I need to sign up for a PLOFIX to buy ULTRAFIT products?',
        a: [
          {
            text: 'You must sign in PLOFIX account or SNS account (Google and Facebook) and place an order in ULTRAFIT SHOP.'
          }
        ]
      }
    ],
    'Payment & Billing': [
      {
        q: 'Which payment options are available in ULTRAFIT SHOP?',
        a: [
          {
            text: 'You can pay with PayPal, debit/credit card, PLOFIX Credit, and Profit.'
          }
        ]
      },
      {
        q: 'How can I change my payment information?',
        a: [
          {
            text: 'If you make payments through PayPal, you may change the information on the PayPal website.'
          },
          {
            text: 'You can enter new debit/credit card information on the payment page.'
          }
        ]
      }
    ],
    Coupons: [
      {
        q: 'Where can I find available coupons? And how can I use them?',
        a: [
          {
            text: 'Go to COUPONS under your account name. You can check your coupons were offered.'
          },
          {
            text: 'Coupons will appear on the payment page when you make a purchase. You can apply the discount coupon on the coupon option.'
          }
        ]
      }
    ],
    Order: [
      {
        q: 'There is an error. I cannot finalize my order. Why am I unable to place an order?',
        a: [
          {
            text: 'Please check your:'
          },
          {
            list: [
              'MY ORDERS to see if the transaction was successful.',
              'Payment transaction was made with your debit/credit card or PayPal payment.'
            ]
          }
        ]
      },
      {
        q: 'Can I change or modify my order?',
        a: [
          {
            text: 'No, changes cannot be made after the order is placed. You may have to cancel the order and reorder again.'
          }
        ]
      },
      {
        q: 'I ordered the wrong item. How to cancel the order?',
        a: [
          {
            text: 'You can cancel orders by visiting the MY ORDERS under your account name. You cannot change or cancel the order if the shipment is on the way.'
          }
        ]
      },
      {
        q: 'How to track orders?',
        a: [
          {
            text: 'Click the MY ORDERS under your account name to see your tracking status.'
          }
        ]
      }
    ],
    'Return Policy': [
      {
        q: 'What is your return policy?',
        a: [
          {
            text: 'You can apply a return request on MY ORDER after your order is delivered to your shipping address. We will contact you regarding your request. If you want to know more, please check the relevant section ',
            link1: {
              text: 'Article 12 REFUND POLICY',
              url: ''
            },
            text2: ' from ',
            link2: {
              text: 'the Terms and Conditions',
              url: 'https://plofix.com/terms_and_policy/terms'
            },
            text3: ' to see our return and refund policy.'
          }
        ]
      }
    ],
    Shipping: [
      {
        q: 'What shipment methods do you offer?',
        a: [
          {
            text: 'FedEx, UPS, and DHL.'
          }
        ]
      },
      {
        q: 'How long does shipping take?',
        a: [
          {
            text: 'Delivery time varies depending on the destination country and region.'
          }
        ]
      }
    ]
  }
};

export default faq;
