/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPopUpList } from '../../api/adminPopUp';

import bannerStyles from './Banner.module.scss';
import { getMyProfile, startFreeTrial } from '../../api/profile';
import { UserProfileContext } from '../../store/Context';
import { formatLink, openInNewTab } from '../../utils/function';
import { useAlert } from '../hooks/useAlert/useAlert';

function Banner({ onFreeTrial }) {
  const navigate = useNavigate();
  const [lowBanner, setLowBanner] = useState('');

  const { userProfile } = useContext(UserProfileContext) || {};

  const { onAlert, onConfirmAlert } = useAlert();

  const fetchBanners = async () => {
    const getBannersRes = await getPopUpList(
      '메인페이지, Low Banner',
      'banner'
    );

    setLowBanner(getBannersRes[0]);
  };

  const handleStartNowClick = async (e) => {
    e.stopPropagation();
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      const goToAuth = confirm('Log in is required for these actions.');

      if (goToAuth) {
        navigate('/auth');
      }

      return;
    }

    const freeTrialResponse = await startFreeTrial();
    const updatedUserData = await getMyProfile();

    if (
      userProfile?.premiumTime !== null &&
      userProfile?.freeTrialAt === null
    ) {
      onAlert('', 'Okay', 'Your editor subscription extended for 30 days!');

      onFreeTrial(updatedUserData);

      return;
    }

    if (freeTrialResponse.errorStatus) {
      onConfirmAlert(
        '',
        () => navigate('/pricing/subscription'),
        'Okay',
        'Close',
        'Your subscription trial period expired. Would you like to pay for an additional subscription?'
      );

      return;
    }

    onAlert('', 'Okay', 'Your editor subscription is active for 30 days!');

    onFreeTrial(updatedUserData);
  };

  const handleNavigateLink = () => {
    if (lowBanner?.linkTarget) {
      // window.open(formatLink(lowBanner?.linkTarget), '_blank');
      openInNewTab(lowBanner?.linkTarget);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <section
      onClick={handleNavigateLink}
      className={bannerStyles.banner}
      style={
        lowBanner?.file
          ? {
              backgroundImage: `url(${lowBanner?.file})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize:
                lowBanner?.option?.width && lowBanner?.option?.height
                  ? `${lowBanner?.option?.width}px ${lowBanner?.option?.height}px`
                  : 'cover',
              backgroundPosition: 'center'
            }
          : {}
      }>
      <h2 className={bannerStyles.title}>
        Try the edit & plot for free for a month
      </h2>
      <button
        onClick={handleStartNowClick}
        type="button"
        className={bannerStyles.start_btn}>
        Start now!
      </button>
    </section>
  );
}

export default Banner;
