/* eslint-disable react/jsx-props-no-spreading */
import registerNewCarStyles from './RegisterNewCar.module.scss';

function RegisterNewCarInput({ category, name, type, register }) {
  return (
    <label className={registerNewCarStyles.input_label} htmlFor={name}>
      <p>{category}</p>
      <input
        {...register}
        className={registerNewCarStyles.input}
        name={name}
        type={type}
        placeholder="Enter..."
      />
    </label>
  );
}

export default RegisterNewCarInput;
