/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import modalStyles from './ModalNoticeDelivery.module.scss';
import ShopOrderContext from '../Context/Context';
import { changeValues } from '../Context/reducer';

function ModalNoticeDelivery({ setModalIsOpened }) {
  const [state, dispatch] = useContext(ShopOrderContext);

  const navigate = useNavigate();

  const { values, errors, partPrice } = state;
  // console.log('values?.shippingError', values?.shippingError);
  return (
    <>
      {values?.showModal === true && (
        <div className={modalStyles.modal}>
          <div className={modalStyles.modal_container}>
            <h1>Cannot complete order</h1>
            <p>
              This item cannot be shipped to your selected delivery location.
              Please Contact Us.
            </p>
            <div className={modalStyles.group_btn}>
              <button
                className={modalStyles.btn_cancel}
                onClick={() =>
                  dispatch(changeValues({ name: 'showModal', value: false }))
                }
                type="button">
                Cancel
              </button>
              <button
                className={modalStyles.btn_confirm}
                onClick={() => {
                  dispatch(changeValues({ name: 'showModal', value: false }));
                  navigate('/contact_us');
                }}
                type="button">
                Contact us
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ModalNoticeDelivery;
