/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { useClickOutside } from '../../hooks/useClickOutside';

// import { activateEmail, sendCode, userRegister } from '../../../api/api';
import { uploadAvatar } from '../../../api/utils';
import {
  sendCode,
  verifyCode,
  userRegister,
  checkWithdrawal
} from '../../../api/auth';
import { getPolicy } from '../../../api/termsAndPolicy';

import signUpFormStyles from '../Forms.module.scss';
import sighUpFormStyles2 from '../FindPass/FindPass.module.scss';
import signUpFormScopedStyles from './SignUpForm.module.scss';
import profileIcon from '../../../img/profile_signup.svg';

import { phoneNumberCodes } from '../../../utils/phoneNumberCodes';
import countries from '../../../utils/countries';
import TermsAndServiceModal from './TermsAndServiceModal/TermsAndServiceModal';

import iconDown from '../../../img/icon_down1.svg';
import iconUp from '../../../img/icon_down.svg';
import { checkImageSize } from '../../../utils/function';
import { useAlert } from '../../hooks/useAlert/useAlert';

function SignUpForm({ onLogin }) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    mode: 'onChange'
  });

  const [codeSent, setCodeSent] = useState(false);
  const [verificationPassed, setVerificationPassed] = useState(false);
  const [verificationErrors, setVerificationErrors] = useState({
    email: '',
    code: ''
  });

  const [telCodeSelectIsOpen, setTelCodeSelectIsOpen] = useState(false);
  const [selectedTelCode, setSelectedTelCode] = useState('+82');

  const [country, setCountry] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [countrySelectIsOpen, setCountrySelectIsOpen] = useState(false);

  // states for input[type='file']
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(profileIcon);

  // states for modal
  const [membershipPolicyIsOpen, setMembershipPolicyIsOpen] = useState(false);
  const [termsIsOpen, setTermsIsOpen] = useState(false);

  // terms&policy
  const [privacyPolicy, setPrivacyPolicy] = useState({ __html: '' });
  const [terms, setTerms] = useState({ __html: '' });

  const { onAlert } = useAlert();

  // refs
  const phoneCodeRef = useRef(null);
  const countryRef = useRef(null);

  // password validation reg exp
  const regExpLetters = /[a-zA-Z]/g;
  const regExpNumbers = /\d/;
  const regExpSymbs = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/g;

  useClickOutside(phoneCodeRef, () => {
    setTelCodeSelectIsOpen(false);
  });

  useClickOutside(countryRef, () => {
    setCountrySelectIsOpen(false);
  });

  // change selectedFile state on event
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    checkImageSize(e.target.files[0], 320, 320)
      .then((result) => {
        setSelectedFile(e.target.files[0]);
      })
      .catch((error) => {
        onAlert('', 'OK', error.message);
      });
  };

  const handleTelBtnClick = () => {
    setTelCodeSelectIsOpen(!telCodeSelectIsOpen);
  };

  const handleSelectedTelChange = (e) => {
    setSelectedTelCode(e.target.value);
    setTelCodeSelectIsOpen(false);
  };

  // const handleCountrySelectMouseLeave = () => {
  //   setCountrySelectIsOpen(false);
  // };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    clearErrors('country');
  };

  const handleSelectCountry = (e) => {
    setCountry(e.target.value);
    setCountrySelectIsOpen(false);
    setSearchCountry('');
    setValue('country', e.target.value);
    clearErrors('country');
  };

  const fetchTermsAndPolicy = async () => {
    const policyDataRes = await getPolicy('privacy');
    const termsDataRes = await getPolicy('terms');

    setPrivacyPolicy({ __html: policyDataRes });
    setTerms({ __html: termsDataRes });
  };

  const sendVerificationCode = async () => {
    const enteredEmail = getValues('email');

    if (!enteredEmail) {
      setVerificationErrors({ ...verificationErrors, email: 'Enter Email.' });
      return;
    }

    setVerificationErrors({ ...verificationErrors, email: '' });

    const checkWithdrawalRes = await checkWithdrawal({
      email: enteredEmail.trim()
    });

    if (checkWithdrawalRes.data === true) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already withdraw'
      });
      return;
    }

    const emailPlusCodeData = {
      email: enteredEmail,
      type: 'registration'
    };
    const sendCodeRes = await sendCode(emailPlusCodeData);

    if (sendCodeRes.errorStatus) {
      const errorMessage =
        sendCodeRes.message === 'Email is existed'
          ? 'This Email is already registered.'
          : 'Email must be a valid email address.';

      setVerificationErrors({
        ...verificationErrors,
        email: errorMessage
      });

      return;
    }

    if (!sendCodeRes.data) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already registered.'
      });
      return;
    }

    setCodeSent(true);
    setVerificationErrors({ ...verificationErrors, email: '' });
  };

  const verifyEmail = async () => {
    setVerificationErrors({ ...verificationErrors, code: '' });

    const emailPlusCodeData = {
      email: getValues('email'),
      code: getValues('code'),
      type: 'registration'
    };
    const activateEmailRes = await verifyCode(emailPlusCodeData);

    if (!activateEmailRes.data) {
      setVerificationPassed(false);
      setVerificationErrors({
        ...verificationErrors,
        code: 'Wrong verification code.'
      });
      return;
    }

    setCodeSent(false);
    setVerificationPassed(true);
    setVerificationErrors({ ...verificationErrors, code: '' });
  };

  const registerSubmit = async (data) => {
    const checkWithdrawalRes = await checkWithdrawal({
      email: data.email.trim()
    });

    if (checkWithdrawalRes.data === true) {
      setVerificationErrors({
        ...verificationErrors,
        email: 'This email is already withdraw.'
      });
      onAlert('', 'OK', 'Email is already withdraw');
      return;
    }

    let avatarSrc = '';
    const avatar = new FormData();

    if (data.upload[0] !== undefined) {
      avatar.append('file', data.upload[0]);

      avatarSrc = await uploadAvatar(avatar);
    }

    if (data.pass !== data.retype) {
      setError('pass', {
        type: 'custom',
        message: 'Passwords don’t match.'
      });
      return;
    }

    clearErrors(['pass', 'retype']);

    const registerData = {
      firstName: data.firstname,
      lastName: data.lastname,
      phoneNumber: `${selectedTelCode}${data.phone.trim()}`,
      nickName: data.nickname,
      address1: data.address1,
      address2: data.address2,
      city: data.town,
      country,
      postalCode: data.zipcode.split(' ').join(''),
      stateRegion: data.state,
      companyName: data.company,
      avatar: avatarSrc,
      email: data.email,
      password: data.pass,
      code: data.code
    };

    const registerRes = await userRegister(registerData);

    if (registerRes.errorStatus) {
      onAlert('', 'OK', registerRes.message);
      return;
    }

    onAlert('', 'OK', 'Registration complete!');
    const loginState = {
      isLoggedIn: true,
      nickname: registerRes.data.user.nickName,
      email: registerRes.data.user.email,
      id: registerRes.data.user.id
    };

    localStorage.setItem('token', registerRes.data.token);
    localStorage.setItem('refreshToken', registerRes.data.refreshToken);

    onLogin(loginState);
    navigate('/');
  };

  useEffect(() => {
    fetchTermsAndPolicy();
  }, []);

  useEffect(() => {
    if (membershipPolicyIsOpen || termsIsOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'unset';
  }, [membershipPolicyIsOpen, termsIsOpen]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(profileIcon);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <section className={signUpFormStyles.form_section}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(registerSubmit)}
        className={signUpFormStyles.form}>
        <h1 className={signUpFormStyles.title}>Sign Up</h1>
        <p className={signUpFormScopedStyles.subtitle}>
          Welcome to your PLOFIX Registration
        </p>
        <div className={signUpFormStyles.form_container}>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p className={signUpFormScopedStyles.data_title}>Profile</p>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_upload}
                htmlFor="upload">
                <div className={signUpFormScopedStyles.profile_icon}>
                  <img
                    className={signUpFormScopedStyles.profile_icon}
                    src={preview}
                    alt="profile icon"
                  />
                </div>
                <div className={signUpFormScopedStyles.upload_container}>
                  <input
                    {...register('upload')}
                    onChange={onSelectFile}
                    className={signUpFormScopedStyles.input_upload}
                    type="file"
                    name="upload"
                    accept=".png, .jpg, .gif"
                  />
                  <p className={signUpFormScopedStyles.profile_desc}>
                    You can upload files by PNG, JPG, and GIF.
                  </p>
                  <p className={signUpFormScopedStyles.profile_desc_info}>
                    Image size should be at least 320 x 320 pixels.
                  </p>
                </div>
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="nickname">
                <p>*Username</p>
                <input
                  {...register('nickname', {
                    required: 'Enter username.',
                    maxLength: 14
                  })}
                  className={signUpFormScopedStyles.profile_nickname}
                  type="text"
                  name="nickname"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 14) {
                      setValue('nickname', value);
                      clearErrors('nickname');
                    } else {
                      e.target.value = value.substring(0, 14);
                    }
                  }}
                />
              </label>
              {errors.nickname && errors.nickname.type === 'required' && (
                <p className={signUpFormScopedStyles.validation_warn}>
                  {errors.nickname?.message}
                </p>
              )}
              {errors.nickname && errors.nickname.type === 'maxLength' && (
                <p className={signUpFormScopedStyles.validation_warn}>
                  Up to 14 letters can be entered.
                </p>
              )}
              {/* <p className={signUpFormScopedStyles.validation_warn}>
                {errors.nickname?.message}
              </p> */}
            </fieldset>
          </div>
          <label
            className={signUpFormScopedStyles.label_input}
            style={{ marginBottom: '40px' }}
            htmlFor="email">
            <p className={signUpFormScopedStyles.data_title}>*Email</p>
            <input
              {...register('email', { required: 'Enter email.' })}
              type="email"
              name="email"
              onChange={(e) => {
                setCodeSent(false);
                clearErrors('email');
                setValue('email', e.target.value);
                setVerificationErrors({ ...verificationErrors, email: '' });
              }}
            />
            <button
              onClick={sendVerificationCode}
              type="button"
              className={`${sighUpFormStyles2.input_btn} ${sighUpFormStyles2.btn_send}`}>
              {codeSent ? 'resend' : 'send'}
            </button>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.email?.message}
              {verificationErrors.email}
            </p>
            {codeSent && (
              <p
                className={signUpFormScopedStyles.validation_warn}
                style={{ color: '#4bb3ff' }}>
                Code sent to the entered email.
              </p>
            )}
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            style={{ marginBottom: '40px' }}
            htmlFor="code">
            <p className={signUpFormScopedStyles.data_title}>
              *Verification code
            </p>
            <input
              // onChange={(e) => {
              //   setVerificationCode(e.target.value);
              //   setVerificationPassed(false);
              //   setVerificationErrors({
              //     ...verificationErrors,
              //     code: ''
              //   });
              //   clearErrors('code');
              // }}
              onInput={(e) => {
                setVerificationPassed(false);
                setVerificationErrors({
                  ...verificationErrors,
                  code: ''
                });
                setValue('code', e.target.value);
                // clearErrors('code');
              }}
              {...register('code', {
                required: 'Please confirm verification code.',
                maxLength: 6
              })}
              type="text"
              name="code"
              maxLength={6}
            />
            <button
              onClick={verifyEmail}
              type="button"
              className={`${sighUpFormStyles2.input_btn} ${sighUpFormStyles2.btn_verify}`}
              disabled={!(codeSent && getValues('code')?.length === 6)}>
              Verify
            </button>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.code?.message} {verificationErrors.code}
            </p>
            {verificationPassed && (
              <p
                className={signUpFormScopedStyles.validation_warn}
                style={{ color: '#4bb3ff' }}>
                Certified.
              </p>
            )}
          </label>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p
              className={signUpFormScopedStyles.data_title}
              style={{ marginBottom: '4px' }}>
              *Password
            </p>
            <span className={signUpFormScopedStyles.pass_descr}>
              Passoword must have at&nbsp;least 8&nbsp;characters and contain
              a&nbsp;combination of&nbsp;English letters, numbers, and special
              characters
            </span>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="pass">
                <p>*Password</p>
                <input
                  autoComplete="new-password"
                  {...register('pass', {
                    required: 'Please enter password.',
                    validate: (fieldValue) => {
                      if (
                        !regExpLetters.test(fieldValue) ||
                        !regExpNumbers.test(fieldValue)
                      ) {
                        return 'Your password should include letters and numbers.';
                      }
                      if (!regExpSymbs.test(fieldValue)) {
                        return 'Your password should include at least one special character.';
                      }
                      if (
                        fieldValue !== getValues('retype') &&
                        getValues('retype')?.length > 0
                      ) {
                        return 'Passwords don’t match.';
                      }
                    },
                    minLength: {
                      value: 8,
                      message:
                        'Your password should include at least 8 characters.'
                    },
                    maxLength: {
                      value: 15,
                      message: 'Your password must be less than 16 characters.'
                    }
                  })}
                  type="password"
                  name="pass"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 15) {
                      setValue('pass', e.target.value);
                      clearErrors('pass');
                    } else {
                      e.target.value = value.substring(0, 15);
                    }
                  }}
                />
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                style={{ borderTop: '1px solid #ededed' }}
                htmlFor="retype">
                <p>*Retype password</p>
                <input
                  {...register('retype', {
                    required: 'Please fill in the retype password.'
                  })}
                  type="password"
                  name="retype"
                  onChange={(e) => {
                    setValue('retype', e.target.value);
                    clearErrors('retype');
                  }}
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.retype?.message || errors.pass?.message}
              </p>
            </fieldset>
          </div>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p className={signUpFormScopedStyles.data_title}>*Name</p>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="firstname">
                <p>*First name</p>
                <input
                  {...register('firstname', { required: 'Enter first name.' })}
                  type="text"
                  name="firstname"
                  onChange={(e) => {
                    setValue('firstname', e.target.value);
                    clearErrors('firstname');
                  }}
                />
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                style={{ borderTop: '1px solid #ededed' }}
                htmlFor="lastname">
                <p>*Last name</p>
                <input
                  {...register('lastname', { required: 'Enter last name.' })}
                  type="text"
                  name="lastname"
                  onChange={(e) => {
                    setValue('lastname', e.target.value);
                    clearErrors('lastname');
                  }}
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.firstname?.message} {errors.lastname?.message}
              </p>
            </fieldset>
          </div>
          {/* <label className={signUpFormScopedStyles.label_input} htmlFor="phone">
            <p>Mobile phone</p>
            <input
              {...register('phone', { required: 'Enter mobile phone.' })}
              type="tel"
              name="phone"
              onChange={() => clearErrors('phone')}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.phone?.message}
            </p>
          </label> */}
          <fieldset
            className={`${signUpFormScopedStyles.mobile_fieldset} ${signUpFormScopedStyles.fieldset}`}>
            <p>*Mobile Phone</p>
            <div className={signUpFormScopedStyles.mobile_controls}>
              <div
                className={signUpFormScopedStyles.mobile_select}
                ref={phoneCodeRef}>
                <button
                  onClick={handleTelBtnClick}
                  className={signUpFormScopedStyles.custom_select_btn}
                  type="button">
                  <span>{selectedTelCode}</span>
                  <img
                    src={iconDown}
                    alt="arrow"
                    style={
                      telCodeSelectIsOpen ? { transform: 'rotate(180deg)' } : {}
                    }
                  />
                </button>
                <select
                  onChange={(e) => handleSelectedTelChange(e)}
                  className={signUpFormScopedStyles.custom_select}
                  name="mobile_code"
                  style={telCodeSelectIsOpen ? { display: 'flex' } : {}}
                  multiple>
                  {phoneNumberCodes.map((phoneCode) => (
                    <option key={phoneCode.id} value={phoneCode.value}>
                      {phoneCode.text.toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>
              <input
                {...register('phone', { required: 'Enter mobile phone.' })}
                className={signUpFormScopedStyles.mobile_input}
                type="text"
                name="phone"
                onInput={(e) => {
                  const phoneValue = e.target.value
                    .split('')
                    .filter((value) => Number.isInteger(+value))
                    .join('')
                    .replace(/\s/g, '');

                  setValue('phone', phoneValue);
                }}
                onWheel={(e) => e.target.blur()}
              />
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.phone?.message}
              </p>
            </div>
          </fieldset>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="company">
            <p>Company name</p>
            <input {...register('company')} type="text" name="company" />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.company?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address1">
            <p>*Address 1</p>
            <input
              {...register('address1', { required: 'Enter address 1.' })}
              type="text"
              name="address1"
              onChange={(e) => {
                setValue('address1', e.target.value);
                clearErrors('address1');
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address1?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address2">
            <p>*Address 2</p>
            <input
              {...register('address2', { required: 'Enter address 2.' })}
              type="text"
              name="address2"
              onChange={(e) => {
                setValue('address2', e.target.value);
                clearErrors('address2');
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address2?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="town">
            <p>*Town / City</p>
            <input
              {...register('town', { required: 'Enter town / city' })}
              type="text"
              name="town"
              onChange={(e) => {
                setValue('town', e.target.value);
                clearErrors('town');
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.town?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="state">
            <p>*State / Province / Region</p>
            <input
              {...register('state', {
                required: 'Enter state / province / region.'
              })}
              type="text"
              name="state"
              onChange={(e) => {
                setValue('state', e.target.value);
                clearErrors('state');
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.state?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="zipcode">
            <p>*ZIP / Postal Code</p>
            <input
              {...register('zipcode', { required: 'Enter zip / postal code.' })}
              type="number"
              name="zipcode"
              onChange={(e) => {
                setValue('zipcode', e.target.value);
                clearErrors('zipcode');
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.zipcode?.message}
            </p>
          </label>
          <fieldset
            className={`${signUpFormScopedStyles.country_fieldset} ${signUpFormScopedStyles.fieldset}`}
            htmlFor="country"
            ref={countryRef}>
            <p>*Country</p>
            <div className={signUpFormScopedStyles.dropdown}>
              <input
                {...register('country', { required: 'Choose country.' })}
                onClick={() => setCountrySelectIsOpen(!countrySelectIsOpen)}
                // onChange={handleCountryChange}
                onChange={(e) => setSearchCountry(e.target.value)}
                name="country"
                type="text"
                className={signUpFormScopedStyles.country_input}
                value={countrySelectIsOpen ? searchCountry : country}
                placeholder={
                  countrySelectIsOpen && !searchCountry
                    ? country
                    : 'Choose country'
                }
                style={
                  countrySelectIsOpen
                    ? {
                        outline: '1px solid #0046a6',
                        backgroundImage: `url(${iconUp})`
                      }
                    : {
                        backgroundImage: `url(${iconDown})`
                      }
                }
              />
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.country?.message && !country
                ? errors.country?.message
                : null}
            </p>
            {countrySelectIsOpen && (
              <select name="country" multiple onChange={handleSelectCountry}>
                {searchCountry.length > 0
                  ? countries
                      .filter((countryData) =>
                        countryData.name
                          .toLowerCase()
                          .includes(searchCountry.toLowerCase())
                      )
                      .map((countryData, index) => (
                        <option key={index} value={countryData.name}>
                          {countryData.name}
                        </option>
                      ))
                  : countries.map((countryData, index) => (
                      <option key={index} value={countryData.name}>
                        {countryData.name}
                      </option>
                    ))}
              </select>
            )}
          </fieldset>
          <label
            htmlFor="policy"
            className={`${signUpFormScopedStyles.label_input} ${signUpFormScopedStyles.label_checkbox}`}>
            <div className={signUpFormScopedStyles.policy_service}>
              <input
                {...register('policy', {
                  required: 'Check Privacy Policy.'
                })}
                type="checkbox"
                onChange={() => {
                  setValue('policy', !getValues('policy'));
                  clearErrors('policy');
                }}
              />
              <span>*Privacy Policy</span>
              <button
                type="button"
                onClick={() => setMembershipPolicyIsOpen(true)}>
                View
              </button>
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.policy?.message}
            </p>
          </label>
          <label htmlFor="terms" className={signUpFormScopedStyles.label_input}>
            <div className={signUpFormScopedStyles.policy_service}>
              <input
                {...register('terms', {
                  required: 'Check Terms and Conditions.'
                })}
                type="checkbox"
                onChange={() => {
                  setValue('terms', !getValues('terms'));
                  clearErrors('terms');
                }}
              />
              <span>*Terms and Conditions</span>
              <button type="button" onClick={() => setTermsIsOpen(true)}>
                View
              </button>
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.terms?.message}
            </p>
          </label>

          <div className={signUpFormScopedStyles.btn_container}>
            <Link
              to="/auth"
              className={signUpFormScopedStyles.cancel_btn}
              type="button">
              Cancel
            </Link>
            <button
              className={signUpFormScopedStyles.register_btn}
              type="submit">
              Register
            </button>
          </div>
        </div>
      </form>
      {membershipPolicyIsOpen && (
        <TermsAndServiceModal
          title="Privacy Policy"
          textInnerHTML={privacyPolicy}
          onClose={() => {
            setMembershipPolicyIsOpen(false);
            setValue('policy', true);
            clearErrors('policy');
          }}
        />
      )}
      {termsIsOpen && (
        <TermsAndServiceModal
          title="Terms and Conditions"
          textInnerHTML={terms}
          onClose={() => {
            setTermsIsOpen(false);
            setValue('terms', true);
            clearErrors('terms');
          }}
        />
      )}
    </section>
  );
}

export default SignUpForm;
