/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  recallGetBalance,
  setCreditBalance
} from '../../../store/actions/credit';
import { useStore } from '../../../store/hooks';
import {
  addDay,
  convertDate,
  roundDecimalNumber
} from '../../../utils/function';
import { addPpOrder } from '../../../api/transaction';
import { UserProfileContext } from '../../../store/Context';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { useAlert } from '../../hooks/useAlert/useAlert';
import { getMyProfile } from '../../../api/profile';

function PaypalPricingCreditPacks({ option, showToast }) {
  const { setUserProfile } = useContext(UserProfileContext) || {};
  const fetchUserData = async () => {
    if (!window.localStorage.getItem('token')) {
      return;
    }
    try {
      const userProfileRes = await getMyProfile();

      setUserProfile(userProfileRes);
    } catch (e) {
      console.log(e);
    }
  };
  const navigate = useNavigate();
  const price = useMemo(() => {
    return option && option.total ? option.total : 0;
  }, [option]);
  const productId = useMemo(
    () => (option && option.productId.length > 0 ? option.productId : []),
    [option]
  );
  // const type = option && option?.type ? option.type : '';
  // const patternId = option && option?.patternId ? option.patternId : '';
  const paypal = useRef();
  const [state, dispatch] = useStore();
  const { onAlert } = useAlert();

  // console.log('authToken', authToken);
  // console.log('price', price);

  useEffect(() => {
    const authToken = window.localStorage.getItem('token');
    if (window.paypalBtn) window.paypalBtn.close();
    window.paypalBtn = window.paypal?.Buttons({
      style: {
        layout: 'horizontal',
        label: 'paypal',
        color: 'blue',
        shape: 'pill',
        height: 55,
        width: 217,
        tagline: 'false'
      },
      // onclick: async function (data, actions) {
      //   console.log('authToken', price);

      // },
      // onInit: function (data, actions) {
      //   // Disable the buttons
      //   // eslint-disable-next-line no-unused-expressions
      //   (!authToken || !price) && actions.disable();
      // },
      //   onClick: async function (data, actions) {
      //     // return actions.reject();
      //     // return false;
      //     if (type === 'sponsor') {
      //       try {
      //         const body = {
      //           amount: price,
      //           start: convertDate(new Date(), true),
      //           end: convertDate(addDay(new Date(), 30), true),
      //           patternId: patternId
      //         };
      //         const res = await buySponsorCredit(authToken, body);
      //         if (res?.status === 'ok') {
      //           return actions.reject();
      //         }
      //       } catch (error) {
      //         console.log(error?.message ?? 'Error');
      //         return actions.reject();
      //       }
      //     }
      //   },
      createOrder: (data, actions) => {
        if (!authToken) {
          navigate('/auth');
          return;
        }
        if (!price) {
          onAlert('', 'Okay', 'Please select a package!');
          return;
        }
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: roundDecimalNumber(Number(price))
              }
            }
          ]
        });
      },
      onApprove: async function (data, actions) {
        const body = {
          productIds: productId,
          orderId: data.orderID
        };
        // console.log(productId, data.orderID);
        try {
          const dataRes = (await addPpOrder(body)) || {};
          if (dataRes) {
            fetchUserData();
          }
          showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');

          dispatch(recallGetBalance());
        } catch (error) {
          throw new Error(error?.message ?? 'Error');
        }
      }
      // onError: (error) => {
      //   alert('Please select one package!');
      // }
    });
    window.paypalBtn.render(paypal.current);
    // .render(paypal.current);
  }, [price, productId]);

  return <div ref={paypal}></div>;
}

export default withToast(PaypalPricingCreditPacks);
