import SupportPageTable from '../SupportPageTable/SupportPageTable';

function CreditSubProblem() {
  const topics = [
    {
      q: 'What is the difference between Credit fee and Editor fee?',
      a: 'Credits can be used instead of cash on the site and can be recharged. Credits are deducted by the amount used for pattern printing, product purchase, etc. \n The Editor fee is a monthly subscription fee you pay to use the Editor that allows you to edit patterns and connect with plotting'
    },
    {
      q: 'How does auto-renewal work?',
      a: 'If you subscribe to the Editor, you will be automatically billed every month.'
    },
    {
      q: 'How do I cancel my Editor subscription?',
      a: 'You can cancel your subscription on My page.'
    },
    {
      q: 'Is there a fee for canceling the Editor subscription?',
      a: 'No we do not charge any cancelation fee.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default CreditSubProblem;
