function Truck({ checkedParts }) {
  const style = `.cls-1, .cls-2, .cls-3, .cls-4 {
    fill: ${checkedParts?.fullPackage ? '#ff6900' : 'none'};
  }

  .cls-2, .cls-3, .cls-4 {
    stroke: #222;
    stroke-miterlimit: 10;
    stroke-width: .4px;
  }

  .cls-3 {
    isolation: isolate;
  }

  .cls-3, .cls-5 {
    opacity: .4;
  }

  .cls-6, .cls-4 {
    opacity: .4;
  }`;

  return (
    <svg
      id="_레이어_2"
      data-name="레이어 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 470.53 642.2"
      style={{
        transform: 'rotate(90deg)'
      }}>
      <defs>
        <style>{style}</style>
      </defs>
      <g id="_레이어_2-2" data-name="레이어 2">
        <g id="_레이어_2-2" data-name=" 레이어 2-2">
          <g>
            <path
              className="cls-3"
              d="m289.8,191.45s-.89-3.27-1.78-5.34c-.89-2.08-.89-8.61-.59-10.99.3-2.38-1.09-2.57-1.09-2.57-3.17-.4-30.88-1.58-30.88-1.58-.99,3.76-3.96,5.94-5.94,5.94h-28.51c-1.98,0-4.95-2.18-5.94-5.94,0,0-27.71,1.19-30.88,1.58,0,0-1.39.2-1.09,2.57.3,2.38.3,8.91-.59,10.99s-1.78,5.34-1.78,5.34c0,0-1.29,17.62-1.29,36.92v81.66s12.92,4.9,17.82,5.64c4.9.74,7.13,1.34,8.61,2.6,1.48,1.26,6.83,3.49,9.2,3.64,2.38.15,20.19,0,20.19,0,0,0,17.82.15,20.19,0,2.38-.15,7.72-2.38,9.2-3.64s3.71-1.86,8.61-2.6c4.9-.74,17.82-5.64,17.82-5.64v-81.66c0-19.3-1.29-36.92-1.29-36.92h0Zm-86.9-2.72c-.37,3.19-3.49,6.38-4.6,6.24-1.11-.15-2.6-1.41-2.6-3.41v-12.25h7.2s.37,6.24,0,9.43h0Zm71.93,2.81c0,2-1.48,3.27-2.6,3.41-1.11.15-4.23-3.04-4.6-6.24-.37-3.19,0-9.43,0-9.43h7.2v12.25h0Z"
              style={checkedParts?.roof ? { fill: '#ff6900' } : {}}
            />
            <g className="cls-5">
              <path
                className="cls-1"
                d="m226.33,86.51c-.44-.24-.8-.5-1.06-.78.26.28.62.54,1.06.78Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m235.27,88.22h0c-1.81,0-3.51-.16-5-.43,1.49.27,3.19.43,5,.43Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m230.27,87.79c-.89-.16-1.71-.37-2.42-.61.71.24,1.53.45,2.42.61Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m185.74,74.95c.3,0,.65-.03,1.06-.09-.41.05-.76.09-1.06.09h0Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m278.03,73.78c-.97-.18-1.98-.36-3-.5,1.02.14,2.03.31,3,.5Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m188.15,74.64c-.5.09-.95.17-1.36.22.41-.05.86-.13,1.36-.22Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m275.02,73.28c-1.02-.14-2.06-.24-3.08-.29,1.02.04,2.05.15,3.08.29Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m243.64,82.51c.2.09.39.18.56.28-.17-.1-.36-.19-.56-.28Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-1"
                d="m235.27,81.09c3.12,0,5.92.47,7.84,1.2-1.92-.74-4.72-1.2-7.84-1.2-5.79,0-10.49,1.6-10.49,3.56,0,.37.17.72.47,1.06-.31-.33-.47-.69-.47-1.06,0-1.97,4.7-3.56,10.49-3.56Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m186.8,74.87c.41-.05.86-.13,1.36-.22.5-.09,1.03-.2,1.6-.32s1.17-.24,1.8-.36c2.5-.49,5.37-.99,8.04-.99h19.8s.1-1.83-1.73-3.22c-1.83-1.39-2.57-3.17-2.57-4.45v-2.77s-34.59.89-44.59,1.93v10.49h15.24c.3,0,.65-.03,1.06-.09h-.01Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m245.76,84.65c0-.68-.58-1.32-1.56-1.86-.17-.1-.36-.19-.56-.28-.17-.08-.34-.15-.53-.22-1.92-.74-4.72-1.2-7.84-1.2-5.79,0-10.49,1.6-10.49,3.56,0,.37.17.72.47,1.06,0,0,.02.02.03.02.26.28.62.54,1.06.78.43.24.93.46,1.51.66.71.24,1.53.45,2.42.61,1.49.27,3.19.43,5,.43,5.79,0,10.49-1.6,10.49-3.56Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m271.95,73c1.02.04,2.05.15,3.08.29s2.03.31,3,.5c1.29.24,2.51.5,3.58.72,1.34.27,2.45.46,3.19.46h15.24v-10.49c-10-1.04-44.59-1.93-44.59-1.93v2.77c0,1.29-.74,3.07-2.57,4.45-1.83,1.39-1.73,3.22-1.73,3.22h19.8c.33,0,.67,0,1.01.02h0Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m300.04,74.95h-15.24c-.74,0-1.85-.19-3.19-.46-1.07-.21-2.29-.47-3.58-.72-.97-.18-1.98-.36-3-.5s-2.06-.24-3.08-.29c-.34-.01-.68-.02-1.01-.02h-71.35c-2.67,0-5.54.49-8.04.99-.62.12-1.23.25-1.8.36s-1.11.22-1.6.32-.95.17-1.36.22-.76.09-1.06.09h-15.24v36.23h129.54v-36.23h0Zm-64.77,13.27h0c-1.81,0-3.51-.16-5-.43-.89-.16-1.71-.37-2.42-.61-.58-.2-1.08-.42-1.51-.66-.44-.24-.8-.5-1.06-.78,0,0-.02-.02-.03-.02-.31-.33-.47-.69-.47-1.06,0-1.97,4.7-3.56,10.49-3.56,3.12,0,5.92.47,7.84,1.2.18.07.36.14.53.22.2.09.39.18.56.28.98.54,1.56,1.18,1.56,1.86,0,1.97-4.7,3.56-10.49,3.56Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m191.55,73.96c2.5-.49,5.37-.99,8.04-.99h0c-2.67,0-5.54.49-8.04.99Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m191.55,73.96c-.62.12-1.23.25-1.8.36.57-.12,1.17-.24,1.8-.36Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m244.2,82.79c.98.54,1.56,1.18,1.56,1.86,0,1.97-4.7,3.56-10.49,3.56h0c5.79,0,10.49-1.6,10.49-3.56,0-.68-.58-1.32-1.56-1.86Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m243.11,82.29c.18.07.36.14.53.22-.17-.08-.34-.15-.53-.22Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m225.27,85.74s-.02-.02-.03-.02c0,0,.02.02.03.02Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m227.85,87.17c-.58-.2-1.08-.42-1.51-.66.43.24.93.46,1.51.66Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m284.8,74.95h0c-.74,0-1.85-.19-3.19-.46,1.34.27,2.45.46,3.19.46Z"
                style={checkedParts?.trunk ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m216.64,3.19l-38.4-.59-2.47-2.38-13.16,2.47s0,5.74.49,8.91.99,4.65.99,4.65c0,0,3.37-1.39,7.23-1.88s7.03-.69,7.03-.69c0,0-3.76,1.09-5.34,4.06-1.58,2.97-1.29,5.94-2.28,7.72,0,0,2.57.99,3.36-.49.79-1.48.89-4.65,2.47-5.64,1.58-.99,1.48-1.48,2.77-1.09,1.29.4,15.54,4.26,15.54,4.26l1.68-3.27,9.01-.1.2-1.19,19.2-.2s-3.37-4.26-4.85-7.42c-1.48-3.16-3.46-7.13-3.46-7.13h0Zm-48.64,5.29c-.84,0-1.52-.68-1.52-1.52s.68-1.52,1.52-1.52,1.52.68,1.52,1.52-.68,1.52-1.52,1.52Zm17.82-.85c-.84,0-1.52-.68-1.52-1.52s.68-1.52,1.52-1.52,1.52.68,1.52,1.52-.68,1.52-1.52,1.52Z"
                style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m294.77.23l-2.47,2.38-38.4.59s-1.98,3.96-3.46,7.13c-1.48,3.17-4.85,7.42-4.85,7.42l19.2.2.2,1.19,9.01.1,1.68,3.27s14.25-3.86,15.54-4.26,1.19.1,2.77,1.09,1.68,4.16,2.47,5.64,3.36.49,3.36.49c-.99-1.78-.69-4.75-2.28-7.72-1.58-2.97-5.34-4.06-5.34-4.06,0,0,3.17.2,7.03.69s7.23,1.88,7.23,1.88c0,0,.49-1.48.99-4.65.49-3.17.49-8.91.49-8.91l-13.16-2.47h0Zm-10.06,7.4c-.84,0-1.52-.68-1.52-1.52s.68-1.52,1.52-1.52,1.52.68,1.52,1.52-.68,1.52-1.52,1.52Zm17.82.85c-.84,0-1.52-.68-1.52-1.52s.68-1.52,1.52-1.52,1.52.68,1.52,1.52-.68,1.52-1.52,1.52Z"
                style={checkedParts?.rearBumper ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m72.95,19.82c0,1.98,0,2.77,1.48,5.05,1.48,2.28,15.14,12.97,17.02,14.35,1.88,1.39,4.35,1.19,7.23,1.19s3.76-1.39,3.76-4.06.1-8.51.79-13.86c.69-5.34,2.97-10.49,2.97-10.49h-33.26v7.82h0Z"
                style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m364.33,12s2.28,5.15,2.97,10.49c.69,5.34.79,11.18.79,13.86s.89,4.06,3.76,4.06,5.34.2,7.23-1.19c1.88-1.39,15.54-12.08,17.02-14.35,1.48-2.27,1.48-3.07,1.48-5.05v-7.82h-33.26,0Z"
                style={checkedParts?.tailLamp ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <polygon
                className="cls-2"
                points="109.07 276.95 148.96 274.69 148.96 266.47 109.07 267.56 109.07 276.95"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="cls-2"
                points="109.07 286.66 148.96 283.49 148.96 274.69 109.07 276.95 109.07 286.66"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m176.33,332.88c-.59,2.97-13.51,38.3-14.7,42.06-1.19,3.76-4.01,5.89-4.01,5.89l5.49,8.61,20.49-64.43h-6.38s-.3,4.9-.89,7.87h0Z"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="cls-2"
                points="321.57 266.47 321.57 274.69 361.46 276.95 361.46 267.56 321.57 266.47"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="cls-2"
                points="321.57 283.49 361.46 286.66 361.46 276.95 321.57 274.69 321.57 283.49"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m308.9,374.95c-1.19-3.76-14.1-39.1-14.7-42.06-.59-2.97-.89-7.87-.89-7.87h-6.38l20.49,64.43,5.49-8.61s-2.82-2.13-4.01-5.89h0Z"
                style={checkedParts?.pillar ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m106.3,428.3c0-1.48-1.83-2.28-2.72-2.28s-.74.99-.74.99c0,0-17.82-.15-19.6-.15s-3.56,2.23-3.56,2.23h14.25v8.61l-18.51,4.45,1.39,4.06s1.88-.4,2.28.59-1.39,2.08-1.39,2.08c0,0,0,2.18,3.17,4.75,3.17,2.57,4.85,3.27,4.85,3.27l22.86-10.99s-3.37-2.57-3.76-2.92c-.4-.35,1.29-.64,2.57-.45,1.29.2,2.08.45,2.08.45l.49-6.98-3.76-.3s.1-5.94.1-7.42h0Z"
                style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m393.73,446.21l1.39-4.06-18.51-4.45v-8.61h14.25s-1.78-2.23-3.56-2.23-19.6.15-19.6.15c0,0,.15-.99-.74-.99s-2.72.79-2.72,2.28.1,7.42.1,7.42l-3.76.3.49,6.98s.79-.25,2.08-.45c1.29-.2,2.97.1,2.57.45s-3.76,2.92-3.76,2.92l22.86,10.99s1.68-.69,4.85-3.27c3.17-2.57,3.17-4.75,3.17-4.75,0,0-1.78-1.09-1.39-2.08.4-.99,2.28-.59,2.28-.59h0Z"
                style={checkedParts?.sideMirror ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m155.79,390.09s-3.27,2.97-8.12,3.76c-4.85.79-22.27,1.39-33.06,1.39s-34.34-3.27-34.34-3.27v12.08h5.64l1.68,3.76s29.49,1.39,33.95,24.25c4.45,22.86-3.56,33.26-5.34,35.33-1.78,2.08-1.93,3.35-1.78,5.74.3,4.87.61,11.87,3.76,16.13,0,0,4.35-5.34,11.58-10.59s11.09-4.55,12.07-3.46,3.27,13.06,3.27,13.06c0,0,3.76-2.18,4.16-3.37.4-1.19,0-4.65,0-4.65,0,0,1.39,1.09,1.88-1.58.5-2.67,1.09-16.43.79-33.35-.3-16.92-1.39-20.09-1.09-22.76s2.08-6.73,4.26-8.81c2.18-2.08,4.75-2.47,4.75-2.47v-9.6c0-2.97-2.97-4.95-3.46-6.93-.49-1.98-.59-4.65-.59-4.65h-.01Zm-14.35,17.41c0,1.68-2.47,2.87-6.04,2.87s-7.03-2.08-9.01-3.86-5.25-4.16-5.74-5.05c-.49-.89-.59-2.97-.59-2.97,0-1.09.69-1.19,2.97,0,2.28,1.19,6.73,3.46,9.6,3.46s3.86-.76,3.86-2.16,0-2.16,1.19-2.16,3.76-.63,3.76,1.15v8.71h0Z"
                style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m355.92,395.24c-10.79,0-28.21-.59-33.06-1.39-4.85-.79-8.12-3.76-8.12-3.76,0,0-.1,2.67-.59,4.65-.5,1.98-3.46,3.96-3.46,6.93v9.6s2.57.4,4.75,2.47c2.18,2.08,3.96,6.14,4.26,8.81.3,2.67-.79,5.84-1.09,22.76s.3,30.68.79,33.35c.49,2.67,1.88,1.58,1.88,1.58,0,0-.4,3.46,0,4.65s4.16,3.37,4.16,3.37c0,0,2.28-11.98,3.27-13.06s4.85-1.78,12.08,3.46c7.23,5.25,11.58,10.59,11.58,10.59,3.15-4.27,3.46-11.26,3.76-16.13.15-2.39,0-3.66-1.78-5.74-1.78-2.08-9.8-12.47-5.34-35.33,4.45-22.86,33.95-24.25,33.95-24.25l1.68-3.76h5.64v-12.08s-23.56,3.27-34.34,3.27h-.02Zm-6.04,6.25c-.49.89-3.76,3.27-5.74,5.05-1.98,1.78-5.44,3.86-9.01,3.86s-6.04-1.19-6.04-2.87v-8.71c0-1.78,2.57-1.15,3.76-1.15s1.19.76,1.19,2.16.99,2.16,3.86,2.16,7.32-2.28,9.6-3.46c2.28-1.19,2.97-1.09,2.97,0,0,0-.1,2.08-.59,2.97h0Z"
                style={checkedParts?.frontFender ? { fill: '#ff6900' } : {}}
              />
            </g>
            <path
              className="cls-3"
              d="m300.74,482.34c.25-2.18,7.87-56.61,7.87-58.99s-1.58-3.07-1.58-3.07c0,0,.17-2.99-.57-5.89-.74-2.9-4.83-5.64-6.53-5.57-1.71.07-6.68,6.31-6.68,6.31,0,0-4.08.22-8.09.67-4.01.45-4.23,3.04-4.23,3.04,0,0-6.16.3-11.51.59-5.34.3-6.01,2.6-6.01,2.6,0,0-5.2.37-6.01.74s-3.79,4.08-3.79,4.08c0,0,.22-1.34-1.19-1.34h-34.3c-1.41,0-1.19,1.34-1.19,1.34,0,0-2.97-3.71-3.79-4.08s-6.01-.74-6.01-.74c0,0-.67-2.3-6.01-2.6-5.34-.3-11.51-.59-11.51-.59,0,0-.22-2.6-4.23-3.04-4.01-.45-8.09-.67-8.09-.67,0,0-4.97-6.24-6.68-6.31-1.71-.07-5.79,2.67-6.53,5.57-.74,2.89-.57,5.89-.57,5.89,0,0-1.58.69-1.58,3.07s7.62,56.81,7.87,58.99-.89,5.05-.89,5.05c0,0,6.09,7.13,13.71,11.48,7.62,4.36,9.7,5.05,12.08,4.36,2.38-.69,4.06-.1,5.64.99s17.12,3.17,34.94,3.17,33.35-2.08,34.94-3.17c1.58-1.09,3.27-1.68,5.64-.99,2.38.69,4.45,0,12.08-4.36,7.62-4.35,13.71-11.48,13.71-11.48,0,0-1.14-2.87-.89-5.05h-.02Z"
              style={checkedParts?.hood ? { fill: '#ff6900' } : {}}
            />
            <g className="cls-5">
              <path
                className="cls-2"
                d="m155.02,561.89c0,2-.15,6.29,1.41,10.24,1.78,4.53,5.27,11.65,5.27,11.65h14.85v-24.2h-20.19s-1.34.3-1.34,2.3h0Z"
                style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m326.2,561.89c0-2-1.34-2.3-1.34-2.3h-20.19v24.2h14.85s3.49-7.13,5.27-11.65c1.56-3.95,1.41-8.24,1.41-10.24h0Z"
                style={checkedParts?.headlight ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m233.19,573.64v-2.23h-31.77s-.94.4-.94,1.63h-13.31v2.87h13.61s.64,2.52,1.53,2.52h30.88v-2.13s-1.24-.3-1.24-1.34,1.24-1.34,1.24-1.34v.02Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m154.2,592.6c.45,1.06,3.04,3.66,5.57,4.26,2.52.59,11.95.37,13.88,0,1.93-.37,8.39-6.46,8.39-6.46h-25.76c-2.67,0-2.52,1.14-2.08,2.2h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m155.69,583.79s-2.23-8.91-2.45-15.37c-.22-6.46.51-9.13,1.78-9.65s21.98,0,23.83,0,6.68-3.79,6.68-3.79c0,0-35.78-2.45-39.49-2.38s-4.6,4.97-4.53,8.02,4.23,15.14,6.46,18.34c2.23,3.19,4.45,4.68,7.72,4.83h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m199.29,570.52c2.03-.45,1.78-2.82,1.78-2.82l-1.58-.79s1.78.64,2.47.15,3.96-3.27,3.96-3.27c0,0,14.2.99,34.69.99s34.69-.99,34.69-.99c0,0,3.27,2.77,3.96,3.27.69.49,2.47-.15,2.47-.15l-1.58.79s-.25,2.38,1.78,2.82c2.03.45,12.97-3.07,12.97-3.07,0,0,.49-.89.4-3.61-.1-2.72-1.48-4.65-1.48-4.65,0,0-4.7,2.13-7.32,1.98-2.62-.15-5.84-3.12-6.14-3.56-.2-.3-2.13-.2-3.37-.1,1.63-.17,4.61-.6,4.61-1.39,0-1.09-.59-1.14-.59-1.14,0,0-20.88,1.48-40.38,1.48s-40.38-1.48-40.38-1.48c0,0-.59.05-.59,1.14,0,.79,2.98,1.22,4.61,1.39-1.24-.1-3.17-.2-3.37.1-.3.45-3.51,3.41-6.14,3.56-2.62.15-7.32-1.98-7.32-1.98,0,0-1.39,1.93-1.48,4.65-.1,2.72.4,3.61.4,3.61,0,0,10.94,3.51,12.97,3.07h-.04Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m248.03,576.32v2.13h30.88c.89,0,1.53-2.52,1.53-2.52h13.61v-2.87h-13.31c0-1.24-.94-1.63-.94-1.63h-31.77v2.23s1.24.3,1.24,1.34-1.24,1.34-1.24,1.34v-.02Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m185.91,594.18c.46,1.29,2.04,1.29,4.62.99,2.57-.3,3.76-.57,5.25.16,1.48.73,6.53,3.2,7.62,3.2s8.04-.16,9.82-.27c1.99-.12,27.39.27,27.39.27,0,0,25.4-.39,27.39-.27,1.78.11,8.73.27,9.82.27s6.14-2.47,7.62-3.2c1.48-.73,2.67-.46,5.25-.16,2.57.3,4.16.3,4.62-.99s1.22-5.94,1.22-5.94c0,0-3.27-1.58-4.85-1.58h-10.1v2.28l-5.44,4.26h-71.06l-5.44-4.26v-2.28h-10.1c-1.58,0-4.85,1.58-4.85,1.58,0,0,.77,4.65,1.22,5.94h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m323.85,596.95c-3.66.79-8.19,1.58-8.19,1.58-.74,1.16-2.6,2.55-9.72,2.92-7.13.37-11.88.25-11.88.25v-5.25s-15.14,7.52-20.59,7.52h-65.72c-5.44,0-20.59-7.52-20.59-7.52v5.25s-4.75.12-11.88-.25c-7.13-.37-8.98-1.76-9.72-2.92,0,0-4.53-.79-8.19-1.58-3.66-.79-11.38-2.97-11.38-2.97,0,0-.69,1.88,0,2.87.69.99,1.68.3,1.78,1.68.1,1.39-1.68,1.29-1.68,1.29,0,0,.89,3.17,5.84,5.54,4.95,2.38,18.31,4.78,18.31,4.78,0,0,.07-1.04,1.34-1.04s1.41,1.04,1.41,1.04c0,0,4.38.89,12.4,1.71,8.02.82,10.24.67,11.21,0,.96-.67,4.08-1.56,4.08-1.56l39.94.3,39.94-.3s3.12.89,4.08,1.56,3.19.82,11.21,0,12.4-1.71,12.4-1.71c0,0,.15-1.04,1.41-1.04s1.34,1.04,1.34,1.04c0,0,13.36-2.4,18.31-4.78s5.84-5.54,5.84-5.54c0,0-1.78.1-1.68-1.29.1-1.39,1.09-.69,1.78-1.68s0-2.87,0-2.87c0,0-7.72,2.18-11.38,2.97h-.02Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m322.71,613.08c-3.91-.35-7.23-.68-7.23-.68,0,0-.15.33-.1,1.03s2.47,1.24,2.47,1.24c-3.61-.1-3.98.67-4.73,2.08-.74,1.41-2.3,3.64-5.49,4.31-3.19.67-14.33,1.26-15.29.82-.97-.45-5.42-4.53-6.24-5.2-.82-.67-3.93-1.41-7.5-1.41h-76.01c-3.56,0-6.68.74-7.5,1.41s-5.27,4.75-6.24,5.2c-.96.45-12.1-.15-15.29-.82-3.19-.67-4.75-2.9-5.49-4.31-.74-1.41-1.11-2.18-4.73-2.08,0,0,2.43-.54,2.47-1.24.05-.69-.1-1.03-.1-1.03,0,0-3.32.33-7.23.68-3.91.35-10.69.4-10.69.4l-1.43,3.73,2.97,17.15s24.45.89,39.39,3.07l16.53-13.86h70.67l16.53,13.86c14.95-2.18,39.39-3.07,39.39-3.07l2.97-17.15-1.44-3.73s-6.78-.05-10.69-.4h.03Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m264.96,630.01h-48.7c-2.97,0-19.3,2.67-19.3,2.67l-4.85,7.82h6.14s3.07-4.45,3.66-5.25c.59-.79,3.96-1.78,7.42-1.78s3.86,1.68,4.65,3.27c.79,1.58,1.29,5.25,1.29,5.25,0,0,13.16-.99,25.34-.99s25.34.99,25.34.99c0,0,.49-3.66,1.29-5.25.79-1.58,1.19-3.27,4.65-3.27s6.83.99,7.42,1.78c.59.79,3.66,5.25,3.66,5.25h6.14l-4.85-7.82s-16.33-2.67-19.3-2.67h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m321.45,596.85c2.52-.59,5.12-3.19,5.57-4.26s.59-2.2-2.08-2.2h-25.76s6.46,6.09,8.39,6.46c1.93.37,11.36.59,13.88,0h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m335.18,552.61c-3.71-.07-39.49,2.38-39.49,2.38,0,0,4.83,3.79,6.68,3.79s22.57-.52,23.83,0c1.27.52,2,3.19,1.78,9.65-.22,6.46-2.45,15.37-2.45,15.37,3.27-.15,5.49-1.63,7.72-4.83,2.23-3.19,6.38-15.29,6.46-18.34.08-3.05-.82-7.94-4.53-8.02h0Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m104.54,555.21s2.82,2.45,3.64,2.45l-5.12-.07-.52,4.73,2.28.46v4.58c0,1.19,1.58,3.21,1.58,3.21h18.51v-1.42h-8.61c-1.19,0-2.18-.89-2.18-3.56s1.09-3.36,2.87-3.36h7.92c1.78,0,4.65-1.29,4.65-1.29v-2.97h-3.56,3.56v-1.56l-8.46-7.42-16.55,6.24v-.02Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m378.16,557.58l-5.12.07c.82,0,3.64-2.45,3.64-2.45l-16.55-6.24-8.46,7.42v1.56h3.56-3.56v2.97s2.87,1.29,4.65,1.29h7.92c1.78,0,2.87.69,2.87,3.36s-.99,3.56-2.18,3.56h-8.61v1.42h18.51s1.58-2.02,1.58-3.21v-4.58l2.28-.46-.52-4.73v.02Z"
                style={checkedParts?.frontBumper ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m56.61,179.12v-6.89c-6.94,2.15-15.39,2.68-15.39,2.68v3.08l-7.98.78v12.88h5.94l2.15,1.82h19.83v-4.8s-4.55-8.17-4.55-9.55Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m48.7,58.42l-3.76,36.92c5.86,1.6,10.75,3.35,14.89,5.39l2.77-43.17c-4.35.36-13.9.87-13.9.87h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m104.91,50.8c-1.19,3.12-2.52,12.47-2.38,17.82.15,5.34-1.19,6.83-5.64,6.83s-6.09-2.08-13.36-7.42c-7.27-5.34-10.59-9.65-10.59-12.03v-12.47l-7.42.35c.69,1.78.59,7.42.59,9.6s-.3,3.56-1.48,3.86c-.23.06-.98.13-2.04.22l-2.77,43.17c7.12,3.5,11.98,7.85,15.55,13.77,5.64,9.35,7.57,23.68,3.86,34.37-3.71,10.69-12.92,19.6-20.12,22.49-.8.32-1.64.61-2.5.88v6.89c0,1.39,4.55,9.55,4.55,9.55v4.8h45.68s5.79-131.39,6.24-135.55c.45-4.16,2.38-10.24,2.38-10.24l-7.87-1.78s-1.48,1.78-2.67,4.9h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m429.31,178v-3.08s-8.45-.53-15.39-2.68v6.89c0,1.39-4.55,9.55-4.55,9.55v4.8h19.83l2.15-1.82h5.94v-12.88l-7.98-.78h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m425.6,95.34l-3.76-36.92s-9.55-.51-13.9-.87l2.77,43.17c4.14-2.04,9.03-3.79,14.89-5.39h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m413.92,179.12v-6.89c-.86-.27-1.7-.56-2.5-.88-7.2-2.9-16.41-11.8-20.12-22.49-3.71-10.69-1.78-25.02,3.86-34.37,3.57-5.92,8.43-10.26,15.55-13.77l-2.77-43.17c-1.05-.09-1.81-.16-2.04-.22-1.19-.3-1.48-1.68-1.48-3.86s-.1-7.82.59-9.6l-7.42-.35v12.47c0,2.38-3.32,6.68-10.59,12.03-7.27,5.34-8.91,7.42-13.36,7.42s-5.79-1.48-5.64-6.83c.15-5.35-1.19-14.7-2.38-17.82-1.19-3.12-2.67-4.9-2.67-4.9l-7.87,1.78s1.93,6.09,2.38,10.24c.45,4.16,6.24,135.55,6.24,135.55h45.68v-4.8s4.55-8.17,4.55-9.55h0Zm-33.98,7.42h-6.22c-1.64,0-3-1.25-3.09-2.84l-.57-10.48c-.1-1.81,1.48-3.29,3.35-3.14l5.81.47c1.49.12,2.68,1.26,2.82,2.7l.98,10c.17,1.76-1.25,3.28-3.08,3.28h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m379.21,170.56l-5.81-.47c-1.87-.15-3.44,1.33-3.35,3.14l.57,10.48c.09,1.59,1.44,2.84,3.09,2.84h6.22c1.82,0,3.25-1.52,3.08-3.28l-.98-10c-.14-1.44-1.33-2.58-2.82-2.7h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m416.59,148.28l-5.81-.47c-1.87-.15-3.44,1.33-3.35,3.14l.57,10.48c.09,1.59,1.44,2.84,3.09,2.84h6.22c1.82,0,3.25-1.52,3.08-3.28l-.98-10c-.14-1.44-1.33-2.58-2.82-2.7h0Z"
                style={checkedParts?.rearFender ? { fill: '#ff6900' } : {}}
              />
            </g>
            <g className="cls-5">
              <path
                className="cls-2"
                d="m108.18,275.43v-44.24c0-10.1.59-14.9,1.93-17.67,1.34-2.77,2.87-4.6,5.2-4.6h22.57c5.15,0,9.9,2.62,9.9,6.78s1.58,28.51,1.58,37.02-.05,21.23-.05,21.23c0,0,3.56-.59,3.56-1.93s-.3-23.01-1.04-40.98c-.74-17.96-1.19-24.2-3.27-26.87-2.08-2.67-16.03-4.6-42.76-4.6s-68.15,4.9-68.15,4.9l.74,73.49h48.1c6.68,0,21.68-2.52,21.68-2.52h0Zm-18.35-68.24c.53-1.58,2.11-2.54,3.17-.91.9,1.37.78,3.06.52,4.44-.2,1.06-1.12,2.15-2.16,2.24-1.21.11-1.96-1.26-1.96-1.26,0,0-.22,3.75-.07,5.83.16,2.25.3,3.93.3,3.93,0,0,.92-.65,2.18-.59,1.26.06,1.83,1.01,2.07,2.28.22,1.13.21,2.17-.61,3.11-.71.82-2.41.38-3.04-.35-.59-.68-1.03-2.93-1.26-6.93-.23-4,.04-9.33.87-11.79h0Z"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m147.97,318.26c-1.78,8.39-22.86,39.57-27.02,45.95-4.16,6.38-10.24,6.83-13.66,6.83s-4.9-1.04-4.9-5.34v-39.75l6.68-17.71v-31.29s-15.29,3.73-20.93,3.73h-48.84s.89,57.4,1.63,72.4c.74,14.99,1.44,20.98,1.44,20.98,9.9.79,53.65.89,58.89.89s13.96-.59,17.91-3.56c3.96-2.97,10.69-12.87,21.38-30.48,10.69-17.62,10.79-19.1,11.38-22.17s2.67-41.78,2.67-41.78h-3.27s-1.58,32.92-3.37,41.31h0Zm-53.44-12.45c-.71.82-2.41.38-3.04-.35-.59-.68-1.03-2.93-1.26-6.93-.23-4,.04-9.33.87-11.79.53-1.58,2.11-2.54,3.17-.91.9,1.37.78,3.06.52,4.44-.2,1.06-1.12,2.15-2.16,2.24-1.21.11-1.96-1.26-1.96-1.26,0,0-.22,3.75-.07,5.83.16,2.25.3,3.93.3,3.93,0,0,.92-.65,2.18-.59s1.83,1.01,2.07,2.28c.22,1.13.21,2.17-.61,3.11h0Z"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m321.97,204.17c-2.08,2.67-2.52,8.91-3.27,26.87-.74,17.96-1.04,39.64-1.04,40.98s3.56,1.93,3.56,1.93c0,0-.05-12.72-.05-21.23s1.58-32.86,1.58-37.02,4.75-6.78,9.9-6.78h22.57c2.33,0,3.86,1.83,5.2,4.6,1.34,2.77,1.93,7.57,1.93,17.67v44.24s14.99,2.52,21.68,2.52h48.1l.74-73.49s-41.42-4.9-68.15-4.9-40.68,1.93-42.76,4.6h.01Zm54.69,18.99c.24-1.27.82-2.22,2.07-2.28,1.26-.06,2.18.59,2.18.59,0,0,.14-1.68.3-3.93.15-2.08-.07-5.83-.07-5.83,0,0-.74,1.37-1.96,1.26-1.05-.1-1.96-1.18-2.16-2.24-.26-1.38-.38-3.07.52-4.44,1.06-1.63,2.64-.67,3.17.91.83,2.46,1.1,7.79.87,11.79s-.67,6.25-1.26,6.93c-.64.73-2.33,1.16-3.04.35-.82-.94-.83-1.98-.61-3.11h0Z"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <path
                className="cls-2"
                d="m361.46,276.95v31.29l6.68,17.71v39.75c0,4.31-1.48,5.34-4.9,5.34s-9.5-.45-13.66-6.83c-4.16-6.38-25.24-37.56-27.02-45.95-1.78-8.39-3.37-41.31-3.37-41.31h-3.27s2.08,38.71,2.67,41.78c.59,3.07.69,4.55,11.38,22.17,10.69,17.62,17.42,27.52,21.38,30.48,3.96,2.97,12.67,3.56,17.91,3.56s48.99-.1,58.89-.89c0,0,.69-5.99,1.44-20.98.74-14.99,1.63-72.4,1.63-72.4h-48.84c-5.64,0-20.93-3.73-20.93-3.73h0Zm18.84,21.6c-.23,4-.67,6.25-1.26,6.93-.64.73-2.33,1.16-3.04.35-.82-.94-.83-1.98-.61-3.11.24-1.27.81-2.22,2.07-2.28,1.26-.06,2.18.59,2.18.59,0,0,.14-1.68.3-3.93.15-2.08-.07-5.83-.07-5.83,0,0-.74,1.37-1.96,1.26-1.05-.1-1.96-1.18-2.16-2.24-.26-1.38-.38-3.07.52-4.44,1.06-1.63,2.64-.67,3.17.91.83,2.46,1.1,7.79.87,11.79h0Z"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="cls-2"
                points=".23 198.08 27.48 198.08 27.48 191.47 1.22 191.47 .23 198.08"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <rect
                className="cls-2"
                x=".23"
                y="199.56"
                width="27.25"
                height="3.61"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <polygon
                className="cls-2"
                points="469.31 191.47 443.05 191.47 443.05 198.08 470.3 198.08 469.31 191.47"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
              <rect
                className="cls-2"
                x="443.05"
                y="199.56"
                width="27.25"
                height="3.61"
                style={checkedParts?.door ? { fill: '#ff6900' } : {}}
              />
            </g>
          </g>
          <g>
            <g className="cls-6">
              <rect
                className="cls-2"
                x="329.1"
                y="377.16"
                width="4.77"
                height="12.52"
                rx="1.21"
                ry="1.21"
              />
              <path
                className="cls-2"
                d="m348.76,376.7c-.26.17-6.75,7.05-7.93,7.31-.89.2-5.25.1-7.01.03v4.54c0,.17-.05.32-.13.46h7.27c2.09,0,10.01-10.13,10.01-10.35s-1.95-2.16-2.21-1.99Z"
              />
            </g>
            <g className="cls-6">
              <rect
                className="cls-2"
                x="136.67"
                y="377.16"
                width="4.77"
                height="12.52"
                rx="1.21"
                ry="1.21"
                transform="translate(278.11 766.84) rotate(-180)"
              />
              <path
                className="cls-2"
                d="m121.77,376.7c.26.17,6.75,7.05,7.93,7.31.89.2,5.25.1,7.01.03v4.54c0,.17.05.32.13.46-1.75,0-5.75,0-7.27,0-2.09,0-10.01-10.13-10.01-10.35s1.95-2.16,2.21-1.99Z"
              />
            </g>
          </g>
          <g>
            <path
              className="cls-4"
              d="m353.88,297.83c-1.4,0-1.56-6.99,0-6.99s.89,1.44,1.95,1.44c.65,0,.98-1.44,1.76-1.44,1.67,0,1.47,6.99,0,6.99-1.1,0-.94-.81-1.92-.81-.74,0-.45.81-1.8.81Z"
            />
            <path
              className="cls-4"
              d="m353.88,226.24c-1.4,0-1.56-6.99,0-6.99s.89,1.44,1.95,1.44c.65,0,.98-1.44,1.76-1.44,1.67,0,1.47,6.99,0,6.99-1.1,0-.94-.81-1.92-.81-.74,0-.45.81-1.8.81Z"
            />
            <path
              className="cls-4"
              d="m112.87,297.83c-1.4,0-1.56-6.99,0-6.99s.89,1.44,1.95,1.44c.65,0,.98-1.44,1.76-1.44,1.67,0,1.47,6.99,0,6.99-1.1,0-.94-.81-1.92-.81-.74,0-.45.81-1.8.81Z"
            />
            <path
              className="cls-4"
              d="m112.87,226.24c-1.4,0-1.56-6.99,0-6.99s.89,1.44,1.95,1.44c.65,0,.98-1.44,1.76-1.44,1.67,0,1.47,6.99,0,6.99-1.1,0-.94-.81-1.92-.81-.74,0-.45.81-1.8.81Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Truck;
