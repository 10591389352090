import SupportPageTable from '../SupportPageTable/SupportPageTable';

function UploadSupport() {
  const topics = [
    {
      q: 'What are the qualifications for becoming a Seller? ',
      a: 'There is no separate qualification for becoming a seller. \n Anyone who has a pattern file without defects can become a seller on PLOFIX.'
    },
    {
      q: 'Do sellers have to pay Editor fees?',
      a: "If you are only active as a Seller, instead of a Downloader, which means you don't have to use the Editor, then you don't have to pay for Editor fee. "
    },
    {
      q: 'Can uploaded files be edited or deleted?',
      a: 'While you can only edit the unit price and description of the uploaded files, you may also delete it.'
    },
    {
      q: 'The car model of the pattern I want to upload is not in the category, how can I upload it?',
      a: 'You can upload by directly entering the category on the upload page.'
    },
    {
      q: 'What happens to the files I uploaded when I unsubscribe?',
      a: 'All uploaded files will be deleted upon withdrawal.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default UploadSupport;
