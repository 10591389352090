/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
// import { useState, useEffect } from 'react';
import { useState, useEffect } from 'react';
import uploadPatternStyles from '../UploadPattern.module.scss';
import Coupe from './CoverageVehicleTypes/Coupe';
import SUV from './CoverageVehicleTypes/SUV';
import Sedan from './CoverageVehicleTypes/Sedan';
import Truck from './CoverageVehicleTypes/Truck';

// { newPatternsParams }
function UploadCoverage({ vehicleType, newPatternsParams }) {
  console.log(vehicleType, newPatternsParams);

  const [checkedParts, setCheckedParts] = useState({
    frontBumper: false,
    frontFender: false,
    headLight: false,
    hood: false,
    sideMirror: false,
    pillar: false,
    door: false,
    rearBumper: false,
    rearFender: false,
    roof: false,
    tailLamp: false
  });

  const subPartIsChecked = (part) =>
    newPatternsParams.filter(
      (param) => param.part?.name?.toLowerCase() === part
    ).length > 0;

  useEffect(() => {
    setCheckedParts({
      fullPackage: subPartIsChecked('full package'),
      frontBumper: subPartIsChecked('front bumper'),
      frontFender: subPartIsChecked('front fender'),
      headlight: subPartIsChecked('headlight'),
      hood: subPartIsChecked('hood'),
      sideMirror: subPartIsChecked('side mirror'),
      pillar: subPartIsChecked('pillar'),
      door: subPartIsChecked('door'),
      rearBumper: subPartIsChecked('rear bumper'),
      rearFender: subPartIsChecked('rear fender'),
      roof: subPartIsChecked('roof'),
      tailLamp: subPartIsChecked('tail lamp'),
      trunk: subPartIsChecked('trunk')
    });
  }, [newPatternsParams]);

  return (
    <div className={uploadPatternStyles.coverage_container}>
      <p className={uploadPatternStyles.coverage_title}>Preview</p>
      <div className={uploadPatternStyles.coverage}>
        {vehicleType === 'Sedan' && <Sedan checkedParts={checkedParts} />}
        {vehicleType === 'SUV' && <SUV checkedParts={checkedParts} />}
        {vehicleType === 'Truck' && <Truck checkedParts={checkedParts} />}
        {vehicleType === 'Coupe' && <Coupe checkedParts={checkedParts} />}
      </div>
    </div>
  );
}

export default UploadCoverage;
