/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import uploadPatternStyles from './UploadPattern.module.scss';
import iconLink from '../../../img/icon_link.svg';
import removeFile from '../../../img/close_new_pattern.svg';
// import {
//   convertUpload,
//   getConvertStatus,
//   getBase64FromConvertId
// } from '../../../api/utils';
// import { urlToFile } from '../../../utils/function';

// const fileTypes = ['SVG', 'AI', 'EPS', 'CDR', 'CDT', 'DWG', 'DXF'];
const fileTypes = ['SVG'];

function DragDropChildren({ file }) {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    if (typeof file !== 'string') {
      const objUrl = URL.createObjectURL(file);
      setPreview(objUrl);
      return;
    }

    setPreview(file);

    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(file);
  }, [file]);

  return file ? (
    <div className={uploadPatternStyles.dragdrop}>
      <img src={preview} alt={file.name} />
    </div>
  ) : (
    <div className={uploadPatternStyles.dragdrop}>
      <p>Drag and Drop or Browse Files</p>
      <button type="button" onClick={() => alert('Only SVG can be uploaded.')}>
        upload
      </button>
    </div>
  );
}

function NewPatternDnd({ id, newPatParams, setNewPatternsParams, editMode }) {
  const handleChange = async (newFile) => {
    const patternImg = new FormData();

    patternImg.append('file', newFile, newFile?.name);

    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return {
            ...paramObj,
            patternImg: newFile
          };
        }

        return paramObj;
      })
    );
    const fileInput = document.getElementsByName('file')[0];
    if (fileInput) {
      console.log('fileInput', fileInput.value);
      fileInput.value = null;
      console.log('fileInput 2', fileInput.value);
    }
  };

  const handleRemoveFile = () => {
    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return {
            ...paramObj,
            patternImg: null
          };
        }

        return paramObj;
      })
    );
  };

  return (
    <>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        disabled={editMode}
        key={
          newPatParams.patternImg
            ? newPatParams.patternImg.name + newPatParams.patternImg.size
            : 'default'
        }>
        <DragDropChildren file={newPatParams.patternImg} />
      </FileUploader>
      <div className={uploadPatternStyles.dragdrop_filename}>
        {newPatParams.patternImg ? (
          <>
            <img
              className={uploadPatternStyles.link_img}
              src={iconLink}
              alt="link"
            />
            <p>
              {newPatParams.patternImg.name ||
                newPatParams.patternImg.replace(
                  'https://inogrow.s3.ap-northeast-2.amazonaws.com/',
                  ''
                )}
            </p>
            {!editMode && (
              <button
                type="button"
                onClick={handleRemoveFile}
                disabled={editMode}>
                <img src={removeFile} alt="remove" />
              </button>
            )}
          </>
        ) : (
          <p>no files uploaded yet</p>
        )}
      </div>
    </>
  );
}

export default NewPatternDnd;
