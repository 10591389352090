/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef, useContext } from 'react';
import {
  // getMyPatterns,
  getMyPatternsApproveStatus
} from '../../../../../api/patterns';
import { getCategories } from '../../../../../api/categories';
import myPatternStyles from '../MyPattern/MyPattern.module.scss';
import PatternList from '../../../../PatternList/PatternList';
import SelectorFilter from '../../../../SelectorFilter/SelectorFilter';
import Pagination from '../../../../Pagination/Pagination';

import AddSponsorModal from './AddSponsorModal';
import ProfitSponsorModal from './ProfitSponsorModal';
import { UserProfileContext } from '../../../../../store/Context';

function SponsorADItem() {
  const { userProfile } = useContext(UserProfileContext) || {};

  const patternsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const [patternDepthCategories, setPatternDepthCategories] = useState([
    {
      checked: false,
      text: 'bikini'
    },
    {
      checked: false,
      text: 'wrapped'
    },
    {
      checked: false,
      text: 'sensors'
    }
  ]);

  const [addSponsorModalState, setAddSponsorModalState] = useState({
    isOpened: false,
    option: null,
    patternId: null
  });
  const [proftSponsorIsOpened, setProfitSponsorIsOpened] = useState(false);

  const [myPatterns, setMyPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [partSectionCategories, setPartSectionCategories] = useState([]);
  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState('');
  const [selectedSideCategoryIds, setSelectedSideCategoryIds] = useState('');
  const [selectedDepthCategoryIds, setSelectedDepthCategoryIds] = useState('');
  const [timeFetch, setTimeFetch] = useState(0);

  const preSelectedSideCategoryIds = useRef();

  const preSelectedMainCategoryId = useRef();

  const searchDepthCategoriesIds = (node, parentIds, categoryName, ids) => {
    if (!node?.subCategory.length) {
      if (
        node.category === categoryName.toLowerCase() &&
        parentIds.split(',').filter((item) => +item === node.parentId).length
      ) {
        ids.push(node.id);
      }
    } else {
      for (const childNode of node.subCategory) {
        searchDepthCategoriesIds(childNode, parentIds, categoryName, ids);
      }
    }
  };

  const fetchDepthCategories = async () => {
    const depthCategoriesRes = await getCategories();

    setPartSectionCategories(
      depthCategoriesRes.filter(
        (category) =>
          category?.column === 'part_section' && category?.type === 'category'
      )
    );
  };

  const fetchMyPats = async (modelIds = '', vehicleIds = '') => {
    let page = currentPage;
    if (vehicleIds && vehicleIds !== preSelectedSideCategoryIds.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (modelIds && modelIds !== preSelectedMainCategoryId.current) {
      setCurrentPage(1);
      page = 1;
    }

    const patternsResponse = await getMyPatternsApproveStatus({
      page,
      limit: patternsOnPage,
      modelIds: modelIds > 0 ? modelIds : '',
      vehicleIds,
      status: 'approved'
    });

    setTotalCount(patternsResponse.count);
    setMyPatterns(patternsResponse.data);
  };

  const handleSuccess = () => {
    fetchMyPats();
  };

  useEffect(() => {
    const selectedDepthCategories = patternDepthCategories
      .reduce((ids, depthCategory) => {
        if (depthCategory?.checked) {
          ids.push(depthCategory.ids);
        }

        return ids;
      }, [])
      .join(',');

    setSelectedDepthCategoryIds(selectedDepthCategories);
  }, [patternDepthCategories]);

  useEffect(() => {
    fetchMyPats(
      selectedMainCategoryId,
      selectedDepthCategoryIds || selectedSideCategoryIds
    );
  }, [
    timeFetch,
    currentPage,
    selectedMainCategoryId,
    selectedSideCategoryIds,
    selectedDepthCategoryIds
  ]);

  useEffect(() => {
    if (partSectionCategories.length) {
      setPatternDepthCategories((prevState) => [
        {
          checked: prevState[0]?.checked,
          text: 'Bikini',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Bikini',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[1]?.checked,
          text: 'Wrapped',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Wrapped',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[2]?.checked,
          text: 'Sensors',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Sensors',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        }
      ]);
    }
  }, [partSectionCategories, selectedSideCategoryIds]);

  useEffect(() => {
    fetchDepthCategories();
  }, []);

  return (
    <>
      <div
        className={myPatternStyles.mypattern_container}
        style={{ marginTop: '65px' }}>
        <div className={myPatternStyles.title}>
          <h2>my pattern list ({totalCount})</h2>
        </div>
        <div className={myPatternStyles.selector_container}>
          <SelectorFilter setSelectedCategory={setSelectedMainCategoryId} />
        </div>
        <div
          className={myPatternStyles.pattern_categories}
          style={{
            width: '100%',
            justifyContent: 'flex-end',
            maxWidth: '1200px',
            margin: '0 auto 20px'
          }}>
          {selectedSideCategoryIds?.length
            ? patternDepthCategories.map((category, index) => (
                <label key={index} htmlFor={category.text}>
                  <input
                    type="checkbox"
                    id={category.text}
                    checked={category.checked}
                    onChange={() =>
                      setPatternDepthCategories((prevState) =>
                        prevState.map((prevCategory) => {
                          if (prevCategory.text === category.text) {
                            return {
                              ...prevCategory,
                              checked: !prevCategory.checked
                            };
                          }

                          return prevCategory;
                        })
                      )
                    }
                  />
                  <span>{category.text}</span>
                </label>
              ))
            : null}
        </div>
        <div className={myPatternStyles.mypatterns_container}>
          <PatternList
            patterns={myPatterns}
            setSelectedSideCategories={setSelectedSideCategoryIds}
            uploaderId={userProfile?.id}
            isSponsorPage
            setAddSponsorModalState={setAddSponsorModalState}
          />
        </div>
        {totalCount > patternsOnPage && (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={patternsOnPage}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
      {addSponsorModalState.isOpened && (
        <AddSponsorModal
          addSponsorModalState={addSponsorModalState}
          setAddSponsorModalState={setAddSponsorModalState}
          setProfitSponsorIsOpened={setProfitSponsorIsOpened}
          setTimeFetch={setTimeFetch}
        />
      )}
      {proftSponsorIsOpened && (
        <ProfitSponsorModal
          addSponsorModalState={addSponsorModalState}
          setProfitSponsorIsOpened={setProfitSponsorIsOpened}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
}

export default SponsorADItem;
