import modalStyles from './Modal.module.scss';

function ModalConfirm({ setModalIsOpened, onConfirm }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h1>WITHDRAWAL</h1>
        <p>Are you sure you want to delete your account?</p>
        <div className={modalStyles.group_btn}>
          <button
            className={modalStyles.no}
            onClick={() => setModalIsOpened(false)}
            type="button">
            No
          </button>
          <button className={modalStyles.yes} onClick={onConfirm} type="button">
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalConfirm;
