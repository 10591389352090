import modalStyles from './Modal.module.scss';

function ModalNotify({ setModalIsOpened }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h1>WITHDRAWAL</h1>
        <p>Your account has been deleted successfully.</p>
        <div className={modalStyles.group_btn}>
          <button
            className={modalStyles.yes}
            onClick={() => setModalIsOpened(false)}
            type="button">
            confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalNotify;
