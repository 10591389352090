/* eslint-disable no-alert */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addDecimalNumber,
  formatNumber,
  roundDecimalNumber,
  subtractDecimalNumber
} from '../../../utils/function';
import PaypalPurchaseProduct from '../../Paypal/PaypalPurchaseProduct/PaypalPurchaseProduct';
import detailsStyles from './ShopOrder.module.scss';
import ShopOrderContext from './Context/Context';
import {
  changeMultiValuePartPrice,
  changeMultiValues,
  changePartPrice,
  changeValues,
  handleValidate
} from './Context/reducer';

import { calculateOrder, createOrder } from '../../../api/order';
import { recallGetBalance } from '../../../store/actions/credit';
import { decCartItems, getCartItems } from '../../../store/actions/cart';
import { getMyCart } from '../../../api/ultrafitShop';
// import productImg from '../../../img/shop-product.png';
import { useStore } from '../../../store/hooks';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';

function OrderDetails({ listProduct, showToast }) {
  const [state, dispatch] = useContext(ShopOrderContext);
  const [stateStore, dispatchStore] = useStore();
  const { values, errors, partPrice } = state;

  const preShippingCountry = useRef();

  const navigate = useNavigate();

  // console.log('partPrice', partPrice);

  // useEffect(() => {
  //   const productPrice = listProduct.reduce(
  //     (result, currItem) => result + currItem.price *
  // (currItem.quantity ?? 1),
  //     0
  //   );
  //   dispatch(changePartPrice({ name: 'itemSubtotal', value: productPrice }));
  // }, [listProduct]);

  const fetchCartsLength = async () => {
    try {
      const myCartRes = await getMyCart();

      const carts = myCartRes?.orderDetail ?? [];
      let cartsLength = 0;
      if (carts?.length > 0) {
        cartsLength = carts?.reduce(
          (prevVal, currVal) => prevVal + currVal.quantity,
          0
        );
      }
      dispatchStore(
        getCartItems({
          length: cartsLength,
          listCart: carts
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // console.log('listProduct', listProduct);
    async function fetchData() {
      const orderDetail = listProduct.map((product) => ({
        productId: product.id,
        quantity: product.quantity ?? 1,
        price: product.price,
        inches: product?.optionSelected?.inches ?? ''
      }));
      dispatch(changeValues({ name: 'orderDetail', value: orderDetail }));
      let body = {
        orderDetail
      };
      if (values.shippingCountry) {
        body = { ...body, shippingCountry: values.shippingCountry };
      }
      if (values.couponId) {
        body = { ...body, couponId: values.couponId };
      }

      try {
        const response = await calculateOrder(body);

        dispatch(
          changeMultiValuePartPrice({
            discount: response.discountAmount,
            itemSubtotal: response.totalAmount
            // shipping: response.shippingFee
          })
        );

        if (response.shippingFee === -1) {
          dispatch(changeValues({ name: 'shippingError', value: true }));
          dispatch(changePartPrice({ name: 'shipping', value: 0 }));
          // dispatch(changeValues({ name: 'showModal', value: true }));
        } else {
          dispatch(changeValues({ name: 'shippingError', value: false }));
          dispatch(
            changePartPrice({ name: 'shipping', value: response.shippingFee })
          );
        }
      } catch (error) {
        // console.log(error.message === 'Region not found');
        if (error.message === 'Region not found') {
          dispatch(changeValues({ name: 'shippingError', value: true }));
        }
        dispatch(
          changeMultiValuePartPrice({
            shipping: 0
          })
        );
      }
    }
    // console.log('listProduct', listProduct);

    if (listProduct.length > 0) {
      fetchData();
    }
  }, [listProduct, values.shippingCountry, values.couponId]);

  const grandFinal = useMemo(() => {
    // const total = Number(
    //   Number(partPrice.shipping.toString()) +
    //     Number(partPrice.itemSubtotal.toString()) -
    //     Number(partPrice.discount.toString()) -
    //     Number(partPrice.creditUsed.toString()) -
    //     Number(partPrice.profitUsed.toString())
    // );
    let total = addDecimalNumber(partPrice.shipping, partPrice.itemSubtotal);
    total = subtractDecimalNumber(total, partPrice?.discount || 0);
    total = subtractDecimalNumber(total, partPrice?.creditUsed || 0);
    total = subtractDecimalNumber(total, partPrice?.profitUsed || 0);
    return total;
  }, [partPrice]);

  const handleValidateValue = (values1, errors1) => {
    let isErrorNull = false;
    let isErrorEmail = false;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const errorList = Object.keys(errors1).map((item) => {
      if (values1[item].length === 0) {
        return 'null';
      }
      if (
        values1[item] &&
        item === 'customerEmail' &&
        !emailPattern.test(values1[item])
      ) {
        return 'email';
      }
      if (
        values1[item] &&
        item === 'shippingEmail' &&
        !emailPattern.test(values1[item])
      ) {
        return 'email';
      }

      return false;
    });
    dispatch(handleValidate());
    // isErrorEmail = false;
    isErrorEmail = errorList.includes('email');
    isErrorNull = errorList.includes('null');
    return [isErrorNull, isErrorEmail];
  };

  const placeOrder = async () => {
    try {
      const authToken = window.localStorage.getItem('token');
      const stateOrder = window.localStorage.getItem('state_order');
      const errorsList = handleValidateValue(values, errors);
      if (!authToken) {
        throw new Error('Please Login');
      } else if (errorsList[0]) {
        throw new Error('Please fill data!');
      } else if (errorsList[1]) {
        throw new Error('Please correct email!');
      } else if (grandFinal < 0) {
        throw new Error(
          'Please check the amount of credits/profits you have used!'
        );
      } else if (values.shippingError) {
        dispatch(changeValues({ name: 'showModal', value: true }));
        return;
      }
      let body = {
        // orderId: data.orderID,
        totalAmount: roundDecimalNumber(
          partPrice.itemSubtotal + partPrice.shipping - partPrice.discount
        ),
        discountAmount: partPrice.discount,
        // paymentMethod: 'paypal',
        type: 'order',
        customerName: values.customerName,
        customerEmail: values.customerEmail,
        customerPhone: values.customerPhone,
        customerCountry: values.customerCountry,
        customerAddress: values.customerAddress,
        shippingName: values.shippingName,
        shippingEmail: values.shippingEmail,
        shippingPhone: values.shippingPhone,
        shippingAddress1: values.shippingAddress1,
        shippingAddress2: values.shippingAddress2,
        shippingCity: values.shippingCity,
        shippingState: values.shippingState,
        shippingZip: values.shippingZip,
        shippingVat: values.shippingVat,
        totalCredit: Number(partPrice.creditUsed),
        totalProfit: Number(partPrice.profitUsed),
        shippingFee: partPrice.shipping,
        orderDetail: values.orderDetail,
        shippingCountry: values.shippingCountry
      };
      if (values.couponId) {
        body = { ...body, couponId: values.couponId };
      }
      try {
        await createOrder(body);
        // console.log('body', body);
        await fetchCartsLength();
        dispatch(decCartItems(0));
        dispatchStore(recallGetBalance());
        navigate('/order_history');
        showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');
      } catch (error) {
        // throw new Error(error?.message ?? 'Error');
        // alert(error?.message ?? 'Something went wrong. Try again later.');
        showToast(
          TOAST_TYPE.ERROR,
          error?.message ?? 'Something went wrong. Try again later.'
        );
      }
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        error.message || 'Something went wrong. Please try again later.'
      );
    }
  };

  const onClickCC = () => {
    showToast(TOAST_TYPE.WARN, 'this feature is not developed yet');
  };

  return (
    <div className={detailsStyles.details_container}>
      <h2
        className={`${detailsStyles.content_title} ${detailsStyles.content_title_container}`}
        style={{ marginBottom: '20px' }}>
        Order Details
      </h2>
      <div
        className={detailsStyles.details_content}
        style={{ paddingRight: '5px' }}>
        <div
          className={detailsStyles.details_order}
          style={listProduct.length === 3 ? { overflowY: 'scroll' } : {}}>
          {listProduct.length
            ? listProduct.map((item) => (
                <div className={detailsStyles.details_product} key={item.id}>
                  <img src={item.image} alt="product" />
                  <div className={detailsStyles.details_product_info}>
                    <h3>{item?.name ?? ''}</h3>
                    <p className={detailsStyles.product_category}>
                      {item?.category1?.category}
                    </p>
                    <p className={detailsStyles.product_quater}>
                      Quantity: {item?.quantity ?? 1} EA
                    </p>
                    <p className={detailsStyles.product_price}>
                      $ {formatNumber(item?.optionSelected?.amount)}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <h2
        className={`${detailsStyles.content_title} ${detailsStyles.content_title_container}`}
        style={{ marginBottom: '20px' }}>
        Order Summary
      </h2>
      <div
        className={detailsStyles.details_content}
        style={{ marginBottom: '30px' }}>
        <div className={detailsStyles.details_pricing}>
          <h4>Shipping</h4>
          <p>$ {partPrice.shipping}</p>
        </div>
        <div className={detailsStyles.details_pricing}>
          <h4>Item Subtotal</h4>
          <p>$ {partPrice.itemSubtotal}</p>
        </div>
        <div className={detailsStyles.details_pricing}>
          <h4>Coupon</h4>
          <p>-$ {partPrice.discount}</p>
        </div>
        <div className={detailsStyles.details_pricing}>
          <h4>Credits Used</h4>
          <p>-$ {partPrice.creditUsed}</p>
        </div>
        <div className={detailsStyles.details_pricing}>
          <h4>Profit Used</h4>
          <p>-$ {partPrice.profitUsed}</p>
        </div>
      </div>
      <div className={detailsStyles.details_content}>
        <div className={detailsStyles.details_total}>
          <h2>Grand Total</h2>
          <p>$ {grandFinal <= 0 ? 0 : grandFinal}</p>
        </div>
        <p className={detailsStyles.details_warning}>
          ※ You can not cancel your order for products that have been shipped.
        </p>
        <div className={detailsStyles.details_pay_controls}>
          {Number(grandFinal) <= 0 ? (
            <button
              className={detailsStyles.details_paypal_pay_btn}
              type="button"
              form="hook-form-banner"
              onClick={placeOrder}>
              PAYMENT
            </button>
          ) : (
            <>
              <PaypalPurchaseProduct price={grandFinal} />
              <button
                type="button"
                onClick={onClickCC}
                className={detailsStyles.details_card_pay_btn}>
                CARD PAYMENT
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withToast(OrderDetails);
