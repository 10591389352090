import { useState } from 'react';
import filterStyles from './StatusFilter.module.scss';
import arrowImg from '../../../../../img/dropdown_arrow.svg';

function StatusFilter({ onChange, listOptions, width = '179px' }) {
  const [selectedTextValue, setSelectedTextValue] = useState('All');
  const [filterIsOpened, setFilterIsOpened] = useState();

  // onClick handler for select filter
  const handleSelectClick = () => {
    setFilterIsOpened(!filterIsOpened);
  };

  // onChange handler for select filter
  const handleSelectChange = (e) => {
    const select = e.target;

    setSelectedTextValue(select.selectedOptions[0].text);
    onChange(select.selectedOptions[0].value);

    setFilterIsOpened(false);
  };

  return (
    <label
      htmlFor="select_status"
      className={filterStyles.filter_label}
      style={{ width }}>
      <button
        onClick={handleSelectClick}
        className={filterStyles.approval_select_btn}
        type="button">
        {selectedTextValue}
        <img
          src={arrowImg}
          alt=""
          style={{ transform: filterIsOpened ? 'rotate(180deg)' : '' }}
        />
      </button>
      {filterIsOpened && (
        <select
          name="select_status"
          multiple
          onChange={handleSelectChange}
          style={{ height: `${37.66 * (listOptions.length - 1) + 60}px` }}>
          {listOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      )}
    </label>
  );
}

export default StatusFilter;
