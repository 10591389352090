import vehicleSelectionStyles from './VehicleSelection.module.scss';

// { setSelectedVehicleType }
function VehicleSelection({ selectedVehicleType, setSelectedVehicleType }) {
  const handleVehicleTypeClick = (e) => {
    setSelectedVehicleType(e.target.value);
  };
  return (
    <div className={vehicleSelectionStyles.vehicle_selection}>
      <p className={vehicleSelectionStyles.vehicle_selection_title}>
        Vehicle Selection
      </p>
      <ul>
        <li>
          <button
            type="button"
            value="Sedan"
            style={
              selectedVehicleType === 'Sedan'
                ? { backgroundColor: '#0046a6', color: '#fff' }
                : {}
            }
            onClick={handleVehicleTypeClick}>
            Sedan (4 Door)
          </button>
        </li>
        <li>
          <button
            type="button"
            value="Coupe"
            style={
              selectedVehicleType === 'Coupe'
                ? { backgroundColor: '#0046a6', color: '#fff' }
                : {}
            }
            onClick={handleVehicleTypeClick}>
            Coupe (2 Door Sedan)
          </button>
        </li>
        <li>
          <button
            type="button"
            value="SUV"
            style={
              selectedVehicleType === 'SUV'
                ? { backgroundColor: '#0046a6', color: '#fff' }
                : {}
            }
            onClick={handleVehicleTypeClick}>
            SUV/VAN
          </button>
        </li>
        <li>
          <button
            type="button"
            value="Truck"
            style={
              selectedVehicleType === 'Truck'
                ? { backgroundColor: '#0046a6', color: '#fff' }
                : {}
            }
            onClick={handleVehicleTypeClick}>
            Truck
          </button>
        </li>
      </ul>
    </div>
  );
}

export default VehicleSelection;
