import SupportPageTable from '../SupportPageTable/SupportPageTable';

function DownloadPlotting() {
  const topics = [
    {
      q: 'Are downloaded files saved?',
      a: 'All of downloaded files are stored in the cloud and can be loaded by ‘Import’, but not keep them externally. '
    },
    {
      q: "If I don't plot, do I have to pay?",
      a: "You will be billed at the same time you plot, so if you don't plot, you don't have to pay. However, you can only save the file to your favorites but you won’t be able to edit it."
    },
    {
      q: 'Can I edit the saved patterns?',
      a: 'You can edit and save the saved files in Editor. The edited files will be saved as Project and can be plotted at any time.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default DownloadPlotting;
