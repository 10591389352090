/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';

import { getPolicy } from '../../api/termsAndPolicy';

import termsAndPolicyStyles from './TermsAndPolicy.module.scss';

function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState({ __html: '' });

  const fetchPolicy = async () => {
    const policyDataRes = await getPolicy('privacy');

    setPrivacyPolicy({ __html: policyDataRes });
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <div className={termsAndPolicyStyles.terms_and_policy_outer}>
      <div
        className={termsAndPolicyStyles.terms_and_policy_inner}
        dangerouslySetInnerHTML={privacyPolicy}
      />
    </div>
  );
}

export default PrivacyPolicy;
