/* eslint-disable no-alert */
// import { useState } from 'react';
import modalStyles from './CreditRefundModal.module.scss';

function CreditRefundModal({ refundAmount, onRefund, onClose }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>Credit Refund</h1>
        <div className={modalStyles.modal_body}>
          <div className={modalStyles.credit_to_be_refund}>
            <p>XX Credits</p>
            <p>{refundAmount} Credits</p>
            <p>
              *Credit refunds are available only within the first seven days for
              the initial payment. Please note that bonus credits are not
              refundable.
            </p>
          </div>
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={() => onClose()}
              className={modalStyles.btn_close}
              type="button">
              Cancel
            </button>
            <button
              onClick={() => onRefund()}
              className={modalStyles.btn_confirm}
              type="button">
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditRefundModal;
