import { useState } from 'react';

import supportTopicCardStyles from './SupportTopicCard.module.scss';

import iconDropDown from '../../../img/dropdown_arrow.svg';

function SupportTopicCard({ title, text }) {
  const [detailIsOpened, setDetailIsOpened] = useState(false);

  const hadnleDetailExposureClick = () => {
    setDetailIsOpened(!detailIsOpened);
  };

  return (
    <div className={supportTopicCardStyles.topic_container}>
      <button
        type="button"
        onClick={hadnleDetailExposureClick}
        style={
          detailIsOpened
            ? {
                borderBottom: '1px solid #ededed'
              }
            : {}
        }>
        <p>{title}</p>
        <img
          className={supportTopicCardStyles.dropdown_icon}
          src={iconDropDown}
          alt="icon_dropdown"
          style={detailIsOpened ? { transform: 'rotate(180deg)' } : {}}
        />
      </button>
      {detailIsOpened && (
        <div className={supportTopicCardStyles.details_content}>
          {text.split('\n').map((par, idx) => (
            <p key={idx}>{par}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default SupportTopicCard;
