/* eslint-disable react/no-danger */
import modalStyles from './TermsAndServiceModal.module.scss';

function TermsAndServiceModal({ title, textInnerHTML, onClose }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>{title}</h1>
        <div
          className={modalStyles.modal_inner_text}
          dangerouslySetInnerHTML={textInnerHTML}
        />
        <hr />
        <button type="button" onClick={onClose}>
          check
        </button>
      </div>
    </div>
  );
}

export default TermsAndServiceModal;
