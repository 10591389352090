/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  recallGetBalance,
  setCreditBalance
} from '../../../store/actions/credit';
import {
  addDay,
  convertDate,
  roundDecimalNumber
} from '../../../utils/function';
import { addPpOrder } from '../../../api/transaction';
import ShopOrderContext from '../../UltrafitShop/ShopOrder/Context/Context';
import {
  changeValues,
  handleValidate
} from '../../UltrafitShop/ShopOrder/Context/reducer';
import { createOrder } from '../../../api/order';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { getMyCart } from '../../../api/ultrafitShop';
import { getCartItems, incCartItems } from '../../../store/actions/cart';
import { useStore } from '../../../store/hooks';

function PaypalPurchaseProduct({ showToast }) {
  const navigate = useNavigate();

  const [state, dispatch] = useContext(ShopOrderContext);
  const [stateStore, dispatchStore] = useStore();

  const fetchCartsLength = async () => {
    try {
      const myCartRes = await getMyCart();

      const carts = myCartRes?.orderDetail ?? [];
      let cartsLength = 0;
      if (carts?.length > 0) {
        cartsLength = carts?.reduce(
          (prevVal, currVal) => prevVal + currVal.quantity,
          0
        );
      }
      dispatchStore(
        getCartItems({
          length: cartsLength,
          listCart: carts
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // console.log(state);

  // const { values: value1, partPrice } = state;

  const paypal = useRef();

  // const valuesRef = useRef();
  // const partPriceRef = useRef();

  //   console.log('authToken', authToken);
  //   console.log('price', price);

  useEffect(() => {
    window.localStorage.setItem('state_order', JSON.stringify(state));
  }, [state]);

  const handleValidateValue = (values, errors) => {
    let isErrorNull = false;
    let isErrorEmail = false;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const errorList = Object.keys(errors).map((item) => {
      if (values[item].length === 0) {
        return 'null';
      }
      if (
        values[item] &&
        item === 'customerEmail' &&
        !emailPattern.test(values[item])
      ) {
        return 'email';
      }
      if (
        values[item] &&
        item === 'shippingEmail' &&
        !emailPattern.test(values[item])
      ) {
        return 'email';
      }

      return false;
    });
    dispatch(handleValidate());
    // isErrorEmail = false;
    isErrorEmail = errorList.includes('email');
    isErrorNull = errorList.includes('null');
    return [isErrorNull, isErrorEmail];
  };

  useEffect(() => {
    const authToken = localStorage.getItem('token');
    if (window.paypalBtn) window.paypalBtn.close();
    window.paypalBtn = window.paypal?.Buttons({
      style: {
        layout: 'horizontal',
        label: 'paypal',
        color: 'blue',
        shape: 'pill',
        height: 55,
        width: 217,
        tagline: 'false'
      },
      createOrder: async (data, actions) => {
        const stateOrder = window.localStorage.getItem('state_order');
        const { values, errors, partPrice } = JSON.parse(stateOrder);
        const paypalPrice = Number(
          partPrice.shipping +
            partPrice.itemSubtotal -
            partPrice.discount -
            partPrice.creditUsed -
            partPrice.profitUsed
        );
        const errorsList = handleValidateValue(values, errors);
        if (!authToken) {
          throw new Error('Please Login');
        } else if (errorsList[0]) {
          throw new Error('Please fill data!');
        } else if (errorsList[1]) {
          throw new Error('Please correct email!');
        } else if (values.shippingError) {
          // return;
          throw new Error('Cannot delivery!');
        }
        if (paypalPrice < 0) {
          throw new Error('Paypal price must be a positive number!');
        }
        if (paypalPrice === 0) {
          let body = {
            // orderId: data.orderID,
            totalAmount: roundDecimalNumber(
              partPrice.itemSubtotal + partPrice.shipping - partPrice.discount
            ),
            discountAmount: partPrice.discount,
            // paymentMethod: 'paypal',
            type: 'order',
            customerName: values.customerName,
            customerEmail: values.customerEmail,
            customerPhone: values.customerPhone,
            customerCountry: values.customerCountry,
            customerAddress: values.customerAddress,
            shippingName: values.shippingName,
            shippingEmail: values.shippingEmail,
            shippingPhone: values.shippingPhone,
            shippingAddress1: values.shippingAddress1,
            shippingAddress2: values.shippingAddress2,
            shippingCity: values.shippingCity,
            shippingState: values.shippingState,
            shippingZip: values.shippingZip,
            shippingVat: values.shippingVat,
            totalCredit: Number(partPrice.creditUsed),
            totalProfit: Number(partPrice.profitUsed),
            shippingFee: partPrice.shipping,
            orderDetail: values.orderDetail,
            shippingCountry: values.shippingCountry
          };
          if (values.couponId) {
            body = { ...body, couponId: values.couponId };
          }
          try {
            await createOrder(body);
            await fetchCartsLength();
            dispatchStore(recallGetBalance());
            navigate('/order_history');
            showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');
          } catch (error) {
            throw new Error(
              error.message || 'Something went wrong. Please try again'
            );
          }
        }
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: roundDecimalNumber(paypalPrice)
              }
            }
          ]
        });
      },
      onApprove: async function (data, actions) {
        const stateOrder = window.localStorage.getItem('state_order');
        const { values, errors, partPrice } = JSON.parse(stateOrder);
        let body = {
          orderId: data.orderID,
          totalAmount: roundDecimalNumber(
            partPrice.itemSubtotal + partPrice.shipping - partPrice.discount
          ),
          discountAmount: partPrice.discount,
          // paymentMethod: 'paypal',
          type: 'order',
          customerName: values.customerName,
          customerEmail: values.customerEmail,
          customerPhone: values.customerPhone,
          customerCountry: values.customerCountry,
          customerAddress: values.customerAddress,
          shippingName: values.shippingName,
          shippingEmail: values.shippingEmail,
          shippingPhone: values.shippingPhone,
          shippingAddress1: values.shippingAddress1,
          shippingAddress2: values.shippingAddress2,
          shippingCity: values.shippingCity,
          shippingState: values.shippingState,
          shippingZip: values.shippingZip,
          shippingVat: values.shippingVat,
          totalCredit: Number(partPrice.creditUsed),
          totalProfit: Number(partPrice.profitUsed),
          shippingFee: partPrice.shipping,
          orderDetail: values.orderDetail,
          shippingCountry: values.shippingCountry
        };
        if (values.couponId) {
          body = { ...body, couponId: values.couponId };
        }
        try {
          await createOrder(body);
          await fetchCartsLength();
          dispatchStore(recallGetBalance());
          navigate('/order_history');
          showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');
        } catch (error) {
          showToast(
            TOAST_TYPE.ERROR,
            error.message || 'Something went wrong. Please try again later.'
          );
        }
      },
      onError: (error) => {
        if (error.message === 'Cannot delivery!') {
          dispatch(changeValues({ name: 'showModal', value: true }));
        } else {
          showToast(
            TOAST_TYPE.ERROR,
            error.message || 'Something went wrong. Please try again later.'
          );
        }
      }
    });
    window.paypalBtn.render(paypal.current);
    // .render(paypal.current);
  }, []);

  return <div ref={paypal}></div>;
}

export default withToast(PaypalPurchaseProduct);
