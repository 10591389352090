import { useState } from 'react';
import searchIcon from '../../../img/icon_search.svg';
import searchStyles from './ShopSearch.module.scss';

function ShopSearch({ onSearch = () => {} }) {
  const [searchInputValue, setSearchInputValue] = useState('');

  return (
    <form className={searchStyles.search}>
      <img src={searchIcon} alt="" />
      <input
        name="shop_search"
        type="text"
        placeholder="Product search…"
        value={searchInputValue}
        onChange={(e) => setSearchInputValue(e.target.value)}
      />
      <button type="button" onClick={() => onSearch(searchInputValue)}>
        Search
      </button>
    </form>
  );
}

export default ShopSearch;
