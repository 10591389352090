import modalStyles from './ReviewModal.module.scss';
// import {
//   formatDate,
//   generateImageSrc,
//   formatNumber
// } from '../../../../utils/function';

function ReviewModal({
  setModalIsOpen,
  handleRegisterReview,
  review,
  setReview
}) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>Write a review</h1>
        <div className={modalStyles.modal_body}>
          <p>Comment</p>
          <textarea
            type="text"
            placeholder="Enter..."
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={() => {
                setModalIsOpen(false);
                setReview('');
              }}
              className={modalStyles.btn_close}
              type="button">
              Cancel
            </button>
          </div>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={() => handleRegisterReview()}
              className={modalStyles.btn_register}
              type="button">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewModal;
