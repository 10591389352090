/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';

import { LoginStateContext } from '../../store/Context';

import PatternCardUser from './PatternCardUser';
import SponsoredMark from './SponsoredMark';
import patternCardsStyles from './PatternCard.module.scss';
import markedImg from '../../img/marked.svg';
import iconDisable from '../../img/icon_disable.png';
import patternImg from '../../img/pattern.png';
import plusImg from '../../img/icon_plus.svg';
import profileImg from '../../img/icon_account.svg';
import PatternSponsorPeriod from './PatternSponsorPeriod';
import { addToFavorites } from '../../api/patterns';
import { editorUrl } from '../../utils/editorURL';

import {
  renderDate,
  filterByColumn,
  renderNumberToFixed,
  renderNickname
} from '../../utils/function';
import PatternCardUserRank from './PatternCardUserRank';

// main component
function PatternCard({
  card,
  projectId,
  patternId,
  isSponsorPage,
  setAddSponsorModalState,
  myProjectPage,
  type = 'pattern',
  isMyPatternPage,
  isUploaderPage,
  isPatternCollection,
  uploaderId,
  onDelete,
  onRemoveBookmark,
  isBookmarkPage,
  onStopSelling
}) {
  const loginState = useContext(LoginStateContext);
  // const [favPatterns, setFavPatterns] = useState([]);
  const [favMarked, setFavMarked] = useState(
    +card?.liked || type === 'bookmark'
  );
  const [hoverModalIsOpened, setHoverModalIsOpened] = useState(false);
  const navigate = useNavigate();

  // favourite mark bg color
  const favActive = {
    backgroundColor: '#ff6900'
  };
  const favInactive = {
    backgroundColor: '#ededed'
  };

  // onClick handler that marks favourite pattern
  const handleFavMarkedClick = async () => {
    if (!loginState || !loginState.isLoggedIn) {
      navigate('/auth?fromPattern');
      return;
    }

    const addToFavRes = await addToFavorites(card.patternId);

    if (addToFavRes.errorStatus) {
      alert('Something went wrong. Try again later.');
      return;
    }

    if (isBookmarkPage) {
      onRemoveBookmark();
    }

    if (!favMarked) {
      setFavMarked(true);
      return;
    }

    setFavMarked(false);
  };

  // onClick handler to go for clicked pattern details
  const handleDetailsNavigate = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (isMyPatternPage) {
      navigate(`/sell/patternId/${card.patternId}`);
      return;
    }

    if (isSponsorPage || isPatternCollection) {
      return;
    }

    if (isUploaderPage) {
      navigate(`/pattern/uploaderId/${uploaderId}/patternId/${card.patternId}`);
      return;
    }

    navigate(`/pattern/patternId/${card.patternId}`);
  };

  // const handleDelete = async () => {
  //   await deleteMyProjectPattern(authToken, {
  //     projectId,
  //     patternId: card.id
  //   });
  // };

  const handleOpenEditor = () => {
    const authToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    window.open(
      `${editorUrl}?fromUserWeb&projectPatternId=${card.id}&projectId=${projectId}&token=${authToken}&refreshToken=${refreshToken}`,
      '_blank'
    );
  };

  useEffect(() => {
    setFavMarked(+card?.liked);
  }, [card?.liked]);

  // card array render
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      onMouseOver={() => setHoverModalIsOpened(true)}
      onMouseLeave={() => setHoverModalIsOpened(false)}
      className={patternCardsStyles.card}
      style={isSponsorPage ? { height: '505px' } : { height: '560px' }}>
      {(isSponsorPage && card?.sponsor && (
        <>
          <div className={patternCardsStyles.card_sponsored}>
            <SponsoredMark sponsored={card.sponsor} />
          </div>
          <div className={patternCardsStyles.upper}>
            <PatternSponsorPeriod sponsor={card.sponsor} />
          </div>
        </>
      )) ||
        (isSponsorPage && !card?.sponsor && (
          <div
            className={patternCardsStyles.upper}
            style={{ justifyContent: 'flex-start' }}>
            <button
              className={patternCardsStyles.add_sponsor}
              type="button"
              onClick={() =>
                setAddSponsorModalState((currentState) => ({
                  ...currentState,
                  isOpened: true,
                  patternId: card.patternId
                }))
              }>
              <img src={plusImg} alt="plus" />
              <p>Add sponsor AD</p>
            </button>
          </div>
        ))}
      {!isSponsorPage && (
        <>
          {card?.sponsor ? (
            <div className={patternCardsStyles.card_sponsored}>
              <SponsoredMark sponsored={card.sponsor} />
            </div>
          ) : null}
          <div className={patternCardsStyles.upper}>
            {!isPatternCollection && (
              <span className={patternCardsStyles.upload_date}>
                Upload date: {renderDate(card?.createdAt)}
                {/* {renderDate(myProjectPage ? card.uploadAt : card.createdAt)} */}
              </span>
            )}
            {!myProjectPage && (
              <button
                type="button"
                onClick={handleFavMarkedClick}
                className={patternCardsStyles.marked}
                style={favMarked ? favActive : favInactive}>
                <img src={markedImg} alt="fav mark" />
              </button>
            )}
          </div>
        </>
      )}
      <button
        type="button"
        onClick={handleDetailsNavigate}
        className={patternCardsStyles.card_pattern_button}>
        <div
          className={patternCardsStyles.card_pattern_container}
          onClick={type === 'bookmark' ? handleDetailsNavigate : undefined}>
          {card?.svg && (
            <img
              className={patternCardsStyles.card_pattern}
              src={card?.svg || patternImg}
              alt="pattern"
            />
          )}
        </div>
        <div
          className={patternCardsStyles.card_text}
          style={myProjectPage ? { marginTop: '10px' } : {}}>
          <h2 className={patternCardsStyles.card_title}>
            {card?.models?.length > 0
              ? `${
                  filterByColumn(card.models, 'region').length > 0
                    ? `${filterByColumn(card.models, 'region')}`
                    : ''
                } ${
                  filterByColumn(card.models, 'year').length > 0
                    ? ` > ${filterByColumn(card.models, 'year')}`
                    : ''
                } ${
                  filterByColumn(card.models, 'brand').length > 0
                    ? ` > ${filterByColumn(card.models, 'brand')}`
                    : ''
                } ${
                  filterByColumn(card.models, 'model').length > 0
                    ? ` > ${filterByColumn(card.models, 'model')}`
                    : ''
                } ${
                  filterByColumn(card.models, 'sub_model').length > 0
                    ? ` > ${filterByColumn(card.models, 'sub_model')}`
                    : ''
                } ${
                  filterByColumn(card.models, 'series').length > 0
                    ? ` > ${filterByColumn(card.models, 'series')}`
                    : ''
                }`
              : null}
          </h2>
          <h3 className={patternCardsStyles.card_subtitle}>
            {card?.vehicles?.length > 0
              ? `${
                  filterByColumn(card.vehicles, 'part_section').length > 0
                    ? `${filterByColumn(card.vehicles, 'part_section')}`
                    : ''
                } ${
                  filterByColumn(card.vehicles, 'pattern_part').length > 0
                    ? `> ${filterByColumn(card.vehicles, 'pattern_part')}`
                    : ''
                } ${
                  filterByColumn(card.vehicles, 'sub_part').length > 0
                    ? `> ${filterByColumn(card.vehicles, 'sub_part')}`
                    : ''
                }`
              : null}
          </h3>
        </div>
      </button>
      <hr className={patternCardsStyles.upper_hr} />
      {!myProjectPage ? (
        <div className={patternCardsStyles.card_info}>
          <div className={patternCardsStyles.card_rating}>
            <Rating
              ratingValue={+card?.ratingAvg * 20}
              readonly
              size={20}
              emptyColor="#ededed"
              fillColor="#ffd500"
            />
          </div>
          <div className={patternCardsStyles.card_details}>
            <span className={patternCardsStyles.card_price}>
              {(Number(card?.unitPrice) * Number(card?.sqft)).toFixed(2)}{' '}
              Credits
            </span>
            <span className={patternCardsStyles.card_downloads}>
              {card?.downloads || 0} downloads
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className={patternCardsStyles.card_project_info}>
            <div className={patternCardsStyles.card_project_info_item}>
              <span className={patternCardsStyles.card_project_info_title}>
                Registered area
              </span>
              <span className={patternCardsStyles.card_project_info_value}>
                {renderNumberToFixed(card?.sqft)} Sq.ft (
                {renderNumberToFixed(card?.sqft, 0.09290304)} Sq.m)
              </span>
            </div>
            <div className={patternCardsStyles.card_project_info_item}>
              <span className={patternCardsStyles.card_project_info_title}>
                Unit price
              </span>
              <span className={patternCardsStyles.card_project_info_value}>
                {renderNumberToFixed(card?.unitPrice)} Credits (per 1Sq.ft)
              </span>
            </div>
            <div className={patternCardsStyles.card_project_info_item}>
              <span className={patternCardsStyles.card_project_info_title}>
                Total price
              </span>
              <span className={patternCardsStyles.card_project_info_value}>
                {renderNumberToFixed(card?.price)} Credits
              </span>
            </div>
          </div>
          <hr className={patternCardsStyles.lower_hr} />
          <div className={patternCardsStyles.card_project_uploader_info}>
            <div className={patternCardsStyles.card_author}>
              <img src={card?.user?.avatar || profileImg} alt="pfp" />
              <span className="card_username">
                by{' '}
                {card?.user?.nickName
                  ? renderNickname(card?.user?.nickName, 14)
                  : ''}
              </span>
            </div>
            {!isPatternCollection && (
              <Rating
                ratingValue={+card?.ratingAvg * 20}
                readonly
                size={20}
                emptyColor="#ededed"
                fillColor="#ffd500"
              />
            )}

            <PatternCardUserRank
              className={patternCardsStyles.card_project_user_rank}
              rank={card?.user?.level}
            />
          </div>
        </>
      )}
      {!isSponsorPage && !myProjectPage && <PatternCardUser user={card} />}
      {isMyPatternPage && card?.status === 'stop_selling' && (
        <div className={patternCardsStyles.card_menu}>
          <button
            type="button"
            onClick={() => onStopSelling(card?.reason || '')}
            className={patternCardsStyles.menu_stop_selling_btn}>
            stop selling
          </button>
        </div>
      )}
      {myProjectPage &&
        !isPatternCollection &&
        hoverModalIsOpened &&
        !card?.deletedAt &&
        card?.status === 'approved' && (
          <div className={patternCardsStyles.card_menu}>
            <button
              type="button"
              className={patternCardsStyles.menu_editor_btn}
              onClick={handleOpenEditor}>
              open in editor
            </button>
            <button
              type="button"
              onClick={() => onDelete(projectId, patternId)}
              className={patternCardsStyles.menu_delete_btn}>
              delete
            </button>
          </div>
        )}
      {myProjectPage &&
        (card?.deletedAt || card?.status !== 'approved') &&
        hoverModalIsOpened &&
        !isPatternCollection && (
          <div className={patternCardsStyles.card_menu}>
            <img src={iconDisable} alt="icon_disable" />
            <p>DELETED BY UPLOADER</p>
            <p>* Deleted history will disappear after 1 month.</p>
            <button
              type="button"
              onClick={() => onDelete(projectId, patternId)}
              className={patternCardsStyles.menu_delete_btn}>
              delete
            </button>
          </div>
        )}
    </div>
  );
}

export default PatternCard;
