/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';

import supportCardStyles from './SupportCard.module.scss';

import iconDropDown from '../../../img/dropdown_arrow.svg';

function SupportCard({ item }) {
  const [detailIsOpened, setDetailIsOpened] = useState(false);

  useEffect(() => {
    setDetailIsOpened(false);
  }, [item]);

  const hadnleDetailExposureClick = () => {
    setDetailIsOpened(!detailIsOpened);
  };

  const renderAnswers = () => {
    const objectKeys = Object.keys(item);
    const objectKeyLength = Object.keys(item).length;
    if (objectKeyLength === 4 && objectKeys.includes('list')) {
      return (
        <ul>
          {item.list.map((listItem) => (
            <li key={listItem}>{listItem}</li>
          ))}
        </ul>
      );
    }
    if (objectKeyLength === 4 && objectKeys.includes('text')) {
      return <p>{item.text}</p>;
    }
    if (
      objectKeyLength === 5 &&
      objectKeys.includes('title') &&
      objectKeys.includes('text')
    ) {
      return (
        <>
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </>
      );
    }
    if (
      objectKeyLength === 6 &&
      objectKeys.includes('title') &&
      objectKeys.includes('text') &&
      objectKeys.includes('list')
    ) {
      return (
        <>
          <h3>{item.title}</h3>
          <p>{item.text}</p>
          <ul>
            {item.list.map((listItem) => (
              <li key={listItem}>{listItem}</li>
            ))}
          </ul>
        </>
      );
    }

    if (
      objectKeyLength === 6 &&
      objectKeys.includes('title') &&
      objectKeys.includes('text') &&
      objectKeys.includes('text2')
    ) {
      return (
        <>
          <p>{item.text}</p>
          <h3>{item.title}</h3>
          <p>{item.text2}</p>
        </>
      );
    }
    if (
      objectKeyLength === 7 &&
      objectKeys.includes('title1') &&
      objectKeys.includes('text1') &&
      objectKeys.includes('title2') &&
      objectKeys.includes('text2')
    ) {
      return (
        <>
          <h3>{item.title1}</h3>
          <p>{item.text1}</p>
          <h3>{item.title2}</h3>
          <p>{item.text2}</p>
        </>
      );
    }
  };

  return (
    <div className={supportCardStyles.topic_container}>
      <button
        type="button"
        onClick={hadnleDetailExposureClick}
        style={
          detailIsOpened
            ? {
                borderBottom: '1px solid #ededed'
              }
            : {}
        }>
        <p id={item.id}>{item.heading}</p>
        <img
          className={supportCardStyles.dropdown_icon}
          src={iconDropDown}
          alt="icon_dropdown"
          style={detailIsOpened ? { transform: 'rotate(180deg)' } : {}}
        />
      </button>
      {detailIsOpened && (
        <div className={supportCardStyles.details_content}>
          {renderAnswers()}
        </div>
      )}
    </div>
  );
}

export default SupportCard;
