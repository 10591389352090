/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  getCategories,
  getUltrafitCategoriesCount
} from '../../../api/categories';
import sideFilterStyles from './ShopSideFilter.module.scss';

function ShopSideFilter({ onChange, allCategory }) {
  const [ultrafitCategories, setUltrafitCategories] = useState([]);
  const [ultrafitCategoriesCount, setUltrafitCategoriesCount] = useState([]);

  const transformDepth3Category = (depth3Category, parentCategory) => {
    if (!isEmpty(depth3Category)) {
      allCategory.current.set(depth3Category.id, parentCategory);
    }
    return {
      ...depth3Category,
      isChecked: false
    };
  };

  const transformDepth2Category = (depth2Category, parentCategory) => {
    if (!isEmpty(depth2Category)) {
      allCategory.current.set(depth2Category.id, parentCategory);
    }
    return {
      ...depth2Category,
      isChecked: false,
      isOpen: false,
      depth3: depth2Category?.subCategory
        ?.sort((a, b) => a.order - b.order)
        .map((depth3Category) =>
          transformDepth3Category(depth3Category, parentCategory)
        )
    };
  };

  const fetchUltrafitCategories = async () => {
    const ultrafitCategoriesDataRes = await getCategories();
    const countRes = await getUltrafitCategoriesCount();

    setUltrafitCategoriesCount(countRes);
    setUltrafitCategories(() =>
      ultrafitCategoriesDataRes
        .filter((category) => category.column === 'depth_1')
        .sort((a, b) => a.order - b.order)
        .map((category) => {
          if (!isEmpty(category)) {
            allCategory.current.set(category.id, category.category);
          }
          const tranformCategory = {
            ...category,
            isOpen: false,
            isChecked: false,
            depth2: category?.subCategory
              ?.sort((a, b) => a.order - b.order)
              ?.map((depth2Category) =>
                transformDepth2Category(depth2Category, category.category)
              )
          };
          return tranformCategory;
        })
    );
  };

  const handleCategoryClick = (categoryId) => {
    setUltrafitCategories((currentCategories) =>
      currentCategories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            isOpen: !category.isOpen
          };
        }

        return category;
      })
    );
  };

  const handleDepth2CategoryClick = (mainCategoryId, depth2CategoryId) => {
    setUltrafitCategories((currentCategories) =>
      currentCategories.map((category) => {
        if (category.id === mainCategoryId) {
          return {
            ...category,
            depth2: category?.depth2.map((depth2Category) => {
              if (depth2Category.id === depth2CategoryId) {
                return {
                  ...depth2Category,
                  isOpen: !depth2Category.isOpen
                };
              }

              return depth2Category;
            })
          };
        }

        return category;
      })
    );
  };

  const handleMainCategoryCheck = (e, category) => {
    setUltrafitCategories((currentCategories) =>
      currentCategories.map((currentCategory) => {
        if (currentCategory.id === category.id) {
          return {
            ...currentCategory,
            isChecked: e.target.checked
          };
        }

        return currentCategory;
      })
    );
  };

  const handleDepth2CategoryCheck = (e, mainCategory, depth2Category) => {
    setUltrafitCategories((currentCategories) =>
      currentCategories.map((currentCategory) => {
        if (currentCategory.id === mainCategory.id) {
          return {
            ...currentCategory,
            depth2: currentCategory?.depth2.map((currentDepth2Category) => {
              if (currentDepth2Category.id === depth2Category.id) {
                return {
                  ...currentDepth2Category,
                  isChecked: e.target.checked
                };
              }

              return currentDepth2Category;
            })
          };
        }

        return currentCategory;
      })
    );
  };

  const handleDepth3CategoryCheck = (
    e,
    mainCategory,
    depth2Category,
    depth3Category
  ) => {
    setUltrafitCategories((currentCategories) =>
      currentCategories.map((currentCategory) => {
        if (currentCategory.id === mainCategory.id) {
          return {
            ...currentCategory,
            depth2: currentCategory?.depth2.map((currentDepth2Category) => {
              if (currentDepth2Category.id === depth2Category.id) {
                return {
                  ...currentDepth2Category,
                  depth3: currentDepth2Category?.depth3.map(
                    (currentDepth3Categry) => {
                      if (currentDepth3Categry.id === depth3Category.id) {
                        return {
                          ...currentDepth3Categry,
                          isChecked: e.target.checked
                        };
                      }

                      return currentDepth3Categry;
                    }
                  )
                };
              }

              return currentDepth2Category;
            })
          };
        }

        return currentCategory;
      })
    );
  };

  useEffect(() => {
    fetchUltrafitCategories();
  }, []);

  useEffect(() => {
    onChange(ultrafitCategories);
  }, [ultrafitCategories]);

  return (
    <div className={sideFilterStyles.filter_container}>
      <h3>Categories</h3>
      <form>
        {ultrafitCategories && ultrafitCategories.length
          ? ultrafitCategories.map((category) => (
              <div key={category?.id}>
                <label htmlFor={`${category?.category}${category?.id}`}>
                  <input
                    type="checkbox"
                    name={`${category?.category}${category?.id}`}
                    onChange={(e) => handleMainCategoryCheck(e, category)}
                  />
                  <p onClick={() => handleCategoryClick(category?.id)}>
                    {category?.category} (
                    {ultrafitCategoriesCount?.totalCategory1.reduce(
                      (count, category1) => {
                        if (category1?.category_1 === category?.id) {
                          count = category1?.total;
                        }

                        return count;
                      },
                      0
                    )}
                    )
                  </p>
                </label>
                {category?.isOpen && category?.depth2?.length
                  ? category?.depth2?.map((depth2Category) => {
                      if (!depth2Category?.category) return null;

                      return (
                        <div
                          key={depth2Category?.id}
                          className={sideFilterStyles.depth_categories}>
                          <label
                            className={sideFilterStyles.depth2_categories}
                            htmlFor={`${depth2Category?.category}${depth2Category?.id}`}>
                            <input
                              type="checkbox"
                              name={`${depth2Category?.category}${depth2Category?.id}`}
                              onChange={(e) =>
                                handleDepth2CategoryCheck(
                                  e,
                                  category,
                                  depth2Category
                                )
                              }
                            />
                            <p
                              onClick={() =>
                                handleDepth2CategoryClick(
                                  category?.id,
                                  depth2Category?.id
                                )
                              }>
                              {depth2Category?.category} (
                              {ultrafitCategoriesCount?.totalCategory2.reduce(
                                (count, category2) => {
                                  if (
                                    category2?.category_2 === depth2Category?.id
                                  ) {
                                    count = category2?.total;
                                  }

                                  return count;
                                },
                                0
                              )}
                              )
                            </p>
                          </label>
                          {depth2Category?.isOpen &&
                          depth2Category?.depth3.length
                            ? depth2Category?.depth3.map((depth3Category) => {
                                if (!depth3Category?.category) return null;

                                return (
                                  <label
                                    key={depth3Category?.id}
                                    className={
                                      sideFilterStyles?.depth3_categories
                                    }
                                    htmlFor={`${depth3Category?.category}${depth3Category?.id}`}>
                                    <input
                                      type="checkbox"
                                      name={`${depth3Category?.category}${depth3Category?.id}`}
                                      onChange={(e) =>
                                        handleDepth3CategoryCheck(
                                          e,
                                          category,
                                          depth2Category,
                                          depth3Category
                                        )
                                      }
                                    />
                                    <p>
                                      {depth3Category?.category} (
                                      {ultrafitCategoriesCount?.totalCategory3.reduce(
                                        (count, category3) => {
                                          if (
                                            category3?.category_3 ===
                                            depth3Category?.id
                                          ) {
                                            count = category3?.total;
                                          }

                                          return count;
                                        },
                                        0
                                      )}
                                      )
                                    </p>
                                  </label>
                                );
                              })
                            : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            ))
          : null}
      </form>
    </div>
  );
}

export default ShopSideFilter;
