/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUltrafitById } from '../../../../api/ultrafitShop';

import productInfoStyles from './ShopDetailsInfo.module.scss';

function ShopDetailsInfo() {
  const { productId } = useParams();
  const [product, setProduct] = useState({});

  const fetchData = async () => {
    const ultrafitRes = await getUltrafitById(productId);
    setProduct(ultrafitRes);
  };

  useEffect(() => {
    fetchData();
  }, [productId]);
  return (
    <div
      className={productInfoStyles.info_container}
      dangerouslySetInnerHTML={{ __html: product?.introduction }}
    />
  );
}

export default ShopDetailsInfo;
