import classNames from 'classnames';
import { setRank } from '../../utils/function';
import patternCardsStyles from './PatternCard.module.scss';

function PatternCardUserRank({ className, rank, renderRankName }) {
  return (
    <div
      className={classNames(patternCardsStyles.card_rank, {
        [className]: className
      })}>
      <img src={setRank(rank)} alt="rank" />
      {renderRankName && <span>{rank}</span>}
    </div>
  );
}

export default PatternCardUserRank;
