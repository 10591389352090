import descriptionStyles from './Description.module.scss';
import descrnBg1 from '../../img/description1.png';
import descrBg2 from '../../img/description2.png';
import descrBg3 from '../../img/description3.png';
import bedgeIcon from '../../img/descr_bedge.svg';
import pentoolIcon from '../../img/descr_pentool.svg';
import moneyIcon from '../../img/descr_money.svg';
import descrTextBg from '../../img/descr_text_bg.png';

function Description() {
  return (
    <section className={descriptionStyles.descr}>
      <div className={descriptionStyles.bg_holder}>
        <img src={descrnBg1} alt="bg1" />
      </div>
      <div className={descriptionStyles.text_container_bg}>
        <img
          className={descriptionStyles.text_bg_img}
          src={descrTextBg}
          alt="background"
        />
        <div className={descriptionStyles.text_icon}>
          <img src={bedgeIcon} alt="bedge icon" />
        </div>
        <h4 className={descriptionStyles.subtitle}>
          The largest/first pattern marketplace for automotive film
          professionals in&nbsp;the world.
        </h4>
      </div>
      <div className={descriptionStyles.text_container}>
        <div className={descriptionStyles.text_icon}>
          <img src={pentoolIcon} alt="pentool" />
        </div>
        <h4 className={descriptionStyles.subtitle}>
          Search for thousands of&nbsp;automotive patterns, customize
          to&nbsp;fit your needs, and plot online any film regardless
          of&nbsp;brand!
        </h4>
        <p className={descriptionStyles.par}>
          Patterns are available for viewing free of charge. No fee will be
          applied until the plotting starts.
        </p>
      </div>
      <div className={descriptionStyles.bg_holder}>
        <img src={descrBg2} alt="bg2" />
      </div>
      <div className={descriptionStyles.bg_holder}>
        <img src={descrBg3} alt="bg3" />
      </div>
      <div className={descriptionStyles.text_container_bg}>
        <img
          className={descriptionStyles.text_bg_img}
          src={descrTextBg}
          alt="background"
        />
        <div className={descriptionStyles.text_icon}>
          <img src={moneyIcon} alt="money icon" />
        </div>
        <h4 className={descriptionStyles.subtitle}>
          Easily upload your own patterns and start making money now!
        </h4>
        <p className={descriptionStyles.par}>
          It is very easy to upload your own pattern. Earn money every time
          someone plots your pattern.
        </p>
      </div>
    </section>
  );
}

export default Description;
