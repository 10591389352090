import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getMyProfile, updateProfileMerchantRole } from '../../../api/profile';

import sellPageStyles from '../SellPage.module.scss';
import uploadApplicationStyles from './UploadApplication.module.scss';
import { getPolicy } from '../../../api/termsAndPolicy';

function UploadApplication({ updateUserProfile }) {
  const navigate = useNavigate();
  const [applicationText, setApplicationText] = useState('');

  const fetchApplicationText = async () => {
    const profileData = JSON.parse(sessionStorage.getItem('userProfile'));

    const applicationTextRes = await getPolicy(
      't_c',
      profileData?.country || null
    );

    setApplicationText(applicationTextRes);
  };

  const handleStartClick = async () => {
    await updateProfileMerchantRole();
    const updatedProfile = await getMyProfile();

    updateUserProfile(updatedProfile);
    navigate('/sell/pattern/mypattern');
  };

  useEffect(() => {
    fetchApplicationText();
  }, []);

  return (
    <section className={uploadApplicationStyles.application_content}>
      <h1 className={sellPageStyles.title}>Sell pattern</h1>
      <div className={uploadApplicationStyles.application_container}>
        <p className={uploadApplicationStyles.application_par}>
          {applicationText}
        </p>
        <button
          onClick={handleStartClick}
          type="button"
          className={uploadApplicationStyles.application_start}>
          start
        </button>
      </div>
    </section>
  );
}

export default UploadApplication;
