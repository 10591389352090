/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getPopUpList = (category, type) =>
  customFetch(`${apiUrl}/banners?status=enable&type=${type || ''}`)
    .then((res) => res.json())
    .then((popUpsData) => {
      if (popUpsData.errorStatus) {
        throw new Error(popUpsData.message);
      }

      return popUpsData.data.filter((popup) => {
        if (category) {
          return popup.category === category && popup.status === 'enable';
        }

        return popup.status === 'enable';
      });
    });
