/* eslint-disable react/function-component-definition */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSearchParams } from 'react-router-dom';

function withQuery(Component) {
  return (props) => {
    const [searchParams] = useSearchParams();
    return (
      <Component
        {...props}
        searchParams={Object.fromEntries([...searchParams])}
      />
    );
  };
}

export default withQuery;
