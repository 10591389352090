/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import UploaderRank from './UploaderRank';
import SelectorFilter from '../SelectorFilter/SelectorFilter';
import PatternList from '../PatternList/PatternList';
import Pagination from '../Pagination/Pagination';

import uploaderPageStyles from './UploaderPage.module.scss';
import patternPageStyles from '../PatternPage/PatternPage.module.scss';

import { getProfileById } from '../../api/profile';
import { getPatterns } from '../../api/patterns';
import { getCategories } from '../../api/categories';
import {
  renderDate,
  sortByDate,
  sortPatternsBy,
  renderNickname
} from '../../utils/function';

import profileImg from '../../img/icon_account.svg';
import uploadImg from '../../img/icon_upload.svg';
import downloadImg from '../../img/icon_download.svg';
import countryImg from '../../img/icon_country.svg';
import companyImg from '../../img/icon_company.svg';
import mailImg from '../../img/icon_mail.svg';
import arrow from '../../img/dropdown_arrow.svg';

const mapOrderBy = {
  Newest: 'newest',
  'Best rated': 'best_rated',
  'Most downloaded': 'most_downloaded'
};

function UploaderPage() {
  const { uploaderId } = useParams();

  const patternsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const [patternDepthCategories, setPatternDepthCategories] = useState([
    {
      checked: false,
      text: 'bikini'
    },
    {
      checked: false,
      text: 'wrapped'
    },
    {
      checked: false,
      text: 'sensors'
    }
  ]);

  const [isOpened, setOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Newest');

  const [patterns, setPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [partSectionCategories, setPartSectionCategories] = useState([]);

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(() => {
    const selectedCategoryId = localStorage.getItem('selectedCategoryId') || 0;

    localStorage.removeItem('selectedCategoryId');

    return selectedCategoryId;
  });
  const [selectedSideCategoryIds, setSelectedSideCategoryIds] = useState(0);
  const [selectedDepthCategoryIds, setSelectedDepthCategoryIds] = useState('');

  const [uploaderInfo, setUploaderInfo] = useState();

  const preSelectedSideCategoryIds = useRef(null);

  const preSelectedMainCategoryId = useRef(null);

  const preSelectedValue = useRef();

  const searchDepthCategoriesIds = (node, parentIds, categoryName, ids) => {
    if (!node?.subCategory.length) {
      if (
        node.category === categoryName.toLowerCase() &&
        parentIds.split(',').filter((item) => +item === node.parentId).length
      ) {
        ids.push(node.id);
      }
    } else {
      for (const childNode of node.subCategory) {
        searchDepthCategoriesIds(childNode, parentIds, categoryName, ids);
      }
    }
  };

  const fetchDepthCategories = async () => {
    const depthCategoriesRes = await getCategories();

    setPartSectionCategories(
      depthCategoriesRes.filter(
        (category) =>
          category?.column === 'part_section' && category?.type === 'category'
      )
    );
  };

  const fetchData = async (modelIds, vehicleIds, orderBy) => {
    if (!uploaderInfo) {
      const userData = await getProfileById(uploaderId);
      setUploaderInfo(userData);
    }

    let page = currentPage;
    if (vehicleIds && vehicleIds !== preSelectedSideCategoryIds.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (modelIds && modelIds !== preSelectedMainCategoryId.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (orderBy && orderBy !== preSelectedValue.current) {
      setCurrentPage(1);
      page = 1;
    }

    const patternsResponse = await getPatterns({
      uploaderId,
      page,
      limit: patternsOnPage,
      modelIds: modelIds > 0 ? modelIds : '',
      vehicleIds,
      orderBy: mapOrderBy[orderBy]
    });
    preSelectedSideCategoryIds.current = vehicleIds;
    preSelectedMainCategoryId.current = modelIds;
    preSelectedValue.current = orderBy;

    setTotalCount(patternsResponse.count);
    setPatterns(patternsResponse.data);
  };

  // onClick handler for "newest, best rated, most downloads" select filter
  const handleSelectClick = () => {
    setOpened(!isOpened);
  };

  // onChange handler for "newest, best rated, most downloads" select filter
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);

    setOpened(false);

    if (e.target.value === 'Newest') {
      sortByDate(patterns, 'createdAt');
    }

    if (e.target.value === 'Best rated') {
      sortPatternsBy(patterns, 'popularity');
    }

    if (e.target.value === 'Most downloaded') {
      sortPatternsBy(patterns, 'downloads');
    }
  };

  useEffect(() => {
    fetchDepthCategories();
  }, []);

  useEffect(() => {
    if (partSectionCategories.length) {
      setPatternDepthCategories((prevState) => [
        {
          checked: prevState[0]?.checked,
          text: 'Bikini',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Bikini',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[1]?.checked,
          text: 'Wrapped',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Wrapped',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[2]?.checked,
          text: 'Sensors',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Sensors',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        }
      ]);
    }
  }, [partSectionCategories, selectedSideCategoryIds]);

  useEffect(() => {
    const selectedDepthCategories = patternDepthCategories
      .reduce((ids, depthCategory) => {
        if (depthCategory?.checked) {
          ids.push(depthCategory.ids);
        }

        return ids;
      }, [])
      .join(',');

    setSelectedDepthCategoryIds(selectedDepthCategories);
  }, [patternDepthCategories]);

  useEffect(() => {
    fetchData(
      selectedMainCategoryId,
      selectedDepthCategoryIds || selectedSideCategoryIds,
      selectedValue
    );
  }, [
    currentPage,
    selectedMainCategoryId,
    selectedSideCategoryIds,
    selectedDepthCategoryIds,
    selectedValue
  ]);

  return (
    <section className={uploaderPageStyles.uploader_section}>
      {uploaderInfo && (
        <div className={uploaderPageStyles.uploader_profile}>
          <div className={uploaderPageStyles.profile_container}>
            <div className={uploaderPageStyles.profile_upper}>
              <div className={uploaderPageStyles.profile_img}>
                <img
                  src={uploaderInfo?.avatar || profileImg}
                  alt="pfp"
                  className={uploaderPageStyles.pfp}
                />
                <UploaderRank rank={uploaderInfo?.level} />
              </div>
              <div className={uploaderPageStyles.profile_info}>
                <p>
                  {uploaderInfo?.nickName
                    ? renderNickname(uploaderInfo?.nickName, 28)
                    : ''}
                </p>
                <span>Join date: {renderDate(uploaderInfo?.createdAt)}</span>
              </div>
              <p className={uploaderPageStyles.profile_uploads}>
                <img src={uploadImg} alt="upload" />
                <span>{uploaderInfo?.uploadTotal} Uploaded</span>
              </p>
              <p className={uploaderPageStyles.profile_downloads}>
                <img src={downloadImg} alt="downloads" />
                <span>{uploaderInfo?.downloadTotal} Downloaded</span>
              </p>
            </div>
            <hr />
            <ul className={uploaderPageStyles.profile_middle}>
              <li className={uploaderPageStyles.profile_country}>
                <img src={countryImg} alt="country" />
                <h4 className={uploaderPageStyles.profile_subtitle}>Country</h4>
                <p>{uploaderInfo?.country}</p>
              </li>
              <li className={uploaderPageStyles.profile_company}>
                <img src={companyImg} alt="company" />
                <h4 className={uploaderPageStyles.profile_subtitle}>Company</h4>
                <p>{uploaderInfo?.companyName}</p>
              </li>
              <li className={uploaderPageStyles.profile_email}>
                <img src={mailImg} alt="mail" />
                <h4 className={uploaderPageStyles.profile_subtitle}>
                  Contact mail
                </h4>
                <p>{uploaderInfo?.email}</p>
              </li>
            </ul>
            <hr />
            <div className={uploaderPageStyles.profile_lower}>
              <h4 className={uploaderPageStyles.profile_subtitle}>
                Seller Informaition
              </h4>
              <p style={{ minHeight: '58px' }}>{uploaderInfo?.introduction}</p>
            </div>
          </div>
        </div>
      )}
      <div className={uploaderPageStyles.uploader_selector}>
        <SelectorFilter
          setSelectedCategory={setSelectedMainCategoryId}
          uplloaderId={uploaderId}
          isUploaderPage
        />
      </div>
      <div className={uploaderPageStyles.uploader_patterns}>
        <div className={patternPageStyles.pattern_title}>
          <h1>Total {totalCount}</h1>
          <div className={patternPageStyles.pattern_categories}>
            {selectedSideCategoryIds?.length
              ? patternDepthCategories.map((category, index) => (
                  <label key={index} htmlFor={category.text}>
                    <input
                      type="checkbox"
                      id={category.text}
                      checked={category.checked}
                      onChange={() =>
                        setPatternDepthCategories((prevState) =>
                          prevState.map((prevCategory) => {
                            if (prevCategory.text === category.text) {
                              return {
                                ...prevCategory,
                                checked: !prevCategory.checked
                              };
                            }

                            return prevCategory;
                          })
                        )
                      }
                    />
                    <span>{category.text}</span>
                  </label>
                ))
              : null}
          </div>
          <label
            htmlFor="select_condition"
            className={patternPageStyles.pattern_dd_filter}>
            <button onClick={handleSelectClick} type="button">
              {selectedValue}
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: isOpened ? 'rotate(180deg)' : '' }}
              />
            </button>
            {isOpened && (
              <select
                onChange={handleSelectChange}
                multiple
                name="select_condition">
                <option value="Newest">Newest</option>
                <option value="Best rated">Best rated</option>
                <option value="Most downloaded">Most downloaded</option>
              </select>
            )}
          </label>
        </div>
        <section className={patternPageStyles.patterns_list}>
          <PatternList
            patterns={patterns}
            setSelectedSideCategories={setSelectedSideCategoryIds}
            isUploaderPage
            uploaderId={uploaderId}
          />
          {totalCount > patternsOnPage && (
            <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={patternsOnPage}
              onPageChange={setCurrentPage}
            />
          )}
        </section>
      </div>
    </section>
  );
}

export default UploaderPage;
