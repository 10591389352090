/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';

import { getPolicy } from '../../api/termsAndPolicy';

import termsAndPolicyStyles from './TermsAndPolicy.module.scss';

function TermsConditions() {
  const [terms, setTerms] = useState({ __html: '' });

  const fetchTerms = async () => {
    const termsDataRes = await getPolicy('terms');

    setTerms({ __html: termsDataRes });
  };

  useEffect(() => {
    fetchTerms();
  }, []);

  return (
    <div className={termsAndPolicyStyles.terms_and_policy_outer}>
      <div
        className={termsAndPolicyStyles.terms_and_policy_inner}
        dangerouslySetInnerHTML={terms}
      />
    </div>
  );
}

export default TermsConditions;
