import sponsoredImg from '../../img/sponsored.png';

// renders SPONSORED mark
function SponsoredMark({ sponsored }) {
  if (sponsored) {
    return <img src={sponsoredImg} alt="sponsored" />;
  }
}

export default SponsoredMark;
