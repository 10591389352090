/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from 'classnames';

import { usePagination, DOTS } from '../hooks/usePagination';

import arrowIcon from '../../img/icon_down.svg';

import paginationStyles from './Pagination.module.scss';

export default function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 3,
  currentPage,
  pageSize,
  className
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === lastPage) return;

    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return;

    onPageChange(currentPage - 1);
  };

  return (
    <ul
      className={classnames(
        paginationStyles.pagination_container,
        className ? { [className]: className } : ''
      )}>
      {paginationRange.length > 10 && (
        <li
          className={classnames(paginationStyles.pagination_item, {
            [paginationStyles.item_disabled]: currentPage === 1
          })}
          onClick={onPrevious}>
          <img src={arrowIcon} alt="" className={paginationStyles.arrow_left} />
        </li>
      )}
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className={paginationStyles.item}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classnames(paginationStyles.pagination_item, {
              [paginationStyles.item_selected]: pageNumber === currentPage
            })}
            onClick={() => onPageChange(+pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      {paginationRange.length > 10 && (
        <li
          className={classnames(paginationStyles.pagination_item, {
            [paginationStyles.item_disabled]: currentPage === lastPage
          })}
          onClick={onNext}>
          <img
            src={arrowIcon}
            alt=""
            className={paginationStyles.arrow_right}
          />
        </li>
      )}
    </ul>
  );
}
