import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '../../../api/categories';

import SelectDropdown from './SelectDropdown';

import patternFilterStyle from './PatternFilter.module.scss';

import iconFilter from '../../../img/icon_filter.png';
import searchIcon from '../../../img/icon_search.png';
import resetIcon from '../../../img/icon_reset.svg';
import { filterPatternsBylargeCategory } from '../../../utils/function';

function PatternFilter({ uploaderId, isUploaderPage, isMyPatternsPage }) {
  const navigate = useNavigate();

  const [largeCategories, setLargeCategories] = useState([]);
  const [largeCategoryFilterOptions, setLargeCategoryFilterOptions] = useState({
    // region: [],
    // year: [],
    // make: [],
    // model: [],
    // subModel: [],
    // series: []
  });

  const [selectedFilterOptions, setSelectedFilterOptions] = useState({
    // region: '',
    // year: '',
    // make: '',
    // model: '',
    // 'subModel': '',
    // series: ''
  });

  const fetchPatternCategories = async () => {
    const patternCategoriesResponse = await getCategories();

    setLargeCategories(
      patternCategoriesResponse.filter(
        (category) =>
          category.column === 'region' && category.type === 'vehicle'
      )
    );
  };

  const handleSearchFilterClick = async () => {
    const selectedCategoryId = filterPatternsBylargeCategory(
      selectedFilterOptions
    );

    localStorage.setItem('selectedCategoryId', selectedCategoryId);

    if (isMyPatternsPage) {
      localStorage.setItem(
        'myPatternsPagePatternDetails',
        JSON.stringify(selectedFilterOptions)
      );

      navigate('/sell/pattern/mypattern');
      return;
    }

    if (isUploaderPage) {
      localStorage.setItem(
        'uploaderPagePatternDetails',
        JSON.stringify(selectedFilterOptions)
      );

      navigate(`/pattern/uploaderId/${uploaderId}`);
      return;
    }

    localStorage.setItem(
      'patternsPagePatternDetails',
      JSON.stringify(selectedFilterOptions)
    );

    navigate('/pattern/list');
  };

  const handleResetClick = () => {
    setSelectedFilterOptions({
      region: null,
      year: null,
      make: null,
      model: null,
      'sub-model': null,
      series: null
    });
  };

  useEffect(() => {
    fetchPatternCategories();

    // if (editMode) {
    //   fetchCurrentPattern();
    // }
  }, []);

  useEffect(() => {
    setLargeCategoryFilterOptions((currentState) => ({
      ...currentState,
      location: largeCategories.map((category) => ({
        id: category.id,
        value: category.category,
        subCategory: category.subCategory
      }))
    }));
  }, [largeCategories]);

  const lagreCategoryFilterEffect = (option, parentValue) => {
    useEffect(() => {
      if (parentValue) {
        setLargeCategoryFilterOptions((currentState) => ({
          ...currentState,
          [option]: parentValue.subCategory.map((subCategory) => ({
            id: subCategory.id,
            value: subCategory.category,
            subCategory: subCategory.subCategory
          }))
        }));
      }
    }, [parentValue]);
  };

  lagreCategoryFilterEffect('year', selectedFilterOptions.location);
  lagreCategoryFilterEffect('make', selectedFilterOptions.year);
  lagreCategoryFilterEffect('model', selectedFilterOptions.make);
  lagreCategoryFilterEffect('sub-model', selectedFilterOptions.model);
  lagreCategoryFilterEffect('series', selectedFilterOptions['sub-model']);

  return (
    <section className={patternFilterStyle.pattern_filter}>
      <div className={patternFilterStyle.title}>
        <img src={iconFilter} alt="" />
        <span>Filter</span>
      </div>
      <div className={patternFilterStyle.select_list}>
        {largeCategoryFilterOptions && (
          <SelectDropdown
            selectedValue={selectedFilterOptions?.location}
            setSelectedFilterOptions={setSelectedFilterOptions}
            category="location"
            options={largeCategoryFilterOptions?.location}
          />
        )}
        <SelectDropdown
          selectedValue={selectedFilterOptions?.year}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="year"
          options={largeCategoryFilterOptions?.year}
          disabled={
            !largeCategoryFilterOptions?.year || !selectedFilterOptions.location
          }
        />
        <SelectDropdown
          selectedValue={selectedFilterOptions?.make}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="make"
          options={largeCategoryFilterOptions?.make}
          disabled={
            !largeCategoryFilterOptions?.make || !selectedFilterOptions.year
          }
        />
        <SelectDropdown
          selectedValue={selectedFilterOptions?.model}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="model"
          options={largeCategoryFilterOptions?.model}
          disabled={
            !largeCategoryFilterOptions?.model || !selectedFilterOptions.make
          }
        />
        <SelectDropdown
          selectedValue={selectedFilterOptions['sub-model']}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="sub-model"
          options={largeCategoryFilterOptions['sub-model']}
          disabled={
            !largeCategoryFilterOptions['sub-model'] ||
            !selectedFilterOptions.model
          }
        />
        <SelectDropdown
          selectedValue={selectedFilterOptions?.series}
          setSelectedFilterOptions={setSelectedFilterOptions}
          category="series"
          options={largeCategoryFilterOptions?.series}
          disabled={
            !largeCategoryFilterOptions?.series ||
            !selectedFilterOptions['sub-model']
          }
        />
      </div>
      <div className={patternFilterStyle.button_group}>
        <button type="button" onClick={handleResetClick}>
          <img src={resetIcon} alt="" />
          <span>Reset</span>
        </button>
        <button type="button" onClick={handleSearchFilterClick}>
          <img src={searchIcon} alt="" />
          <span>Search</span>
        </button>
      </div>
    </section>
  );
}

export default PatternFilter;
