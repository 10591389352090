import uploadPatternStyles from '../UploadPattern.module.scss';
import descriptionStyles from './NewPatternDescription.module.scss';

export default function NewPatternDescription({
  id,
  newPatParams,
  setNewPatternsParams
}) {
  const handleChange = (e) => {
    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return {
            ...paramObj,
            descr: e.target.value
          };
        }

        return paramObj;
      })
    );
  };

  return (
    <div className={descriptionStyles.form_descr}>
      <p className={uploadPatternStyles.form_subtitle}>Description</p>
      <textarea
        onChange={handleChange}
        name="description"
        placeholder="Enter.."
        value={newPatParams.descr || ''}
      />
    </div>
  );
}
