/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import editorSubscriptionStyles from './EditorSubscriptionHistory.module.scss';
// import { cancelSubscription, getPremiumTime2 } from '../../../api/api';
import { renderDate } from '../../../utils/function';
import withToast from '../../HOC/withToast';
import { getPremiumHistory } from '../../../api/transaction';
// import { TOAST_TYPE } from '../../../utils/constants';
import attentionIcon from '../../../img/attention_icon.svg';

// { authToken, showToast }

function EditorSubscriptionHistory() {
  const navigate = useNavigate();

  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  // const [error, setError] = useState(null);

  const fetchData = async () => {
    const premiumHistoryRes = await getPremiumHistory();

    setSubscriptionHistory(premiumHistoryRes);
  };

  const getSubscriptionRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return (
      endDate.getMonth() -
      startDate.getMonth() +
      (endDate.getFullYear() > startDate.getFullYear() ? 12 : 0)
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className={editorSubscriptionStyles.subscription_main}>
      <section className={editorSubscriptionStyles.subscription_section}>
        <h1>EDIT & PLOT SUBSCRIPTION HISTORY</h1>
        <table className={editorSubscriptionStyles.subscription_table}>
          <thead>
            <tr>
              <td>EDIT & PLOT Subscription Packages</td>
              <td>Subscription Period</td>
              <td>Payment method</td>
              <td>Payment date</td>
            </tr>
          </thead>
          <tbody>
            {subscriptionHistory.length ? (
              subscriptionHistory.map((historyItem) => (
                <tr key={historyItem.id}>
                  <td>
                    {getSubscriptionRange(historyItem.start, historyItem.end)}
                    -MONTH SUBSCRIPTION
                  </td>
                  <td>
                    {renderDate(historyItem.start)} ~{' '}
                    {renderDate(historyItem.end)}
                  </td>
                  <td>
                    {historyItem.paymentMethod === null
                      ? 'Free'
                      : historyItem.paymentMethod === 'paypal'
                      ? 'Paypal'
                      : ''}
                  </td>
                  <td>{renderDate(historyItem.createdAt)}</td>
                </tr>
              ))
            ) : (
              <tr className={editorSubscriptionStyles.tr_nodata}>
                <td className={editorSubscriptionStyles.td_nodata}>
                  <img src={attentionIcon} alt="" />
                  <p>There are no sales records.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <button type="button" onClick={() => navigate('/pricing/subscription')}>
          EXTEND SUBSCRIPTION PERIOD
        </button>
      </section>
    </main>
  );
}

export default withToast(EditorSubscriptionHistory);
