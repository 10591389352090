/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  recallGetBalance,
  setCreditBalance
} from '../../../store/actions/credit';
import { useStore } from '../../../store/hooks';
import {
  addDay,
  convertDate,
  roundDecimalNumber
} from '../../../utils/function';
import { buyPremium } from '../../../api/transaction';
import { getMyProfile } from '../../../api/profile';
import { TOAST_TYPE } from '../../../utils/constants';
import withToast from '../../HOC/withToast';
import { UserProfileContext } from '../../../store/Context';
import { useAlert } from '../../hooks/useAlert/useAlert';

function PaypalPricingSubscription({
  option,
  updateProfileOnPayment,
  showToast
}) {
  const navigate = useNavigate();
  const price = option && option?.total ? option?.total() : 0;
  const month = option && option?.month ? option?.month : 0;
  const type = option && option?.type ? option.type : '';
  const patternId = option && option?.patternId ? option.patternId : '';
  const paypal = useRef();
  const [state, dispatch] = useStore();

  const { userProfile } = useContext(UserProfileContext) || {};
  const { onAlert } = useAlert();

  useEffect(() => {
    const authToken = window.localStorage.getItem('token');

    console.log(price);

    if (window.paypalBtn) window.paypalBtn.close();
    window.paypalBtn = window.paypal?.Buttons({
      style: {
        layout: 'horizontal',
        label: 'paypal',
        color: 'blue',
        shape: 'pill',
        height: 55,
        width: 217,
        tagline: 'false'
      },
      // onclick: async function (data, actions) {
      //   console.log('authToken', price);

      // },
      // onInit: function (data, actions) {
      //   // Disable the buttons
      //   // eslint-disable-next-line no-unused-expressions
      //   (!authToken || !price) && actions.disable();
      // },
      //   onClick: async function (data, actions) {
      //     // return actions.reject();
      //     // return false;
      //     if (type === 'sponsor') {
      //       try {
      //         const body = {
      //           amount: price,
      //           start: convertDate(new Date(), true),
      //           end: convertDate(addDay(new Date(), 30), true),
      //           patternId: patternId
      //         };
      //         const res = await buySponsorCredit(authToken, body);
      //         if (res?.status === 'ok') {
      //           return actions.reject();
      //         }
      //       } catch (error) {
      //         console.log(error?.message ?? 'Error');
      //         return actions.reject();
      //       }
      //     }
      //   },
      createOrder: (data, actions) => {
        const currentDate = new Date();
        const subscriptionEndDate = new Date(userProfile?.premiumTime);

        if (!authToken) {
          navigate('/auth');
          return;
        }
        if (!price) {
          onAlert('', 'Okay', 'Please select a package!');
          return;
        }

        // if (userProfile?.premiumTime && currentDate <= subscriptionEndDate) {
        //   const isContinue = confirm(
        //     'You have an unexpired package.
        // Would you like to purchase new package?'
        //   );

        //   if (isContinue) {
        //     return actions.order
        //       .create({
        //         purchase_units: [
        //           {
        //             amount: {
        //               value: roundDecimalNumber(Number(price))
        //             }
        //           }
        //         ]
        //       })
        //       .then((orderID) => {
        //         return orderID;
        //       })
        //       .catch((error) => {
        //         console.error('Error while creating order:', error);
        //       });
        //   } else {
        //     return;
        //   }
        // }
        return actions.order
          .create({
            purchase_units: [
              {
                amount: {
                  value: roundDecimalNumber(Number(price))
                }
              }
            ]
          })
          .then((orderID) => {
            return orderID;
          })
          .catch((error) => {
            console.error('Error while creating order:', error);
          });
      },
      onApprove: async function (data, actions) {
        const body = {
          month: month,
          orderId: data.orderID
        };
        try {
          await buyPremium(body);
          showToast(TOAST_TYPE.SUCCESS, 'Payment has been completed');

          const updatedUserData = await getMyProfile();

          updateProfileOnPayment(updatedUserData);
          // const balanceData = await getBalance(authToken);
          // if (balanceData === null) {
          //   dispatch(setCreditBalance(0));
          // } else {
          //   dispatch(setCreditBalance(balanceData));
          // }

          dispatch(recallGetBalance());
        } catch (error) {
          throw new Error(error?.message ?? 'Error');
        }
      }
      // onError: (error) => {
      //   alert('Please select one package!');
      // }
    });
    window.paypalBtn.render(paypal.current);
    // .render(paypal.current);
  }, [price, type, patternId]);

  return <div ref={paypal}></div>;
}

export default withToast(PaypalPricingSubscription);
