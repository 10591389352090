/* eslint-disable react/no-this-in-sfc */
import { useState, useEffect, useMemo } from 'react';
import { getCreditPacks } from '../../api/creditPacks';
import CreditPack from './CreditPack';
import creditPacksStyles from './CreditPacks.module.scss';
import PaypalPricingCreditPacks from '../Paypal/PaypalPricingCreditPacks/PaypalPricingCreditPacks';

function CreditPacks({ homePage, loginState }) {
  const [packs, setPacks] = useState([]);
  const [selectedPark, setSelectedPark] = useState([]);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCredit = async () => {
      const crPacks = await getCreditPacks();

      setPacks(crPacks);
    };

    fetchCredit();
  }, []);

  useEffect(() => {
    if (selectedPark.length) {
      const sumCost = selectedPark.reduce(
        (result, item) => result + item.cost,
        0
      );
      const items = selectedPark.map((item) => ({
        total: +item.cost,
        productId: item.id
      }));
      setTotal(sumCost);
      setOptions(items);
    }
  }, [selectedPark]);

  const option = useMemo(
    () => ({
      total,
      productId: options.map((item) => item.productId)
    }),
    [options, total]
  );

  return (
    <section className={creditPacksStyles.credit_packs}>
      <div className={creditPacksStyles.container}>
        <h2 className={creditPacksStyles.title}>CREDIT PACKAGES</h2>
        <p className={creditPacksStyles.subtitle}>
          Choose credit plan designed to&nbsp;meet your needs.
        </p>
        <div className={creditPacksStyles.packs_container}>
          {packs
            .map((pack) => (
              <CreditPack
                loginState={loginState}
                homePage={homePage}
                key={pack.id}
                pack={pack}
                selectedPark={selectedPark}
                setSelectedPark={setSelectedPark}
              />
            ))
            .reverse()}
        </div>
        <p className={creditPacksStyles.total}>Total : ${total || 0}</p>
        <div className={creditPacksStyles.payment_controls}>
          <PaypalPricingCreditPacks option={option} />
          <button
            type="button"
            // onClick={handleByWithCardClick}
            className={creditPacksStyles.payment_credit}>
            CARD PAYMENT
          </button>
        </div>
      </div>
    </section>
  );
}

export default CreditPacks;
