import SupportPageTable from '../SupportPageTable/SupportPageTable';

function AccountManagement() {
  const topics = [
    {
      q: 'How do I delete my PLOFIX account?',
      a: 'You can delete your account from “My Page”. However, you cannot re-register with the same username after withdrawal.'
    },
    {
      q: 'How do I change my account information?',
      a: 'You can edit basic information on My page.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default AccountManagement;
