/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';

import { getBalance, refundRequest } from '../../../api/transaction';
import { getProfileCreditHistory } from '../../../api/profile';

import { formatNumber, renderDate } from '../../../utils/function';

import Pagination from '../../Pagination/Pagination';
import InquiryFilter from '../../SellPage/SellPattern/SellPatternOutlets/InquiryFilter/InquiryFilter';
import StatusFilter from '../../SellPage/SellPattern/SellPatternOutlets/StatusFilter/StatusFilter';
import CreditRefundModal from './CreditRefundModal/CreditRefundModal';
import RefundNoticeModal from './RefundNoticeModal/RefundNoticeModal';

import creditHistoryStyles from './CreditHistory.module.scss';

import attentionMark from '../../../img/attention_icon.svg';

import withToast from '../../HOC/withToast';
import { TOAST_TYPE } from '../../../utils/constants';
import CreditRefundReasonModal from './CreditRefundReasonModal/CreditRefundReasonModal';

function renderActivity(activity) {
  // if (activity === 'credit_charge,charge_bonus')
  //   return 'Recharge + Recharge Bonus';

  if (activity === 'code_bonus') return `Product Code Authentication`;

  if (activity === 'reward_bonus') return `Rewards`;

  // if (activity === 'charge_bonus') return 'Recharge Bonus';

  if (activity === 'review') return `Review Bounus Credit`;

  // if (activity === 'credit_ultrafit_shop_refund')
  // return `Place Order Refund`;

  if (activity === 'plotting') return `Plotting`;

  if (activity === 'credit_ultrafit_shop') return `Place Order`;

  if (activity === 'credit_charge_refund') return `Refund`;

  return null;
}

function isRefundable(historyItem) {
  const nonRefundableActivities = [
    'code_bonus',
    'reward_bonus',
    'charge_bonus',
    'review',
    // 'credit_ultrafit_shop_refund',
    'plotting',
    'credit_ultrafit_shop',
    'credit_charge_refund'
  ];

  const currentDate = new Date();
  const sevenDaysPastDate = new Date();
  const historyDate = new Date(historyItem.createdAt);

  currentDate.setHours(23, 59, 59, 999);

  sevenDaysPastDate.setHours(0, 0, 0, 0);
  sevenDaysPastDate.setDate(currentDate.getDate() - 7);

  return (
    sevenDaysPastDate <= historyDate &&
    !nonRefundableActivities.includes(historyItem.activity) &&
    historyDate <= currentDate &&
    !(historyItem?.usedAmount > 0)
  );
}

function CreditHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });
  const [creditBalance, setCreditBalance] = useState(0);

  const [creditHistory, setCreditHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [refundTransactionId, setRefundTransactionId] = useState('');
  const [refundSuccess, setRefundSuccess] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState(
    'credit_charge,code_bonus,reward_bonus,charge_bonus,review,plotting,credit_ultrafit_shop,credit_charge_refund'
  );
  const activityList = [
    {
      value:
        'credit_charge,code_bonus,reward_bonus,charge_bonus,review,plotting,credit_ultrafit_shop,credit_charge_refund',
      text: 'all'
    },
    {
      value: 'credit_charge,charge_bonus',
      text: 'Recharge + Recharge Bonus'
    },
    {
      value: 'code_bonus',
      text: 'Product Code Authentication'
    },
    {
      value: 'reward_bonus',
      text: 'Rewards'
    },
    // {
    //   value: 'charge_bonus',
    //   text: 'Recharge Bonus'
    // },
    {
      value: 'review',
      text: 'Review Bounus Credit'
    },
    // {
    //   value: 'credit_ultrafit_shop_refund',
    //   text: 'Place Order Refund'
    // },
    {
      value: 'plotting',
      text: 'Plotting'
    },
    {
      value: 'credit_ultrafit_shop',
      text: 'Place Order'
    },
    {
      value: 'credit_charge_refund',
      text: 'Refund'
    }
  ];

  const [refundModalIsOpen, setRefundModalIsOpen] = useState(false);
  const [refundReasonModalIsOpen, setRefundReasonModalIsOpen] = useState(false);
  const [refundNoticeIsOpen, setRefundNoticeIsOpen] = useState(false);
  const [reason, setReason] = useState('');

  const fetchCreditHistoryData = async (
    from = '',
    to = '',
    activity = '',
    page = 1,
    limit = 20
  ) => {
    if (!creditBalance) {
      const creditBalanceRes = await getBalance('credit');
      setCreditBalance(creditBalanceRes);
    }

    const creditHistoryRes = await getProfileCreditHistory({
      from,
      to,
      activity,
      page,
      limit
    });

    setCreditHistory(creditHistoryRes.data);
    setTotalCount(creditHistoryRes.count);
  };

  const handleCreditRefundClick = (transactionId) => {
    setRefundModalIsOpen(true);
    setRefundTransactionId(transactionId);
  };

  const handleSendRefund = async () => {
    if (refundTransactionId) {
      const refundResponse = await refundRequest(refundTransactionId, reason);

      if (refundResponse.errorStatus) {
        setRefundSuccess(false);
      } else {
        setRefundSuccess(true);
      }
    }

    setRefundModalIsOpen(false);
    setRefundReasonModalIsOpen(false);
    setRefundNoticeIsOpen(true);
    setReason('');
  };

  useEffect(() => {
    fetchCreditHistoryData(
      filterDates.from,
      filterDates.to,
      selectedActivity,
      currentPage
    );
  }, [currentPage, filterDates, selectedActivity, refundSuccess]);

  return (
    <main className={creditHistoryStyles.main}>
      <div className={creditHistoryStyles.content}>
        <h1 className={creditHistoryStyles.credit_history}>credit history</h1>
        <h2 className={creditHistoryStyles.my_credit}>
          <span>MY CREDIT BALANCE:&nbsp;</span>
          <span>{formatNumber(Number(creditBalance))}&nbsp;</span>
        </h2>
        <InquiryFilter
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
        />
        <div className={creditHistoryStyles.credit_history_status}>
          <StatusFilter
            onChange={(value) => {
              setSelectedActivity(value);
              setCurrentPage(1);
            }}
            listOptions={activityList}
            width="263px"
          />
        </div>
        <table className={creditHistoryStyles.credit_table}>
          <thead>
            <tr>
              <td>Date</td>
              <td>Activity</td>
              <td />
              <td>Product name</td>
              <td>Deposit</td>
              <td>Used Credits</td>
              <td>Remaining Balance</td>
            </tr>
          </thead>
          <tbody>
            {creditHistory.length ? (
              creditHistory.map((trow) => (
                <tr key={trow.id}>
                  <td>{renderDate(trow?.createdAt) ?? ''}</td>
                  <td>{renderActivity(trow?.activity) ?? ''}</td>
                  <td className={creditHistoryStyles.credit_refund}>
                    {isRefundable(trow) ? (
                      <button
                        type="button"
                        onClick={() => handleCreditRefundClick(trow.id)}>
                        refund
                      </button>
                    ) : null}
                  </td>
                  <td>
                    {trow?.order?.orderDetail.length > 1
                      ? trow?.order?.orderDetail[0]?.ulftrafits?.name.concat(
                          '...'
                        )
                      : trow?.order?.orderDetail[0]?.ulftrafits?.name ||
                        trow?.pattern?.category ||
                        trow?.activationNumber}
                  </td>
                  <td className={creditHistoryStyles.deposit}>
                    {trow?.amount && trow?.type === 'deposit'
                      ? `${+trow.amount.toFixed(2)} Credits`
                      : ''}
                  </td>
                  <td className={creditHistoryStyles.withdrawl}>
                    {trow?.amount && trow?.type === 'withdrawal'
                      ? `${+trow.amount.toFixed(2)} Credits`
                      : ''}
                  </td>
                  <td> {trow?.balance ? `${trow?.balance} Credits` : ''}</td>
                </tr>
              ))
            ) : (
              <div className={creditHistoryStyles.shop_empty_list}>
                <img src={attentionMark} alt="attention!" />
                <p>No Credit history</p>
              </div>
            )}
          </tbody>
        </table>
        {totalCount > 20 && (
          <Pagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalCount={totalCount}
            pageSize={20}
          />
        )}
      </div>
      {refundModalIsOpen && (
        <CreditRefundModal
          refundAmount={
            creditHistory.find(
              (historyItem) => historyItem.id === refundTransactionId
            )?.amount || 0
          }
          onClose={() => {
            setRefundModalIsOpen(false);
            setRefundTransactionId('');
          }}
          onRefund={() => {
            setRefundModalIsOpen(false);
            setRefundReasonModalIsOpen(true);
          }}
        />
      )}
      {refundReasonModalIsOpen && (
        <CreditRefundReasonModal
          onRefund={handleSendRefund}
          reason={reason}
          setReason={setReason}
          onClose={() => {
            setRefundReasonModalIsOpen(false);
            setRefundTransactionId('');
            setReason('');
          }}
        />
      )}
      {refundNoticeIsOpen && (
        <RefundNoticeModal
          setModalIsOpened={setRefundNoticeIsOpen}
          refundSuccess={refundSuccess}
        />
      )}
    </main>
  );
}

export default withToast(CreditHistory);
