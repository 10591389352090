/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getMyOrders = () =>
  customFetch(`${apiUrl}/orders/my`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const cancelMyOrders = (idOrder) =>
  customFetch(`${apiUrl}/orders/cancel/${idOrder}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const returnMyOrders = (idOrder, reason) =>
  customFetch(`${apiUrl}/orders/return/${idOrder}`, {
    method: 'PUT',
    body: JSON.stringify({ reason }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const calculateOrder = (body) =>
  customFetch(`${apiUrl}/orders/calculate`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const createOrder = (body) =>
  customFetch(`${apiUrl}/orders`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });
