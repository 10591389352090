import { Rating } from 'react-simple-star-rating';

import PatternCardUserRank from '../../PatternCard/PatternCardUserRank';

import listItemStyles from './MyProjectListItem.module.scss';

import profileImg from '../../../img/icon_account.svg';
import patternImg from '../../../img/pattern.png';
import deletedIcon from '../../../img/icon_disabled.svg';
import {
  renderDate,
  filterByColumn,
  renderNumberToFixed,
  renderNickname
} from '../../../utils/function';

import SponsoredMark from '../../PatternCard/SponsoredMark';

function MyProjectListItem({ pattern, onCheck }) {
  const patternData = pattern.pattern;

  return (
    <div className={listItemStyles.item}>
      {patternData?.sponsor ? (
        <div className={listItemStyles.item_sponsored}>
          <SponsoredMark sponsored={patternData?.sponsor} />
        </div>
      ) : null}
      <div className={listItemStyles.item_container}>
        <input type="checkbox" onChange={(e) => onCheck(e, pattern)} />
        <div className={listItemStyles.list_item}>
          {patternData?.status === 'stop_selling' && (
            <div className={listItemStyles.item_deleted}>
              <img src={deletedIcon} alt="icon" />
              <p>This Pattern is no longer available</p>
            </div>
          )}
          {(patternData?.deletedAt || patternData?.status !== 'approved') &&
            patternData?.status !== 'stop_selling' && (
              <div className={listItemStyles.item_deleted}>
                <img src={deletedIcon} alt="icon" />
                <p>deleted by uploader</p>
              </div>
            )}
          <img
            className={listItemStyles.list_pattern_img}
            src={patternData?.svg || patternImg}
            alt="pattern"
          />
          <div className={listItemStyles.item_details}>
            <div className={listItemStyles.item_details_upper}>
              <span className={listItemStyles.item_details_date}>
                Upload date : {renderDate(patternData?.createdAt)}
              </span>
              <div className={listItemStyles.item_details_categories}>
                {patternData?.models?.length > 0
                  ? `${
                      filterByColumn(patternData.models, 'region').length > 0
                        ? `${filterByColumn(patternData.models, 'region')}`
                        : ''
                    } ${
                      filterByColumn(patternData.models, 'year').length > 0
                        ? ` > ${filterByColumn(patternData.models, 'year')}`
                        : ''
                    } ${
                      filterByColumn(patternData.models, 'brand').length > 0
                        ? ` > ${filterByColumn(patternData.models, 'brand')}`
                        : ''
                    } ${
                      filterByColumn(patternData.models, 'model').length > 0
                        ? ` > ${filterByColumn(patternData.models, 'model')}`
                        : ''
                    } ${
                      filterByColumn(patternData.models, 'sub_model').length > 0
                        ? ` > ${filterByColumn(
                            patternData.models,
                            'sub_model'
                          )}`
                        : ''
                    } ${
                      filterByColumn(patternData.models, 'series').length > 0
                        ? ` > ${filterByColumn(patternData.models, 'series')}`
                        : ''
                    }`
                  : null}
              </div>
              <div className={listItemStyles.item_details_subcategories}>
                {patternData?.vehicles?.length > 0
                  ? `${
                      filterByColumn(patternData.vehicles, 'part_section')
                        .length > 0
                        ? `${filterByColumn(
                            patternData.vehicles,
                            'part_section'
                          )}`
                        : ''
                    } ${
                      filterByColumn(patternData.vehicles, 'pattern_part')
                        .length > 0
                        ? `> ${filterByColumn(
                            patternData.vehicles,
                            'pattern_part'
                          )}`
                        : ''
                    } ${
                      filterByColumn(patternData.vehicles, 'sub_part').length >
                      0
                        ? `> ${filterByColumn(
                            patternData.vehicles,
                            'sub_part'
                          )}`
                        : ''
                    }`
                  : null}
              </div>
            </div>
            <div className={listItemStyles.item_uploader_details}>
              <div className={listItemStyles.item_uploader}>
                <img src={patternData?.user?.avatar || profileImg} alt="pfp" />
                <span className="card_username">
                  by{' '}
                  {patternData?.user?.nickName
                    ? renderNickname(patternData?.user?.nickName, 14)
                    : ''}
                </span>
              </div>
              <Rating
                ratingValue={
                  patternData?.ratingAvg ? +patternData.ratingAvg * 20 : 0
                }
                readonly
                size={20}
                emptyColor="#ededed"
                fillColor="#ffd500"
              />
              <PatternCardUserRank
                rank={patternData?.user?.level}
                renderRankName
              />
            </div>
            <div className={listItemStyles.item_details_lower}>
              <div className={listItemStyles.item_lower_detail}>
                <span className={listItemStyles.item_detail_name}>Area</span>
                <span className={listItemStyles.item_detail_value}>
                  {renderNumberToFixed(patternData.sqft, 1)} Sq.ft (
                  {renderNumberToFixed(patternData.sqft, 0.09290304)} Sq.m)
                </span>
              </div>
              <div className={listItemStyles.item_lower_detail}>
                <span className={listItemStyles.item_detail_name}>
                  Price per Area
                </span>
                <span className={listItemStyles.item_detail_value}>
                  {renderNumberToFixed(patternData?.unitPrice)} Credits (per
                  Sq.ft)
                </span>
              </div>
              <div className={listItemStyles.item_lower_detail}>
                <span className={listItemStyles.item_detail_name}>
                  Total price
                </span>
                <span className={listItemStyles.item_detail_value}>
                  {renderNumberToFixed(patternData?.price)} Credits
                </span>
              </div>
              <div className={listItemStyles.item_lower_detail}>
                <span className={listItemStyles.item_detail_name}>
                  Download
                </span>
                <span className={listItemStyles.item_detail_value}>
                  {patternData?.downloads || 0}
                </span>
              </div>
            </div>
          </div>
          <div className={listItemStyles.item_date}>
            Saved date: {renderDate(pattern?.updatedAt)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProjectListItem;
