import { useState } from 'react';
import supportPageTableStyles from '../SupportPageTable/SupportPageTable.module.scss';

function SupportPageTableRow({ index, row }) {
  const [answerIsOpened, setAnswerIsOpened] = useState(false);

  const hadnleAnswerExposureClick = () => {
    setAnswerIsOpened(!answerIsOpened);
  };

  return (
    <>
      <div
        className={supportPageTableStyles.table_row}
        style={answerIsOpened ? { borderBottom: 'none' } : {}}>
        <div className={supportPageTableStyles.table_cell}>{index + 1}</div>
        <div className={supportPageTableStyles.table_cell}>
          <button type="button" onClick={hadnleAnswerExposureClick}>
            {row.q}
          </button>
        </div>
      </div>
      {answerIsOpened && (
        <div
          className={supportPageTableStyles.table_row}
          style={{ paddingTop: 0 }}>
          <div className={supportPageTableStyles.table_cell}> </div>
          <div className={supportPageTableStyles.table_cell}>
            {row.a.split('\n').map((par, idx) => (
              <p key={idx}>{par}</p>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default SupportPageTableRow;
