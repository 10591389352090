/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import supportPageStyles from './SupportPage.module.scss';
import SupportLinkCard from './SupportLinkCard/SupportLinkCard';
import SupportTopicCard from './SupportTopicCard/SupportTopicCard';
import SupportPageSidebarLinks from './SupportPageSidebarLinks/SupportPageSidebarLinks';
import DropdownFilter from './DropdownFilter/DropdownFilter';
import faq from '../../utils/faq';
import FAQTable from './FAQTable/FAQTable';
import learnAndSupport from '../../utils/learnAndSuport';
import SupportCard from './SupportCard/SupportCard';

function SupportPage() {
  // const navigate = useNavigate();

  // useEffect(() => navigate('/support/credit&sub'), []);
  const [selectedCategory, setSelectedCategory] = useState('General');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const [selectedSupport, setSelectedSupport] = useState('Upload your pattern');
  const [selectedSubSupport, setSelectedSubSupport] = useState('');

  useEffect(() => {
    if (selectedCategory) {
      const listSubCategory = faq[selectedCategory];
      const firstSubCategory = Object.keys(listSubCategory)[0];
      setSelectedSubCategory(firstSubCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSupport) {
      const listSubSupport = learnAndSupport[selectedSupport];
      const firstSubCategory = Object.keys(listSubSupport)[0];
      setSelectedSubSupport(firstSubCategory);
    }
  }, [selectedSupport]);

  const listSubSupport = useMemo(() => {
    if (selectedSupport) {
      const subSupport = learnAndSupport[selectedSupport];
      return subSupport;
    }
    return [];
  }, [selectedSupport]);

  const listSubCategory = useMemo(() => {
    if (selectedCategory && selectedSubCategory) {
      const subCategory = faq[selectedCategory];
      return Object.keys(subCategory);
    }
    return [];
  }, [selectedCategory, selectedSubCategory]);

  const listAAndQ = useMemo(() => {
    if (selectedCategory && selectedSubCategory) {
      const subCategory = faq[selectedCategory];
      const subCategoryAAndQ = subCategory[selectedSubCategory];
      return subCategoryAAndQ;
    }
    return [];
  }, [selectedCategory, selectedSubCategory]);

  return (
    <main className={supportPageStyles.support_main}>
      <div className={supportPageStyles.support_container}>
        <h1>Support</h1>
        <div className={supportPageStyles.container}>
          <h2>FAQ</h2>
          <DropdownFilter
            value={selectedCategory}
            onSelect={setSelectedCategory}
          />
        </div>
        <hr />
        <div
          className={supportPageStyles.sub_category}
          style={{
            gridTemplateColumns: `repeat( ${listSubCategory.length}, 1fr)`
          }}>
          {listSubCategory.map((item, index) => (
            <button
              key={index}
              type="button"
              style={{
                color: selectedSubCategory === item ? '#fff' : '#222',
                background: selectedSubCategory === item ? '#0046a6' : '#fff',
                border:
                  selectedSubCategory === item ? 'none' : '1px solid #ededed'
              }}
              onClick={() => setSelectedSubCategory(item)}>
              {item}
            </button>
          ))}
        </div>

        <FAQTable tableRows={listAAndQ} />

        {/* <nav>
          <NavLink
            to="/support/credit&sub"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            2 Credit & Subscribe Problems
          </NavLink>
          <NavLink
            to="/support/billing&payment&receipt"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            Billing, Payment, <br /> Receipt
          </NavLink>
          <NavLink
            to="/support/upload"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            Upload
          </NavLink>
          <NavLink
            to="/support/download&plotting"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            Download, Plotting
          </NavLink>
          <NavLink
            to="/support/account"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            Account Management
          </NavLink>
          <NavLink
            to="/support/other"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#222',
              background: isActive ? '#0046a6' : '#fff',
              border: isActive ? 'none' : '1px solid #ededed'
            })}>
            Other Problems
          </NavLink>
        </nav> */}
        <Outlet />
        <section>
          <h1>Learn & Support</h1>
          <h2>How to get the most out of PLOFIX</h2>
          <hr />
          <div className={supportPageStyles.how_to_cards_container}>
            <SupportLinkCard
              text="Upload your pattern"
              onSelect={setSelectedSupport}
              selected={selectedSupport}
            />
            <SupportLinkCard
              text="Download a Pattern"
              onSelect={setSelectedSupport}
              selected={selectedSupport}
            />
            <SupportLinkCard
              text="Using the EDIT & PLOT"
              onSelect={setSelectedSupport}
              selected={selectedSupport}
            />
          </div>
        </section>
      </div>
      <section className={supportPageStyles.topics_section_wrapper}>
        <SupportPageSidebarLinks
          items={listSubSupport}
          selected={selectedSubSupport}
          onSelect={setSelectedSubSupport}
        />
        <div>
          <h2>{selectedSupport}</h2>
          <hr />
          <div className={supportPageStyles.support_topics_wrapper}>
            {Object.keys(listSubSupport).length > 0
              ? Object.keys(listSubSupport).map((item, index) => (
                  <SupportCard key={index} item={listSubSupport[item]} />
                ))
              : null}
          </div>
        </div>
      </section>
    </main>
  );
}

export default SupportPage;
