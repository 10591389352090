/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import {
  loginByPass,
  loginViaGoogle,
  loginViaFacebook,
  checkWithdrawal
} from '../../../api/auth';

import loginFormStyles from '../Forms.module.scss';
import loginFromScopedStyles from './LogInForm.module.scss';
import signinGoogle from '../../../img/icon_google.png';
import signinFb from '../../../img/icon_facebook.png';
import formArrow from '../../../img/form_arrow.svg';
import iconPasswordShow from '../../../img/icon_password_show.svg';
import iconPasswordHide from '../../../img/icon_password_hide.svg';
import { firebaseConfig } from '../../../utils/constants';
import { useAlert } from '../../hooks/useAlert/useAlert';

const app = initializeApp(firebaseConfig);
const auth = getAuth();

function LogInForm({ onLogin }) {
  const navigate = useNavigate();
  // react-hook-form tools
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const { onAlert } = useAlert();

  // states for label border color on blur/focus
  const [emailBorderColor, setEmailBorderColor] = useState({
    borderColor: '#ededed'
  });
  const [pwdBorderColor, setPwdBorderColor] = useState({
    borderColor: '#ededed'
  });

  const [isShowPw, setIsShowPw] = useState(false);

  // const loginGoogle = useGoogleLogin({
  //   onSuccess: async (codeResponse) => {
  //     console.log(codeResponse);
  //     const loginGoogleRes = await loginViaGoogle(codeResponse.code);
  //     console.log(loginGoogleRes);
  //   }
  // });

  // async function handleCredentialResponse(response) {
  //   console.log(response);
  //   const googleLoginRes = await loginViaGoogle(response.credential);
  //   console.log(googleLoginRes);

  //   if (googleLoginRes.msg === 'success login') {
  //     const loginState = {
  //       isLoggedIn: true,
  //       nickname: googleLoginRes.nick,
  //       id: googleLoginRes.id,
  //       jwt: googleLoginRes.jwt,
  //       refreshToken: googleLoginRes.refreshToken
  //     };

  //     localStorage.setItem('token', loginState.authToken);
  //     localStorage.setItem('refreshToken', googleLoginRes.refreshToken);
  //     setLoginState(loginState);

  //     navigate('/');
  //   }
  // }

  // useEffect(() => {
  //   const { google } = window;

  //   google.accounts.id.initialize({
  //     client_id: clientId,
  //     callback: handleCredentialResponse
  //   });
  //   google.accounts.id.renderButton(
  //     document.getElementById('googleSignin'),
  //     {
  //       theme: 'outline',
  //       size: 'large',
  //       text: 'signin_with',
  //       shape: 'pill',
  //       logo_alignment: 'left',
  //       width: 400,
  //       locale: 'en_EN'
  //     } // customization attributes
  //   );
  // }, []);

  // getting loginResponse
  const onSubmit = async (data) => {
    const { email, password } = data;
    const logRes = await loginByPass({
      email: email.trim(),
      password: password.trim()
    });

    if (logRes?.errorStatus) {
      setError('email', {
        type: 'custom',
        message: 'Email or Password is wrong.'
      });

      return;
    }

    const loginData = logRes.data;

    const loginState = {
      isLoggedIn: true,
      nickname: loginData.user.nickName,
      email: loginData.user.email,
      id: loginData.user.id
    };

    localStorage.setItem('token', loginData.authToken);
    localStorage.setItem('refreshToken', loginData.refreshToken);

    onLogin(loginState);

    if (window.location.search.includes('fromPattern')) {
      navigate(-1);
      return;
    }

    navigate('/');
  };

  // const responseFacebook = async (response) => {
  //   if (response.status !== 'unknown') {
  //     try {
  //       const fbLoginRes = await loginViaFacebook({
  //         id_token: response.accessToken
  //       });

  //       if (fbLoginRes.msg === 'success login') {
  //         const loginState = {
  //           isLoggedIn: true,
  //           nickname: fbLoginRes.nick,
  //           id: fbLoginRes.id,
  //           jwt: fbLoginRes.jwt,
  //           refreshToken: fbLoginRes.refreshToken
  //         };

  //         setLoginState(loginState);

  //         navigate('/');
  //       }
  //     } catch (error) {
  //       console.log('error login via fb', error);
  //     }
  //   }
  // };

  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider
      .addScope('email')
      .addScope('https://www.googleapis.com/auth/plus.login')
      .addScope('https://www.googleapis.com/auth/plus.me')
      .addScope('https://www.googleapis.com/auth/userinfo.email')
      .addScope('https://www.googleapis.com/auth/userinfo.profile')
      .addScope('https://www.googleapis.com/auth/user.phonenumbers.read');

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        // eslint-disable-next-line no-underscore-dangle
        const tokenResponse = result._tokenResponse;
        const user = result.user;
        const token = user.accessToken;
        try {
          const googleLoginRes = await loginViaGoogle({ token });

          if (googleLoginRes?.statusCode === 200) {
            const loginState = {
              isLoggedIn: true,
              nickname: googleLoginRes.data.user.nickName,
              email: googleLoginRes.data.user.email,
              id: googleLoginRes.data.user.id
            };

            localStorage.setItem('token', googleLoginRes.data.token);
            localStorage.setItem(
              'refreshToken',
              googleLoginRes.data.refreshToken
            );

            onLogin(loginState);

            if (window.location.search.includes('fromPattern')) {
              navigate(-1);
              return;
            }

            navigate('/');
          } else {
            // eslint-disable-next-line no-lonely-if
            if (googleLoginRes?.errorStatus === 400) {
              // const email = user?.email || tokenResponse.email || '';
              // if (!email) {
              //   throw new Error('Email is null');
              // }
              const checkWithdrawalRes = await checkWithdrawal({
                email: user?.email || tokenResponse.email || ''
              });

              if (checkWithdrawalRes.data === true) {
                throw new Error('This email is already withdraw');
              }
              navigate('/signup-sns', {
                state: {
                  email: user?.email || tokenResponse.email || '',
                  profilePicture: user?.photoURL ?? '',
                  uid: user?.uid ?? '',
                  type: 'google'
                }
              });
            }
          }
        } catch (err) {
          // console.log('err', err);
          if (err.message && err.message.includes('Email is null')) {
            onAlert('', 'OK', err.message);
          }
          if (
            err.message &&
            err.message.includes('This email is already withdraw')
          ) {
            onAlert('', 'OK', err.message);
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);
      });
  }

  function loginWithFaceBook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    // provider.addScope('phone');

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        // eslint-disable-next-line no-underscore-dangle
        const tokenResponse = result._tokenResponse;
        const user = result.user;
        const token = user.accessToken;
        try {
          const fbLoginRes = await loginViaFacebook({
            token
          });
          if (fbLoginRes?.statusCode === 200) {
            const loginState = {
              isLoggedIn: true,
              nickname: fbLoginRes.data.user.nickName,
              email: fbLoginRes.data.user.email,
              id: fbLoginRes.data.user.id
            };

            localStorage.setItem('token', fbLoginRes.data.token);
            localStorage.setItem('refreshToken', fbLoginRes.data.refreshToken);

            onLogin(loginState);

            if (window.location.search.includes('fromPattern')) {
              navigate(-1);
              return;
            }

            navigate('/');
          } else {
            // eslint-disable-next-line no-lonely-if
            if (fbLoginRes?.errorStatus === 400) {
              const email = user?.email || tokenResponse.email || '';
              // if (!email) {
              //   throw new Error('Email is null');
              // }
              const checkWithdrawalRes = await checkWithdrawal({
                email: user?.email || tokenResponse.email || ''
              });

              if (checkWithdrawalRes.data === true) {
                throw new Error('This email is already withdraw');
              }
              navigate('/signup-sns', {
                state: {
                  email,
                  profilePicture: user?.photoURL ?? '',
                  uid: user?.uid ?? '',
                  type: 'facebook'
                }
              });
            }
          }
        } catch (err) {
          // console.log('err', err);
          if (err.message && err.message.includes('Email is null')) {
            onAlert('', 'OK', err.message);
          }
          if (
            err.message &&
            err.message.includes('This email is already withdraw')
          ) {
            onAlert('', 'OK', err.message);
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        // console.log('error', error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // alert(errorMessage);
        // console.log('errorMessage', errorMessage);
      });
  }

  return (
    <section className={loginFormStyles.form_section}>
      <form className={loginFormStyles.form} onSubmit={handleSubmit(onSubmit)}>
        <h1 className={loginFormStyles.title}>Login</h1>
        <div className={loginFormStyles.form_container}>
          <label
            onFocus={() => setEmailBorderColor({ borderColor: '#0046a6' })}
            onBlur={() => setEmailBorderColor({ borderColor: '#ededed' })}
            className={loginFromScopedStyles.email_label}
            htmlFor="email"
            style={emailBorderColor}>
            <p>E-mail</p>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required.'
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  name="email"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {/* <input
              {...register('email')}
              name="email"
              type="email"
              onChange={() => clearErrors()}
            /> */}
          </label>
          <label
            onFocus={() => setPwdBorderColor({ borderColor: '#0046a6' })}
            onBlur={() => setPwdBorderColor({ borderColor: '#ededed' })}
            className={loginFromScopedStyles.pwd_label}
            htmlFor="password"
            style={pwdBorderColor}>
            <p>Password</p>
            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Password is required.'
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  name="password"
                  type={isShowPw ? 'text' : 'password'}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <img
              src={isShowPw ? iconPasswordShow : iconPasswordHide}
              onClick={() => setIsShowPw(!isShowPw)}
              alt="Eye Logo"
            />
            {/* <input
              {...register('password')}
              name="password"
              type="password"
              onChange={() => clearErrors()}
            /> */}
          </label>
          <Link to="/find_pass" className={loginFromScopedStyles.find_pwd_link}>
            Forgot your password?
          </Link>
          <div className={loginFormStyles.warnings_holder}>
            <p className={loginFormStyles.validation_warning}>
              {errors.email?.message} {errors.password?.message}
            </p>
          </div>
          <button className={loginFormStyles.submit_btn} type="submit">
            Login
          </button>
          <div className={loginFromScopedStyles.divider}>
            <hr />
            <span>Or</span>
            <hr />
          </div>
          <button
            type="button"
            onClick={loginWithGoogle}
            className={`${loginFromScopedStyles.signin_with} ${loginFromScopedStyles.signin_google}`}>
            <img src={signinGoogle} alt="Google Logo" />
            <span>Sign in with Google</span>
            <img src={formArrow} alt="arrow" />
          </button>

          {/* <FacebookLogin
            appId="713246760370883"
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                type="button"
                onClick={renderProps.onClick}
                className={`${loginFromScopedStyles.signin_with}
                // ${loginFromScopedStyles.signin_fb}`}>
                <img src={signinFb} alt="FB Logo" />
                <span>Sign in with Facebook</span>
                <img src={formArrow} alt="arrow" />
              </button>
            )}
          /> */}

          <button
            type="button"
            onClick={loginWithFaceBook}
            className={`${loginFromScopedStyles.signin_with} ${loginFromScopedStyles.signin_fb}`}>
            <img src={signinFb} alt="FB Logo" />
            <span>Sign in with Facebook</span>
            <img src={formArrow} alt="arrow" />
          </button>
          <p className={loginFromScopedStyles.sign_up}>
            Don&rsquo;t have an&nbsp;accounting?{' '}
            <Link to="/signup">Sign up.</Link>
          </p>
        </div>
      </form>
    </section>
  );
}

export default LogInForm;
