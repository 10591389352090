/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate } from 'react-router-dom';
import PatternCard from '../PatternCard';

// import patternLayerImg from '../../../img/pattern_collection_bg.svg';

import patternCollectionStyles from './PatternCollection.module.scss';

function PatternCollection({ project, setTimeRecall, onDelete }) {
  const navigate = useNavigate();

  const handleCollectionClick = () => {
    navigate(`/myproject/list/${project.id}`);
  };

  return (
    <div
      className={patternCollectionStyles.collection_container}
      onClick={handleCollectionClick}>
      {project?.projectPatterns.slice(0, 3).map((card, index) => (
        <div
          key={card.id}
          className={patternCollectionStyles.collection_layer}
          style={{
            width: `${100 - 5 * (index + 1)}%`,
            zIndex: `${10 - (index + 1)}`,
            top: `${-8 * (index + 1)}px`
          }}
        />
      ))}
      <PatternCard
        card={project?.projectPatterns[0]?.pattern}
        projectId={project?.id}
        isPatternCollection
        myProjectPage
        setTimeRecall={setTimeRecall}
        onDelete={onDelete}
      />
    </div>
  );
}

export default PatternCollection;
