/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const createSponsor = (body) =>
  customFetch(`${apiUrl}/sponsors`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const getSponsorshipPrice = () =>
  customFetch(`${apiUrl}/sponsors/price`)
    .then((res) => res.json())
    .then((sponsorPriceData) => {
      if (sponsorPriceData.errorStatus) {
        throw new Error(sponsorPriceData.message);
      }

      return sponsorPriceData.data.price;
    });
