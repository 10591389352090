import { useNavigate } from 'react-router-dom';

import salesStyles from './Sales.module.scss';
import enterArrow from '../../../../../img/icon_enter.svg';

function SalesInfo({ infoIcon, infoCategory, infoAmount }) {
  const navigate = useNavigate();

  return (
    <div className={salesStyles.info_item}>
      <div className={salesStyles.item_content}>
        <img src={infoIcon} alt="" />
        <span>{infoCategory}</span>
        {infoCategory === 'My Profit' && (
          <button
            type="button"
            onClick={() => navigate('/sell/pattern/payout')}>
            Withdraw request
            <img src={enterArrow} alt="enter" />
          </button>
        )}
      </div>
      <p className={salesStyles.item_amount}>{infoAmount}</p>
    </div>
  );
}

export default SalesInfo;
