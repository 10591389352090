/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from 'react';

import PatternList from '../PatternList/PatternList';
import PatternPageHero from '../PatternPageHero/PatternPageHero';

import patternPageStyles from './PatternPage.module.scss';

import { getPatterns } from '../../api/patterns';
// import { sortByDate, sortPatternsBy } from '../../utils/function';

import arrow from '../../img/dropdown_arrow.svg';
import AdPopUp from '../AdPopUp/AdPopUp';
import Pagination from '../Pagination/Pagination';
import { getCategories } from '../../api/categories';

const mapOrderBy = {
  Newest: 'newest',
  'Best rated': 'best_rated',
  'Most downloaded': 'most_downloaded'
};

function PatternPage() {
  const patternsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const [patternDepthCategories, setPatternDepthCategories] = useState([
    {
      checked: false,
      text: 'Bikini',
      ids: []
    },
    {
      checked: false,
      text: 'Wrapped',
      ids: []
    },
    {
      checked: false,
      text: 'Sensors',
      ids: []
    }
  ]);
  // state for "newest, best rated, most downloads" select filter
  const [sortIsOpened, setSortIsOpened] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Newest');

  const [patterns, setPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [partSectionCategories, setPartSectionCategories] = useState([]);

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(() => {
    const selectedCategoryId = localStorage.getItem('selectedCategoryId') || 0;

    localStorage.removeItem('selectedCategoryId');

    return selectedCategoryId;
  });
  const [selectedSideCategoryIds, setSelectedSideCategoryIds] = useState('');
  const [selectedDepthCategoryIds, setSelectedDepthCategoryIds] = useState('');
  const [selectedTag] = useState(
    localStorage.getItem('searchPatternByTag') || ''
  );

  const preSelectedSideCategoryIds = useRef();

  const preSelectedMainCategoryId = useRef();

  const preSelectedValue = useRef();

  // onClick handler for "newest, best rated, most downloads" select filter
  const handleSelectClick = () => {
    setSortIsOpened(!sortIsOpened);
  };

  // onChange handler for "newest, best rated, most downloads" select filter
  const handleSelectChange = (e) => {
    setSelectedSort(e.target.value);

    setSortIsOpened(false);
  };

  const searchDepthCategoriesIds = (node, parentIds, categoryName, ids) => {
    if (!node?.subCategory.length) {
      if (
        node.category === categoryName.toLowerCase() &&
        parentIds.split(',').filter((item) => +item === node.parentId).length
      ) {
        ids.push(node.id);
      }
    } else {
      for (const childNode of node.subCategory) {
        searchDepthCategoriesIds(childNode, parentIds, categoryName, ids);
      }
    }
  };

  const fetchDepthCategories = async () => {
    const depthCategoriesRes = await getCategories();

    setPartSectionCategories(
      depthCategoriesRes.filter(
        (category) =>
          category?.column === 'part_section' && category?.type === 'category'
      )
    );
  };

  const fetchPatterns = async ({
    modelIds = '',
    vehicleIds = '',
    orderBy = '',
    tag = ''
  }) => {
    let page = currentPage;
    if (vehicleIds && vehicleIds !== preSelectedSideCategoryIds.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (modelIds && modelIds !== preSelectedMainCategoryId.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (orderBy && orderBy !== preSelectedValue.current) {
      setCurrentPage(1);
      page = 1;
    }

    const patternsResponse = await getPatterns({
      page,
      limit: patternsOnPage,
      modelIds: modelIds > 0 ? modelIds : '',
      vehicleIds,
      orderBy: mapOrderBy[orderBy],
      tag
    });
    preSelectedSideCategoryIds.current = vehicleIds;
    preSelectedMainCategoryId.current = modelIds;
    preSelectedValue.current = orderBy;

    setTotalCount(patternsResponse.count);
    setPatterns(patternsResponse.data);
  };

  useEffect(() => {
    fetchDepthCategories();
  }, []);

  useEffect(() => {
    if (partSectionCategories.length) {
      setPatternDepthCategories((prevState) => [
        {
          checked: prevState[0]?.checked,
          text: 'Bikini',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Bikini',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[1]?.checked,
          text: 'Wrapped',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Wrapped',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[2]?.checked,
          text: 'Sensors',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Sensors',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        }
      ]);
    }
  }, [partSectionCategories, selectedSideCategoryIds]);

  useEffect(() => {
    const selectedDepthCategories = patternDepthCategories
      .reduce((ids, depthCategory) => {
        if (depthCategory?.checked) {
          ids.push(depthCategory.ids);
        }

        return ids;
      }, [])
      .join(',');

    setSelectedDepthCategoryIds(selectedDepthCategories);
  }, [patternDepthCategories]);

  useEffect(() => {
    fetchPatterns({
      modelIds: selectedMainCategoryId,
      vehicleIds: selectedDepthCategoryIds || selectedSideCategoryIds,
      orderBy: selectedSort,
      tag: selectedTag
    });

    if (selectedTag) {
      localStorage.removeItem('searchPatternByTag');
    }
  }, [
    currentPage,
    selectedMainCategoryId,
    selectedSideCategoryIds,
    selectedDepthCategoryIds,
    selectedSort,
    selectedTag
  ]);

  return (
    <main>
      <PatternPageHero setSelectedCategory={setSelectedMainCategoryId} />
      <div className={patternPageStyles.pattern_page_content}>
        <div className={patternPageStyles.pattern_title}>
          <h1>Total {totalCount}</h1>
          <div className={patternPageStyles.pattern_categories}>
            {selectedSideCategoryIds?.length
              ? patternDepthCategories.map((category, index) => (
                  <label key={index} htmlFor={category.text}>
                    <input
                      type="checkbox"
                      id={category.text}
                      checked={category.checked}
                      onChange={() =>
                        setPatternDepthCategories((prevState) =>
                          prevState.map((prevCategory) => {
                            if (prevCategory.text === category.text) {
                              return {
                                ...prevCategory,
                                checked: !prevCategory.checked
                              };
                            }

                            return prevCategory;
                          })
                        )
                      }
                    />
                    <span>{category.text}</span>
                  </label>
                ))
              : null}
          </div>
          <label
            htmlFor="select_condition"
            className={patternPageStyles.pattern_dd_filter}>
            <button onClick={handleSelectClick} type="button">
              {selectedSort}
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: sortIsOpened ? 'rotate(180deg)' : '' }}
              />
            </button>
            {sortIsOpened && (
              <select
                onChange={handleSelectChange}
                multiple
                name="select_condition">
                <option value="Newest">Newest</option>
                <option value="Best rated">Best rated</option>
                <option value="Most downloaded">Most downloaded</option>
              </select>
            )}
          </label>
        </div>
        <section className={patternPageStyles.patterns_list}>
          <PatternList
            patterns={patterns}
            setSelectedSideCategories={setSelectedSideCategoryIds}
          />
          {totalCount > patternsOnPage && (
            <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={patternsOnPage}
              onPageChange={setCurrentPage}
            />
          )}
        </section>
      </div>
      <AdPopUp popUpCategory="패턴페이지" />
    </main>
  );
}

export default PatternPage;
