/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-new */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';

import { getPatternById, addToFavorites } from '../../api/patterns';
import {
  renderDate,
  filterByColumn,
  renderNumberToFixed
} from '../../utils/function';

import PatternReviews from './PatternReviews/PatternReviews';
import PatternFilter from './PatternFilter/PatternFilter';
import SponsoredMark from '../PatternCard/SponsoredMark';

import { LoginStateContext } from '../../store/Context';

import detailsStyles from './PatternDetails.module.scss';

import markedImg from '../../img/marked.svg';
import profileImg from '../../img/icon_account.svg';
import dowloadsImg from '../../img/icon_download.svg';
import reviewsImg from '../../img/icon_review.svg';
import { getPremiumTime } from '../../api/profile';

import { editorUrl } from '../../utils/editorURL';
import ModalPaymentEP from '../Header/ModalPaymentEP/ModalPaymentEP';

// import PatternFilter from './PatternFilter/PatternFilter';

// favourite mark bg color
const favActive = {
  backgroundColor: '#ff6900'
};
const favInactive = {
  backgroundColor: '#ededed'
};

function PatternDetails({ isUploaderPage, isMyPatternsPage }) {
  const { patternId, uploaderId } = useParams();

  const navigate = useNavigate();

  const loginState = useContext(LoginStateContext);

  const [pattern, setPattern] = useState(null);
  const [favMarked, setFavMarked] = useState(false);
  const [premiumTime, setPremiumTime] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const currentCard = cardId
  //   ? cards.find((card) => +card.id === +cardId)
  //   : cards.find((card) => +card.id === +id);

  // onClick handler that marks favourite pattern
  const handleDetailsFavClick = async () => {
    if (!loginState?.isLoggedIn) {
      navigate('/auth');
      return;
    }

    const addToFavRes = await addToFavorites(pattern.patternId);

    if (addToFavRes.errorStatus) {
      alert('Something went wrong. Try again later.');
      return;
    }

    if (!favMarked) {
      setFavMarked(true);
      return;
    }

    setFavMarked(false);
  };

  const handleUploaderNavigate = () => {
    if (pattern) {
      navigate(`/pattern/uploaderId/${pattern.ownerId}`);
    }
  };

  const handleEditorClick = async () => {
    const authToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const currentDate = new Date();
    const subscriptionEndDate = new Date(premiumTime);

    if (isMyPatternsPage) {
      navigate(`/sell/edit/${patternId}`);
      return;
    }

    if (!loginState?.isLoggedIn) {
      navigate('/auth');
      return;
    }

    if (currentDate > subscriptionEndDate) {
      // const subscribe = confirm('Subscribe to “Edit & Plot”');

      // if (subscribe) {
      //   navigate('/pricing/subscription');

      //   return;
      // }

      setModalIsOpen(true);

      return;
    }

    window.open(
      `${editorUrl}?fromUserWeb&patternId=${patternId}&token=${authToken}&refreshToken=${refreshToken}`,
      '_blank'
    );
  };

  const fetchPatternData = async () => {
    const fetchedPattern = await getPatternById(patternId);

    setPattern(fetchedPattern);
  };

  const fetchPremiumTime = async () => {
    const premiumTimeRes = await getPremiumTime();

    setPremiumTime(premiumTimeRes);
  };

  // useEffect that renders favMark on favourite pattern
  useEffect(() => {
    fetchPatternData();

    if (!isMyPatternsPage) {
      fetchPremiumTime();
    }
  }, []);

  useEffect(() => {
    if (pattern?.liked) {
      setFavMarked(true);
      return;
    }

    setFavMarked(false);
  }, [pattern]);

  // useEffect(() => {
  //   if (favPatterns && favPatterns.length) {
  //     favPatterns.forEach((pattern) => {
  //       if (currentCard.id === pattern.id) {
  //         setFavMarked(true);
  //       }
  //     });
  //   }
  // }, [favPatterns]);

  return (
    <section className={detailsStyles.pattern_details}>
      <PatternFilter
        uploaderId={uploaderId}
        isUploaderPage={isUploaderPage}
        isMyPatternsPage={isMyPatternsPage}
      />
      {pattern && (
        <div className={detailsStyles.details_container}>
          <div className={detailsStyles.details_title}>
            <p>
              {pattern?.models?.length > 0 &&
                `${
                  filterByColumn(pattern.models, 'region').length
                    ? `${filterByColumn(pattern.models, 'region')} >`
                    : ''
                } ${
                  filterByColumn(pattern.models, 'year').length
                    ? `${filterByColumn(pattern.models, 'year')} >`
                    : ''
                } ${
                  filterByColumn(pattern.models, 'brand').length
                    ? `${filterByColumn(pattern.models, 'brand')} >`
                    : ''
                } ${
                  filterByColumn(pattern.models, 'model').length
                    ? `${filterByColumn(pattern.models, 'model')} >`
                    : ''
                } ${
                  filterByColumn(pattern.models, 'sub_model').length
                    ? `${filterByColumn(pattern.models, 'sub_model')} >`
                    : ''
                } ${
                  filterByColumn(pattern.models, 'series').length
                    ? `${filterByColumn(pattern.models, 'series')}`
                    : ''
                }`}
            </p>
            <h1>
              {pattern?.vehicles?.length > 0 &&
                `${
                  filterByColumn(pattern.vehicles, 'part_section').length
                    ? `${filterByColumn(pattern.vehicles, 'part_section')} >`
                    : ''
                } ${
                  filterByColumn(pattern.vehicles, 'pattern_part').length
                    ? `${filterByColumn(pattern.vehicles, 'pattern_part')} >`
                    : ''
                } ${
                  filterByColumn(pattern.vehicles, 'sub_part').length
                    ? `${filterByColumn(pattern.vehicles, 'sub_part')}`
                    : ''
                }`}
            </h1>
          </div>
          <div className={detailsStyles.details_rating}>
            <Rating
              ratingValue={pattern?.ratingAvg ? +pattern.ratingAvg * 20 : 0}
              readonly
              size={20}
              emptyColor="#ededed"
              fillColor="#ffd500"
            />
          </div>
          <div className={detailsStyles.details_info}>
            <button
              onClick={handleUploaderNavigate}
              type="button"
              className={detailsStyles.info_profile}>
              <img src={pattern?.avatar || profileImg} alt="pfp" />
              <span>By {pattern?.nickName}</span>
            </button>
            <div className={detailsStyles.info_reviews}>
              <img src={reviewsImg} alt="reviews" />
              <span>{pattern?.totalReview || 0} Reviews</span>
            </div>
            <div className={detailsStyles.info_downloads}>
              <img src={dowloadsImg} alt="downloads" />
              <span>{pattern?.downloads} Downloaded</span>
            </div>
          </div>
          <hr />
          <div className={detailsStyles.card_container}>
            <div className={detailsStyles.details_pattern}>
              <div className={detailsStyles.pattern_sponsored}>
                <SponsoredMark card={pattern} />
              </div>
              <div className={detailsStyles.pattern_info}>
                <span className={detailsStyles.pattern_upload}>
                  Upload date: {renderDate(pattern?.createdAt)}
                </span>
                <button
                  type="button"
                  onClick={handleDetailsFavClick}
                  className={detailsStyles.pattern_marked}
                  style={favMarked ? favActive : favInactive}>
                  <img src={markedImg} alt="unmarked" />
                </button>
              </div>
              {pattern?.svg && (
                <div className={detailsStyles.details_pattern_img_container}>
                  <img
                    className={detailsStyles.details_pattern_img}
                    src={pattern?.svg}
                    alt="pattern"
                  />
                </div>
              )}
            </div>
            <div className={detailsStyles.details_descr}>
              <h3 className={detailsStyles.descr_title}>Description</h3>
              <p className={detailsStyles.descr_content}>
                {pattern?.description === null ? '' : pattern.description}
              </p>
              <hr />
              <p className={detailsStyles.descr_credit}>
                {(Number(pattern?.unitPrice) * Number(pattern?.sqft)).toFixed(
                  2
                ) || 0}{' '}
                Credits
              </p>
              <hr />
              <div className={detailsStyles.descr_tags}>
                <h3 className={detailsStyles.descr_title}>Tag</h3>
                <div className={detailsStyles.tags_container}>
                  {pattern?.tags &&
                    pattern.tags.split(',').map((tag, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => {
                          localStorage.setItem('searchPatternByTag', tag);
                          navigate('/pattern/list');
                        }}>
                        {tag}
                      </button>
                    ))}
                </div>
              </div>
              <hr />
              <button
                onAuxClick={handleEditorClick}
                onClick={handleEditorClick}
                className={detailsStyles.descr_editor_btn}
                type="button">
                {isMyPatternsPage ? 'edit' : 'OPEN IN EDIT & PLOT'}
              </button>
            </div>
          </div>
        </div>
      )}

      <PatternReviews patternId={patternId} />

      {modalIsOpen && (
        <ModalPaymentEP
          setModalIsOpen={setModalIsOpen}
          action={() => {
            setModalIsOpen(false);
            navigate('/pricing/subscription');
          }}
        />
      )}
    </section>
  );
}

export default PatternDetails;
