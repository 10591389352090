/* eslint-disable import/prefer-default-export */
export const phoneNumberCodes = [
  {
    id: 1,
    value: '+93',
    text: 'Afganistan +93'
  },
  {
    id: 2,
    value: '+1-907',
    text: 'Alaska (USA) +1-907'
  },
  {
    id: 3,
    value: '+355',
    text: 'Albania +355'
  },
  {
    id: 4,
    value: '+213',
    text: 'Algeria +213'
  },
  {
    id: 5,
    value: '+684',
    text: 'American Samoa +684'
  },
  {
    id: 6,
    value: '+376',
    text: 'Andorra +376'
  },
  {
    id: 7,
    value: '+244',
    text: 'Angola +244'
  },
  {
    id: 8,
    value: '+1-264',
    text: 'Anguilla +1-264'
  },
  {
    id: 9,
    value: '+1-268',
    text: 'Antigua & Barbuda +1-268'
  },
  {
    id: 10,
    value: '+54',
    text: 'Argentina +54'
  },
  {
    id: 11,
    value: '+374',
    text: 'Armenia +374'
  },
  {
    id: 12,
    value: '+297',
    text: 'Aruba +297'
  },
  {
    id: 13,
    value: '+247',
    text: 'Ascension +247'
  },
  {
    id: 14,
    value: '+61',
    text: 'Australia +61'
  },
  {
    id: 15,
    value: '+43',
    text: 'Austria +43'
  },
  {
    id: 16,
    value: '+994',
    text: 'Azerbaijan +994'
  },
  {
    id: 17,
    value: '+1-242',
    text: 'Bahamas +1-242'
  },
  {
    id: 18,
    value: '+973',
    text: 'Bahrain +973'
  },
  {
    id: 19,
    value: '+880',
    text: 'Bangladesh +880'
  },
  {
    id: 20,
    value: '+1-246',
    text: 'Barbados +1-246'
  },
  {
    id: 21,
    value: '+375',
    text: 'Belarus +375'
  },
  {
    id: 22,
    value: '+32',
    text: 'Belguim +32'
  },
  {
    id: 23,
    value: '+501',
    text: 'Belize +501'
  },
  {
    id: 24,
    value: '+229',
    text: 'Benin +229'
  },
  {
    id: 25,
    value: '+1-441',
    text: 'Bermuda +1-441'
  },
  {
    id: 26,
    value: '+975',
    text: 'Bhutan +975'
  },
  {
    id: 27,
    value: '+591',
    text: 'Bolivia +591'
  },
  {
    id: 28,
    value: '+387',
    text: 'Bosnia/Herzegovina +387'
  },
  {
    id: 29,
    value: '+267',
    text: 'Botswana +267'
  },
  {
    id: 30,
    value: '+55',
    text: 'Brazil +55'
  },
  {
    id: 31,
    value: '+1-284',
    text: 'British Virginislands +1-284'
  },
  {
    id: 32,
    value: '+673',
    text: 'Brunei +673'
  },
  {
    id: 33,
    value: '+359',
    text: 'Bulgaria +359'
  },
  {
    id: 34,
    value: '+226',
    text: 'Bukina Faso +226'
  },
  {
    id: 35,
    value: '+257',
    text: 'Burundi +257'
  },
  {
    id: 36,
    value: '+855',
    text: 'Cambodia +855'
  },
  {
    id: 37,
    value: '+237',
    text: 'Cameroon +237'
  },
  {
    id: 38,
    value: '+1',
    text: 'Canada +1'
  },
  {
    id: 39,
    value: '+238',
    text: 'Cape Verde +238'
  },
  {
    id: 40,
    value: '+1-345',
    text: 'Cayman Islands +1-345'
  },
  {
    id: 41,
    value: '+236',
    text: 'Central African Rrepublic +236'
  },
  {
    id: 42,
    value: '+235',
    text: 'Chad +235'
  },
  {
    id: 43,
    value: '+56',
    text: 'Chile +56'
  },
  {
    id: 44,
    value: '+86',
    text: 'China +86'
  },
  {
    id: 45,
    value: '+57',
    text: 'Colombia +57'
  },
  {
    id: 46,
    value: '+269',
    text: 'Comoros +269'
  },
  {
    id: 47,
    value: '+242',
    text: 'Congo +242'
  },
  {
    id: 48,
    value: '+243',
    text: 'Congo. Dem. Rep +243'
  },
  {
    id: 49,
    value: '+682',
    text: 'Cook Island +682'
  },
  {
    id: 50,
    value: '+506',
    text: 'Costa Rica +506'
  },
  {
    id: 51,
    value: '+385',
    text: 'Croatia +385'
  },
  {
    id: 52,
    value: '+53',
    text: 'Cuba +53'
  },
  {
    id: 53,
    value: '+357',
    text: 'Cyprus +357'
  },
  {
    id: 54,
    value: '+420',
    text: 'Czech Republic +420'
  },
  {
    id: 55,
    value: '+45',
    text: 'Denmark +45'
  },
  {
    id: 56,
    value: '+246',
    text: 'Diego Garcia +246'
  },
  {
    id: 57,
    value: '+253',
    text: 'Djibouti +253'
  },
  {
    id: 58,
    value: '+1-767',
    text: 'Dominica +1-767'
  },
  {
    id: 59,
    value: '+1-809',
    text: 'Dominican Republic +1-809'
  },
  {
    id: 60,
    value: '+670',
    text: 'East Timor +670'
  },
  {
    id: 61,
    value: '+593',
    text: 'Ecuador +593'
  },
  {
    id: 62,
    value: '+20',
    text: 'Egypt +20'
  },
  {
    id: 63,
    value: '+503',
    text: 'El Salvador +503'
  },
  {
    id: 64,
    value: '+240',
    text: 'Equatorial Guinea +240'
  },
  {
    id: 65,
    value: '+291',
    text: 'Eritrea +291'
  },
  {
    id: 66,
    value: '+372',
    text: 'Estonia +372'
  },
  {
    id: 67,
    value: '+251',
    text: 'Ethiopia +251'
  },
  {
    id: 68,
    value: '+500',
    text: 'Falkland Islands +500'
  },
  {
    id: 69,
    value: '+298',
    text: 'Faroe Islands +298'
  },
  {
    id: 70,
    value: '+679',
    text: 'Fiji +679'
  },
  {
    id: 71,
    value: '+358',
    text: 'Finland +358'
  },
  {
    id: 72,
    value: '+33',
    text: 'France +33'
  },
  {
    id: 73,
    value: '+594',
    text: 'French Guiana +594'
  },
  {
    id: 74,
    value: '+689',
    text: 'French Polynesia +689'
  },
  {
    id: 75,
    value: '+241',
    text: 'Gabon +241'
  },
  {
    id: 76,
    value: '+220',
    text: 'Gambia +220'
  },
  {
    id: 77,
    value: '+995',
    text: 'Georgia +995'
  },
  {
    id: 78,
    value: '+49',
    text: 'Germany +49'
  },
  {
    id: 79,
    value: '+233',
    text: 'Ghana +233'
  },
  {
    id: 80,
    value: '+350',
    text: 'Gibraltar +350'
  },
  {
    id: 81,
    value: '+30',
    text: 'Greece +30'
  },
  {
    id: 82,
    value: '+299',
    text: 'Greenland +299'
  },
  {
    id: 83,
    value: '+1-473',
    text: 'Grenada +1-473'
  },
  {
    id: 84,
    value: '+590',
    text: 'Guadaloupe +590'
  },
  {
    id: 85,
    value: '+1-671',
    text: 'Guam +1-671'
  },
  {
    id: 86,
    value: '+502',
    text: 'Guatemala +502'
  },
  {
    id: 87,
    value: '+224',
    text: 'Guinea +224'
  },
  {
    id: 88,
    value: '+245',
    text: 'Guinea Bissau +245'
  },
  {
    id: 89,
    value: '+592',
    text: 'Guyana +592'
  },
  {
    id: 90,
    value: '+509',
    text: 'Haiti +509'
  },
  {
    id: 91,
    value: '+1-808',
    text: 'Hawaii (USA) +1-808'
  },
  {
    id: 92,
    value: '+504',
    text: 'Honduras +504'
  },
  {
    id: 93,
    value: '+852',
    text: 'Hong Kong +852'
  },
  {
    id: 94,
    value: '+36',
    text: 'Hungary +36'
  },
  {
    id: 95,
    value: '+354',
    text: 'Iceland +354'
  },
  {
    id: 96,
    value: '+91',
    text: 'India +91'
  },
  {
    id: 97,
    value: '+62',
    text: 'Indonesia +62'
  },
  {
    id: 98,
    value: '+98',
    text: 'Iran +98'
  },
  {
    id: 99,
    value: '+964',
    text: 'Iraq +964'
  },
  {
    id: 100,
    value: '+353',
    text: 'Ireland +353'
  },
  {
    id: 101,
    value: '+972',
    text: 'Israel +972'
  },
  {
    id: 102,
    value: '+39',
    text: 'Italy +39'
  },
  {
    id: 103,
    value: '+225',
    text: 'Ivory Coast +225'
  },
  {
    id: 104,
    value: '+1-876',
    text: 'Jamaica +1-876'
  },
  {
    id: 105,
    value: '+81',
    text: 'Japan +81'
  },
  {
    id: 106,
    value: '+962',
    text: 'Jordan +962'
  },
  {
    id: 107,
    value: '+7',
    text: 'Kazakhstan +7'
  },
  {
    id: 108,
    value: '+254',
    text: 'Kenya +254'
  },
  {
    id: 109,
    value: '+686',
    text: 'Kiribati +686'
  },
  {
    id: 110,
    value: '+850',
    text: 'Korea, North +850'
  },
  {
    id: 111,
    value: '+82',
    text: 'Korea, South +82'
  },
  {
    id: 112,
    value: '+965',
    text: 'Kuwait +965'
  },
  {
    id: 113,
    value: '+996',
    text: 'Kyrgyzstan +996'
  },
  {
    id: 114,
    value: '+856',
    text: 'Laos +856'
  },
  {
    id: 115,
    value: '+371',
    text: 'Laivia +371'
  },
  {
    id: 116,
    value: '+961',
    text: 'Lebanon +961'
  },
  {
    id: 117,
    value: '+266',
    text: 'Lesotho +266'
  },
  {
    id: 118,
    value: '+231',
    text: 'Liberia +231'
  },
  {
    id: 119,
    value: '+218',
    text: 'Libya +218'
  },
  {
    id: 120,
    value: '+423',
    text: 'Liechtenstein +423'
  },
  {
    id: 121,
    value: '+370',
    text: 'Lithuania +370'
  },
  {
    id: 122,
    value: '+352',
    text: 'Luxembourg +352'
  },
  {
    id: 123,
    value: '+853',
    text: 'Macau +853'
  },
  {
    id: 124,
    value: '+389',
    text: 'Macedonia +389'
  },
  {
    id: 125,
    value: '+261',
    text: 'Madagascar +261'
  },
  {
    id: 126,
    value: '+265',
    text: 'Malawi +265'
  },
  {
    id: 127,
    value: '+60',
    text: 'Malaysia +60'
  },
  {
    id: 128,
    value: '+960',
    text: 'Maldives +960'
  },
  {
    id: 129,
    value: '+223',
    text: 'Mali +223'
  },
  {
    id: 130,
    value: '+356',
    text: 'Malta +356'
  },
  {
    id: 131,
    value: '+1-670',
    text: 'Mariana +1-670'
  },
  {
    id: 132,
    value: '+692',
    text: 'Marshall Island +692'
  },
  {
    id: 133,
    value: '+596',
    text: 'Martinique +596'
  },
  {
    id: 134,
    value: '+222',
    text: 'Mauritania +222'
  },
  {
    id: 135,
    value: '+230',
    text: 'Mauritius +230'
  },
  {
    id: 136,
    value: '+269',
    text: 'Mayotte +269'
  },
  {
    id: 137,
    value: '+52',
    text: 'Mexico +52'
  },
  {
    id: 138,
    value: '+691',
    text: 'Micronesia +691'
  },
  {
    id: 139,
    value: '+373',
    text: 'Moldova +373'
  },
  {
    id: 140,
    value: '+377',
    text: 'Monaco +377'
  },
  {
    id: 141,
    value: '+976',
    text: 'Mongolia +976'
  },
  {
    id: 142,
    value: '+1-664',
    text: 'Montserrat +1-664'
  },
  {
    id: 143,
    value: '+212',
    text: 'Morocco +212'
  },
  {
    id: 144,
    value: '+258',
    text: 'Mozambique +258'
  },
  {
    id: 145,
    value: '+95',
    text: 'Myanmar +95'
  },
  {
    id: 146,
    value: '+264',
    text: 'Namibia +264'
  },
  {
    id: 147,
    value: '+674',
    text: 'Nauru +674'
  },
  {
    id: 148,
    value: '+977',
    text: 'Nepal +977'
  },
  {
    id: 149,
    value: '+31',
    text: 'Netherlands +31'
  },
  {
    id: 150,
    value: '+599',
    text: 'Netherlands Antilles +599'
  },
  {
    id: 151,
    value: '+687',
    text: 'New Caledonia +687'
  },
  {
    id: 152,
    value: '+64',
    text: 'New Zealand +64'
  },
  {
    id: 153,
    value: '+505',
    text: 'Nicaragua +505'
  },
  {
    id: 154,
    value: '+227',
    text: 'Niger +227'
  },
  {
    id: 155,
    value: '+234',
    text: 'Niregia +234'
  },
  {
    id: 156,
    value: '+683',
    text: 'Niue Island +683'
  },
  {
    id: 157,
    value: '+47',
    text: 'Norway +47'
  },
  {
    id: 158,
    value: '+968',
    text: 'Oman +968'
  },
  {
    id: 159,
    value: '+92',
    text: 'Pakistan +92'
  },
  {
    id: 160,
    value: '+680',
    text: 'Palau +680'
  },
  {
    id: 161,
    value: '+970',
    text: 'Palestine +970'
  },
  {
    id: 162,
    value: '+507',
    text: 'Panama +507'
  },
  {
    id: 163,
    value: '+675',
    text: 'Papua New Guinea +675'
  },
  {
    id: 164,
    value: '+595',
    text: 'Paraguay +595'
  },
  {
    id: 165,
    value: '+51',
    text: 'Peru +51'
  },
  {
    id: 166,
    value: '+63',
    text: 'Philippines +63'
  },
  {
    id: 167,
    value: '+48',
    text: 'Poland +48'
  },
  {
    id: 168,
    value: '+351',
    text: 'Portugal +351'
  },
  {
    id: 169,
    value: '+1-787',
    text: 'Puerto Rico (I) +1-787'
  },
  {
    id: 170,
    value: '+1-939',
    text: 'Puerto Rico (II) +1-939'
  },
  {
    id: 171,
    value: '+974',
    text: 'Qatar +974'
  },
  {
    id: 172,
    value: '+262',
    text: 'Reunion +262'
  },
  {
    id: 173,
    value: '+40',
    text: 'Romania +40'
  },
  {
    id: 174,
    value: '+7',
    text: 'Russia +7'
  },
  {
    id: 175,
    value: '+250',
    text: 'Rwanda +250'
  },
  {
    id: 176,
    value: '+685',
    text: 'Samoa Western +685'
  },
  {
    id: 177,
    value: '+378',
    text: 'San Marino +378'
  },
  {
    id: 178,
    value: '+239',
    text: 'Sao Tome & Principe +239'
  },
  {
    id: 179,
    value: '+966',
    text: 'Saudi Arabia +966'
  },
  {
    id: 180,
    value: '+221',
    text: 'Senegal +221'
  },
  {
    id: 181,
    value: '+248',
    text: 'Seychelles +248'
  },
  {
    id: 182,
    value: '+232',
    text: 'Sierra Leone +232'
  },
  {
    id: 183,
    value: '+65',
    text: 'Singapore +65'
  },
  {
    id: 184,
    value: '+421',
    text: 'Slovakia +421'
  },
  {
    id: 185,
    value: '+386',
    text: 'Slovenia +386'
  },
  {
    id: 186,
    value: '+677',
    text: 'Solomon Islands +677'
  },
  {
    id: 187,
    value: '+252',
    text: 'Somalia +252'
  },
  {
    id: 188,
    value: '+27',
    text: 'South Africa +27'
  },
  {
    id: 189,
    value: '+34',
    text: 'Spain +34'
  },
  {
    id: 190,
    value: '+94',
    text: 'Sri Lanka +94'
  },
  {
    id: 191,
    value: '+290',
    text: 'St Helena +290'
  },
  {
    id: 192,
    value: '+1-869',
    text: 'St Kitts & Nevis +1-869'
  },
  {
    id: 193,
    value: '+1-758',
    text: 'St Lucia +1-758'
  },
  {
    id: 194,
    value: '+1-784',
    text: 'St Vincent & Grenadines +1-784'
  },
  {
    id: 195,
    value: '+508',
    text: 'St Pierre & Miquelon +508'
  },
  {
    id: 196,
    value: '+249',
    text: 'Sudan +249'
  },
  {
    id: 197,
    value: '+597',
    text: 'Suriname +597'
  },
  {
    id: 198,
    value: '+268',
    text: 'Swaziland +268'
  },
  {
    id: 199,
    value: '+46',
    text: 'Sweden +46'
  },
  {
    id: 200,
    value: '+41',
    text: 'Switzerland +41'
  },
  {
    id: 201,
    value: '+963',
    text: 'Syria +963'
  },
  {
    id: 202,
    value: '+886',
    text: 'Taiwan +886'
  },
  {
    id: 203,
    value: '+992',
    text: 'Tajikistan +992'
  },
  {
    id: 204,
    value: '+255',
    text: 'Tanzania +255'
  },
  {
    id: 205,
    value: '+66',
    text: 'Thailand +66'
  },
  {
    id: 206,
    value: '+228',
    text: 'Togo +228'
  },
  {
    id: 207,
    value: '+690',
    text: 'Tokelau +690'
  },
  {
    id: 208,
    value: '+676',
    text: 'Tonga +676'
  },
  {
    id: 209,
    value: '+1-868',
    text: 'Trinidad & Tobago +1-868'
  },
  {
    id: 210,
    value: '+216',
    text: 'Tunisia +216'
  },
  {
    id: 211,
    value: '+90',
    text: 'Turkey +90'
  },
  {
    id: 212,
    value: '+993',
    text: 'Turkmenistan +993'
  },
  {
    id: 213,
    value: '+1-649',
    text: 'Turks & Caicosislands +1-649'
  },
  {
    id: 214,
    value: '+688',
    text: 'Tuvalu +688'
  },
  {
    id: 215,
    value: '+256',
    text: 'Uganda +256'
  },
  {
    id: 216,
    value: '+380',
    text: 'Ukraine +380'
  },
  {
    id: 217,
    value: '+971',
    text: 'United Arab Emirates +971'
  },
  {
    id: 218,
    value: '+44',
    text: 'United Kingdom +44'
  },
  {
    id: 219,
    value: '+598',
    text: 'Uruguay +598'
  },
  {
    id: 220,
    value: '+998',
    text: 'Uzbekistan +998'
  },
  {
    id: 221,
    value: '+678',
    text: 'Vanuatu +678'
  },
  {
    id: 222,
    value: '+39',
    text: 'Vatican City +39'
  },
  {
    id: 223,
    value: '+58',
    text: 'Venezuela +58'
  },
  {
    id: 224,
    value: '+84',
    text: 'Vietnam +84'
  },
  {
    id: 225,
    value: '+1-340',
    text: 'Virgin Islands, U.S. +1-340'
  },
  {
    id: 226,
    value: '+681',
    text: 'Wallis & Futuna +681'
  },
  {
    id: 227,
    value: '+967',
    text: 'Yemen +967'
  },
  {
    id: 228,
    value: '+381',
    text: 'Yugoslavia +381'
  },
  {
    id: 229,
    value: '+260',
    text: 'Zambia +260'
  },
  {
    id: 230,
    value: '+255',
    text: 'Zanzibar +255'
  },
  {
    id: 231,
    value: '+263',
    text: 'Zimbabwe +263'
  }
];
