/* eslint-disable react/function-component-definition */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import useToast from '../hooks/useToast';

function withToast(Component) {
  return (props) => {
    const showToast = useToast();
    return <Component {...props} showToast={showToast} />;
  };
}

export default withToast;
