/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import cardStyles from './ShopCard.module.scss';

import cartIcon from '../../../../img/icon_cart.svg';
import { addToCart } from '../../../../api/ultrafitShop';
import { useStore } from '../../../../store/hooks';
import { getCartItems, incCartItems } from '../../../../store/actions/cart';
import { executeAsync } from '../../../../utils/common';
import { TOAST_TYPE } from '../../../../utils/constants';
import withToast from '../../../HOC/withToast';
import ModalAddToCart from '../ModalAddToCart/ModalAddToCart';

function ShopCard({ ultrafit, showToast }) {
  const navigate = useNavigate();
  const [state, dispatch] = useStore();
  const isProcess = useRef(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleAddToCart = async () => {
    if (isProcess.current) return;

    const [res, err] = await executeAsync(() =>
      addToCart({
        productId: ultrafit.id,
        quantity: 1,
        price: ultrafit.price,
        inches: ultrafit?.options?.[0].inches
      })
    );
    if (err) {
      showToast(
        TOAST_TYPE.ERROR,
        err?.message ?? 'Cannot add to cart, please try later!'
      );
      isProcess.current = false;
      return;
    }
    const { data } = res || {};
    const { orderDetail } = data || [];
    let cartsLength = 0;
    if (orderDetail?.length > 0) {
      cartsLength = orderDetail?.reduce(
        (prevVal, currVal) => prevVal + currVal.quantity,
        0
      );
    }
    // dispatch(
    //   getCartItems({
    //     length: cartsLength,
    //     listCart: orderDetail
    //   })
    // );
    dispatch(incCartItems(cartsLength));
    isProcess.current = false;
    setModalIsOpen(true);
  };

  // const handleCardClick = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  return (
    <div
      className={cardStyles.card_container}
      // onClick={handleCardClick}
    >
      <div className={cardStyles.card_product}>
        <img
          src={ultrafit?.image}
          onClick={() => navigate(`/shop/${ultrafit.id}/info`)}
          alt="product"
        />
      </div>
      <button
        className={cardStyles.card_cart_btn}
        type="button"
        onClick={handleAddToCart}>
        <img src={cartIcon} alt="" />
      </button>
      <div className={cardStyles.card_info}>
        <h3>{ultrafit?.name}</h3>
        <p>{ultrafit?.category1?.category}</p>
        <button
          onClick={() => navigate(`/shop/${ultrafit.id}/info`)}
          type="button">
          buy
        </button>
      </div>
      {modalIsOpen && (
        <ModalAddToCart
          setModalIsOpen={setModalIsOpen}
          action={() => {
            setModalIsOpen(false);
            navigate('/shop/cart');
          }}
        />
      )}
    </div>
  );
}

export default withToast(ShopCard);
