/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import {
  getCategories,
  getPatternsCategoriesCount
} from '../../../api/categories';
import Dropdown from './Dropdown';
import dropdownStyles from './DropdownFilter.module.scss';

function DropdownFilter({
  uploaderId,
  setSelectedSideCategories,
  isMyPatternPage
}) {
  const [patternCategories, setPatternCategories] = useState([]);

  const countVehiclesByCategory = (node, vehicles) => {
    let count = 0;
    vehicles.forEach((vehicle) => {
      if (vehicle.vehicleId === node.id) {
        count += Number(vehicle.patternTotal);
      }
    });
    return count;
  };

  const countVehiclesByCategoryTree = (node, vehicles) => {
    let count = 0;
    if (!node.subCategory?.length) {
      vehicles.forEach((vehicle) => {
        if (vehicle.vehicleId === node.id) {
          count += Number(vehicle.patternTotal);
        }
      });
    } else {
      count += countVehiclesByCategory(node, vehicles);
      for (const childNode of node.subCategory) {
        count += countVehiclesByCategoryTree(childNode, vehicles);
      }
    }
    return count;
  };

  const fetchCategories = async () => {
    const categoriesCountData = await getPatternsCategoriesCount({
      uploaderId,
      ...(isMyPatternPage && { status: 'approved,stop_selling' })
    });
    const patternCategoriesResponse = await getCategories();

    setPatternCategories(
      patternCategoriesResponse
        .filter(
          (category) =>
            category.column === 'part_section' && category.type === 'category'
        )
        .sort((categoryA, categoryB) => categoryA.order - categoryB.order)
        // .sort((categoryA, categoryB) =>
        //   categoryA.category.localeCompare(categoryB.category)
        // )
        .map((category) => ({
          id: category.id,
          part: category.category,
          total: countVehiclesByCategoryTree(category, categoriesCountData),
          subParts: category.subCategory
            .sort((catA, catB) => catA.order - catB.order)
            .map((subCat) => ({
              isChecked: false,
              id: subCat.id,
              category: subCat.category,
              column: subCat.column,
              total: countVehiclesByCategoryTree(subCat, categoriesCountData)
            }))
        }))
    );
  };

  useEffect(() => {
    fetchCategories();
  }, [uploaderId, isMyPatternPage]);

  useEffect(() => {
    const selectedCategoriesIds = patternCategories
      .map((category) =>
        category.subParts
          .reduce((selectedSubParts, subPart) => {
            if (subPart.isChecked) {
              selectedSubParts.push(subPart.id);
            }

            return selectedSubParts;
          }, [])
          .join(',')
      )
      .filter((selectedCategories) => selectedCategories.length)
      .join();

    setSelectedSideCategories(selectedCategoriesIds);
  }, [patternCategories]);

  return (
    <div className={dropdownStyles.dropdown_container}>
      {patternCategories.map((dropdownCategory) => (
        <Dropdown
          key={dropdownCategory.id}
          dropDownCategory={dropdownCategory}
          setDropdownState={setPatternCategories}
        />
      ))}
    </div>
  );
}

export default DropdownFilter;
