import modalStyles from './MyProjectConfirmDeleteModal.module.scss';

function MyProjectDeleteModal({ multiplePatterns, onCancel, onDelete }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h2 className={modalStyles.modal_title}>delete</h2>
        <p className={modalStyles.modal_text}>
          Are you sure you want to delete the pattern/s?
        </p>
        <hr />
        <div className={modalStyles.modal_controls}>
          <button
            type="button"
            className={modalStyles.modal_cancel}
            onClick={onCancel}>
            cancel
          </button>
          <button
            type="button"
            className={modalStyles.modal_confirm}
            onClick={onDelete}>
            delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyProjectDeleteModal;
