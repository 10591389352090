import { useState, useEffect } from 'react';
import { renderDate } from '../../utils/function';
import patternCardsStyles from './PatternCard.module.scss';

function PatternSponsorPeriod({ sponsor }) {
  const [sponsorshipPeriod, setSponsorshipPeriod] = useState(null);

  useEffect(() => {
    const [start, end] = sponsor.split('/');

    setSponsorshipPeriod({
      start,
      end
    });
  }, []);

  return sponsorshipPeriod ? (
    <span className={patternCardsStyles.sponsor_period}>
      Sponsor Period : {renderDate(sponsorshipPeriod.start)} ~{' '}
      {renderDate(sponsorshipPeriod.end)}
    </span>
  ) : null;
}

export default PatternSponsorPeriod;
