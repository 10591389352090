/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const getMyCoupons = ({
  registration_from = '',
  registration_to = '',
  type = '',
  page = 1,
  limit = 20
}) =>
  customFetch(
    `${apiUrl}/coupons/my?${
      registration_from ? `&registration_from=${registration_from}` : ''
    }${registration_to ? `&registration_to=${registration_to}` : ''}${
      type ? `&type=${type}` : ''
    }${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();
        throw new Error(data.message);
      }

      return data;
    });

export const getMyCouponsAvailable = () =>
  customFetch(`${apiUrl}/coupons/my?type=available`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();
        throw new Error(data.message);
      }

      return data;
    });

export const getMyCouponsAutomatic = () =>
  customFetch(`${apiUrl}/coupons/automatic`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        window.location.reload();
        throw new Error(data.message);
      }

      return data;
    });
