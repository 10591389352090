/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MyPageTitle from '../MyPageTitle/MyPageTitle';
import MyProjectDeleteModal from './MyProjectConfirmDeleteModal/MyProjectConfirmDeleteModal';

import myProjectStyles from '../MyPage.module.scss';
import MyProjectListItem from './MyProjectListItem';
import {
  deleteMyProjectPattern,
  getPatternByProjectId
} from '../../../api/patterns';
import { editorUrl } from '../../../utils/editorURL';
import { filterByColumn } from '../../../utils/function';
import { useAlert } from '../../hooks/useAlert/useAlert';
// import { formatDate2 } from '../../../utils/function';

function MyProjectList() {
  const navgiate = useNavigate();

  const { id } = useParams();

  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
  const [project, setProject] = useState({});
  const [projectPatterns, setProjectPatterns] = useState([]);
  const [checkedPatterns, setCheckedPatterns] = useState([]);

  const { onAlert } = useAlert();

  const fetchPatterns = async () => {
    const patternByProjectIdRes = await getPatternByProjectId({
      id
    });

    if (
      patternByProjectIdRes?.projectPatterns?.length <= 1 ||
      patternByProjectIdRes.errorStatus === 404
    ) {
      navgiate('/myproject/main');
      return;
    }

    const sortedProjectPatterns = patternByProjectIdRes.projectPatterns.sort(
      (projectPattern1, projectPattern2) => {
        const patternData1 = projectPattern1.pattern;
        const patternData2 = projectPattern2.pattern;

        const patternRegion1 = filterByColumn(patternData1.models, 'region');
        const patternRegion2 = filterByColumn(patternData2.models, 'region');

        if (patternRegion1 > patternRegion2) return 1;
        if (patternRegion1 < patternData2) return -1;

        return 0;
      }
    );

    setProject(patternByProjectIdRes);
    setProjectPatterns(sortedProjectPatterns);
  };

  const handlePatternCheck = (e, pattern) => {
    const { checked } = e.target;
    const { patternId } = pattern;

    if (
      checked &&
      checkedPatterns.map((checkedId) => checkedId).indexOf(patternId) === -1
    ) {
      setCheckedPatterns((prevChecked) => [...prevChecked, pattern]);
      return;
    }

    setCheckedPatterns((prevCheckedPatterns) =>
      prevCheckedPatterns.filter(
        (prevCheckedPattern) =>
          prevCheckedPattern.patternId !== pattern.patternId
      )
    );
  };

  const handleDeletePatterns = async () => {
    if (!checkedPatterns.length) return;

    await deleteMyProjectPattern({
      projectId: +id,
      projectPatternIds: checkedPatterns?.map((cp) => cp?.id)
    });

    setTimeout(() => {
      fetchPatterns();
    }, 1000);

    setCheckedPatterns([]);
    setDeleteConfirmIsOpen(false);
  };

  const handleOpenInEditor = () => {
    const authToken = localStorage.getItem('token');
    if (!checkedPatterns.length) return;

    const refreshToken = localStorage.getItem('refreshToken');
    const deletedPatterns = checkedPatterns.filter(
      (checkedPattern) =>
        checkedPattern?.pattern?.deletedAt ||
        checkedPattern?.pattern?.status !== 'approved'
    );

    if (deletedPatterns.length) {
      onAlert('', 'Okay', 'Some of the selected patterns have been deleted.');
      return;
    }

    window.open(
      `${editorUrl}?fromUserWeb&projectPatternId=${checkedPatterns
        .map((checkedPattern) => checkedPattern.patternId)
        .join(
          ','
        )}&projectId=${id}&token=${authToken}&refreshToken=${refreshToken}`
    );
  };

  useEffect(() => {
    console.log(checkedPatterns);
  }, [checkedPatterns]);

  useEffect(() => {
    if (id) {
      fetchPatterns();
    }
  }, [id]);

  return (
    <main className={myProjectStyles.main}>
      <div className={myProjectStyles.content}>
        <MyPageTitle
          title="my project"
          isMyProjectAssetListPage
          onFilter={(from, to) => {
            localStorage.setItem(
              'myProjectFilterDates',
              JSON.stringify({
                from: new Date(from),
                to: new Date(to)
              })
            );

            navgiate(-1);
          }}
        />
        <hr className={myProjectStyles.upper_hr} />
        <h3 className={myProjectStyles.sub_title}>
          <strong>{`${project?.name ? `"${project.name}"` : ''}`}</strong>{' '}
          PATTERN ASSET LIST
        </h3>
        <div className={myProjectStyles.cards_container}>
          {projectPatterns?.map((pattern) => (
            <MyProjectListItem
              key={pattern.id}
              pattern={pattern}
              onCheck={(e, clickedPattern) =>
                handlePatternCheck(e, clickedPattern)
              }
            />
          ))}
        </div>
        <div className={myProjectStyles.project_controls}>
          <button
            type="button"
            disabled={!checkedPatterns.length}
            className={myProjectStyles.controls_open_in_editor}
            onClick={handleOpenInEditor}>
            open in editor
          </button>
          <button
            disabled={!checkedPatterns.length}
            type="button"
            className={myProjectStyles.controls_delete}
            onClick={() => setDeleteConfirmIsOpen(true)}>
            delete
          </button>
        </div>
      </div>
      {deleteConfirmIsOpen && (
        <MyProjectDeleteModal
          multiplePatterns={checkedPatterns.length > 1}
          onCancel={() => setDeleteConfirmIsOpen(false)}
          onDelete={handleDeletePatterns}
        />
      )}
    </main>
  );
}

export default MyProjectList;
