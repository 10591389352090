/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import PatternCard from '../../PatternCard/PatternCard';
import MyPageTitle from '../MyPageTitle/MyPageTitle';
import bookmarkStyles from '../MyPage.module.scss';
import { getPatterns } from '../../../api/patterns';

import Pagination from '../../Pagination/Pagination';

function Bookmark() {
  const projectsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });

  const [bookmarkedPatterns, setBookmarkedPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async ({
    from = '',
    to = '',
    page = 1,
    limit = projectsOnPage
  }) => {
    const bookmarkedPatternsRes = await getPatterns({
      from,
      to,
      page,
      limit,
      bookMarked: true
    });

    setBookmarkedPatterns(bookmarkedPatternsRes.data);
    setTotalCount(bookmarkedPatternsRes.count);
  };

  useEffect(() => {
    fetchData({
      from: filterDates.from,
      to: filterDates.to,
      page: currentPage
    });
  }, [currentPage, filterDates]);

  return (
    <main className={bookmarkStyles.main}>
      <div className={bookmarkStyles.content}>
        <MyPageTitle
          title={`Bookmark (${totalCount})`}
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
          isRequiredReset
        />
        <hr className={bookmarkStyles.upper_hr} />
        <div className={bookmarkStyles.cards_container}>
          {bookmarkedPatterns.map((card) => (
            <PatternCard
              key={card.patternId}
              card={card}
              type="bookmark"
              onRemoveBookmark={() =>
                fetchData({
                  from: filterDates.from,
                  to: filterDates.to,
                  page: currentPage
                })
              }
              isBookmarkPage
            />
          ))}
        </div>
        {totalCount > projectsOnPage && (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={projectsOnPage}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </main>
  );
}

export default Bookmark;
