import modalStyles from './ModalCheckExpired.module.scss';

function ModalCheckExpired({ setModalIsOpen, action }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h2 className={modalStyles.modal_title}>&nbsp;</h2>
        <p className={modalStyles.modal_text}>
          You have an unexpired package. Would you like to purchase new package?
        </p>
        <hr />
        <div className={modalStyles.modal_controls}>
          <button
            type="button"
            className={modalStyles.modal_confirm}
            onClick={() => action()}>
            OK
          </button>
          <button
            type="button"
            className={modalStyles.modal_cancel}
            onClick={() => setModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalCheckExpired;
