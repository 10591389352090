/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import findPassStyles from '../Forms.module.scss';
import findPassScopedStyles from './FindPass.module.scss';
import { sendCode, verifyCode } from '../../../api/auth';

function FindPass() {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  const [codeSent, setCodeSent] = useState(false);

  const [isVerified, setIsVerified] = useState(false);

  // sends verification code to email
  const sendEmailCode = async () => {
    const userEmail = getValues('email');

    if (!userEmail) {
      setError('email', { type: 'custom', message: 'Email is required.' });
      return;
    }

    const emailData = { email: userEmail, type: 'forgotPassword' };

    const sendCodeRes = await sendCode(emailData);

    if (
      sendCodeRes.errorStatus === 400 &&
      sendCodeRes.message === 'User not found'
    ) {
      setError('email', {
        type: 'custom',
        message: 'User not found.'
      });
      return;
    }

    if (sendCodeRes.errorStatus) {
      setError('email', {
        type: 'custom',
        message: 'Email must be a valid email address.'
      });
      return;
    }

    setCodeSent(true);
  };

  // changes verification code state
  const verifyHandler = async () => {
    const verifyData = {
      email: getValues('email'),
      code: getValues('code'),
      type: 'forgotPassword'
    };

    const verificationRes = await verifyCode(verifyData);

    if (!verificationRes.data) {
      setIsVerified(false);
      setError('code', { type: 'custom', message: 'Wrong verification code.' });
      return;
    }

    setIsVerified(true);
  };

  const nextBtnClick = (data) => {
    sessionStorage.setItem('emailResetPass', data.email);
    sessionStorage.setItem('codeResetPass', data.code);
    navigate('/new_pass');
  };

  return (
    <section className={findPassStyles.form_section}>
      <form
        onSubmit={handleSubmit(nextBtnClick)}
        className={findPassStyles.form}>
        <h1 className={findPassStyles.title} style={{ marginBottom: '60px' }}>
          Find password
        </h1>
        <div className={findPassStyles.form_container}>
          <label className={findPassScopedStyles.email_label} htmlFor="email">
            <p>E-mail</p>
            <input
              onInput={() => clearErrors('email')}
              {...register('email', { required: 'Email is required' })}
              name="email"
              type="email"
            />
            <button
              type="button"
              onClick={sendEmailCode}
              className={`${findPassScopedStyles.input_btn} ${findPassScopedStyles.btn_send}`}>
              {codeSent ? 'resend' : 'send'}
            </button>
          </label>
          <label className={findPassScopedStyles.code_label} htmlFor="code">
            <p>Verification code</p>
            <input
              onInput={() => {
                clearErrors('code');
                setIsVerified(false);
              }}
              {...register('code', {
                required: 'Please enter verification code.'
              })}
              name="code"
              type="text"
            />
            <button
              type="button"
              onClick={verifyHandler}
              className={`${findPassScopedStyles.input_btn} ${findPassScopedStyles.btn_verify}`}
              disabled={!codeSent || !getValues('code')}>
              verify
            </button>
            <p
              className={findPassStyles.validation_warning}
              style={{ position: 'absolute', bottom: '-30px' }}>
              {!isVerified && errors.code?.message}
            </p>
            {isVerified && (
              <p
                className={findPassStyles.validation_warning}
                style={{
                  color: '#4bb3ff',
                  position: 'absolute',
                  bottom: '-30px'
                }}>
                Certified.
              </p>
            )}
          </label>
          <div className={findPassStyles.warnings_holder}>
            <p className={findPassStyles.validation_warning}>
              {errors.email?.message}
            </p>
          </div>
          <button
            className={findPassStyles.submit_btn}
            style={{ marginBottom: '0' }}
            type="submit"
            disabled={!isVerified}>
            next
          </button>
        </div>
      </form>
    </section>
  );
}

export default FindPass;
