/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { getPopUpList } from '../../api/adminPopUp';

import SelectorFilter from '../SelectorFilter/SelectorFilter';

import heroStyles from './Hero.module.scss';
import { formatLink, openInNewTab } from '../../utils/function';

function Hero({ setSelectedCategory }) {
  const [heroBanner, setHeroBanner] = useState({});

  const fetchBanners = async () => {
    const getBannersRes = await getPopUpList(
      '메인페이지, Top Banner',
      'banner'
    );

    setHeroBanner(getBannersRes[0]);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const handleNavigateLink = () => {
    // if (heroBanner?.linkTarget) {
    //   window.open(
    //     `${heroBanner?.linkTarget}`,
    //     '_blank',
    //     'rel=noopener noreferrer'
    //   );
    // }
    if (heroBanner?.linkTarget) {
      // window.open(formatLink(heroBanner?.linkTarget), '_blank');
      openInNewTab(heroBanner?.linkTarget);
    }
  };

  return (
    <section
      onClick={handleNavigateLink}
      className={heroStyles.hero}
      style={
        heroBanner?.file
          ? {
              backgroundImage: `url(${heroBanner?.file})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize:
                heroBanner?.option?.width && heroBanner?.option?.height
                  ? `${heroBanner?.option?.width}px ${heroBanner?.option?.height}px`
                  : 'cover',
              backgroundPosition: 'center'
            }
          : {}
      }>
      <div className={heroStyles.container}>
        <h1 className={heroStyles.title}>
          Find Your Paint Protection Film Cutting File.
        </h1>
        <SelectorFilter isRedirect setSelectedCategory={setSelectedCategory} />
      </div>
    </section>
  );
}

export default Hero;
