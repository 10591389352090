import close from '../../../../img/close_new_pattern.svg';
import tagStyles from './NewPatternTags.module.scss';

function NewPatternTag({ tagname, tags, setTags }) {
  const removeTag = () => {
    setTags(tags.filter((tag) => tag !== tagname));
  };

  return (
    <div className={tagStyles.tag}>
      <p className={tagStyles.tagname}>{tagname}</p>
      <button onClick={() => removeTag()} type="button">
        <img src={close} alt="remove" />
      </button>
    </div>
  );
}

export default NewPatternTag;
