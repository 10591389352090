import modalStyles from './ConfirmRefundModal.module.scss';

function ConfirmRefundModal({ setModalIsOpen, handleReturn }) {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_container}>
        <h2 className={modalStyles.modal_title}>Refund</h2>
        <p className={modalStyles.modal_text}>
          Do you really want to request a return for the product?
        </p>
        <hr />
        <div className={modalStyles.modal_controls}>
          <button
            type="button"
            className={modalStyles.modal_cancel}
            onClick={() => setModalIsOpen(false)}>
            cancel
          </button>
          <button
            type="button"
            className={modalStyles.modal_confirm}
            onClick={handleReturn}>
            confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmRefundModal;
