/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const uploadFile = (data) =>
  customFetch(`${apiUrl}/uploads`, {
    method: 'POST',
    body: data,
    headers: {
      Accept: 'application/json'
    }
  }).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      alert('Something went wrong uploading file. Please try again later.');

      throw new Error(response.message);
    }

    return response.data;
  });

export const uploadAvatar = (data) =>
  customFetch(`${apiUrl}/uploads/avatar`, {
    method: 'POST',
    body: data,
    headers: {
      Accept: 'application/json'
    }
  }).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      alert('Something went wrong uploading file. Please try again later.');
      throw new Error(response.message);
    }

    return response.data;
  });

export const convertUpload = (data) =>
  customFetch(`${apiUrl}/uploads/convert`, {
    method: 'POST',
    body: data,
    headers: {
      Accept: 'application/json'
    }
  }).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      alert('Something went wrong. Please try again later.');
      throw new Error(response.message);
    }

    return response.data;
  });

export const getConvertStatus = (fileId) =>
  customFetch(`${apiUrl}/uploads/status/${fileId}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return {
        step: data.data.step
      };
    });

export const getBase64FromConvertId = (converIt) =>
  customFetch(`https://api.convertio.co/convert/${converIt}/dl/base64`)
    .then((res) => res.json())
    .then((data) => data.data.content);

export const getFloatVariables = (type) =>
  customFetch(`${apiUrl}/float-variables?type=${type}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });
