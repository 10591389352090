/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useState, useMemo, useEffect, useContext } from 'react';
import PaypalPricingSubscription from '../../Paypal/PaypalPricingSubscription/PaypalPricingSubscription';
import subscriptionStyles from '../Pricing.module.scss';
import subscriptionScopedStyles from './PricingSubscription.module.scss';
// import { getPremiumTime2, buyPremium } from '../../../api/api';
import { getPremiumTime } from '../../../api/profile';
import { buyPremium } from '../../../api/transaction';

import paymentStyles from '../PricingCreditPacks/PricingCreditPacks.module.scss';
import { getFloatVariables } from '../../../api/utils';
import { LoginStateContext, UserProfileContext } from '../../../store/Context';
import ModalCheckExpired from '../ModalCheckExpired/ModalCheckExpired';

function PricingSubscription({ updateProfileOnPayment }) {
  const navigate = useNavigate();
  const loginState = useContext(LoginStateContext);
  const { userProfile } = useContext(UserProfileContext) || {};
  const [checkExpired, setCheckExpired] = useState(false);
  const [packageId, setPackageId] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [freeTrialChecked, setFreeTrialChecked] = useState(false);
  const [subCards, setSubCards] = useState([
    {
      isChecked: false,
      id: 1,
      name: '1-month subscription',
      price: '',
      month: 1
    },
    {
      isChecked: false,
      id: 2,
      name: '3-month subscription',
      price: '',
      month: 3
    },
    {
      isChecked: false,
      id: 3,
      name: '6-month subscription',
      price: '',
      month: 6
    },
    {
      isChecked: false,
      id: 4,
      name: '9-month subscription',
      price: '',
      month: 9
    },
    {
      isChecked: false,
      id: 5,
      name: '12-month subscription',
      price: '',
      month: 12
    }
  ]);

  const option = useMemo(() => {
    if (subCards) {
      const listSelected = subCards.filter((item) => item.isChecked);
      const price = listSelected.reduce((sum, item) => sum + item.price, 0);
      const month = listSelected.reduce((sum, item) => sum + item.month, 0);
      return {
        type: 'subscription',
        month,
        total() {
          return price;
        }
      };
    }
    return {
      type: 'subscription',
      total() {
        return 0;
      }
    };
  }, [subCards]);

  const fetchData = async () => {
    const getFloatVariablesRes = await getFloatVariables(
      'editor_subcription_fee'
    );

    if (!getFloatVariablesRes.length) {
      setSubCards((currentCards) =>
        currentCards.map((card) => {
          card.price = 30;

          return card;
        })
      );
    } else {
      const currPrice = getFloatVariablesRes[0].variable;
      setSubCards((currentCards) =>
        currentCards.map((card) => {
          card.price = currPrice * card.month;

          return card;
        })
      );
    }
  };

  const handleSubscriptionChange = (id) => {
    setSubCards((current) =>
      current.map((subCard) => {
        if (subCard.id === id) {
          return {
            ...subCard,
            isChecked: !subCard.isChecked
          };
        }

        return {
          ...subCard,
          isChecked: false
        };
      })
    );
  };

  const handleCheck = (e, id) => {
    if (e.target.checked) {
      if (!checkExpired) {
        const currentDate = new Date();
        const subscriptionEndDate = userProfile?.premiumTime
          ? new Date(userProfile?.premiumTime)
          : 0;
        if (subscriptionEndDate && currentDate <= subscriptionEndDate) {
          setPackageId(id);
          setTimeout(() => setModalIsOpen(true), 0);
        } else {
          handleSubscriptionChange(id);
        }
      } else {
        handleSubscriptionChange(id);
      }
    } else {
      handleSubscriptionChange(id);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCardPayment = () => {
    const selectedSub = subCards.filter((card) => card.isChecked);

    if (!loginState?.isLoggedIn) {
      navigate('/auth');
      return;
    }

    if (!selectedSub.length) {
      alert('Please select one package');
      return;
    }

    alert('Payment is in development');
    // TODO: Cart Payment
  };

  return (
    <div className={subscriptionStyles.content}>
      <h2 className={subscriptionStyles.content_title}>
        &lsquo;EDIT &amp;&nbsp;PLOT&rsquo; SUBSCRIPTION
      </h2>
      <div className={subscriptionScopedStyles.subs_conainer}>
        {subCards.map((subCard) => (
          <div
            key={subCard.id}
            className={subscriptionScopedStyles.sub_subscription}>
            <label
              htmlFor="freetrial"
              className={subscriptionScopedStyles.trial_label}>
              <input
                name="freetrial"
                type="checkbox"
                onChange={(e) => handleCheck(e, subCard.id)}
                checked={subCard.isChecked}
              />
              {subCard.name}
            </label>
            <p className={subscriptionScopedStyles.total}>Total price</p>
            <div className={subscriptionScopedStyles.price_container}>
              <p className={subscriptionScopedStyles.price}>
                ${subCard.price}
                <span>/mo</span>
              </p>
              <p className={subscriptionScopedStyles.price_descr}>
                *VAT not included in prices.
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={paymentStyles.payment_controls}>
        {/* {subscriptionHistory ? (
          <PricingPayPal option={option} authToken={authToken} />
        ) : (
          <div className={subscriptionScopedStyles.box}>
            <button
              disabled={loadingFirstBuy || !freeTrialChecked || !authToken}
              // disabled
              type="button"
              onClick={handleFirstSubscription}
              className={subscriptionScopedStyles.paypal_button}>
              <span className={subscriptionScopedStyles.paypal_logo}>
                PayPal
              </span>
            </button>
          </div>
        )} */}
        <PaypalPricingSubscription
          option={option}
          updateProfileOnPayment={updateProfileOnPayment}
        />
        <button
          onClick={handleCardPayment}
          type="button"
          className={paymentStyles.payment_credit}>
          CARD PAYMENT
        </button>
      </div>
      {modalIsOpen && (
        <ModalCheckExpired
          setModalIsOpen={setModalIsOpen}
          action={() => {
            setModalIsOpen(false);
            setCheckExpired(true);
            handleSubscriptionChange(packageId);
          }}
        />
      )}
    </div>
  );
}

export default PricingSubscription;
