/* eslint-disable no-alert */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ModalConfirm from './Modal/ModalConfirm';
import ModalNotify from './Modal/ModalNotify';
import withdrawalStyles from './Withdrawal.module.scss';
import { deleteMyProfile } from '../../../../api/profile';
import { useAlert } from '../../../hooks/useAlert/useAlert';

function Withdrawal({ resetUserData }) {
  const navigate = useNavigate();
  const [openModalNotify, setOpenModalNotify] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [checked, setChecked] = useState(false);

  const { onAlert } = useAlert();

  useEffect(() => {
    const fetchData = async () => {};

    fetchData();
  }, []);

  const handleWithdrawalRequest = async () => {
    navigate('/sell/pattern/payout?fromProfileWithdrawal');
  };

  const handleWithdrawal = () => {
    if (!checked) {
      onAlert('', 'Okay', 'Please read and agree to the Terms');
      return;
    }
    setOpenModalConfirm(true);
  };

  const handleDelete = async () => {
    const handleDeleteRes = await deleteMyProfile();

    if (handleDeleteRes.statusCode === 200) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      onAlert('', 'Okay', 'Your account was deleted successfully.');
      resetUserData();
      navigate('/');
    }
  };

  return (
    <section className={withdrawalStyles.withdrawal_section}>
      <div className={withdrawalStyles.withdrawal_content}>
        <h2>Delete my account</h2>
        <span>
          Please read this carefully. Deleting your account will result in a
          permanent data loss.
        </span>
        <span>
          You are about to submit a request to have your PLOFIX account deleted.
          If you delete the account, you will lose access to all products and
          services. All your account data including your patterns will be
          deleted.
        </span>
        <span>
          Additionally, we will not be able to provide you with the payment you
          may be expecting, such as refunds, profit withdrawals, etc.
        </span>
        <h3>Loss of data and assets</h3>
        <p>Here are the consequences of deleting your PLOFIX account:</p>
        <span>
          - You will lose access to PLOFIX services, such as &rsquo;Edit &
          Plot&rsquo; or &rsquo;Upload&rsquo; and pattern database;
        </span>
        <span>
          - All your saved projects will be deleted, and unused credit packs
          will be forfeited;
        </span>
        <span>- All data stored in PLOFIX will be permanently lost;</span>
        <span>
          - You won&rsquo;t be able to use your email to sign in to PLOFIX
        </span>
        <h3>Profit Withdraw Request</h3>
        <span>
          - If your earnings make up $30 and above, you may click here to
          request a payout before deleting your account.
        </span>
        <button type="button" onClick={handleWithdrawalRequest}>
          Withdraw Request
        </button>
        <div className={withdrawalStyles.checkbox}>
          <label htmlFor="agree">
            <input
              name="agree"
              type="checkbox"
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
            I have read and agreed to the terms listed above.
          </label>
        </div>
        <button
          type="button"
          className={withdrawalStyles.withdrawal_btn}
          onClick={handleWithdrawal}>
          DELETE MY ACCOUNT
        </button>
      </div>
      {openModalNotify && <ModalNotify setModalIsOpened={setOpenModalNotify} />}
      {openModalConfirm && (
        <ModalConfirm
          setModalIsOpened={setOpenModalConfirm}
          onConfirm={handleDelete}
        />
      )}
    </section>
  );
}

export default Withdrawal;
