import reviewStyles from '../ShopDetailsReviews.module.scss';
import accountImg from '../../../../../img/icon_account.svg';

import { getNumberOfDays } from '../../../../../utils/function';

function ShopReview({ review }) {
  const renderDaysAgo = (daysAgo) => {
    if (daysAgo === 1) {
      return `${daysAgo} day ago`;
    }

    if (daysAgo === 0) {
      return 'Today';
    }

    return `${daysAgo} days ago`;
  };

  return (
    <div className={reviewStyles.review_container}>
      <div className={reviewStyles.review_info}>
        <img src={review?.user?.avatar || accountImg} alt="pfp" />
        <p className={reviewStyles.info_nickname}>
          {review?.user?.nickName ?? ''}
        </p>
        <p className={reviewStyles.info_date}>
          {renderDaysAgo(getNumberOfDays(review?.createdAt, new Date()))}
        </p>
      </div>
      <div className={reviewStyles.review_comment}>
        <p>{review?.review ?? ''}</p>
      </div>
    </div>
  );
}

export default ShopReview;
