import { Mutex } from 'async-mutex';
import apiUrl from './apiUrl';

const getToken = (refreshToken) =>
  fetch(`${apiUrl}/getToken`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

const fetchWithHeader = async (endPoint, options) => {
  const authToken = localStorage.getItem('token');
  const tokenOptions = {
    ...options,
    headers: options?.headers
      ? {
          ...options.headers,
          Authorization: `Bearer ${authToken}`
        }
      : { Authorization: `Bearer ${authToken}` }
  };

  const res = await fetch(endPoint, tokenOptions);

  return res;
};

const customFetch = async (endPoint, options) => {
  const mutex = new Mutex();

  await mutex.waitForUnlock();
  let res = await fetchWithHeader(endPoint, options);

  if (res.status === 401 || res.status === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (!refreshToken) return res;

        const { data } = await getToken(refreshToken);

        if (data) {
          const authToken = data;

          window.localStorage.setItem('token', authToken);

          res = await fetchWithHeader(endPoint, options);
        } else {
          sessionStorage.removeItem('userProfile');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      res = await fetchWithHeader(endPoint, options);
    }
  }

  return res;
};

export default customFetch;
