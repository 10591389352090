/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { getMyCoupons } from '../../../api/coupons';

import {
  calculateDate,
  couponIsExpired,
  formatDate
} from '../../../utils/function';
import { TOAST_TYPE } from '../../../utils/constants';

import Pagination from '../../Pagination/Pagination';

import withToast from '../../HOC/withToast';

import InquiryFilter from '../../SellPage/SellPattern/SellPatternOutlets/InquiryFilter/InquiryFilter';
import StatusFilter from '../../SellPage/SellPattern/SellPatternOutlets/StatusFilter/StatusFilter';

import shopCouponStyles from './ShopCoupon.module.scss';

import attentionMark from '../../../img/attention_icon.svg';

function ShopCoupon() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDates, setFilterDates] = useState({
    from: '',
    to: ''
  });
  const statusList = [
    { value: '', text: 'All' },
    {
      value: 'used',
      text: 'Used Coupon'
    },
    { value: 'available', text: 'Available' },
    { value: 'not_available', text: 'Not Available' }
  ];

  const [selectedValue, setSelectedValue] = useState('');
  const [couponsList, setCouponsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async (
    from = '',
    to = '',
    type = '',
    page = 1,
    limit = 20
  ) => {
    const couponsRes = await getMyCoupons({
      registration_from: from,
      registration_to: to,
      type,
      page,
      limit
    });

    setCouponsList(couponsRes.data);
    setTotalCount(couponsRes.count);
  };

  useEffect(() => {
    fetchData(filterDates.from, filterDates.to, selectedValue, currentPage);
  }, [selectedValue, currentPage, filterDates]);

  // useEffect(() => {
  //   if (selectedValue === 'Available') {
  //   }

  //   if (selectedValue === 'Used') {
  //   }

  //   if (selectedValue === 'Not Available') {
  //   }
  // }, [selectedValue]);

  useEffect(() => {
    console.log(couponsList);
  }, [couponsList]);

  return (
    <main className={shopCouponStyles.main}>
      <div className={shopCouponStyles.content}>
        <h1 className={shopCouponStyles.shop_coupon}>coupons</h1>
        <h2 className={shopCouponStyles.my_shop_coupon_history}>
          My shop coupon history
        </h2>
        <InquiryFilter
          onFilter={(from, to) => {
            setFilterDates({
              from,
              to
            });
            setCurrentPage(1);
          }}
          isRequiredReset
        />
        <div className={shopCouponStyles.shop_coupon_state}>
          <StatusFilter
            onChange={(value) => {
              setSelectedValue(value);
              setCurrentPage(1);
            }}
            listOptions={statusList}
          />
        </div>
        <table className={shopCouponStyles.coupon_table}>
          <thead>
            <tr>
              <td>Coupon</td>
              <td>Issued on</td>
              <td>Usage period</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {couponsList.length ? (
              couponsList.map((trow) => {
                const classStatus = (used) => {
                  if (!used) return shopCouponStyles.available;
                  return shopCouponStyles.not_available;
                };
                const periodOfUse = () => {
                  if (trow?.coupon?.end) {
                    return calculateDate(trow?.createdAt, trow?.coupon?.end);
                  }
                  return '';
                };
                return (
                  <tr key={trow?.id}>
                    <td>{trow?.coupon?.name ? trow?.coupon?.name : ''}</td>
                    <td>{formatDate(trow?.createdAt) || ''}</td>
                    <td style={{ wordBreak: 'keep-all' }}>
                      {periodOfUse() || '–'}
                    </td>
                    <td className={classStatus(trow?.used)}>
                      {trow?.used
                        ? 'Use Complete'
                        : couponIsExpired(trow?.coupon?.end)
                        ? 'Not Available'
                        : 'Available'}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className={shopCouponStyles.shop_empty_list}>
                <img src={attentionMark} alt="attention!" />
                <p>No Coupons</p>
              </div>
            )}
          </tbody>
        </table>
        {totalCount > 20 && (
          <Pagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalCount={totalCount}
            pageSize={20}
          />
        )}
      </div>
    </main>
  );
}

export default withToast(ShopCoupon);
