import { useState, useEffect } from 'react';

import { getNotificationsUnread } from '../../../api/notifications';

import profileMenuStyles from '../ProfileMenu.module.scss';

function NotificationUnread() {
  const [count, setCount] = useState(null);

  const fetchNotificationsCount = async () => {
    const notificationsCount = await getNotificationsUnread();

    if (notificationsCount > 0) {
      setCount(notificationsCount);
    } else {
      setCount(0);
    }
  };

  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  return count > 0 ? (
    <div className={profileMenuStyles.notification_count}>
      <span>{count}</span>
    </div>
  ) : null;
}

export default NotificationUnread;
