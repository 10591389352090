/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import {
  CHANGE_VALUE,
  CHANGE_MULTI_VALUES,
  CHANGE_PART_PRICE,
  VALIDATE_VALUE,
  CHANGE_MULTI_VALUE_PART_PRICE
} from './constants';

const errorDefault = {
  customerName: 'Please enter a first and last name.',
  customerEmail: 'Please enter a valid email address.',
  customerPhoneCode: 'Please enter a valid phone number.',
  customerPhone: 'Please enter a valid phone number.',
  customerCountry: 'Select country',
  customerAddress: 'Please enter a valid address.',
  // customerAddress2: 'Please enter a valid address.',
  shippingName: 'Please enter a first and last name.',
  shippingEmail: 'Please enter a valid email address.',
  shippingPhoneCode: 'Please enter a valid phone number.',
  shippingPhone: 'Please enter a valid phone number.',
  shippingAddress: 'Please enter a valid address.',
  // shippingAddress2: 'Please enter a valid address.',
  shippingCity: 'Please enter a valid city.',
  shippingState: 'Please enter a valid State / Province / Region.',
  shippingZip: 'Please enter a valid Zip /  Postal Code.',
  shippingCountry: 'Please enter a valid country.'
};

const initState = {
  values: {
    totalAmount: 0,
    discountAmount: 0,
    paymentMethod: '',
    // shippingInfoId: 0,
    // type: 'order',
    customerName: '',
    customerEmail: '',
    customerPhoneCode: '+82',
    customerPhone: '',
    customerCountry: '',
    customerAddress: '',
    // customerAddress2: '',
    shippingName: '',
    shippingEmail: '',
    shippingPhoneCode: '',
    shippingPhone: '',
    shippingAddress: '',
    // shippingAddress2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    shippingVat: '',
    totalCredit: '',
    orderId: '',
    totalProfit: '',
    shippingFee: '',
    orderDetail: [],
    couponId: '',
    couponSelected: '',
    couponName: '',
    shippingCountry: '',
    shippingError: false,
    showModal: false
  },
  errors: {
    customerName: '',
    customerEmail: '',
    customerPhoneCode: '',
    customerPhone: '',
    customerCountry: '',
    customerAddress: '',
    // customerAddress2: '',
    shippingName: '',
    shippingEmail: '',
    shippingPhoneCode: '',
    shippingPhone: '',
    shippingAddress: '',
    // shippingAddress2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    shippingCountry: ''
  },
  partPrice: {
    shipping: 0,
    itemSubtotal: 0,
    discount: 0,
    coupon: '',
    creditUsed: 0,
    profitUsed: 0
  }
};

const changeValues = (payload) => ({
  type: CHANGE_VALUE,
  payload
});

const changeMultiValues = (payload) => ({
  type: CHANGE_MULTI_VALUES,
  payload
});

const changePartPrice = (payload) => ({
  type: CHANGE_PART_PRICE,
  payload
});

const changeMultiValuePartPrice = (payload) => ({
  type: CHANGE_MULTI_VALUE_PART_PRICE,
  payload
});

const handleValidate = () => ({
  type: VALIDATE_VALUE
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_VALUE:
      const { name, value } = action.payload;
      let { values } = state;
      values = { ...values, [name]: value };
      return { ...state, values };
    case CHANGE_MULTI_VALUES:
      let { values: values2 } = state;
      values2 = { ...values2, ...action.payload };
      return { ...state, values: values2 };
    case CHANGE_PART_PRICE:
      let { partPrice } = state;
      const { name: name1, value: value1 } = action.payload;
      partPrice = { ...partPrice, [name1]: value1 };
      return { ...state, partPrice };
    case CHANGE_MULTI_VALUE_PART_PRICE:
      let { partPrice: partPrice2 } = state;
      partPrice2 = { ...partPrice2, ...action.payload };
      return { ...state, partPrice: partPrice2 };
    case VALIDATE_VALUE:
      const { errors } = state;
      const { values: values3 } = state;
      // console.log('values3', values3);
      let newErrors = {};
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const newErrorsList = Object.keys(errorDefault).map((item) => {
        // console.log('values3[item]', item, values3[`${item}`]);
        if (values3[`${item}`]?.length === 0) {
          return { [item]: errorDefault[item] };
        }
        // console.log(
        //   ' emailPattern.test(values3[item])',
        //   emailPattern.test(values3[item])
        // );
        if (
          values3[item] &&
          item === 'customerEmail' &&
          !emailPattern.test(values3[item])
        ) {
          return { [item]: 'Invalid email' };
        }
        if (
          values3[item] &&
          item === 'shippingEmail' &&
          !emailPattern.test(values3[item])
        ) {
          return { [item]: 'Invalid email' };
        }
        return { [item]: '' };
      });
      newErrorsList.forEach((item) => {
        newErrors = { ...newErrors, ...item };
      });
      return { ...state, errors: { ...errors, ...newErrors } };
    default:
      return state;
  }
};

export default reducer;
export {
  initState,
  changeValues,
  changeMultiValues,
  changePartPrice,
  handleValidate,
  changeMultiValuePartPrice
};
