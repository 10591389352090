// import { useEffect } from 'react';
// import { useState } from 'react';
import dropdownStyles from './DropdownFilter.module.scss';
import dropdownArrow from '../../../img/dropdown_arrow.svg';

function Dropdown({ dropDownCategory, setDropdownState }) {
  const handleChange = (mainId, subId) => {
    setDropdownState((current) =>
      current?.map((category) => ({
        ...category,
        subParts: category?.subParts?.map((subCat) => {
          if (subCat.id === subId) {
            return {
              ...subCat,
              isChecked: !subCat.isChecked
            };
          }

          return subCat;
        })
      }))
    );
  };

  return (
    <div className={dropdownStyles.dropdown}>
      <p className={dropdownStyles.dropdown_title}>
        <button
          type="button"
          onClick={() =>
            setDropdownState((current) =>
              current.map((category) => {
                if (category.id === dropDownCategory.id) {
                  return { ...category, isOpened: !category.isOpened };
                }

                return category;
              })
            )
          }>
          <span>{dropDownCategory?.part}</span>
          <img
            src={dropdownArrow}
            alt="arrow"
            style={{
              transform: dropDownCategory?.isOpened ? 'rotate(180deg)' : ''
            }}
          />
        </button>
      </p>
      {dropDownCategory?.isOpened && (
        <ul className={dropdownStyles.dropdown_list}>
          {dropDownCategory?.subParts?.map((subPart) => (
            <li key={subPart.id} className={dropdownStyles.dropdown_item}>
              <label htmlFor={subPart.category}>
                <input
                  onChange={() => handleChange(dropDownCategory.id, subPart.id)}
                  name={subPart.category}
                  type="checkbox"
                  checked={subPart.isChecked}
                />
                {subPart?.category} ({subPart?.total})
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
