/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import reviewsStyles from './ShopDetailsReviews.module.scss';
import iconAccount from '../../../../img/icon_account.svg';
import ShopReview from './ShopReview/ShopReview';

import withToast from '../../../HOC/withToast';

import {
  getUltrafitRating,
  postUltrafitRating
} from '../../../../api/ultrafitShop';
import { getMyProfile } from '../../../../api/profile';

function ShopDetailsReviews({ showToast }) {
  const { productId } = useParams();

  const [maxReviews, setMaxReviews] = useState(10);

  const [myProfile, setMyProfile] = useState({});

  const [reviews, setReviews] = useState([]);
  const [myReview, setMyReview] = useState('');

  const fetchReviews = async (ultrafitId) => {
    const reviewsRes = await getUltrafitRating(ultrafitId);

    setReviews(reviewsRes);
  };

  const fetchMyProfile = async () => {
    const myProfileRes = await getMyProfile();

    setMyProfile(myProfileRes);
  };

  const handleLoadMoreClick = () => {
    setMaxReviews(maxReviews + 10);
  };

  // const handleRegisterReview = async ({ ultrafitId, review }) => {
  //   await postUltrafitRating({ ultrafitId, review });

  //   setMyReview('');

  //   fetchReviews(productId);
  // };

  useEffect(() => {
    fetchReviews(productId);

    fetchMyProfile();
  }, [productId]);

  return (
    <div className={reviewsStyles.reviews_container}>
      <div className={reviewsStyles.reviews_content}>
        <h3>Reviews ({reviews.length})</h3>
        {reviews
          .map((review) => (
            <ShopReview
              key={review.id}
              review={review}
              currentUserId={myProfile?.id}
            />
          ))
          .slice(0, maxReviews)}
      </div>

      <button
        onClick={handleLoadMoreClick}
        disabled={reviews.length < 10 || maxReviews >= reviews.length}
        className={reviewsStyles.load_btn}
        type="button">
        load more
      </button>
    </div>
  );
}

export default withToast(ShopDetailsReviews);
