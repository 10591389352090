import { useEffect } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';

import AdPopUp from '../AdPopUp/AdPopUp';

import pricingStyles from './Pricing.module.scss';

function Pricing() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/pricing/subscription') {
      navigate('/pricing/subscription');
    } else {
      navigate('/pricing/packs');
    }
  }, []);

  return (
    <section className={pricingStyles.pricing_section}>
      <div className={pricingStyles.pricing_container}>
        <h1 className={pricingStyles.pricing_title}>Pricing</h1>
        <nav className={pricingStyles.nav}>
          <NavLink
            className={pricingStyles.nav_packs}
            to="/pricing/packs"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Credit Packages
          </NavLink>
          <NavLink
            className={pricingStyles.nav_subscription}
            to="/pricing/subscription"
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            &lsquo;EDIT &amp;&nbsp;PLOT&rsquo; Subscription
          </NavLink>
        </nav>
        <Outlet />
      </div>
      <AdPopUp popUpCategory="Pricing 페이지" />
    </section>
  );
}

export default Pricing;
