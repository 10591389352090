/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PackContext from '../Pricing/PricingCreditPacks/Context/Context';
import { selectPack } from '../Pricing/PricingCreditPacks/Context/reducer';
import creditPacksStyles from './CreditPacks.module.scss';

function CreditPack({
  pack,
  homePage,
  loginState,
  className,
  selectedPark = [],
  setSelectedPark
}) {
  const [state, dispatch] = useContext(PackContext);
  const navigate = useNavigate();

  const handleSelect = () => {
    if (homePage) {
      if (loginState?.isLoggedIn) {
        // navigate('/pricing/packs');
        let newSelectedPack = [];
        if (selectedPark.some((item) => item.id === pack.id)) {
          newSelectedPack = selectedPark.filter((item) => item.id !== pack.id);
        } else {
          newSelectedPack = [...selectedPark, pack];
        }
        setSelectedPark(newSelectedPack);
        return;
      }
      navigate('/auth');
      return;
    }
    let newState = [];
    if (state.some((item) => item.id === pack.id)) {
      newState = state.filter((item) => item.id !== pack.id);
    } else {
      newState = [...state, pack];
    }
    dispatch(selectPack(newState));
  };

  const total = () => +pack.cost + +pack.bonus;

  return (
    <div
      className={`${creditPacksStyles.pack} ${className || ''}`}
      onClick={handleSelect}
      style={
        state.some((item) => item.id === pack.id) ||
        selectedPark.some((item) => item.id === pack.id)
          ? { border: 'solid 1px #0046a6' }
          : {}
      }>
      <h3 className={creditPacksStyles.pack_name}>{pack.name}</h3>
      <hr className={creditPacksStyles.pack_hr} />
      <h4 className={creditPacksStyles.pack_price}>
        ${parseInt(pack.recharge, 10)}
      </h4>
      <hr className={creditPacksStyles.pack_hr} />
      <p className={creditPacksStyles.pack_bonus}>
        {parseInt(pack.cost, 10)} Credits + {parseInt(pack.bonus, 10)} Extra
        Credits
      </p>
      <h5 className={creditPacksStyles.pack_total_title}>Total</h5>
      <p className={creditPacksStyles.pack_total}>{total()} Credits</p>
    </div>
  );
}

export default CreditPack;
