import SupportPageTableRow from '../SupportPageTableRow/SupportPageTableRow';
import supportPageTableStyles from './SupportPageTable.module.scss';

function SupportPageTable({ tableRows }) {
  return tableRows ? (
    <div className={supportPageTableStyles.support_table}>
      <div className={supportPageTableStyles.table_head}>
        <div className={supportPageTableStyles.table_row}>
          <div className={supportPageTableStyles.table_cell}>№</div>
          <div className={supportPageTableStyles.table_cell}>Title</div>
        </div>
      </div>
      <div className={supportPageTableStyles.table_body}>
        {tableRows.map((row, index) => (
          <SupportPageTableRow key={index} row={row} index={index} />
        ))}
      </div>
    </div>
  ) : null;
}

export default SupportPageTable;
