// import { useEffect, useState } from 'react';
// import { useState } from 'react';
// import { getCategory } from '../../../../api/api';
import subCatsStyles from './NewPatternSubCat.module.scss';
import uploadPatternStyles from '../UploadPattern.module.scss';
import NewPatternSubCatOptions from './NewPatternSubCatOptions';
// import { useEffect } from 'react';

export default function NewPatternSubCats({
  id,
  subCategories,
  newPatParams,
  setNewPatternsParams,
  selectedVehicleType
}) {
  const handleCatClick = (category) => {
    setNewPatternsParams((currentParam) =>
      currentParam.map((paramObj) => {
        if (paramObj.id === id) {
          return { ...paramObj, section: category, part: {}, subPart: {} };
        }

        return paramObj;
      })
    );
  };

  return subCategories?.length ? (
    <div className={subCatsStyles.newpattern_subcats}>
      <p className={uploadPatternStyles.form_subtitle}>Sub Category</p>
      <div className={subCatsStyles.subcats_container}>
        <ul
          className={subCatsStyles.subcats_names}
          style={
            newPatParams.section
              ? {
                  borderBottom: '1px solid #ededed',
                  gridTemplateColumns: `repeat(${subCategories.length}, 1fr)`
                }
              : { gridTemplateColumns: `repeat(${subCategories.length}, 1fr)` }
          }>
          {subCategories
            .sort((catA, catB) => catA.order - catB.order)
            .map((category) => (
              <li key={category.id}>
                <button
                  onClick={() => handleCatClick(category)}
                  type="button"
                  style={
                    newPatParams.section?.id === category.id ||
                    newPatParams.section?.category === category.category
                      ? { backgroundColor: '#0046a6', color: '#fff' }
                      : {}
                  }>
                  {category.category}
                </button>
              </li>
            ))}
        </ul>
        {newPatParams.section?.subCategory ? (
          <NewPatternSubCatOptions
            id={id}
            options={
              newPatParams.section.subCategory.sort(
                (catA, catB) => catA.order - catB.order
              ) || []
            }
            subOptions={
              newPatParams.section.subCategory
                .filter(
                  (category) => category?.id === newPatParams?.part?.id
                )[0]
                ?.subCategory?.sort((catA, catB) => catA.order - catB.order) ||
              []
            }
            newPatParams={newPatParams}
            setNewPatternsParams={setNewPatternsParams}
            selectedVehicleType={selectedVehicleType}
          />
        ) : null}
      </div>
    </div>
  ) : null;
}
