/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { submitContactUs } from '../../api/contact';
import { uploadAvatar } from '../../api/utils';

import { phoneNumberCodes } from '../../utils/phoneNumberCodes';

import contactUsStyles from './ContactUs.module.scss';
import selectArrow from '../../img/icon_down1.svg';
import { useClickOutside } from '../hooks/useClickOutside';
import { checkFileSize } from '../../utils/function';
import { useAlert } from '../hooks/useAlert/useAlert';

function ContactUs() {
  const navigate = useNavigate();

  const { watch, register, setFocus, setValue, handleSubmit } = useForm();
  const { onAlert } = useAlert();

  const maxWordsCount = 300;

  const [telCodeSelectIsOpen, setTelCodeSelectIsOpen] = useState(false);
  const [selectedTelCode, setSelectedTelCode] = useState('+82');

  const [inquiryTypeSelectIsOpen, setInquiryTypeSelectIsOpen] = useState(false);
  const [selectedInquiryType, setSelectedInquiryType] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const phoneCodeRef = useRef(null);
  const inputFileRef = useRef(null);

  useClickOutside(phoneCodeRef, () => {
    setTelCodeSelectIsOpen(false);
  });

  const isMaxWordsExceeded = (text) => text.length > maxWordsCount;

  const handleSelectFileBoxClick = () => {
    inputFileRef.current.click();
  };

  // tel handlers
  const handleTelBtnClick = () => {
    setTelCodeSelectIsOpen(!telCodeSelectIsOpen);
  };

  const handleSelectedTelChange = (e) => {
    setSelectedTelCode(e.target.value);
    setTelCodeSelectIsOpen(false);
  };

  // inquiry type handlers
  const handleInquiryTypeBtnClick = () => {
    setInquiryTypeSelectIsOpen(!inquiryTypeSelectIsOpen);
  };

  const handleSelectedInquiryTypeChange = (e) => {
    setSelectedInquiryType(e.target.value);
    setInquiryTypeSelectIsOpen(false);
  };

  const handleSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles(undefined);
      return;
    }

    if (!checkFileSize(e.target.files[0])) {
      alert('File upload is possible up to 5MB.');
      return;
    }
    setSelectedFiles(e.target.files);
  };

  const handleDeleteFiles = () => {
    setSelectedFiles([]);
  };

  const handleCancelClick = () => {
    navigate('/');
  };

  const onSubmit = async (data) => {
    let fileSrcs = '';

    if (isMaxWordsExceeded(data.inquiry_detail)) {
      onAlert('', 'OK', 'The text must have up to 300 words.');
      setFocus('inquiry_detail');
      return;
    }

    if (selectedFiles) {
      const uploadFilePromises = Object.values(selectedFiles).map(
        async (file) => {
          let fileSrc = '';
          const fileFormData = new FormData();

          fileFormData.append('file', file);

          fileSrc = await uploadAvatar(fileFormData);

          return fileSrc;
        }
      );

      const uploadFileSrcs = await Promise.all(uploadFilePromises);

      fileSrcs = uploadFileSrcs.join(',');
    }

    const contactUsData = {
      title: data?.inquiry_title || '',
      content: data?.inquiry_detail || '',
      type: data?.inquiry_type.join() || '',
      name: data.fullName,
      file: fileSrcs,
      phoneNumber: data?.phone ? `${selectedTelCode}${data.phone.trim()}` : '',
      email: data.email,
      collecionUser: data.personal_agreement
    };

    const submitResponse = await submitContactUs(contactUsData);

    if (submitResponse?.errorStatus) {
      alert(
        'Something went wrong. Please fill all the form fields or try again later.'
      );
      throw new Error(submitResponse.message);
    }

    alert('Success!');
    navigate('/');
  };

  return (
    <main className={contactUsStyles.contact_main}>
      <div className={contactUsStyles.contact_container}>
        <h1>Contact us</h1>
        <form
          className={contactUsStyles.form}
          onSubmit={handleSubmit(onSubmit, (err) => {
            console.log(err);
          })}>
          <div className={contactUsStyles.form_box1}>
            <label className={contactUsStyles.form_label} htmlFor="statement">
              <h3 className={contactUsStyles.label_title}>Name</h3>
              <input
                {...register('fullName', {
                  required: true
                })}
                type="text"
                name="fullName"
                placeholder="Please enter your full name"
                className={contactUsStyles.statement_input}
              />
            </label>
            <fieldset className={contactUsStyles.mobile_fieldset}>
              <h3 className={contactUsStyles.label_title}>Mobile Phone</h3>
              <div className={contactUsStyles.mobile_controls}>
                <div
                  className={contactUsStyles.mobile_select}
                  ref={phoneCodeRef}>
                  <button
                    onClick={handleTelBtnClick}
                    className={contactUsStyles.custom_select_btn}
                    type="button">
                    <span>{selectedTelCode}</span>
                    <img
                      src={selectArrow}
                      alt="arrow"
                      style={
                        telCodeSelectIsOpen
                          ? { transform: 'rotate(180deg)' }
                          : {}
                      }
                    />
                  </button>
                  <select
                    onChange={(e) => handleSelectedTelChange(e)}
                    className={contactUsStyles.custom_select}
                    name="mobile_code"
                    style={telCodeSelectIsOpen ? { display: 'flex' } : {}}
                    multiple>
                    {phoneNumberCodes.map((phoneCode) => (
                      <option key={phoneCode.id} value={phoneCode.value}>
                        {phoneCode.text}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  {...register('phone', { required: true })}
                  className={contactUsStyles.mobile_input}
                  type="text"
                  name="phone"
                  onInput={(e) => {
                    const phoneValue = e.target.value
                      .split('')
                      .filter((value) => Number.isInteger(+value))
                      .join('')
                      .replace(/\s/g, '');

                    setValue('phone', phoneValue);
                  }}
                  // onKeyDown={(e) => {
                  //   if (
                  //     /[0-9]/.test(e.key) ||
                  //     e.key === 'Backspace' ||
                  //     e.key === 'Delete'
                  //   ) {
                  //     return;
                  //   }

                  //   e.preventDefault();
                  // }}
                  placeholder="Please enter your mobile phone number"
                />
              </div>
            </fieldset>
            <label className={contactUsStyles.form_label} htmlFor="email">
              <h3 className={contactUsStyles.label_title}>E-mail</h3>
              <input
                {...register('email', { required: true })}
                className={contactUsStyles.email_input}
                type="email"
                placeholder="Please enter your email address"
              />
            </label>
            <label
              className={contactUsStyles.form_label}
              htmlFor="inquiry_type">
              <h3 className={contactUsStyles.label_title}>Type Of Inquiry</h3>
              <div className={contactUsStyles.inquiry_select}>
                <button
                  onClick={handleInquiryTypeBtnClick}
                  className={contactUsStyles.custom_select_btn}
                  type="button">
                  <span style={selectedInquiryType ? {} : { color: '#c5c5c5' }}>
                    {`${selectedInquiryType} Inquiry` || 'Select type'}
                  </span>
                  <img
                    src={selectArrow}
                    alt="arrow"
                    style={
                      inquiryTypeSelectIsOpen
                        ? { transform: 'rotate(180deg)' }
                        : {}
                    }
                  />
                </button>
                <select
                  {...register('inquiry_type', { required: true })}
                  onChange={(e) => handleSelectedInquiryTypeChange(e)}
                  className={contactUsStyles.custom_select}
                  style={
                    inquiryTypeSelectIsOpen
                      ? { display: 'flex', width: '100%' }
                      : {}
                  }
                  name="inquiry_type"
                  multiple>
                  <option value="customer">Customer inquiry</option>
                  <option value="product">Product inquiry</option>
                  <option value="affiliate">Affiliate Inquiry</option>
                </select>
              </div>
            </label>
            <label
              className={contactUsStyles.form_label}
              htmlFor="inquiry_title">
              <h3 className={contactUsStyles.label_title}>Title</h3>
              <input
                {...register('inquiry_title', { required: true })}
                type="text"
                name="inquiry_title"
                className={contactUsStyles.inquiry_title_input}
                placeholder="Please enter your inquiry title"
              />
            </label>
            <label
              className={contactUsStyles.form_label}
              htmlFor="inquiry_detail">
              <h3 className={contactUsStyles.label_title}>Detail</h3>
              <textarea
                {...register('inquiry_detail', { required: true })}
                name="inquiry_detail"
                onChange={(e) => {
                  const { value } = e.target;
                  setValue('inquiry_detail', value);
                  if (isMaxWordsExceeded(value)) {
                    e.preventDefault();
                    onAlert('', 'OK', 'The text must have up to 300 words.');
                    e.target.value = value.substring(0, maxWordsCount);
                    setFocus('inquiry_detail');
                  }
                }}
                className={contactUsStyles.detail}
                placeholder="Please enter your inquiry"
              />
            </label>
          </div>
          <div className={contactUsStyles.form_box2}>
            <label
              className={contactUsStyles.select_file_label}
              htmlFor="select_file">
              <h3 className={contactUsStyles.label_title}>File</h3>
              <div className={contactUsStyles.file_select}>
                <button
                  type="button"
                  onClick={handleSelectFileBoxClick}
                  className={contactUsStyles.file_select_window}>
                  Select File
                </button>
                <input
                  onChange={handleSelectFile}
                  type="file"
                  name="upload"
                  ref={inputFileRef}
                  multiple
                  accept=".png, .jpg, .pdf, .svg"
                />
              </div>
              <div className={contactUsStyles.files_container}>
                <div className={contactUsStyles.files_box}>
                  {selectedFiles?.length
                    ? Object.values(selectedFiles).map((file, index) => (
                        <p key={index}>{file.name}</p>
                      ))
                    : ''}
                </div>
                <button type="button" onClick={() => handleDeleteFiles()}>
                  Delete
                </button>
              </div>
            </label>
            <div className={contactUsStyles.user_agreement}>
              <h3>
                Guidance on&nbsp;the collection and use of&nbsp;personal
                information (essential collection and use items)
              </h3>
              <p>
                We&nbsp;collect your personal information to&nbsp;answer your
                inquiries. <br /> Only those who have agreed to&nbsp;the
                collection of&nbsp;personal information can receive inquiries.{' '}
                <br /> Items of&nbsp;personal information collected: Name&nbsp;/
                Contact&nbsp;/ Email
              </p>
              <label htmlFor="personal_agreement">
                <input
                  {...register('personal_agreement', { required: true })}
                  type="checkbox"
                  name="personal_agreement"
                />
                <span>Agree</span>
              </label>
            </div>
            <div className={contactUsStyles.form_control_btns}>
              <button
                className={contactUsStyles.cancel_btn}
                type="button"
                onClick={handleCancelClick}>
                Cancel
              </button>
              <button
                className={contactUsStyles.submit_btn}
                type="submit"
                disabled={
                  !watch('inquiry_title') ||
                  !watch('inquiry_detail') ||
                  !selectedInquiryType ||
                  !watch('fullName') ||
                  !watch('phone') ||
                  !watch('personal_agreement')
                }>
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}

export default ContactUs;
