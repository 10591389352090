/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import {
  getTimeFromToInToDay,
  startAndEndOf3Month,
  startAndEndOfMonth,
  startAndEndOfWeek,
  formatDateUTC,
  renderDate
} from '../../../../../utils/function';

import filterStyles from './InquiryFilter.module.scss';

import PlofixCalendar from '../../../../PlofixCalendar/Plofixcalendar';

import calendarIcon from '../../../../../img/icon_calendar.svg';
import { useClickOutside } from '../../../../hooks/useClickOutside';

function InquiryFilter({ onFilter, isRequiredReset }) {
  const { register, setValue, getValues } = useForm();

  const listPeriod = ['All', 'Today', 'Last Week', 'Last Month', '3 Months'];

  const [selectedPeriod, setSelectedPeriod] = useState('All');

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dateFromCalendarIsOpened, setDateFromCalendarIsOpened] =
    useState(false);
  const [dateToCalendarIsOpened, setDateToCalendarIsOpened] = useState(false);

  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);

  useClickOutside(dateFromRef, () => setDateFromCalendarIsOpened(false));
  useClickOutside(dateToRef, () => setDateToCalendarIsOpened(false));

  const handleChangePeriod = (period) => {
    setDateFrom('');
    setDateTo('');
    setValue('dateFrom', '');
    setValue('dateTo', '');
    setSelectedPeriod(period);
  };

  const handleInquiryClick = () => {
    if (dateFrom && !dateTo) {
      onFilter(dateFrom, '');

      return;
    }

    if (!dateFrom && dateTo) {
      onFilter('', dateTo);

      return;
    }

    const dateStart = new Date(dateFrom);
    const dateEnd = new Date(dateTo);

    if (dateStart > dateEnd) {
      alert('End date must be greater then Start date');
      setDateTo('');
      setValue('dateTo', '');
      return;
    }

    onFilter(dateFrom, dateTo);
  };

  const handleResetClick = () => {
    setDateFrom('');
    setDateTo('');
    setValue('dateTo', '');
    setValue('dateFrom', '');
    setSelectedPeriod('All');
    onFilter('', '');
  };

  useEffect(() => {
    let start = null;
    let end = null;

    if (selectedPeriod === 'Today') {
      [start, end] = getTimeFromToInToDay();
    }

    if (selectedPeriod === 'Last Week') {
      [start, end] = startAndEndOfWeek();
    }

    if (selectedPeriod === 'Last Month') {
      [start, end] = startAndEndOfMonth();
    }

    if (selectedPeriod === '3 Months') {
      [start, end] = startAndEndOf3Month();
    }

    if (start && end) {
      onFilter(formatDateUTC(start, 'from'), formatDateUTC(end, 'to'));
      return;
    }

    onFilter('', '');
  }, [selectedPeriod]);

  return (
    <form className={filterStyles.inquiry_form}>
      <div className={filterStyles.inquiry_filter}>
        {listPeriod.map((period, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleChangePeriod(period)}
            className={
              period === selectedPeriod
                ? `${filterStyles.filter_item} ${filterStyles.active}`
                : filterStyles.filter_item
            }>
            {period}
          </button>
        ))}
      </div>
      <div className={filterStyles.inquiry_date}>
        <label htmlFor="dateFrom" ref={dateFromRef}>
          <input
            {...register('dateFrom')}
            id="dateFrom"
            type="text"
            className="from"
            placeholder="Date"
            onClick={() => setDateFromCalendarIsOpened(true)}
          />
          <img src={calendarIcon} alt="calendar" />
          {dateFromCalendarIsOpened && (
            <div
              style={{
                position: 'absolute',
                width: '340px',
                bottom: '-10px',
                left: '-1px'
              }}>
              <PlofixCalendar
                onChange={(value) => {
                  setSelectedPeriod('All');
                  setDateFrom(formatDateUTC(value, 'from'));
                  setValue('dateFrom', renderDate(value));
                }}
                value={getValues('dateFrom')}
              />
            </div>
          )}
        </label>
        <span>~</span>
        <label htmlFor="dateTo" ref={dateToRef}>
          <input
            {...register('dateTo')}
            id="dateTo"
            type="text"
            className="to"
            placeholder="Date"
            onClick={() => setDateToCalendarIsOpened(true)}
          />
          <img src={calendarIcon} alt="calendar" />
          {dateToCalendarIsOpened && (
            <div
              style={{
                position: 'absolute',
                width: '340px',
                bottom: '-10px',
                left: '207px'
              }}>
              <PlofixCalendar
                onChange={(value) => {
                  setSelectedPeriod('All');
                  setDateTo(formatDateUTC(value, 'to'));
                  setValue('dateTo', renderDate(value));
                }}
                value={getValues('dateTo')}
                minDate={new Date(getValues('dateFrom'))}
              />
            </div>
          )}
        </label>
      </div>
      <button
        className={filterStyles.inquiry_submit}
        type="button"
        // style={
        //   dateFrom && dateTo
        //     ? null
        //     : {
        //         color: 'rgba(17, 17, 17, 1)',
        //         backgroundColor: '#BDBDBD'
        //       }
        // }
        onClick={handleInquiryClick}>
        Update
      </button>
      {isRequiredReset && (
        <button
          type="button"
          onClick={handleResetClick}
          className={filterStyles.reset_button}>
          reset
        </button>
      )}
    </form>
  );
}

export default InquiryFilter;
