import SupportPageTable from '../SupportPageTable/SupportPageTable';

function BillingPaymentReceipt() {
  const topics = [
    {
      q: 'Can I pay only with Paypal?',
      a: 'Yes, you can only pay with PayPal.'
    },
    {
      q: 'How can I change my payment information?',
      a: 'If you make your payments through PayPal, you can change your information within PayPal.'
    },
    {
      q: 'Does PLOFIX charge cancellation fee?',
      a: 'There are no cancellation and subscription cancellation fees..'
    },
    {
      q: 'How do I apply the genuine product code for credit verification?',
      a: 'If you enter the authentication code attached to the product box, it will be automatically credited.'
    },
    {
      q: 'How do I get the receipt?',
      a: 'You can check your payment history on PayPal.'
    }
  ];

  return <SupportPageTable tableRows={topics} />;
}

export default BillingPaymentReceipt;
