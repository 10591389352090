import { useState } from 'react';

import popUpStyles from './AdPopUp.module.scss';
import iconClose from '../../img/icon_close.svg';
import { formatLink } from '../../utils/function';

function AdPopUpModal({ popup, setPopups }) {
  const [dontShowChecked, setDontShowChecked] = useState(false);

  const { option } = popup || {};
  const { width, height } = option || {};

  const closeClick = () => {
    setPopups((current) =>
      current.map((item) => {
        if (item.id === popup.id) {
          if (dontShowChecked) {
            const dontShowDate = new Date();

            localStorage.setItem(
              `dontShowPopup${popup.id}`,
              `${dontShowDate.getDate()}.${dontShowDate.getMonth()}.${dontShowDate.getFullYear()}`
            );
          }

          item.isOpen = false;

          return item;
        }

        return item;
      })
    );
  };

  return (
    popup?.isOpen && (
      <div
        className={popUpStyles.popup_modal}
        style={{ width: width || 412, height: height + 100 || 641 }}>
        <div className={popUpStyles.popup_head}>
          <button type="button" onClick={closeClick}>
            <img src={iconClose} alt="close" />
          </button>
        </div>
        <div className={popUpStyles.popup_ad}>
          <a
            href={formatLink(popup?.linkTarget)}
            target="_blank"
            rel="noreferrer"
            className={popUpStyles.popup_ad_container}
            style={{
              width: width || 412,
              height: height || 641
            }}>
            <img src={popup?.file} alt="ad" />
          </a>
        </div>
        <div className={popUpStyles.popup_show}>
          <label htmlFor="show">
            <input
              type="checkbox"
              id="show"
              checked={dontShowChecked}
              onChange={(e) => setDontShowChecked(e.target.checked)}
            />
            <span>Don&apos;t show today</span>
          </label>
        </div>
      </div>
    )
  );
}

export default AdPopUpModal;
