/* eslint-disable no-alert */
/* eslint-disable no-useless-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import myPageStyles from '../MyPage.module.scss';
import orderHistoryStyles from './OrderHistory.module.scss';
import attentionMark from '../../../img/attention_icon.svg';
import OrderModal from './OrderModal/OrderModal';
import {
  getMyOrders,
  cancelMyOrders,
  returnMyOrders
} from '../../../api/order';
import withToast from '../../HOC/withToast';
import { TOAST_TYPE } from '../../../utils/constants';
import { formatDate, renderDate, listGroupBy } from '../../../utils/function';
import ReviewModal from './ReviewModal/ReviewModal';
import writeImg from '../../../img/icon_write.svg';
import returnImg from '../../../img/icon_return.svg';
import iconCopy from '../../../img/icon_copy.png';
import {
  postUltrafitRating2,
  updateUltrafitRating
} from '../../../api/ultrafitShop';
import ConfirmRefundModal from './ConfirmRefundModal/ConfirmRefundModal';
import RefundReasonModal from './RefundReasonModal/RefundReasonModal';
import { useAlert } from '../../hooks/useAlert/useAlert';

const deliveryTracking = ['shipping', 'delivery'];

const listStatusToEn = {
  order_confirmation: 'Order',
  product_preparation: 'Preparing',
  shipping: 'Shipping',
  delivery: 'Delivered',
  cancellation_pending: 'Cancellation',
  cancellation_completed: 'Canceled',
  return_accepted: 'Return',
  return_processing: 'Return Processing',
  return_completed: 'Returned',
  reject: 'Rejected'
};

function OrderHistory() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalReviewIsOpen, setModalReviewIsOpen] = useState(false);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);

  const [orderDetail, setOrderDetail] = useState(null);
  const [reviewId, setReviewId] = useState(false);
  const [orderSelected, setOrderSelected] = useState('');

  const [orderList, setOrderList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [reRenderTime, setReRenderTime] = useState(0);

  const [ultrafitId, setUltrafitId] = useState('');
  const [myReview, setMyReview] = useState('');
  const [orderId, setOrderId] = useState('');

  const [isCancelling, setIsCancelling] = useState(false);

  const [refundReasonModalIsOpen, setRefundReasonModalIsOpen] = useState(false);
  const [reason, setReason] = useState('');

  const navigate = useNavigate();

  const { onAlert } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMyOrders();
        const orders = response ?? [];
        if (!orders.length) {
          return;
        }
        setOrderList([...orders]);
      } catch (error) {
        // showToast(TOAST_TYPE.ERROR, error?.message ?? 'Error');
        console.log('error', error);
      }
    };

    fetchData();
  }, [reRenderTime]);

  const handleGetOrder = async (code) => {
    const order = orderList.find((item) => item.id === code);
    setOrderDetail({ ...order });
    setModalIsOpen(true);
  };

  const handleOpenReview = (idUltrafit, idOrder, reviews = []) => {
    if (reviews.length === 0) {
      onAlert(
        '',
        'Okay',
        'Product cancellation is not possible after a review has been written'
      );
    }
    if (reviews.length === 0) {
      setIsReviewed(false);
    } else {
      setIsReviewed(true);
      setMyReview(reviews[0].review);
      setReviewId(reviews[0].id);
    }
    setModalReviewIsOpen(true);
    setUltrafitId(idUltrafit);
    setOrderId(idOrder);
  };

  const handleRegisterReview = async () => {
    try {
      if (!isReviewed) {
        await postUltrafitRating2({
          ultrafitId,
          review: myReview,
          orderId
        });
      } else {
        await updateUltrafitRating(reviewId, {
          ultrafitId,
          review: myReview,
          orderId
        });
      }
      setReRenderTime((prevState) => prevState + 1);
      setMyReview('');
      setUltrafitId('');
      setOrderId('');
      setModalReviewIsOpen(false);
      onAlert('', 'Okay', 'Review posted!');
    } catch (error) {
      // console.log(error);
      onAlert(
        '',
        'Okay',
        error?.message ?? 'Something went wrong posting review. Try again later'
      );
    }
  };

  const handleCheck = (e, id) => {
    if (e.target.checked) {
      setListChecked([...listChecked, id]);
    } else {
      const newListChecked = listChecked.filter((_id) => _id !== id);
      setListChecked([...newListChecked]);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const newListChecked = [];
      orderList.forEach((order) => newListChecked.push(order.id));
      setListChecked([...newListChecked]);
      setCheckedAll(true);
    } else {
      setListChecked([]);
      setCheckedAll(false);
    }
  };

  const handleCancelOrder = async () => {
    setIsCancelling(true);
    if (!listChecked.length) {
      // showToast(TOAST_TYPE.WARN, 'Please select order');
      onAlert('', 'Okay', 'Please select order');
      setIsCancelling(false);
      return;
    }
    let isShipping = false;
    let isShipped = false;
    let isCancelled = false;
    let itemIsReviewed = false;
    orderList.forEach((item) => {
      if (listChecked.includes(item.id) && item.reviews.length) {
        if (item.reviews[0].review.length) {
          itemIsReviewed = true;
        }
      }
      if (listChecked.includes(item.id) && item.status === 'shipping') {
        isShipping = true;
      }
      if (listChecked.includes(item.id) && item.status === 'delivery') {
        isShipped = true;
      }
      if (
        listChecked.includes(item.id) &&
        (item.status === 'cancellation_pending' ||
          item.status === 'cancellation_completed')
      ) {
        isCancelled = true;
      }
    });
    if (isShipping || isShipped || isCancelled || itemIsReviewed) {
      if (itemIsReviewed) {
        // alert(
        //   'Products cancellation
        // is not possible after a review has been written.'
        // );
        onAlert(
          '',
          'Okays',
          'Orders that have been reviewed cannot be refund.'
        );
        setIsCancelling(false);
        return;
      }

      if (isShipped) {
        // alert('Orders that have already been shipped cannot be canceled.');
        onAlert(
          '',
          'Confirm',
          'Your order has already been shipped.',
          'You can no longer request cancellation.'
        );
        setIsCancelling(false);
        return;
      }

      if (isShipping) {
        // alert('Orders that have already been shipped cannot be canceled.');
        onAlert(
          '',
          'Confirm',
          'Orders that have already been shipped cannot be canceled.'
        );
        setIsCancelling(false);
        return;
      }

      if (isCancelled) {
        onAlert('', 'Okay', 'You have canceled this order once.');
        setIsCancelling(false);
        return;
      }
    } else {
      try {
        // await cancelOrder(authToken, { ids: [...listChecked] });
        await Promise.all(listChecked.map((item) => cancelMyOrders(item)));

        setListChecked([]);

        setReRenderTime((prevState) => prevState + 1);
        setIsCancelling(false);
        // eslint-disable-next-line no-alert
        // alert('Your orders has been cancelled successfully.');
        onAlert('', 'Confirm', 'Orders have been canceled successfully.');
      } catch (error) {
        setIsCancelling(false);
        onAlert(
          '',
          'Okay',
          'Something whent wrong cancelling selected orders.'
        );
        // showToast(TOAST_TYPE.ERROR, error?.message ?? 'Error');
        setReRenderTime((prevState) => prevState + 1);
      }
    }
  };

  const handleReturn = async () => {
    if (orderSelected.reviews.length > 0) {
      setModalConfirmIsOpen(false);
      onAlert('', 'Okay', 'Returns are not accepted after writing a review.');
      return;
    }

    try {
      await returnMyOrders(orderSelected.id, reason);
      setReason('');
      setReRenderTime((prevState) => prevState + 1);
      onAlert(
        '',
        'Okay',
        'Your return request has been received, we will contact you regarding your request.'
      );
    } catch (error) {
      console.log('error', error);
    }
    setModalConfirmIsOpen(false);
    setRefundReasonModalIsOpen(false);
  };

  const grandTotal = (data) =>
    Number(data?.totalAmount ?? 0) -
    Number(data?.discountAmount ?? 0) -
    Number(data?.totalCredit ?? 0) -
    Number(data?.totalProfit ?? 0);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // setIsCopied(true);
      onAlert('', 'Okay', 'Copied');
    } catch (err) {
      onAlert('', 'Okay', 'Failed to copy text: ', err);
    }
  };

  return (
    <main className={myPageStyles.main}>
      <section className={myPageStyles.content}>
        <h1 className={orderHistoryStyles.title}>MY ORDERS</h1>
        <hr className={myPageStyles.upper_hr} />
        <table className={orderHistoryStyles.order_table}>
          <thead>
            <tr>
              <td className={orderHistoryStyles.td_checkbox}>
                <input
                  type="checkbox"
                  onChange={handleCheckAll}
                  checked={checkedAll}
                />
              </td>
              <td>Product</td>
              <td>Quantity</td>
              <td>Cost</td>
              <td>Shipping Information</td>
            </tr>
          </thead>
          <tbody>
            {orderList.length ? (
              orderList.map((order, index) => (
                <React.Fragment key={index}>
                  <tr className={orderHistoryStyles.tr_date}>
                    <td className={orderHistoryStyles.td_date}>
                      <p>Date of purchase: {renderDate(order.createdAt)}</p>
                      <button
                        type="button"
                        onClick={() => handleGetOrder(order.id)}>
                        learn more
                      </button>
                    </td>
                  </tr>

                  <div key={order.id}>
                    {order.orderDetail.length === 0 ? (
                      <tr>
                        <td className={orderHistoryStyles.td_checkbox}>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, order.id)}
                            checked={!!listChecked.includes(order.id)}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {order.orderDetail.map((item, indx) => (
                      <tr key={indx} className={orderHistoryStyles.tr_product}>
                        <td className={orderHistoryStyles.td_checkbox}>
                          {indx === 0 && order.orderDetail.length > 0 ? (
                            <input
                              type="checkbox"
                              onChange={(e) => handleCheck(e, order.id)}
                              checked={!!listChecked.includes(order.id)}
                            />
                          ) : null}
                        </td>
                        <td
                          className={orderHistoryStyles.td_product}
                          style={{ justifySelf: 'start' }}>
                          {item?.ulftrafits?.image && (
                            <img
                              src={item.ulftrafits.image}
                              alt="product"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                navigate(`/shop/${item?.ulftrafits?.id}/info`)
                              }
                            />
                          )}
                          <div>
                            <h2
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                navigate(`/shop/${item?.ulftrafits?.id}/info`)
                              }>
                              {item?.ulftrafits?.name ?? ''}
                            </h2>
                            <p>
                              {item?.ulftrafits?.category1?.category}
                              {' > '}
                              {item?.ulftrafits?.category1?.category}
                              {' > '}
                              {item?.ulftrafits?.category1?.category}
                            </p>
                          </div>
                        </td>
                        <td>{item?.quantity ?? ''}</td>
                        {/* <td>$ {grandTotal(order)}</td> */}
                        <td>$ {item?.options?.amount ?? ''}</td>
                        <td className={orderHistoryStyles.td_shipping}>
                          {indx === 0 && (
                            <>
                              <p>{listStatusToEn[order.status]}</p>
                              {deliveryTracking.includes(order.status) &&
                                order?.shippingInfos?.information && (
                                  <a
                                    href={order.shippingInfos.information ?? ''}
                                    target="_blank"
                                    rel="noreferrer">
                                    Delivery tracking
                                  </a>
                                )}
                              {deliveryTracking.includes(order.status) && (
                                <div className={orderHistoryStyles.way_number}>
                                  {order.shippingInfos.trackingNumber && (
                                    <>
                                      <span>
                                        {order.shippingInfos.trackingNumber ??
                                          ''}
                                      </span>{' '}
                                      <img
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        src={iconCopy}
                                        alt=""
                                        onClick={() =>
                                          copyToClipboard(
                                            order.shippingInfos.trackingNumber
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td className={orderHistoryStyles.more_action}>
                          {order.status === 'delivery' && (
                            <>
                              <button
                                onClick={() =>
                                  handleOpenReview(
                                    item.productId,
                                    order.id,
                                    order?.reviews ?? []
                                  )
                                }
                                className={orderHistoryStyles.item_review_btn}
                                type="button"
                                style={
                                  order?.reviews?.length > 0
                                    ? {
                                        color: '#222',
                                        fontWeight: '600'
                                      }
                                    : { color: '#fd6908' }
                                }>
                                {order?.reviews?.length > 0 ? (
                                  <>Check your review</>
                                ) : (
                                  <>
                                    <img src={writeImg} alt="" />
                                    Write a review
                                  </>
                                )}
                              </button>
                              {indx === 0 && order?.reviews?.length === 0 && (
                                <button
                                  onClick={() => {
                                    setModalConfirmIsOpen(true);
                                    setOrderSelected(order);
                                  }}
                                  className={orderHistoryStyles.item_review_btn}
                                  type="button"
                                  style={{ color: '#0046a6' }}>
                                  <img src={returnImg} alt="" />
                                  Return
                                </button>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className={orderHistoryStyles.shop_empty_list}>
                <img src={attentionMark} alt="attention!" />
                <p>No order</p>
              </div>
            )}
          </tbody>
        </table>
        <button
          disabled={!orderList.length || isCancelling}
          className={orderHistoryStyles.order_cancel_btn}
          type="button"
          onClick={handleCancelOrder}>
          cancel selected orders
        </button>
      </section>
      {modalIsOpen && (
        <OrderModal setModalIsOpen={setModalIsOpen} data={orderDetail} />
      )}
      {modalReviewIsOpen && (
        <ReviewModal
          setModalIsOpen={setModalReviewIsOpen}
          handleRegisterReview={handleRegisterReview}
          review={myReview}
          setReview={setMyReview}
        />
      )}

      {modalConfirmIsOpen && (
        <ConfirmRefundModal
          setModalIsOpen={setModalConfirmIsOpen}
          // handleReturn={handleReturn}
          handleReturn={() => {
            setModalConfirmIsOpen(false);
            setRefundReasonModalIsOpen(true);
          }}
        />
      )}

      {refundReasonModalIsOpen && (
        <RefundReasonModal
          onRefund={handleReturn}
          reason={reason}
          setReason={setReason}
          onClose={() => {
            setRefundReasonModalIsOpen(false);
            setOrderSelected('');
            setReason('');
          }}
        />
      )}
    </main>
  );
}

export default withToast(OrderHistory);
