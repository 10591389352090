import { toast } from 'react-toastify';
import { TOAST_TYPE } from '../../utils/constants';

const toastSuccess = (content) =>
  toast.success(content, {
    position: toast.POSITION.TOP_RIGHT
  });

const toastError = (content) =>
  toast.error(content, {
    position: toast.POSITION.TOP_RIGHT
  });

const toastWarn = (content) =>
  toast.warn(content, {
    position: toast.POSITION.TOP_RIGHT
  });

const toastInfo = (content) =>
  toast.info(content, {
    position: toast.POSITION.TOP_RIGHT
  });

const toastDefault = (content) =>
  toast(content, {
    position: toast.POSITION.TOP_RIGHT
  });

function useToast() {
  function showToast(type, message) {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        toastSuccess(message);
        break;
      case TOAST_TYPE.ERROR:
        toastError(message);
        break;
      case TOAST_TYPE.WARN:
        toastWarn(message);
        break;
      case TOAST_TYPE.INFO:
        toastInfo(message);
        break;
      default:
        toastDefault(message);
    }
  }
  return showToast;
}

export default useToast;
