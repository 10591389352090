/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import logo from '../../img/logo.svg';
import emailIcon from '../../img/icon_email.svg';
import footerStyles from './Footer.module.scss';

function Footer() {
  const footerClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer id="footer" onClick={footerClick}>
      <div className={footerStyles.container}>
        <div className={footerStyles.upper}>
          <Link to="/" className={footerStyles.logo}>
            <img src={logo} alt="logo" />
          </Link>
          <nav className={footerStyles.nav}>
            <Link to="/support">Learn & Support</Link>
            <Link to="/pattern/list">Our Patterns</Link>
            <Link to="/contact_us">Contact Us</Link>
            <Link to="/shop">Ultrafit Shop</Link>
          </nav>
          <ul className={footerStyles.socials}>
            <li>
              <a
                href="https://www.instagram.com/ultrafit_protection/"
                className={footerStyles.socials_inst}
                target="_blank"
                aria-label="Instagram Link"
                rel="noreferrer"
              />
            </li>
            <li>
              <a
                href="https://www.youtube.com/@ultrafitprotection"
                className={footerStyles.socials_yt}
                target="_blank"
                aria-label="YouTube Link"
                rel="noreferrer"
              />
            </li>
            <li>
              <a
                href="https://www.facebook.com/Ultrafitprotection/"
                className={footerStyles.socials_fb}
                target="_blank"
                aria-label="FaceBook Link"
                rel="noreferrer"
              />
            </li>
          </ul>
        </div>
      </div>
      <hr className={footerStyles.footer_hr} />
      <div className={footerStyles.container}>
        <div className={footerStyles.lower}>
          <nav className={footerStyles.footer_terms_policy}>
            <Link to="/terms_and_policy/policy" style={{ width: '85px' }}>
              Privacy Policy
            </Link>
            <Link to="/terms_and_policy/terms" style={{ width: '135px' }}>
              Terms and Conditions
            </Link>
          </nav>
          <div className={footerStyles.footer_contacts_container}>
            {/* <div className={footerStyles.footer_contacts_container}> */}
            <small>
              #1005, The Liv Gold Tower, 400-12, Yangcheon-ro, Gangseo-gu,
              Seoul, 07573, Republic of Korea | Business Registration:
              438-8601057 | CEO: Haneum Cho
            </small>
            {/* </div> */}
            <small>©2021 INOGROW. All rights reserved.</small>
          </div>
          <p className={footerStyles.footer_tel}>TEL: +82 2-6925-1990</p>
          <div className={footerStyles.email}>
            <img src={emailIcon} alt="email" />
            <span>E-mail:</span>
            <a href="mailto:info@ultrafitprotection.com">admin@plofix.com</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
