import PatternCard from '../PatternCard/PatternCard';
import DropdownFilter from './DropdownFilter/DropdownFilter';

import patternListStyles from './PatternList.module.scss';

import attentionMark from '../../img/attention_icon.svg';

// import { sortPatternsBySponsored } from '../../utils/function';

function PatternList({
  patterns,
  setSelectedSideCategories,
  setAddSponsorModalState,
  isSponsorPage,
  isMyPatternPage,
  isUploaderPage,
  onStopSelling,
  uploaderId
}) {
  return (
    <div className={patternListStyles.pattern_container}>
      <DropdownFilter
        uploaderId={uploaderId}
        setSelectedSideCategories={setSelectedSideCategories}
        isMyPatternPage={isMyPatternPage}
      />
      <div className={patternListStyles.pattern_card}>
        {patterns.length ? (
          <div className={patternListStyles.cards_container}>
            {patterns.map((pattern, index) => (
              <PatternCard
                key={`${index}${pattern.patternId}`}
                card={pattern}
                setAddSponsorModalState={setAddSponsorModalState}
                isSponsorPage={isSponsorPage}
                isMyPatternPage={isMyPatternPage}
                isUploaderPage={isUploaderPage}
                uploaderId={uploaderId}
                onStopSelling={onStopSelling}
              />
            ))}
          </div>
        ) : (
          <div className={patternListStyles.empty_list}>
            <img src={attentionMark} alt="attention!" />
            <p>No uploaded patterns yet</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PatternList;
