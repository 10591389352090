/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { createContext, useCallback, useContext, useState } from 'react';
import modalStyles from './alert.module.scss';

const Context = createContext({});

function ModalAlert({
  title = '',
  content = [],
  buttonText = '',
  isVisible = false,
  hideAlert
}) {
  return (
    <>
      {isVisible ? (
        <div className={modalStyles.modal}>
          <div className={modalStyles.modal_container}>
            <h1>{title}</h1>
            <div className={modalStyles.content}>
              {content.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className={modalStyles.group_btn}>
              <button
                className={modalStyles.btn_confirm}
                onClick={() => hideAlert()}
                type="button">
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

function ModalConfirmAlert({
  title = '',
  content = [],
  action,
  buttonOKText = '',
  buttonCancelText = '',
  isVisible = false,
  hideAlert
}) {
  return (
    <>
      {isVisible ? (
        <div className={modalStyles.modal}>
          <div className={modalStyles.modal_container}>
            <h1>{title}</h1>
            <div className={modalStyles.content}>
              {content.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className={modalStyles.group_btn}>
              <button
                className={modalStyles.btn_confirm}
                onClick={() => {
                  action();
                  hideAlert();
                }}
                type="button">
                {buttonOKText}
              </button>
              <button
                className={modalStyles.btn_cancel}
                onClick={() => hideAlert()}
                type="button">
                {buttonCancelText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export function AlertProvider({ children }) {
  const [alertData, setAlertData] = useState({
    title: '',
    buttonText: '',
    isVisible: false,
    content: []
  });

  const [confirmAlertData, setConfirmAlertData] = useState({
    title: '',
    buttonOKText: '',
    buttonCancelText: '',
    action: () => {},
    isVisible: false,
    content: []
  });

  const onAlert = useCallback((title, buttonText = 'Okay', ...content) => {
    setAlertData({ title, content: [...content], buttonText, isVisible: true });
  }, []);

  const onConfirmAlert = useCallback(
    (
      title,
      action,
      buttonOKText = 'Okay',
      buttonCancelText = 'Close',
      ...content
    ) => {
      setConfirmAlertData({
        title,
        content: [...content],
        action,
        buttonOKText,
        buttonCancelText,
        isVisible: true
      });
    },
    []
  );

  const hideAlert = () => {
    setAlertData({ ...alertData, isVisible: false });
  };

  const hideConfirmAlert = () => {
    setConfirmAlertData({ ...confirmAlertData, isVisible: false });
  };

  return (
    <Context.Provider value={{ onAlert, onConfirmAlert }}>
      <ModalAlert {...alertData} hideAlert={hideAlert} />
      <ModalConfirmAlert {...confirmAlertData} hideAlert={hideConfirmAlert} />
      {children}
    </Context.Provider>
  );
}

export function useAlert() {
  return useContext(Context);
}
