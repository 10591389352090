/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useContext } from 'react';
import { Rating } from 'react-simple-star-rating';
import {
  getPatternRating,
  postPatternRating,
  updatePatternRating
} from '../../../api/patterns';
import modalStyles from './MyPageReviewModal.module.scss';
import { UserProfileContext } from '../../../store/Context';
import { useAlert } from '../../hooks/useAlert/useAlert';

function MyPageReviewModal({ setReviewIsOpen, pattern, setTimeRecall }) {
  const { userProfile } = useContext(UserProfileContext) || {};
  const [rating, setRating] = useState(0);

  const [myReview, setMyReview] = useState({});
  const [listReview, setListReview] = useState([]);
  const [comment, setComment] = useState('');

  const { onAlert } = useAlert();

  const fetchData = async () => {
    const patternRatingRes = await getPatternRating(pattern.id);

    setListReview(patternRatingRes.data);
    setMyReview(
      patternRatingRes.data.filter(
        (patternRating) => patternRating?.userId === userProfile?.id
      )[0]
    );
  };

  const reviewRating = useMemo(() => {
    const total = listReview.length;
    let sumRating = 0;
    let count1star = 0;
    let count2star = 0;
    let count3star = 0;
    let count4star = 0;
    let count5star = 0;

    listReview.forEach((item) => {
      if (item.star) {
        sumRating += Number(item.star);
        if (Number(item.star) > 0 && Number(item.star) <= 1) {
          count1star++;
        }
        if (Number(item.star) > 1 && Number(item.star) <= 2) {
          count2star++;
        }
        if (Number(item.star) > 2 && Number(item.star) <= 3) {
          count3star++;
        }
        if (Number(item.star) > 3 && Number(item.star) <= 4) {
          count4star++;
        }
        if (Number(item.star) > 4 && Number(item.star) <= 5) {
          count5star++;
        }
      }
    });

    return {
      total,
      avgRating: total > 0 ? sumRating / total : 0,
      count1star: total > 0 ? count1star / total : 0,
      count2star: total > 0 ? count2star / total : 0,
      count3star: total > 0 ? count3star / total : 0,
      count4star: total > 0 ? count4star / total : 0,
      count5star: total > 0 ? count5star / total : 0
    };
  }, [listReview]);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleAddReview = async () => {
    if (myReview && Object.keys(myReview).length) {
      try {
        await updatePatternRating(myReview.id, {
          patternId: pattern.id,
          review: comment,
          star: rating / 20
        });

        setTimeRecall((current) => current + 1);
        setReviewIsOpen(false);
        onAlert('', 'Okay', 'Review Posted!');
      } catch (err) {
        onAlert(
          '',
          'Okay',
          'Something went wrong posting review. Try again later'
        );
      }
      return;
    }

    console.log(comment);
    try {
      await postPatternRating({
        patternId: pattern.id,
        review: comment,
        star: rating / 20
      });

      setTimeRecall((current) => current + 1);

      setReviewIsOpen(false);
      onAlert('', 'Okay', 'Review Posted!');
    } catch (err) {
      onAlert(
        '',
        'Okay',
        'Something went wrong posting review. Try again later'
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (myReview) {
      setComment(myReview?.review || '');
      setRating(myReview?.star ? myReview.star * 20 : 0);
    }
  }, [myReview]);

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modal_content}>
        <h1 className={modalStyles.modal_head}>Write a Review</h1>
        <div className={modalStyles.modal_body}>
          <div className={modalStyles.modal_rating}>
            <div className={modalStyles.rating_stars}>
              <p>Customer rating</p>
              <span className={modalStyles.rating_value}>
                {reviewRating.avgRating.toPrecision(2)}
              </span>
              <Rating
                ratingValue={reviewRating.avgRating * 20}
                readonly
                size={30}
                emptyColor="#ededed"
                fillColor="#ffd500"
              />
              <span className={modalStyles.rating_count}>
                ({reviewRating.total} Customer reviews)
              </span>
            </div>
            <div className={modalStyles.rating_chart}>
              <p>Customer rating</p>
              <div className={modalStyles.chart_container}>
                <div className={modalStyles.chart_item}>
                  <div className={modalStyles.item_line}>
                    <span
                      style={{ height: `${reviewRating.count5star * 100}%` }}>
                      {'  '}
                    </span>
                  </div>
                  <span className={modalStyles.item_rating_value}>5</span>
                </div>
                <div className={modalStyles.chart_item}>
                  <div className={modalStyles.item_line}>
                    <span
                      style={{ height: `${reviewRating.count4star * 100}%` }}>
                      {'  '}
                    </span>
                  </div>
                  <span className={modalStyles.item_rating_value}>4</span>
                </div>
                <div className={modalStyles.chart_item}>
                  <div className={modalStyles.item_line}>
                    <span
                      style={{ height: `${reviewRating.count3star * 100}%` }}>
                      {'  '}
                    </span>
                  </div>
                  <span className={modalStyles.item_rating_value}>3</span>
                </div>
                <div className={modalStyles.chart_item}>
                  <div className={modalStyles.item_line}>
                    <span
                      style={{ height: `${reviewRating.count2star * 100}%` }}>
                      {'  '}
                    </span>
                  </div>
                  <span className={modalStyles.item_rating_value}>2</span>
                </div>
                <div className={modalStyles.chart_item}>
                  <div className={modalStyles.item_line}>
                    <span
                      style={{ height: `${reviewRating.count1star * 100}%` }}>
                      {'  '}
                    </span>
                  </div>
                  <span className={modalStyles.item_rating_value}>1</span>
                </div>
              </div>
            </div>
          </div>
          <div className={modalStyles.customer_rating}>
            <p>My review</p>
            <Rating
              size={30}
              emptyColor="#ededed"
              fillColor="#ffd500"
              onClick={handleRating}
              initialValue={rating / 20}
            />
          </div>
          <label className={modalStyles.modal_comment} htmlFor="comment">
            <p>Comment</p>
            <textarea
              name="comment"
              placeholder="Type here."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              cols="30"
              rows="10"
            />
          </label>
          <div className={modalStyles.modal_btns}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setReviewIsOpen(false);
              }}
              className={modalStyles.btn_cancel}
              type="button">
              Cancel
            </button>
            <button
              className={modalStyles.btn_register}
              type="button"
              onClick={handleAddReview}>
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPageReviewModal;
