/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import myPatternStyles from './MyPattern.module.scss';
import SelectorFilter from '../../../../SelectorFilter/SelectorFilter';
import PatternList from '../../../../PatternList/PatternList';

import { getMyPatterns } from '../../../../../api/patterns';
import { getCategories } from '../../../../../api/categories';
import MyPatternProfile from './MyPatternProfile/MyPatternProfile';
import Pagination from '../../../../Pagination/Pagination';
import StopSellingModal from './StopSellingModal/StopSellingModal';

import addPattern from '../../../../../img/icon_add_pattern.svg';
import arrow from '../../../../../img/dropdown_arrow.svg';

import {
  LoginStateContext,
  UserProfileContext
} from '../../../../../store/Context';

const mapOrderBy = {
  Newest: 'newest',
  'Best rated': 'best_rated',
  'Most downloaded': 'most_downloaded'
};

function MyPattern({ onProfileUpdate }) {
  const loginState = useContext(LoginStateContext);
  const { userProfile } = useContext(UserProfileContext) || {};

  const patternsOnPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  // state for "newest, best rated, most downloads" select filter
  const [sortIsOpened, setSortIsOpened] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Newest');

  const [stopSellingModalIsOpen, setStopSellingModalIsOpen] = useState(false);
  const [stopSellingReason, setStopSellingReason] = useState('');

  const [partSectionCategories, setPartSectionCategories] = useState([]);
  const [patternDepthCategories, setPatternDepthCategories] = useState([
    {
      checked: false,
      text: 'bikini'
    },
    {
      checked: false,
      text: 'wrapped'
    },
    {
      checked: false,
      text: 'sensors'
    }
  ]);

  const [patterns, setPatterns] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState('');
  const [selectedSideCategoryIds, setSelectedSideCategoryIds] = useState('');
  const [selectedDepthCategoryIds, setSelectedDepthCategoryIds] = useState('');

  const preSelectedSideCategoryIds = useRef();

  const preSelectedMainCategoryId = useRef();

  const fetchPatterns = async ({
    modelIds = '',
    vehicleIds = '',
    orderBy = ''
  }) => {
    let page = currentPage;
    if (vehicleIds && vehicleIds !== preSelectedSideCategoryIds.current) {
      setCurrentPage(1);
      page = 1;
    }

    if (modelIds && modelIds !== preSelectedMainCategoryId.current) {
      setCurrentPage(1);
      page = 1;
    }

    const patternsResponse = await getMyPatterns({
      page,
      limit: patternsOnPage,
      modelIds,
      vehicleIds,
      orderBy: mapOrderBy[orderBy]
    });
    preSelectedSideCategoryIds.current = vehicleIds;
    preSelectedMainCategoryId.current = modelIds;

    setTotalCount(patternsResponse.count);
    setPatterns(patternsResponse.data);
  };

  // onClick handler for "newest, best rated, most downloads" select filter
  const handleSelectClick = () => {
    setSortIsOpened(!sortIsOpened);
  };

  // onChange handler for "newest, best rated, most downloads" select filter
  const handleSelectChange = (e) => {
    setSelectedSort(e.target.value);

    setSortIsOpened(false);
  };
  const searchDepthCategoriesIds = (node, parentIds, categoryName, ids) => {
    if (!node?.subCategory.length) {
      if (
        node.category === categoryName.toLowerCase() &&
        parentIds.split(',').filter((item) => +item === node.parentId).length
      ) {
        ids.push(node.id);
      }
    } else {
      for (const childNode of node.subCategory) {
        searchDepthCategoriesIds(childNode, parentIds, categoryName, ids);
      }
    }
  };

  const onStopSellingClick = (reason) => {
    setStopSellingReason(reason);
    setStopSellingModalIsOpen(true);
  };

  const fetchDepthCategories = async () => {
    const depthCategoriesRes = await getCategories();

    setPartSectionCategories(
      depthCategoriesRes.filter(
        (category) =>
          category?.column === 'part_section' && category?.type === 'category'
      )
    );
  };

  useEffect(() => {
    fetchDepthCategories();
  }, []);

  useEffect(() => {
    if (partSectionCategories.length) {
      setPatternDepthCategories((prevState) => [
        {
          checked: prevState[0]?.checked,
          text: 'Bikini',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Bikini',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[1]?.checked,
          text: 'Wrapped',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Wrapped',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        },
        {
          checked: prevState[2]?.checked,
          text: 'Sensors',
          ids: partSectionCategories.reduce((ids, category) => {
            searchDepthCategoriesIds(
              category,
              selectedSideCategoryIds,
              'Sensors',
              ids
            );

            if (ids.length === 0) {
              return [0];
            }

            return ids;
          }, [])
        }
      ]);
    }
  }, [partSectionCategories, selectedSideCategoryIds]);

  useEffect(() => {
    const selectedDepthCategories = patternDepthCategories
      .reduce((ids, depthCategory) => {
        if (depthCategory?.checked) {
          ids.push(depthCategory.ids);
        }

        return ids;
      }, [])
      .join(',');

    setSelectedDepthCategoryIds(selectedDepthCategories);
  }, [patternDepthCategories]);

  useEffect(() => {
    fetchPatterns({
      modelIds: selectedMainCategoryId,
      vehicleIds: selectedDepthCategoryIds || selectedSideCategoryIds,
      orderBy: selectedSort
    });
  }, [
    loginState,
    currentPage,
    selectedMainCategoryId,
    selectedSideCategoryIds,
    selectedDepthCategoryIds
  ]);

  useEffect(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    } else {
      fetchPatterns({
        modelIds: selectedMainCategoryId,
        vehicleIds: selectedDepthCategoryIds || selectedSideCategoryIds,
        orderBy: selectedSort
      });
    }
  }, [selectedSort]);

  return (
    <div
      className={myPatternStyles.mypattern_container}
      style={{ marginTop: '60px' }}>
      <MyPatternProfile
        profile={userProfile}
        onProfileUpdate={onProfileUpdate}
      />
      <div className={myPatternStyles.selector_container}>
        <SelectorFilter
          setSelectedCategory={setSelectedMainCategoryId}
          isMyPatternsPage
        />
      </div>
      <div className={myPatternStyles.title}>
        <h2>my pattern list ({totalCount})</h2>
        <div className={myPatternStyles.pattern_categories}>
          {selectedSideCategoryIds?.length
            ? patternDepthCategories.map((category, index) => (
                <label key={index} htmlFor={category.text}>
                  <input
                    type="checkbox"
                    id={category.text}
                    checked={category.checked}
                    onChange={() =>
                      setPatternDepthCategories((prevState) =>
                        prevState.map((prevCategory) => {
                          if (prevCategory.text === category.text) {
                            return {
                              ...prevCategory,
                              checked: !prevCategory.checked
                            };
                          }

                          return prevCategory;
                        })
                      )
                    }
                  />
                  <span>{category.text}</span>
                </label>
              ))
            : null}
        </div>
        <Link to="/sell/upload">
          <img src={addPattern} alt="upload" />
          PATTERN UPLOAD
        </Link>
        <label
          htmlFor="select_condition"
          className={myPatternStyles.pattern_dd_filter}>
          <button onClick={handleSelectClick} type="button">
            {selectedSort}
            <img
              src={arrow}
              alt="arrow"
              style={{ transform: sortIsOpened ? 'rotate(180deg)' : '' }}
            />
          </button>
          {sortIsOpened && (
            <select
              onChange={handleSelectChange}
              multiple
              name="select_condition">
              <option value="Newest">Newest</option>
              <option value="Best rated">Best rated</option>
              <option value="Most downloaded">Most downloaded</option>
            </select>
          )}
        </label>
      </div>
      <div className={myPatternStyles.mypatterns_container}>
        <PatternList
          uploaderId={userProfile?.id}
          patterns={patterns}
          setSelectedSideCategories={setSelectedSideCategoryIds}
          onStopSelling={(reason) => onStopSellingClick(reason)}
          isMyPatternPage
        />
      </div>
      {totalCount > patternsOnPage && (
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={patternsOnPage}
          onPageChange={setCurrentPage}
        />
      )}
      {stopSellingModalIsOpen && (
        <StopSellingModal
          onClose={() => setStopSellingModalIsOpen(false)}
          reason={stopSellingReason}
        />
      )}
    </div>
  );
}

export default MyPattern;
