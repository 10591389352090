import Calendar from 'react-calendar';
import './PlofixCalendar.scss';

function PlofixCalendar({ onChange, value, minDate = '' }) {
  return (
    <Calendar
      value={value}
      onChange={onChange}
      calendarType="gregory"
      minDate={minDate}
      formatShortWeekday={(locale, date) =>
        ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
      }
      locale="en"
    />
  );
}

export default PlofixCalendar;
