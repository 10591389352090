/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import apiUrl from './apiUrl';
import customFetch from './customFetch';

export const registerNewCar = (data) =>
  customFetch(`${apiUrl}/categories/user`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const createPattern = (data) =>
  customFetch(`${apiUrl}/patterns`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const editMyPattern = (patternId, editData) =>
  customFetch(`${apiUrl}/patterns/${patternId}`, {
    method: 'PUT',
    body: JSON.stringify(editData),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const getPatterns = ({
  page = 1,
  limit = 9,
  uploaderId = '',
  bookMarked = false,
  vehicleIds = '',
  modelIds = '',
  from = '',
  to = '',
  orderBy = '',
  tag = ''
}) =>
  customFetch(
    `${apiUrl}/patterns?${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }${uploaderId ? `&uploaderId=${uploaderId}` : ''}${
      bookMarked ? `&bookMarked=${bookMarked}` : ''
    }${vehicleIds ? `&vehicleIds=${vehicleIds}` : ''}${
      modelIds ? `&modelIds=${modelIds}` : ''
    }${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}${
      orderBy ? `&orderBy=${orderBy}` : ''
    }${tag ? `&tag=${tag}` : ''}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getRecentPatterns = (modelIds) =>
  customFetch(
    `${apiUrl}/patterns/recent?${modelIds ? `&modelIds=${modelIds}` : ''}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getMyPatterns = ({
  vehicleIds = '',
  modelIds = '',
  orderBy = '',
  page = 1,
  limit = ''
}) =>
  customFetch(
    `${apiUrl}/patterns/my?${vehicleIds ? `&vehicleIds=${vehicleIds}` : ''}${
      modelIds ? `&modelIds=${modelIds}` : ''
    }${orderBy ? `&orderBy=${orderBy}` : ''}${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }`
  ).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response;
  });

export const getMyPatternsApproveStatus = ({
  vehicleIds = '',
  modelIds = '',
  page = 1,
  limit = '',
  status = ''
}) =>
  customFetch(
    `${apiUrl}/patterns/approval-status?${
      vehicleIds ? `&vehicleIds=${vehicleIds}` : ''
    }${modelIds ? `&modelIds=${modelIds}` : ''}${page ? `&page=${page}` : ''}${
      limit ? `&limit=${limit}` : ''
    }${status ? `&status=${status}` : ''}`
  ).then(async (res) => {
    const response = await res.json();

    if (response.errorStatus) {
      throw new Error(response.message);
    }

    return response;
  });

export const getPatternsApprovalStatus = ({ status, page, limit }) =>
  customFetch(
    `${apiUrl}/patterns/approval-status?${status ? `&status=${status}` : ''}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getPatternHistory = ({
  from = '',
  to = '',
  page = 1,
  limit = 9
}) =>
  customFetch(
    `${apiUrl}/patterns/history?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`
  )
    .then(async (res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const getPatternById = (patternId) =>
  customFetch(`${apiUrl}/patterns/${patternId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data.data;
    });

export const addToFavorites = (patternId) =>
  customFetch(`${apiUrl}/bookmarks`, {
    method: 'POST',
    body: JSON.stringify({ patternId }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());

export const getMyProjectPatterns = ({ from, to, page = 1, limit = 9 }) =>
  customFetch(
    `${apiUrl}/patterns/project?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const getPatternByProjectId = ({ id, from, to }) =>
  customFetch(
    `${apiUrl}/patterns/project/${id}?${from ? `&from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        return data;
      }

      return data.data;
    });

export const deleteMyProjectPattern = ({ projectId, projectPatternIds }) =>
  customFetch(`${apiUrl}/patterns/project/delete`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ projectId, projectPatternIds })
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        alert('Something went wrong deleting the pattern.');
        throw new Error(data.message);
      }
    });

export const deleteMyProjectById = ({ projectId }) =>
  customFetch(`${apiUrl}/patterns/project/${projectId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        alert('Something went wrong deleting project');
        throw new Error(data.message);
      }
    });

export const getPatternRating = (patternId, userId) =>
  customFetch(
    `${apiUrl}/ratings?${patternId ? `&patternId=${patternId}` : ''}${
      userId ? `&userId=${userId}` : ''
    }`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const postPatternRating = ({ patternId, review, star }) =>
  customFetch(`${apiUrl}/ratings`, {
    method: 'POST',
    body: JSON.stringify({ patternId, review, star }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });

export const updatePatternRating = (ratingId, { patternId, review, star }) =>
  customFetch(`${apiUrl}/ratings/${ratingId}`, {
    method: 'PUT',
    body: JSON.stringify({ patternId, review, star }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.errorStatus) {
        throw new Error(data.message);
      }

      return data;
    });
