/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, NavLink, Outlet, useNavigate } from 'react-router-dom';

import { addToCart, getUltrafitById } from '../../../api/ultrafitShop';

import { useStore } from '../../../store/hooks';
import { getCartItems, incCartItems } from '../../../store/actions/cart';

import { LoginStateContext } from '../../../store/Context';
import withToast from '../../HOC/withToast';

import arrowImg from '../../../img/dropdown_arrow.svg';
import productStyles from './ShopProductDetails.module.scss';
import { executeAsync } from '../../../utils/common';
import { TOAST_TYPE } from '../../../utils/constants';

function ShopProductDetails({ showToast }) {
  const { productId } = useParams();

  const loginState = useContext(LoginStateContext);
  const [state, dispatch] = useStore();
  const isProcess = useRef(false);

  const navigate = useNavigate();

  const [optionsIsOpen, setOptionsIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    inches: ''
  });
  const [price, setPrice] = useState(0);

  const [product, setProduct] = useState({});

  const fetchData = async () => {
    const untrafitRes = await getUltrafitById(productId);
    // let productPrice = untrafitRes?.price ? Number(untrafitRes?.price) : 0;
    let productPrice = 0;
    setSelectedOption({ inches: untrafitRes?.options?.[0].inches ?? '' });

    productPrice += untrafitRes?.options?.[0]?.amount ?? 0;
    setProduct(untrafitRes ?? {});
    setPrice(productPrice);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleOptionChange = (e) => {
    if (product?.options) {
      const optionSelected = product.options.find(
        (item) => item.inches === e.target.value
      );
      setPrice(Number(product.price) + Number(optionSelected.amount));
      setSelectedOption({ inches: e.target.value });
    }
    setOptionsIsOpen(!optionsIsOpen);
  };

  const handleBuy = () => {
    if (!selectedOption.inches) {
      alert('Please select one option of product');
    }
    if (loginState?.isLoggedIn) {
      const link = selectedOption.inches
        ? `/shop/order?productId=${productId}&optionInch=${selectedOption.inches}`
        : `/shop/order?productId=${productId}`;
      navigate(`${link}`);
    } else {
      const goToLogin = confirm('You must log in to use');

      if (goToLogin) {
        navigate('/auth');
      }
    }
  };

  const handleAddCart = async () => {
    if (!loginState?.isLoggedIn) {
      const goToLogin = confirm('You must log in to use');

      if (goToLogin) {
        navigate('/auth');
      }

      return;
    }

    const [res, err] = await executeAsync(() =>
      addToCart({
        productId: +productId,
        quantity: 1,
        price: product.price,
        inches: selectedOption.inches
      })
    );
    if (err) {
      showToast(
        TOAST_TYPE.ERROR,
        err?.message ?? 'Cannot add to cart, please try later!'
      );
      isProcess.current = false;
      return;
    }
    const { data } = res || {};
    const { orderDetail } = data || [];
    let cartsLength = 0;
    if (orderDetail?.length > 0) {
      cartsLength = orderDetail?.reduce(
        (prevVal, currVal) => prevVal + currVal.quantity,
        0
      );
    }
    // dispatch(
    //   getCartItems({
    //     length: cartsLength,
    //     listCart: orderDetail
    //   })
    // );
    dispatch(incCartItems(cartsLength));
    isProcess.current = false;
  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  return (
    <>
      <section className={productStyles.product_section}>
        <div className={productStyles.product_container}>
          <img
            className={productStyles.product_img}
            src={product?.image}
            alt="product"
          />
          <div className={productStyles.product_info}>
            <div className={productStyles.product_content}>
              <h2>{product?.name}</h2>
              <p>{product?.category1?.category}</p>
              {product?.options && (
                <label htmlFor="option">
                  <p>Option</p>
                  <button
                    onClick={() => setOptionsIsOpen(!optionsIsOpen)}
                    type="button">
                    {selectedOption?.inches}
                    <img
                      src={arrowImg}
                      alt="arrow"
                      style={
                        optionsIsOpen
                          ? { transform: 'rotate(180deg)' }
                          : { transform: 'rotate(0deg)' }
                      }
                    />
                  </button>
                  {optionsIsOpen && (
                    <select
                      style={
                        product?.options?.length > 4
                          ? {
                              height: `${(40 + 17) * 3}px`,
                              overflowY: 'scroll'
                            }
                          : {
                              height: `${
                                17 * (product.options.length || 1) +
                                ((product.options.length || 1) - 1) * 20 +
                                40
                              }px`,
                              overflowY: 'hidden'
                            }
                      }
                      onChange={handleOptionChange}
                      name="option"
                      multiple>
                      {product?.options && product?.options.length
                        ? product?.options.map((item, index) => (
                            <option key={index} value={item.inches}>
                              {item.inches}
                            </option>
                          ))
                        : null}
                    </select>
                  )}
                </label>
              )}
            </div>
            <hr />
            <p className={productStyles.product_price}>$ {price}</p>
            <hr />
            <div className={productStyles.product_btns}>
              <button
                className={productStyles.btn_add_cart}
                type="button"
                onClick={handleAddCart}>
                Add to cart
              </button>
              <button
                className={productStyles.btn_buy}
                type="button"
                onClick={handleBuy}>
                buy
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        className={productStyles.product_section}
        style={{ marginBottom: '0' }}>
        <nav className={productStyles.product_nav}>
          <NavLink
            className={productStyles.nav_info}
            to={`/shop/${productId}/info`}
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Information
          </NavLink>
          <NavLink
            className={productStyles.nav_reviews}
            to={`/shop/${productId}/reviews`}
            style={({ isActive }) => ({
              color: isActive ? '#fff' : '#808080',
              background: isActive ? '#0046a6' : '#fff'
            })}>
            Reviews
          </NavLink>
        </nav>
        <Outlet />
      </section>
    </>
  );
}

export default withToast(ShopProductDetails);
